import * as React from 'react';
import OrderList from '@/components/Orders';

import Styles from './index.module.less';

function Orders() {
  return (
    <div className={Styles.container}>
      <OrderList />
    </div>
  );
}

export default Orders;
