import { useEffect, useState, useRef } from 'react';
import { Button, LoadMore } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getRobotDetail,
  getRobotDocList,
  changePublishType,
  toggleDisplay,
  removeDocs,
} from '@/api/robot';
import { getTags, createNewDoc } from '@/api/myDoc';
import { DocSearchQuery, DocItem, RobotItem, ReleaseType } from '@/types/robot';
import { TagItem, Ordering } from '@/types/myDoc';

import DetailTableHead from './DetailTableHead';
import DetailTableItem from './DetailTableItem';
import ConfirmMoal from '@/components/Confirm';
import RobotSetting from '../Robot/RobotList/RobotSetting';
import { goToDoc } from '@/utils/doc';
import style from './RobotDetail.module.less';
import EmptyList from '@/components/EmptyList';
import RobotDocsDialog from '@/components/Robot/RobotDocsDialog';

export type Tag = Pick<TagItem, 'name' | 'id'>;

export default function RobotDetail() {
  const navigate = useNavigate();
  const { id: robotId } = useParams();
  const [detailInfo, setDetailInfo] = useState<RobotItem>();
  /** 列表及加载信息 */
  const [selectedIdList, setSelectedIdList] = useState<string[]>([]);
  const [list, setList] = useState<DocItem[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [docDialog, setDocDialog] = useState(false);
  /** tag信息 */
  const [tagList, setTagList] = useState<Tag[]>([]);
  const allTagInfo = useRef({
    name: '全部标签',
    id: '',
  });
  /** 查询条件 */
  const [selectedTag, setSelectedTag] = useState<Tag>(allTagInfo.current);
  const [ordering, setOrdering] = useState<Ordering>(Ordering.descending);
  const [status, setStatus] = useState<null | boolean>(null);

  const listQuery = useRef<DocSearchQuery>({
    sid: '',
    pageSize: 10,
  });

  const [settingVisible, setSettingVisible] = useState<boolean>(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const removeId = useRef('');

  const getListParmas = (): DocSearchQuery => {
    const params = {
      ...listQuery.current,
      orderBy: ordering,
    };

    if (selectedTag.id) {
      params.labelId = selectedTag.id;
    }

    if (status !== null) {
      params.isShow = status;
    }

    return params;
  };

  /** 获取详情信息 */
  const getDetailInfo = async () => {
    const { data } = await getRobotDetail(robotId || '');
    setDetailInfo(data);
  };

  /** 获取列表 */
  const getList = async () => {
    try {
      setLoading(true);
      const params = getListParmas();
      const { data } = await getRobotDocList(robotId || '', params);

      listQuery.current = {
        ...listQuery.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (err) {
      console.error('获取机器人列表失败', err);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    listQuery.current = {
      ...listQuery.current,
      sid: '',
    };
    const data = await getList();
    data && setList(data.results);
  };

  /** 获取tags */
  const getTagList = async () => {
    const { data } = await getTags();
    setTagList(data.results);
  };

  /** 通过tag获取列表 */
  const switchTag = (tag: Tag) => {
    setSelectedTag(tag);
  };

  useEffect(() => {
    getDetailInfo();
    getTagList();
  }, []);

  useEffect(() => {
    initList();
  }, [selectedTag, ordering, status]);

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getList();
    data && setList([...list, ...data.results]);
  };

  /** 全选 */
  const selectAll = (isSelect: boolean) => {
    if (!isSelect) {
      setSelectedIdList([]);
      return;
    }
    setSelectedIdList(list.map(({ id }) => id));
  };

  /** 单选 */
  const selectSingle = (id: string, isSelect: boolean) => {
    if (isSelect) {
      setSelectedIdList([...selectedIdList, id]);
      return;
    }
    setSelectedIdList(selectedIdList.filter(item => item !== id));
  };

  /** 排序 */
  const onSort = () => {
    const order =
      ordering === Ordering.ascending
        ? Ordering.descending
        : Ordering.ascending;
    setOrdering(order);
  };

  /** 去我的机器人页面 */
  const goMyRobot = () => {
    navigate('/doc/robot');
  };

  /** 即时发布 */
  const onPublish = async (releaseType: ReleaseType, id: string) => {
    await changePublishType(robotId || '', [id], releaseType);
    initList();
  };

  /** 上架/下架 */
  const onDisplay = async (isShow: boolean, id: string) => {
    await toggleDisplay(robotId || '', [id], isShow);
    initList();
  };

  /** 批量上架/下架 */
  const onBatchDisplay = async (list: string[], isShow: boolean) => {
    let ids: string[] = [];
    if (list.length > 10) {
      ids = list.splice(0, 10);
      await toggleDisplay(robotId || '', ids, isShow);
      return onBatchDisplay(list, isShow);
    }
    await toggleDisplay(robotId || '', list, isShow);
    return Promise.resolve();
  };

  const batchDisplayHandle = async (isShow: boolean) => {
    await onBatchDisplay([...selectedIdList], isShow);
    initList();
    setSelectedIdList([]);
  };

  const remove = (id: string) => {
    removeId.current = id;
    setRemoveModalVisible(true);
  };

  /** 分批次删除 */
  const onBatchRemove = async (list: string[]) => {
    let ids: string[] = [];
    if (list.length > 10) {
      ids = list.splice(0, 10);
      await removeDocs(robotId || '', ids);
      return onBatchRemove(list);
    }
    await removeDocs(robotId || '', list);
    return Promise.resolve();
  };

  /** 删除 */
  const onRemove = async () => {
    if (selectedIdList.length > 0) {
      await onBatchRemove([...selectedIdList]);
    } else {
      if (!removeId.current) return;
      await removeDocs(robotId || '', [removeId.current]);
    }
    initList();
    setSelectedIdList([]);
    setRemoveModalVisible(false);
  };

  /** 创建文档 */
  const createDoc = async () => {
    try {
      const { data } = await createNewDoc({
        title: '',
        subscribeStoreId: robotId || '',
      });
      initList();
      goToDoc(`/?id=${data.documentId}`);
    } catch (error) {
      console.error('创建文档失败：', error);
    }
  };

  /** 编辑成功 */
  const handleSettingOk = () => {
    setSettingVisible(false);
    initList();
    getDetailInfo();
  };

  return (
    <div className={style['robot-detail-container']}>
      <div className={style.header}>
        <div className={style.title}>
          <span onClick={goMyRobot} className={style['title-path']}>
            我的机器人
          </span>
          <i
            className={cs(
              'iconfont icon-a-common_icon_arrow_next2x',
              style['arrow-icon'],
              style['title-path']
            )}
          />
          {detailInfo?.name}
        </div>

        {selectedIdList.length === 0 && (
          <div>
            <Button
              onClick={() => setSettingVisible(true)}
              className={style.btn}
            >
              <i
                className={cs(
                  'iconfont icon-a-tab_icon_set_def2x',
                  style['btn-icon']
                )}
              />
              机器人设置
            </Button>

            <Button onClick={createDoc} className={style.btn}>
              <i
                className={cs(
                  'iconfont icon-a-robot_icon_doc2x',
                  style['btn-icon']
                )}
              />
              新建文档
            </Button>

            <Button
              className={style.btn}
              onClick={() => {
                setDocDialog(true);
              }}
            >
              <i
                className={cs(
                  'iconfont icon-a-robot_icon_add2x',
                  style['btn-icon']
                )}
              />
              添加文档
            </Button>
          </div>
        )}

        {selectedIdList.length > 0 && (
          <div>
            <Button onClick={() => setSelectedIdList([])} className={style.btn}>
              取消
            </Button>
            <Button
              onClick={() => batchDisplayHandle(true)}
              className={style.btn}
            >
              上架
            </Button>
            <Button
              onClick={() => batchDisplayHandle(false)}
              className={style.btn}
            >
              下架
            </Button>
            <Button
              onClick={() => setRemoveModalVisible(true)}
              className={style.btn}
            >
              删除
            </Button>
          </div>
        )}
      </div>
      <div className={style['detail-table']}>
        <DetailTableHead
          changeStatus={setStatus}
          onSort={onSort}
          selectedIdList={selectedIdList}
          isSelectedAll={selectedIdList.length === list.length}
          selectAll={selectAll}
          selectedTag={selectedTag}
          tagList={tagList}
          allTagInfo={allTagInfo.current}
          switchTag={switchTag}
        />
        {!list.length && !loading ? (
          <EmptyList text="暂时没有文档" style={{ marginTop: 250 }} />
        ) : (
          <div className={style['table-body']}>
            <LoadMore
              onReachBottom={loadMore}
              className={style['load-more']}
              loading={loading}
              hasMore={hasMore}
              finishedText={null}
            >
              {list.map(item => (
                <DetailTableItem
                  onRemove={remove}
                  onPublish={onPublish}
                  onDisplay={onDisplay}
                  selectedIdList={selectedIdList}
                  selectSingle={selectSingle}
                  key={item.id}
                  data={item}
                />
              ))}
            </LoadMore>
          </div>
        )}
      </div>
      <ConfirmMoal
        visible={removeModalVisible}
        title="注意"
        content="你确定要删除文档吗？"
        onCancel={() => {
          setRemoveModalVisible(false);
        }}
        onOk={onRemove}
      />

      <RobotSetting
        visible={settingVisible}
        onCancel={() => setSettingVisible(false)}
        robotInfo={detailInfo}
        onOk={handleSettingOk}
      />

      <RobotDocsDialog
        tagList={tagList}
        allTagInfo={allTagInfo.current}
        visible={docDialog}
        modalOpenCloseFN={() => {
          setDocDialog(false);
        }}
        updateList={() => {
          initList();
        }}
      />
    </div>
  );
}
