export const SocketEvent = {
  /** 直播关闭 */
  LIVE_CLOSE: 'live.close',
  /** 直播状态改变 */
  LIVE_STATUS_CHANGE: 'live.status.change',
  /** 直播录制时间更新 */
  LIVE_RECORD_UPDATE: 'live.record.update',
  /* 复制文档 */
  DOCUMENT_COPY_RESULT: 'document.copy.result',

  /** 会员升级成功 */
  TRANSLATE_MEMBER: 'user.translate.shanjian.member',
  /** 账号过期事件 */
  LOGIN_TOKEN_EXPIRED: 'loginTokenExpired',
};
