export enum OrderStatusEnum {
  Completed = 'completed',
  Paid = 'paid',
  Pending = 'pending',
  Refunding = 'refunding',
  Refunded = 'refunded',
  Cancelled = 'cancelled',
}

export const OrderStatusMap = {
  [OrderStatusEnum.Completed]: '已完成',
  [OrderStatusEnum.Paid]: '已付款',
  [OrderStatusEnum.Pending]: '待支付',
  [OrderStatusEnum.Refunding]: '退款中',
  [OrderStatusEnum.Refunded]: '已退款',
  [OrderStatusEnum.Cancelled]: '已取消',
};
