import { Dialog, Form, Input } from '@bhb-frontend/lithe-ui';
import { useEffect } from 'react';
import Select from '@/components/Select';
import style from './style.module.less';
import TemplateSelect from '@/components/TemplateSelect';
import { RobotItem } from '@/types/robot';
import { addRobot, editRobot } from '@/api/robot';

interface RobotSettingProps {
  visible: boolean;
  onCancel: () => void;
  robotInfo?: RobotItem;
  onOk: () => void;
}
const options = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
];

export default function RobotSetting(props: RobotSettingProps) {
  const { visible, onCancel, robotInfo, onOk } = props;

  const [form] = Form.useForm();

  const isCreate = !robotInfo?.id;

  const init = () => {
    if (isCreate) return;
    const { name, introThemes, dailyContentCount } = robotInfo;
    form.setFieldsValue({
      name,
      introThemes,
      dailyContentCount,
    });
  };

  const handleSub = async () => {
    const res = await form.validateFields();
    const { introThemes = [] } = res;
    const param = {
      ...robotInfo,
      ...res,
      introThemeIds: introThemes.map(item => item.id),
    };
    if (param.id) {
      await editRobot(param.id, param);
    } else {
      await addRobot(param);
    }

    onOk();
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      return;
    }
    init();
  }, [visible]);

  return (
    <Dialog
      visible={visible}
      title={isCreate ? '创建机器人' : '机器人设置'}
      className={style.modale}
      height={450}
      onOk={handleSub}
      onCancel={onCancel}
      timeout={0}
    >
      <Form form={form}>
        <Form.Item
          name="name"
          label="名称"
          rules={[
            {
              required: true,
              message: '名称必填',
            },
            {
              max: 15,
              message: '名称最多15个字符',
            },
          ]}
        >
          <Input placeholder="请输入机器人名称，字数不超过15个字符" />
        </Form.Item>
        <Form.Item
          name="dailyContentCount"
          label="每天推送视频数"
          rules={[
            {
              required: true,
              message: '推送数量必填',
            },
          ]}
        >
          <Select options={options} />
        </Form.Item>

        <Form.Item name="introThemes" label="推荐模板">
          <TemplateSelect />
        </Form.Item>
      </Form>
    </Dialog>
  );
}
