import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import debounce from '@bhb-frontend/utils/lib/debounce';

interface PublishProps {
  maxLength: number;
  onChange: (val: any) => void;
  defaultValue?: string;
}

function PublishBrief(props: PublishProps) {
  const { maxLength, onChange, defaultValue } = props;
  const editorDom = useRef<any>();
  const qlEditorDom = useRef<HTMLDivElement>();
  const quill = useRef<any>();
  const source = useRef('');
  const isComposition = useRef<boolean>(false);

  const handleTextFormat = () => {
    const length = quill.current.getLength();
    quill.current.removeFormat(0, length + 1);
    // eslint-disable-next-line no-useless-escape
    const texts = quill.current?.getText() as string;
    const strArr = texts.match(/#([^#|.|^ ]+)/g);
    if (strArr && strArr?.length > 0) {
      let textIndex = -1;
      strArr?.forEach((text: string) => {
        textIndex = texts.indexOf(text, textIndex + 1);
        quill.current.formatText(textIndex, text.length + 1, {
          // unbolds 'hello' and set its color to blue
          color: '#FF5F20',
        });
      });
    }
  };

  // 字数限制处理
  const handleWordsLimit = () => {
    if (!quill.current) return;
    const words = quill.current.getText().length - 1;
    if (words >= maxLength) {
      quill.current?.deleteText(maxLength, words);
    }
    /* change */
    onChange && onChange(quill.current.getText());
    // this.$emit('text-change', quill?.getText());
  };

  /**
   * 字数限制
   */
  const setWordsLimit = debounce(() => {
    handleWordsLimit();
  }, 200);

  // 格式化处理
  const textFormatter = debounce(() => {
    handleTextFormat();
  }, 300);

  const compositionstartEvent = () => {
    isComposition.current = true;
  };

  // 文字改变处理
  const handleTextChange = () => {
    if (isComposition.current) {
      return;
    }
    setWordsLimit();
    if (source.current === 'api') {
      return;
    }
    textFormatter();
  };

  const compositionendEvent = () => {
    isComposition.current = false;
    handleTextChange();
  };

  const textChange = (_: unknown, __: unknown, value: string) => {
    source.current = value;
    handleTextChange();
  };

  useEffect(() => {
    quill.current = new Quill('#editor', {
      placeholder: '请编辑您的标题',
      modules: {
        keyboard: {
          bindings: {
            enter: {
              key: 13,
              shiftKey: null,
              handler() {
                return false;
              },
            },
            tab: {
              key: 9,
              shiftKey: null,
              handler() {
                return false;
              },
            },
          },
        },
      },
    });
    quill.current.on('text-change', textChange);

    if (defaultValue?.length) {
      quill.current.setText(defaultValue);
    }
  }, []);

  useEffect(() => {
    qlEditorDom.current =
      editorDom.current.querySelector &&
      editorDom.current.querySelector?.('.ql-editor');
    textFormatter(quill.current);
    handleWordsLimit();
    // 给富文本绑定中文输入事件
    qlEditorDom.current?.addEventListener(
      'compositionstart',
      compositionstartEvent,
      true
    );
    qlEditorDom.current?.addEventListener(
      'compositionend',
      compositionendEvent,
      true
    );

    return () => {
      qlEditorDom.current?.removeEventListener(
        'compositionstart',
        compositionstartEvent,
        true
      );
      qlEditorDom.current?.removeEventListener(
        'compositionend',
        compositionendEvent,
        true
      );
    };
  }, []);

  return (
    <div
      id="editor"
      ref={editorDom}
      style={{
        height: 79,
        width: 514,
        border: 'none',
        backgroundColor: '#F5F5F7',
        overflow: 'hidden',
      }}
    />
  );
}

export default PublishBrief;
