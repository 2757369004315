/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import jsxCustomEvent from '@micro-zoe/micro-app/polyfill/jsx-custom-event';
import { observer } from 'mobx-react';
import Login from '@bhb-frontend/mtv-login';
import { ShanJianMember } from '@bhb-frontend/mtv-navbar';
import { APP_INFO } from '@/constants/Apps';
import { configStore, userStore } from '@/store';
import { EVENT_NAME } from '@/constants/event-name';
import { createNewDoc } from '@/api/myDoc';
import { ENV } from '@/utils';
import { goToDoc } from '@/utils/doc';
import { setEnableLoginSuccess } from '@/constants/common';
import { useStickyTop } from '@/hooks';
import { Protocol, SchemeMap, WeiXin } from '@/constants/UrlScheme';
import { getToken } from '@/utils/token';

/** 关闭模板市场弹窗函数，解决路径跳转弹窗没有关闭的bug */
let closeMicroAppModal: () => void;

function Template() {
  const { topDistance, showTopAd } = useStickyTop();
  const { config } = configStore;

  const handleUnmount = () => {
    closeMicroAppModal && closeMicroAppModal();
  };

  /** 创建文档 */
  const createDoc = async (themeId = '') => {
    const params: { title: string; themeId?: string } = {
      title: '',
    };
    if (themeId) {
      params.themeId = themeId;
    }
    const { data } = await createNewDoc(params);
    goToDoc(`/?id=${data.documentId}&themeId=${themeId}&isFromTemplate=1`);
  };

  const handleDataChange = (e: CustomEvent) => {
    function workbenchLoginSuccess() {
      createDoc(e.detail.data.themeId);
      setEnableLoginSuccess(true);
    }
    closeMicroAppModal = e.detail.data.closeDialogCB;
    switch (e.detail.data.type) {
      case EVENT_NAME.CREATE_DOC:
        createDoc();
        break;
      case EVENT_NAME.CREATE_DOC_TEMPLATE:
        if (userStore.isLogin()) {
          workbenchLoginSuccess();
        } else {
          setEnableLoginSuccess(false);
          Login.show?.();
        }
        break;

      default:
        break;
    }
  };

  const clickAdvansiting = (redirectUrl: string) => {
    if (!redirectUrl) return;

    const urlObj = new URL(redirectUrl);
    const queryStr = urlObj.searchParams.get('query');

    /**
     * 处理登录和重定向相关逻辑
     */
    const handleLoginAndRedirect = url => {
      if (!userStore.isLogin()) {
        Login.show();
      } else {
        window.open(url, '_blank');
      }
    };

    /**
     * 如果是urlScheme模式则处理应用内部逻辑
     */
    if (
      urlObj.protocol === Protocol &&
      urlObj.pathname.includes(SchemeMap.Member)
    ) {
      if (!userStore.isLogin()) {
        Login.show();
      } else {
        ShanJianMember?.show();
      }
      return;
    }

    /**
     * 如需携带token，那必须登录
     */
    if (queryStr === 'token') {
      handleLoginAndRedirect(`${urlObj.origin}?token=${getToken()}`);
      return;
    }

    /**
     * 微信小程不跳转
     */
    if (urlObj.protocol === WeiXin) {
      return;
    }
    window.open(redirectUrl, '_blank');
  };

  return (
    <div style={{ paddingTop: 18 }}>
      <micro-app
        name={APP_INFO.TEMPLATE.name}
        url={`${APP_INFO.TEMPLATE.host[ENV]}`}
        data={{
          clickAdvansiting,
          config,
          positionInfo: {
            showTopAd,
            topDistance,
          },
        }}
        ssr
        keep-alive
        onUnmount={handleUnmount}
        onDataChange={handleDataChange}
      />
    </div>
  );
}

export default observer(Template);
