import * as React from 'react';
import { Spin } from '@bhb-frontend/lithe-ui';
import { CSSTransition } from 'react-transition-group';
import { formatTime } from '@bhb-frontend/utils/lib/format';
import { formatDuration } from '@/utils/date';
import {
  EditorProjectsApiModal as Emodal,
  VideoStatus,
  VideoAction,
} from '@/types/editorVideo';
import Style from './VideoItem.module.less';
import assets from '@/assets';

interface PropsInfo {
  info: Emodal.DgetEditorVideosAPI /** 视频信息 */;
  onOperationFN: (
    tpye: VideoAction,
    itemData: Emodal.DgetEditorVideosAPI
  ) => void /** 元素执行函数 */;
  publishFN: (data: Emodal.DgetEditorVideosAPI) => void /** 发布弹窗 */;
}

const { useState, useEffect } = React;

export default function VideoItem(props: PropsInfo) {
  const { info, onOperationFN, publishFN } = props;
  /** 视频时长 */
  const [duration, setDurationFN] = useState(info.duration);
  /** 视频状态 */
  const [status, setStatusFN] = useState(info.status);
  /** 视频url */
  const [renderVideoUrl, setRenderVideoUrlFN] = useState(info.renderVideoUrl);
  /** 视频背景 */
  const [imageUrl, setImageUrlFN] = useState(info.imageUrl);

  // 是否显示按钮
  const [shopBtns, setShopBtnsFN] = useState(false);

  /* 删除元素 */
  const onDeleteFN = () => {
    onOperationFN(VideoAction.delete, info);
  };

  /* 下载 */
  const downloadFN = () => {
    if (!renderVideoUrl) return;
    const el = document.createElement('a');
    document.body.append(el);
    el.href = renderVideoUrl;
    el.download = info.name;
    el.rel = 'noopener noreferrer';
    el.click();
    document.body.removeChild(el);
  };

  /** 预览视频 */
  const previewFN = () => {
    if (status === VideoStatus.succeed) {
      onOperationFN(VideoAction.preview, info);
    }
  };

  useEffect(() => {
    setStatusFN(info.status);
  }, [info.status]);

  useEffect(() => {
    setDurationFN(info.duration);
  }, [info.duration]);

  useEffect(() => {
    setRenderVideoUrlFN(info.renderVideoUrl);
  }, [info.renderVideoUrl]);

  useEffect(() => {
    setImageUrlFN(info.imageUrl);
  }, [info.imageUrl]);

  return (
    <div className={Style['videos-item']}>
      <div
        className={Style['videos-cover']}
        onMouseEnter={() => {
          setShopBtnsFN(true);
        }}
        onMouseLeave={() => {
          setShopBtnsFN(false);
        }}
      >
        {/* 预览照片 */}
        {imageUrl && (
          <div
            className={Style['cover-img']}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
            onClick={previewFN}
          />
        )}

        {/* 视频生成中 */}
        {status === VideoStatus.pending && (
          <Spin spinning className={Style['btn-mask']}>
            <div className={Style['mask-text']}>视频生成中</div>
          </Spin>
        )}

        {/* 视频转换失败 */}
        {status === VideoStatus.failed && (
          <div className={Style['btn-mask']}>
            <div className={Style['mask-iconfont']}>
              <i className="icon-a-fabu_icon_fail2x1 iconfont" />
            </div>
            <div className={Style['mask-text']}>视频生成失败</div>
          </div>
        )}

        {/* icon - 移入显示 - 功能 */}
        <CSSTransition
          timeout={150}
          in={status !== VideoStatus.pending && shopBtns}
          unmountOnExit
          classNames="slide"
        >
          <div className={Style.buttonGroup}>
            {status === VideoStatus.succeed && (
              <>
                <div className={Style.opBtn} onClick={() => publishFN(info)}>
                  <img
                    src={assets.images.common['dingyue_icon_jisifabu@2x.svg']}
                    alt=""
                  />
                </div>

                <div className={Style.opBtn} onClick={downloadFN}>
                  <img
                    src={assets.images.common['preview_icon_dowload.svg']}
                    alt=""
                  />
                </div>
              </>
            )}
            <div className={Style.opBtn} onClick={onDeleteFN}>
              <img src={assets.images.common['video_icon_delete.svg']} alt="" />
            </div>
          </div>
        </CSSTransition>

        {/* icon - 移出显示 - 功能 */}
        <CSSTransition
          timeout={50}
          in={status === VideoStatus.succeed && !shopBtns}
          unmountOnExit
          classNames="date"
        >
          <div className={Style.duration}>{formatDuration(duration)}</div>
        </CSSTransition>
      </div>

      {/* 信息显示 */}
      <div>
        <p className={Style.title}>{info.name || ''}</p>
        <p className={Style.time}>
          {formatTime(new Date(info.createdAt), 'yyyy.mm.dd hh:MM')}
        </p>
      </div>
    </div>
  );
}
