import { SocketResponse } from './api';

export enum PlatformEnum {
  DOU_YIN = 'douyin',
  KUAI_SHOU = 'kuaishou',
}

/**
 * 添加第三方平台账号
 */
export interface ThirdAccountPassport {
  // 授权码
  code: string;
  // 账号平台
  platform: PlatformEnum;
}

/**
 * 第三方账号数据结构
 */
export interface ThirdAccountItem {
  // 账号类型
  platform: PlatformEnum.DOU_YIN | PlatformEnum.KUAI_SHOU;
  // 账号id
  id: string;
  // 昵称
  name: string;
  // 头像
  avatar: string;
  // 是否过期
  isExpired: boolean;
  // 上一次发布时间
  lastPublishAt?: string;
}

/**
 * 页面组件的自定义属性meta的定义
 */
export interface routerMate {
  /** 是否走授权流程 */
  isAuth?: boolean;
}

// 授权路由数据详情
export interface RouteQuery {
  code?: string;
  state?: PlatformEnum;
}

/**
 * 发布视频至抖音
 */
export interface PublishVideoBody {
  fileUrl: string; // 视频地址
  coverUrl: string; // 视频封面地址
  brief: string; // 视频描述
  thirdAccountIds: string[]; // 第三方账号ID数组
}

// 视频发布状态枚举
export enum PublishStatusEnum {
  PUBLISHLOADING = 'publishLoading',
  PUBLISHSUCCEED = 'publishSucceed',
  PUBLISHFAILED = 'publishFailed',
  PUBLISHPARTSUCCEED = 'publishPartSucceed',
}

// 视频发布socket结果
export interface PublishResult {
  taskId: string; // 发布任务id
  status:
    | PublishStatusEnum.PUBLISHSUCCEED
    | PublishStatusEnum.PUBLISHFAILED
    | PublishStatusEnum.PUBLISHPARTSUCCEED; // 发布状态 publishSucceed - 发布成功，publishFailed - 发布失败，publishPartSucceed - 部分发布成功
}

// 第三方发布socekt类型
export type ThirdPublishType = SocketResponse<PublishResult>;
