export enum OrderTypeEnum {
  /** 图文快剪 */
  Video = 'video',
  /** 直播快剪 */
  Live = 'live',
  /** 订阅号 */
  Subscribe = 'subscribe',
  /** 数字人 */
  Figure = 'figure',
  /**  图文单次付费 */
  VideoOnce = 'videoOnce',
  /** 兑换码兑换 */
  RedeemCode = 'redeemCode',
  /** 内部服务商品 */
  Service = 'service',
  /**  图文加量包 */
  VideoPlusPackage = 'videoPlusPackage',
  /** 定制配音 */
  Dubbing = 'dubbing',
  /** 数字人直播会员 */
  FigureLive = 'figureLive',
  /** 口播订阅号 */
  OralSubscribe = 'oralSubscribe',
  /** 团队带货达人 */
  Mktg = 'mktg',
  /** 照片说话 */
  PicSpeaks = 'picSpeaks',
  /** 数字人直播系统 - 数字人 */
  FigureLiveFigure = 'figureLiveFigure',
  /** 智播会员 */
  AiLive = 'aiLive',
  /** 团队带货 - 团队 */
  TeamMktg = 'teamMktg',
  /** 算力 */
  Coin = 'coin',
  /** ai 配音插件 */
  AiDubbingPlugin = 'aiDubbingPlugin',
  /** 智能导播插件 */
  SmartDirectorPlugin = 'smartDirectorPlugin',
  /** 团队分发 */
  TeamDistribute = 'teamDistribute',

  /** 智播个人版 */
  AiLivePersonage = 'aiLivePersonage',
  /** 智播插件 */
  AiLivePlugin = 'aiLivePlugin',
  /** 闪剪会员 */
  Shanjian = 'shanjian',

  /** 定制数字人 (普通) */
  CUSTOM_FIGURE = 'customFigure',
  /** 定制数字人（高端） */
  HIGH_END_CUSTOM_FIGURE = 'highEndCustomFigure',
  /** 定制服务 定制声音 */
  CUSTOM_SOUND = 'customSound',
}
