import { Tooltips, Popup, Menu } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { Tag } from '../RobotDetail';
import style from './DetailTableHead.module.less';
import assets from '@/assets';

const statusList = [
  {
    label: '全部',
    value: null,
  },
  {
    label: '上架',
    value: true,
  },
  {
    label: '下架',
    value: false,
  },
];

interface DetailTableHeadProps {
  tagList: Tag[];
  /** 切换tag*/
  switchTag: (tag: Tag) => void;
  /** 当前选中的tag */
  selectedTag: Tag;
  /** tag => 所有标签信息 */
  allTagInfo: Tag;
  /** 是否全部选中 */
  isSelectedAll: boolean;
  /** 选中列表 */
  selectedIdList: string[];
  /** 全选 */
  selectAll: (isSelect: boolean) => void;
  /** 排序 */
  onSort: () => void;
  /** 切换状态 */
  changeStatus: (val: null | boolean) => void;
}

export default function DetailTableHead(props: DetailTableHeadProps) {
  const {
    tagList,
    allTagInfo,
    switchTag,
    selectedTag,
    onSort,
    changeStatus,
    selectedIdList,
    isSelectedAll,
    selectAll,
  } = props;

  const tagOverlay = (
    <Menu
      className={style['menu-container']}
      onItemClick={({ value }) => {
        switchTag(value);
      }}
    >
      {[allTagInfo, ...tagList].map(tag => (
        <Menu.Item value={tag} key={tag.id}>
          {tag.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const statusOverlay = (
    <Menu
      onItemClick={({ value }) => {
        changeStatus(value);
      }}
      className={style['menu-container']}
    >
      {statusList.map(item => (
        <Menu.Item value={item.value} key={item.label}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const onClickSelectIcon = () => {
    selectAll(!isSelectedAll);
  };

  const selectIcon = (
    <img
      alt=""
      onClick={onClickSelectIcon}
      className={cs(style['select-icon'], {
        [style.selected]: selectedIdList.length > 0,
      })}
      src={
        isSelectedAll
          ? assets.images.doc['icon-choice.png']
          : selectedIdList.length > 0
          ? assets.images.doc['icon-choice2.png']
          : assets.images.doc['icon-nochoice.png']
      }
    />
  );

  return (
    <div className={style['table-head']}>
      <div className={style['select-box']}>{selectIcon}</div>

      <div className={style['table-head-info']}>
        {/* 文档名称 */}
        <div className={cs(style['head-item'], style['doc-name'])}>
          文档名称
        </div>
        {/* tag 标签 */}
        <div className={style['head-item']}>
          <Popup
            overlay={tagOverlay}
            trigger="click"
            clickClosable
            placement="bottom"
          >
            <span className={style['hover-box']}>
              <Tooltips tips="设置过滤项">
                <span className={style['hover-content']}>
                  {selectedTag.name}
                  <i
                    className={cs(
                      'icon-dingyue_icon_pull-down iconfont',
                      style['arrow-icon']
                    )}
                  />
                </span>
              </Tooltips>
            </span>
          </Popup>
        </div>
        {/* 发布时间 */}
        <div className={style['head-item']} onClick={onSort}>
          <Tooltips tips="设置过滤项">
            <span className={cs(style['hover-box'], style['hover-content'])}>
              发布时间
              <i
                className={cs(
                  'icon-dingyue_icon_pull-down iconfont',
                  style['arrow-icon']
                )}
              />
            </span>
          </Tooltips>
        </div>
        {/* 音乐 */}
        <div className={style['head-item']}>音乐</div>
        {/* 状态 */}
        <div className={style['head-item']}>
          <Popup
            overlay={statusOverlay}
            trigger="click"
            clickClosable
            placement="bottom"
          >
            <span className={style['hover-box']}>
              <Tooltips tips="设置过滤项">
                <span className={style['hover-content']}>
                  状态
                  <i
                    className={cs(
                      'icon-dingyue_icon_pull-down iconfont',
                      style['arrow-icon']
                    )}
                  />
                </span>
              </Tooltips>
            </span>
          </Popup>
        </div>
      </div>
    </div>
  );
}
