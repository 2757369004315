import request from '@/api/init';
import {
  GetDocParmas,
  DocItem,
  CreateNewDocParmas,
  TagItem,
} from '@/types/myDoc';
import { CustomListResponse, DetailResponse } from '@/types/api';

/**
 * 获取文档列表
 */
export function getDocList(
  params: GetDocParmas
): Promise<CustomListResponse<DocItem>> {
  return request.get('/clip/documents', params);
}

/**
 * 删除文档
 * @param id 文档id
 */
export function removeDoc(id: string) {
  return request.delete(`/clip/documents/${id}`);
}

/**
 * 批量删除文档
 * @param ids 删除的文档id数组
 */
export function removeDocs(ids: string[]) {
  return request.delete('/clip/documents/many', { documentIds: ids });
}

/**
 * 创建文档
 */
export function createNewDoc(data: CreateNewDocParmas): Promise<
  DetailResponse<{
    documentId: string;
  }>
> {
  return request.post('/clip/documents', data);
}

/**
 * 重命名
 */
export function renameDoc(
  id: string,
  production: string
): Promise<DetailResponse<void>> {
  return request.put(`/clip/documents/${id}/production`, {
    production,
  });
}

/**
 * 获取标签列表
 */
export function getTags(): Promise<CustomListResponse<TagItem>> {
  return request.get('/clip/labels');
}

/**
 * 添加标签
 */
export function addTag(name: string): Promise<DetailResponse<{ id: string }>> {
  return request.post('/clip/labels', {
    name,
  });
}

/**
 * 删除标签
 */
export function removeTag(id: string): Promise<DetailResponse<void>> {
  return request.delete(`/clip/labels/${id}`);
}
/**
 * 编辑标签
 */
export function editTag(
  id: string,
  name: string
): Promise<DetailResponse<void>> {
  return request.put(`/clip/labels/${id}`, {
    name,
  });
}

/**
 * 文档设置标签
 */
export function setTag(
  id: string,
  tagIds: string[]
): Promise<DetailResponse<void>> {
  return request.post(`/clip/documents/${id}/labels`, {
    labelIds: tagIds,
  });
}

/**
 * 同步文档
 */
export function asyncDocs(
  id: string,
  ids: string[]
): Promise<DetailResponse<{ failIds: string[] }>> {
  return request.post('/subscribe/store/document', {
    storeId: id,
    documentIds: ids,
  });
}

/**
 * 复制文档
 */
export function copyDoc(
  id: string
): Promise<DetailResponse<{ taskId: string }>> {
  return request.post(`/clip/documents/${id}/copy`);
}
