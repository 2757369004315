import { cloneElement, ReactElement } from 'react';
import Login from '@bhb-frontend/mtv-login';
import { userStore } from '@/store';

interface LoginPermissionProps {
  children: ReactElement;
  onSuccess?: () => void;
}

export default function LoginPermission(props: LoginPermissionProps) {
  const { children, onSuccess, ...otherProps } = props;

  if (userStore.isLogin()) return cloneElement(children, otherProps);
  const handleClick = () => {
    Login.show({
      onSuccess,
    });
  };

  return cloneElement(children, { onClick: handleClick });
}
