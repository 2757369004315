import { useEffect, useRef, useState } from 'react';
import { images } from '@/assets';
import { HOME_PAGE_MOBILE_VIDEO } from '@/config';
import { useMount } from '@/hooks/useMount';
import { parseUrl } from '@/utils/utils';
import Style from './Mobile.module.less';
import { initHtmlHeight } from '@/utils/initHtmlHeight';
import { isAndroid, isWeixinBrowser } from '@/utils/common';

const Mobile = () => {
  const [isPlay, setPlay] = useState<boolean>(false);
  const videoElement = useRef<HTMLVideoElement>(null);
  const [isShowGuide, setIsShowGuide] = useState(false);

  const setMoblink = () => {
    let URLScheme = 'miaotui://miaotui.com/home';

    // 如果有已缓存的邀请id，则使用单独的 URLScheme
    const params = parseUrl(window.location.href).params;
    const { inviteId, inviteCode } = params;
    if (inviteId) {
      URLScheme = `miaotui://miaotui.com/fenxiao?id=${inviteId}`;
      if (inviteCode) {
        URLScheme += `&inviteCode=${inviteCode}`;
      }
    } else if (inviteCode) {
      URLScheme = `miaotui://miaotui.com/fenxiao?inviteCode=${inviteCode}`;
    }

    window.MobLink({
      el: '#download',
      path: 'miaotui',
      params: {
        URLScheme,
      },
    });
  };

  const initMoblink = () => {
    if (isAndroid && isWeixinBrowser()) return;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//c1b0.t4m.cn/applink.js';
    document.head.appendChild(script);
    script.onload = () => {
      setMoblink();
    };
  };

  useMount(() => {
    initMoblink();
  });

  const headerRender = () => (
    <header className={Style['mobile-header']}>
      <div className={Style['mobile-header-left']}>
        <img
          src={images.common['mobile-logo.png']}
          alt="logo"
          className={Style['mobile-header-logo']}
        />
        <i
          className={`iconfont icon-a-shanjian_logo2x ${Style['mobile-header-icon']}`}
        />
      </div>
    </header>
  );

  // 视频播放
  const trigger = () => {
    setPlay(!isPlay);
  };

  useEffect(() => {
    try {
      isPlay && videoElement.current?.play();
    } catch (error) {
      console.log('浏览器禁止自动播放error', error);
      videoElement.current!.volume = 0;
      isPlay && videoElement.current?.play();
    }
    !isPlay && videoElement.current?.pause();
  }, [isPlay]);

  useEffect(() => {
    initHtmlHeight();
    window.addEventListener('resize', initHtmlHeight);
    return () => {
      window.removeEventListener('resize', initHtmlHeight);
    };
  }, []);

  /** 引导页渲染 */
  const maskRender = () => (
    <div
      className={Style.mobileMask}
      onClick={() => {
        setIsShowGuide(false);
      }}
    >
      <img
        src={images.global.img_guide_mobile_png}
        alt="导航"
        className={Style.mobileMaskImage}
      />
    </div>
  );

  return (
    <div className={Style.mobile}>
      {isShowGuide && maskRender()}
      <img
        src={images.common['icon-mobile-1.png']}
        className={Style['mobile-icon-1']}
        alt="mobile1"
      />
      <img
        src={images.common['icon-mobile-2.png']}
        className={Style['mobile-icon-2']}
        alt="mobile2"
      />
      <img
        src={images.common['icon-mobile-3.png']}
        className={Style['mobile-icon-3']}
        alt="mobile3"
      />

      {headerRender()}

      <div
        className={Style['mobile-container']}
        onClick={() => {
          trigger();
        }}
      >
        {!isPlay && (
          <img
            src={images.common['icon-common-play.png']}
            className={Style['mobile-container-icon']}
            alt="play"
          />
        )}

        <video
          ref={videoElement}
          className={Style['mobile-container-video']}
          webkit-playsinline="true"
          playsInline
          autoPlay
          x5-playsinline="true"
          src={HOME_PAGE_MOBILE_VIDEO}
          poster={images.common['img-mobile-phone.png']}
        />
      </div>
      {/* 安卓微信内需要引导用户拉起应用 */}
      {isAndroid && isWeixinBrowser() ? (
        <button
          className={Style['mobile-button']}
          onClick={() => {
            setIsShowGuide(true);
          }}
        >
          立即下载
        </button>
      ) : (
        <button id="download" className={Style['mobile-button']}>
          立即下载
        </button>
      )}
    </div>
  );
};

export default Mobile;
