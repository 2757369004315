import { IMenuConfig } from '@/types/menu';
/** 海外版路由 */
export const editorMenu: IMenuConfig = [
  {
    path: '/editor',
    key: '/editor',
    name: '我的项目',
    iconClass: 'icon-a-tab_icon_doc_def2x',
    activeIconClass: 'icon-a-tab_icon_doc_def2x',
  },
  {
    path: '/editor/videos',
    key: '/editor/videos',
    name: '我的视频',
    iconClass: 'icon-a-tab_icon_video2x',
    activeIconClass: 'icon-a-tab_icon_video2x',
  },
];
