import { Input } from '@bhb-frontend/lithe-ui';
import { store } from '@bhb-frontend/store';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import classNames from 'classnames';
import Menu from '../Menu';
import { docMenu, robotConfig } from './menuConfig';
import { MY_DOC_SEARCH_KEY, MY_DOC_SEARCH_NAMESPACE } from '@/constants/myDoc';
import Style from './Doc.module.less';
import { userStore } from '@/store';
import { configStore } from '@/store/config';

function DocLayout() {
  const location = useLocation();

  const { agent } = configStore.config;

  const menu = useMemo(() => {
    if (userStore.subscribeAuthList.indexOf('video') === -1) return docMenu;
    return [...docMenu, robotConfig];
  }, [userStore.subscribeAuthList]);

  const content =
    location.pathname === '/doc' ? (
      <span className={Style['input-comp']}>
        <Input
          prefix={
            <i
              className={classNames(
                'iconfont icon-a-common_icon_search2x',
                Style['search-input-icon']
              )}
            />
          }
          onPressEnter={event => {
            store.set(
              MY_DOC_SEARCH_NAMESPACE,
              MY_DOC_SEARCH_KEY,
              (event.target as HTMLInputElement).value
            );
          }}
          placeholder="搜索"
          contentClassName={Style['input-content']}
        />
      </span>
    ) : null;

  return (
    <Menu
      menuConfig={menu}
      title="口播视频"
      searchBox={content}
      module="video"
      isShowInvite={agent?.switch && agent.videoMainPage.switch}
      inviteText={agent && agent.videoMainPage.text}
      isShowBeiAn={false}
    />
  );
}

export default observer(DocLayout);
