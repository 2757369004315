import request from '@/api/init';
import { CommonResponse } from '@/types/api';
import { EditorConvertVideoModal as Emodal } from '@/types/editorConvertVideo';

export interface project {
  basicInfo: string;
  name: string;
  projectStatus: string;
  size: string;
  userId: string;
}

/**
 * 剪辑函数
 */
export function postEditorTwiceEditingAPI(
  data: Emodal.RpostEditorTwiceEditingAPI
) {
  return request.post<CommonResponse<Emodal.DpostEditorTwiceEditingAPI>>(
    '/editor/twice/editing',
    data
  );
}

/**
 * 剪辑函数 - 重试
 */
export function postEditorTwiceEditingRetryAPI(
  data: Emodal.PostEditorTwiceEditingRetryAPI
) {
  return request.post<CommonResponse<Emodal.DpostEditorTwiceEditingAPI>>(
    '/editor/twice/editing/retry',
    data
  );
}

/**
 * 取消二次编辑
 */
export function postEditorTwiceEditingCancelAPI(
  data: Emodal.RpostEditorTwiceEditingCancelAPI
): Promise<Emodal.DpostEditorTwiceEditingCancelAPI> {
  return request.post('/editor/twice/editing/cancel', data);
}
