// 域名地址
// import { ENV } from '@/utils';
import data from '../../config/config.json';

export enum BASE_URL {}

/** 静态资源CDN域名 */
export const STATIC_CDN_HOST = data.cdn_host;

/** 静态资源CDN路径 */
export const STATIC_CDN_PATH = data.cdn_host;

/** 不需要登录的路由路径 */
export const NO_TOKEN_ROUTES = ['/'];

/** 代码环境上下文 */
export enum CONTEXT_ENV {
  dev = 'dev',
  test = 'test',
  gray = 'gray',
  prod = 'prod',
  i18nDev = 'i18nDev',
  i18nTest = 'i18nTest',
  i18nGray = 'i18nGray',
  i18nProd = 'i18nProd',
}

export const ENV = (import.meta.env.MODE || CONTEXT_ENV.dev) as CONTEXT_ENV;

/** 接口域名 */
export enum BASE_URL_MAP {
  dev = 'https://mtv-api.test.bhbapp.cn', // 本地开发
  test = 'https://mtv-api.test.bhbapp.cn', // 测试环境
  gray = 'https://mtv-api.bj.gray.bhbapp.cn', // 预发布环境
  prod = 'https://api.shanjian.tv', // 正式环境
  i18nDev = 'https://mtv-i18n-api.test.bhbapp.cn', // 国际版本地开发
  i18nTest = 'https://mtv-i18n-api.test.bhbapp.cn', // 国际版测试环境
  i18nGray = 'https://api-gray.boltcut.ai', // 国际版预发布环境
  i18nProd = 'https://api.boltcut.ai', // 国际版正式环境
}

/** 工作台域名映射 */
export enum WORKBENCH_URL_MAP {
  dev = 'http://localhost:16000', // 本地开发
  // dev = 'https://mtv-mtv-workbench.test.bhbapp.cn', // 本地开发
  test = 'https://mtv-mtv-workbench.test.bhbapp.cn', // 测试环境
  gray = 'https://mtv-mtv-workbench.bj.gray.bhbapp.cn', // 预发布环境
  prod = 'https://shanjian.tv', // 正式环境
  i18nDev = 'http://localhost:16000', // 国际版本地开发
  i18nTest = 'https://mtv-i18n-mtv-workbench.test.bhbapp.cn', // 国际版测试环境
  i18nGray = 'https://boltcut-workbench-gray.boltcut.ai', // 国际版预发布环境
  i18nProd = 'https://my.boltcut.ai', // 国际版正式环境
}

export enum LINK_MAP {
  /** 帮助中心 */
  HELP = 'https://www.yuque.com/zk17hi',
}

/** 图文快剪地址 */
export enum DOC_LINK_MAP {
  /** 本地 */
  dev = 'http://localhost:12000',
  /** 测试 */
  test = 'https://mtv-mtv-doc.test.bhbapp.cn',
  /** 灰度 */
  gray = 'http://mtv-mtv-doc.bj.gray.bhbapp.cn',
  /** 正式环境 */
  prod = 'https://doc.shanjian.tv',
}

/** 旧web项目地址 */
export enum WEB_LINK_MAP {
  /** 本地 */
  dev = 'http://localhost:3000',
  /** 测试 */
  test = 'https://mtv-mtv-web.test.bhbapp.cn',
  /** 灰度 */
  gray = 'http://mtv-mtv-web.bj.gray.bhbapp.cn',
  /** 正式环境 */
  prod = 'https://my.shanjian.tv',
}

/** 旧web项目地址 */
export enum SUB_LINK_MAP {
  /** 本地 */
  dev = 'http://localhost:17000',
  /** 测试 */
  test = 'https://mtv-mtv-subscription.test.bhbapp.cn',
  /** 灰度 */
  gray = 'https://mtv-mtv-subscription.bj.gray.bhbapp.cn',
  /** 正式环境 */
  prod = 'https://subscription.shanjian.tv',
}

export enum HOME_URL_MAP {
  dev = 'https://mtv-mtv-home.test.bhbapp.cn/', // 本地开发
  test = 'https://mtv-mtv-home.test.bhbapp.cn/', // 测试环境
  gray = 'https://mtv-mtv-home.bj.gray.bhbapp.cn/', // 预发布环境
  prod = 'https://sjzn.com/', // 正式环境
  /** 外海本地 */
  i18nDev = 'http://localhost:16000',
  /** 海外测试 */
  i18nTest = 'https://mtv-i18n-mtv-home.test.bhbapp.cn',
  /** 海外灰度 */
  i18nGray = 'https://boltcut-home-gray.boltcut.ai',
  /** 海外正式 */
  i18nProd = 'https://boltcut.ai',
}

export enum H5_SHOP_URL_MAP {
  /** 本地开发 */
  dev = 'http://localhost:4000/shop',
  /** 测试环境 */
  test = 'https://mtv-mtv-mobile.test.bhbapp.cn/shop',
  /**  预发布环境 */
  gray = 'https://mtv-mtv-shop.bjb.gray.bhbapp.cn/shop',
  /** 正式环境 */
  prod = 'https://h5.feituiplus.cn/shop',
}

/** 链接成片地址 */
export enum LINK_TO_VIDEO_URL_MAP {
  /** 本地开发 */
  dev = 'http://localhost:12100',
  /** 测试 */
  test = 'https://mtv-mtv-link.test.bhbapp.cn',
  /** 灰度 */
  gray = 'https://mtv-mtv-link.bjb.gray.bhbapp.cn',
  /** 正式环境 */
  prod = 'https://link.shanjian.tv',
}

/** h5商店 */
export const H5_SHOP_URL = H5_SHOP_URL_MAP[ENV];

// 移动端官网介绍视频
export const HOME_PAGE_MOBILE_VIDEO =
  'https://vod.shanjian.tv/common/mtv/2022/06/21/14/0fba703200d703fa94adaaad8366da20.mp4';

export const HOME_URL = HOME_URL_MAP[ENV];
/** 图文快剪地址 */
export const DOC_URL: string = DOC_LINK_MAP[ENV];
/** 旧web项目地址 */
export const WEB_URL: string = WEB_LINK_MAP[ENV];

export const WORKBENCH_URL: string = WORKBENCH_URL_MAP[ENV];

/** 订阅号项目地址 */
export const SUB_URL: string = SUB_LINK_MAP[ENV];
/** 链接成片地址 */
export const LINK_TO_VIDEO_URL: string = LINK_TO_VIDEO_URL_MAP[ENV];
