/**
 * @desc 秒数转换为时间
 * @params
 * - duration 秒数
 * - template  'mm:ss'
 */
export function formatDuration(duration: number, template = 'mm:ss') {
  const second = parseInt(String(duration % 60), 10); // 秒
  const minutes = parseInt(String((duration / 60) % 60), 10); // 分
  const hour = parseInt(String(duration / 3600), 10); // 小时

  const padStart = (val: number) =>
    String(val === 60 ? 0 : val).padStart(2, '0');

  return template
    .replace('hh', padStart(hour))
    .replace('mm', padStart(minutes))
    .replace('ss', padStart(second));
}
