/** 公共前缀，用于区分项目缓存 */
const CachePrefix = 'BHB:MTV:WORKBENCH';

/** 直播快剪搜索命名空间 */
export const MY_LIVE_SEARCH_NAMESPACE = `${CachePrefix}:MYLIVE:NAMESPACE`;

/** 直播快剪搜索key */
export const MY_LIVE_SEARCH_KEY = `${CachePrefix}:MYLIVE:SEARCH`;

/** 时间排序
 * 正序 是 最新在前
 * 倒序 是 最新在后
 */
/** 倒序 */
export const Reverse = 'Reverse';

/** 正序 */
export const Positive = 'Positive';

/** 平台 */
export const PLATFORM_LIST = {
  douyin: {
    label: '抖音',
    value: 'douyin',
  },
  kuaishou: {
    label: '快手',
    value: 'kuaishou',
  },
};

/** 直播列表操作弹窗显示 */
export const HANDLE_MODAL_DATA = {
  delete: {
    title: '确定删除直播内容',
    message: '删除后内容将无法恢复，且消耗的录制时长不会返还',
  },
  stop: {
    title: '确定终止录制？',
    message: '终止录制后将无法重新开启，你可以重新创建该直播进行录制',
  },
};
