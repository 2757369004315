import * as React from 'react';
import { observer } from 'mobx-react';
import VideoList from '@/components/VideoList';

import Styles from './index.module.less';
import VideoTypeEnum from '@/constants/videos/VideoTypeEnum';

function Videos() {
  return (
    <div className={Styles.container}>
      <h1 className={Styles.title}>我的视频</h1>
      <VideoList type={VideoTypeEnum.VIDEO} />
    </div>
  );
}

export default observer(Videos);
