import { Modal, Button, Spin } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import React, { useState } from 'react';
import { deleteEditorVideosAPI } from '@/api/editorVideo';

import Style from './index.module.less';

interface ModalWrapperProps extends ModalProps {
  selectedIdList: string[] /** 选中的元素列表 */;
  onConfirmFN: (data: string[]) => void /** 成功函数 */;
  onCancelFN: (data: string[]) => void /** 失败函数 */;
  modalActionFN: (data: boolean) => void /** 弹窗显示函数 */;
}

export default function RemoveDialog(props: ModalWrapperProps) {
  const { visible, onConfirmFN, modalActionFN, selectedIdList, onCancelFN } =
    props;

  /**
   * 创建loading
   */
  const [btnLoading, setBtnLoadingFN] = useState(false);

  /** 控制弹窗开关函数 */
  const modalOpenCloseFN = (data: boolean) => {
    !data && setBtnLoadingFN(false);
    const projectId = selectedIdList[0];
    !data && onCancelFN([projectId]);
    modalActionFN(data);
  };

  /** 确定按钮函数 */
  const onOkFN = async () => {
    setBtnLoadingFN(true);
    const projectId = selectedIdList[0];
    await deleteEditorVideosAPI(projectId);
    onConfirmFN([projectId]);
    modalOpenCloseFN(false);
  };

  return (
    <Modal
      visible={visible}
      width={420}
      height="auto"
      timeout={0}
      onClose={() => {
        modalOpenCloseFN(false);
      }}
      closable={false}
      contentClassName={Style['modal-container']}
    >
      <Spin spinning={btnLoading} className={Style['btn-loading']}>
        <div className={Style['modal-header']}>注意</div>
        <div className={Style['modal-body']}>
          <i
            className="icon-a-fabu_icon_fail2x1 iconfont"
            style={{
              color: 'rgb(255, 23, 92)',
              marginRight: '5px',
            }}
          />
          你确定要删除该视频吗？删除后视频无法找回!
        </div>
        <div className={Style['modal-footer']}>
          <span>
            <Button
              className={Style['cancel-btn']}
              onClick={() => {
                modalOpenCloseFN(false);
              }}
            >
              取消
            </Button>
            <Button className={Style['confirm-btn']} onClick={onOkFN}>
              确定
            </Button>
          </span>
        </div>
      </Spin>
    </Modal>
  );
}
