import { CONTEXT_ENV } from '@/config';

export * from './common';
export * from './addEnv';

export const ENV = (import.meta.env.MODE || CONTEXT_ENV.dev) as CONTEXT_ENV;
export const isI18n = ENV.includes('i18n');
export const getSecret = () =>
  isI18n ? 'qk2Vt6bt02t3inehFJRLNXDF' : 'h98zdbtqwu90qre8md3ndw';

export function addUrlQuery(url: string, key: string, value: string) {
  if (!key || !value) return url;
  try {
    const urlObj = new URL(url);
    const urlParams = urlObj.searchParams;
    urlParams.set(key, value);
    return `${urlObj.origin}${urlObj.pathname}?${urlParams.toString()}`;
  } catch (error) {
    console.log(error);
    return url;
  }
}
