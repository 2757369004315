/**
 * 创建直播间状态枚举映射
 */
export enum CREATED_LIVE {
  noLink,
  creating,
  success,
  noLive,
  created,
  limit,
  used,
  noEnough,
  fail,
}
/**
 * 直播状态描述
 */
export const LIVE_STATUS_DESC = [
  { name: '录制中', desc: '直播间有实时推流内容' },
  { name: '直播中断', desc: '直播间网络不稳定' },
  { name: '已结束', desc: '直播间无实时推流内容' },
  { name: '已终止', desc: '手动停止录制直播内容' },
  { name: '已过期', desc: '录制内容存储已过期' },
];

/**
 * 创建直播快剪时的错误码
 */
export enum CREATE_LIVE_ERROR_CODE {
  /** 创建直播失败 */
  FAIL = 2002,
  /** 直播间数量超过限制 */
  LIVE_NUM = 2005,
  /** 时长不足 */
  USED_TIME = 2007,
  /** 直播间已存在 */
  LIVE_URL_EXISTING = 2008,
}

/** 创建直播合规提示开关 */
export enum CREATE_LIVE_HINT_SWITCH {
  /** 关闭 */
  DISABLE = 'disable',
  /** 开启 */
  ENABLE = 'enable',
}
