// import { useEffect } from 'react';
import Style from './style.module.less';

interface ModalProps {
  visible: boolean;
  style?: React.CSSProperties;
  showClose?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

function Modal(props: ModalProps) {
  const { visible, style, showClose = false, onClose, children } = props;

  const renderClose = () => {
    if (showClose) {
      return (
        <div className={Style['modal-close']} onClick={onClose}>
          <i className="iconfont icon-a-vip_icon_close1x" />
        </div>
      );
    }
    return null;
  };
  return (
    <div
      className={Style.modal}
      style={{ display: visible ? 'block' : 'none' }}
    >
      <div className={Style['modal-mask']} />
      <div className={Style['modal-wrapper']} onClick={onClose}>
        <div
          className={Style['modal-content']}
          style={{ ...style }}
          onClick={e => e.stopPropagation()}
        >
          {renderClose()}
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;
