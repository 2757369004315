/** 字节转 MB */
export function byteToMb(size: number) {
  return size / 1024 / 1024;
}

/** 字节转化单位
 *  如果小于1GB，则单位为MB、向下取整
 *  如果大于1G，则单位为GB、最大保留2位小数
 */
export function convertBytes(bytes: number, pointNumber?: number) {
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  let convertedSize = bytes / 1024 ** i;

  /** 默认单位到了GB 需要保留两位小数
   *  如果 pointNumber有传，优先采用传入规则
   */
  convertedSize = pointNumber
    ? parseFloat(convertedSize.toFixed(pointNumber))
    : i > 2
    ? parseFloat(convertedSize.toFixed(2))
    : Math.floor(convertedSize);

  return `${convertedSize} ${sizes[i]}`;
}
