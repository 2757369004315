import { ENV, getWindow, isI18n } from '@/utils';
import config from '../../config/config.json';

const { ...winConfig } = config;
const win = getWindow();

export function initProject() {
  if (win) {
    // let title = '闪剪工作台';
    // if (isI18n) {
    //   title = 'BoltCut';
    // }
    // win.document.title = title;
    win.MTV_CONFIG = {
      ...winConfig.mtv_config,
      env: ENV,
      lang: isI18n ? 'en-US' : 'zh-CN',
      isI18n,
    };
  }
}
// initProject();
