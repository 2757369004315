/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Modal from '../Modal';

import Style from './style.module.less';

/** 温馨提示结构体 */
export interface StreamingProtocolProps {
  title: string;
  content: string;
  confirm: string;
}

interface PropType {
  modalWidth?: string;
  streamingProtocolText: StreamingProtocolProps;
  textAlign:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  isTimer: boolean;
  iKnow: () => void;
  parentTime?: number;
  everyIntervalTime?: number;
}

type ProtocolProps = React.FC<PropType> & {
  show: (openTimer?: boolean) => void;
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StreamingProtocol: ProtocolProps = props => {
  const {
    modalWidth = '335px',
    streamingProtocolText,
    textAlign = 'center',
    isTimer,
    parentTime = 3,
    everyIntervalTime = 1000,
    iKnow,
  } = props;

  const [streamingProtocolVisible, setStreamingProtocolVisible] =
    useState(false);

  const [isCanClose, setCanClose] = useState(false);

  const [time, setTime] = useState(0);

  const timer = useRef(0);

  const initStreamingProtocolInterface = useMemo(
    () => streamingProtocolText,
    [streamingProtocolText]
  );

  /** 关闭倒计时 */
  const countDown = () => {
    setTime(state => {
      // 到1的时候已经3秒了
      if (state <= 1) {
        clearInterval(timer.current);
        setCanClose(true);
      }
      return state - 1;
    });
  };

  const show = (openTimer = true) => {
    setStreamingProtocolVisible(true);

    // 倒计时间
    setTime(parentTime);
    // 是否需要有禁用
    setCanClose(!openTimer);
    // 开启定时器
    if (openTimer) {
      timer.current = window.setInterval(() => {
        countDown();
      }, everyIntervalTime);
    }
  };

  const hidden = () => {
    setStreamingProtocolVisible(false);
  };

  useEffect(() => {
    StreamingProtocol.show = show;
  }, []);

  const handleKnow = () => {
    if (!isCanClose) return;
    iKnow && iKnow();
    hidden();
  };

  return (
    <Modal
      visible={streamingProtocolVisible}
      style={{ width: modalWidth }}
      onClose={hidden}
    >
      <div className={Style.wrapper} style={{ width: modalWidth }}>
        <p className={Style['wrapper-title']}>
          {initStreamingProtocolInterface.title}
        </p>
        <p className={Style['wrapper-content']} style={{ textAlign }}>
          {initStreamingProtocolInterface.content}
        </p>
        <div
          className={classNames(Style['wrapper-button'], {
            [Style['wrapper-button-active']]: isCanClose,
          })}
          onClick={handleKnow}
        >
          {initStreamingProtocolInterface.confirm}
          {time !== 0 && isTimer && <span>（{time}s）</span>}
        </div>
      </div>
    </Modal>
  );
};

export default StreamingProtocol;
