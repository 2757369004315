import { useRef, useState, useLayoutEffect } from 'react';
import { Empty, Card, LoadMore, Spin } from '@bhb-frontend/lithe-ui';
import { formatTime } from '@bhb-frontend/utils/lib/format';
import Styles from './index.module.less';
import { getMyOrders } from '@/api/orders';
import assets from '@/assets/images';
import ModalInvoice from './ModalInvoice';
import { OrderItem } from '@/types/orders';
import { ListQuery } from '@/types/api';
import { useStickyTop } from '@/hooks';
import { GoodsModuleEnum, GoodsModuleMap } from '@/constants/GoodsModule';
import { OrderStatusMap } from '@/constants/OrderStatus';
import { OrderTypeEnum } from '@/constants/OrderTypeEnum';
import { ServiceUnitMap, ServiceUnitEnum } from '@/constants/ServiceUnit';
import { PaymentMethod } from '@/constants/PaymentMethod';

const getServiceNum = (order: OrderItem) => {
  const { serviceNum, serviceUnit } = order;
  if (serviceNum === undefined || serviceUnit === undefined) return '';

  const numStr =
    serviceUnit === ServiceUnitEnum.Month ? `${serviceNum}个` : serviceNum;

  return `${numStr}${ServiceUnitMap[serviceUnit]}`;
};

const getGoodsRightsText = (order: OrderItem) => {
  const { orderType, coin, goodsModule, goodsInfo } = order;

  /** 如果是兑换码，就以 redeemModule 区别展示，否则以 orderType 区别展示 */
  const type =
    goodsModule === GoodsModuleEnum.RedeemCode ? goodsInfo.belong : orderType;

  /** 图文快剪会员、直播快剪会员、照片数字人会员、 闪剪会员 */
  if (
    [
      OrderTypeEnum.Video,
      OrderTypeEnum.Live,
      OrderTypeEnum.PicSpeaks,
      OrderTypeEnum.Shanjian,
    ].includes(type)
  ) {
    return `${getServiceNum(order)}会员`;
  }

  /**  图文快剪加量包 */
  if (type === OrderTypeEnum.VideoPlusPackage) {
    const { figureDuration, videoMergeNum } = order.videoPlusPackages || {};
    if (figureDuration) {
      return `${figureDuration}秒数字人时长加量包`;
    }
    if (videoMergeNum) {
      return `${videoMergeNum}条视频生成数加量包`;
    }
  }

  /** 定制数字人 */
  if (
    [
      OrderTypeEnum.CUSTOM_FIGURE,
      OrderTypeEnum.HIGH_END_CUSTOM_FIGURE,
    ].includes(type)
  ) {
    return `定制数字人${
      type === OrderTypeEnum.HIGH_END_CUSTOM_FIGURE ? 'Pro' : ''
    }*${goodsInfo.figureSeatSum || 1}`;
  }

  /** 定制声音 */
  if (type === OrderTypeEnum.CUSTOM_SOUND) {
    return `定制声音*${goodsInfo.dubbingSeatSum || 1}`;
  }

  // /** 数字人（图文-数字人，定制服务 -定制数字人（包括普通定制和高端定制类型的商品）） */
  // if ([OrderTypeEnum.Figure, OrderTypeEnum.CUSTOM_FIGURE].includes(type)) {
  //   return `定制数字人*1（${getServiceNum(order)}）`;
  // }

  /**  图文快剪单次购买 */
  if (type === OrderTypeEnum.VideoOnce) {
    return '单次视频制作';
  }

  /** 订阅号（图文订阅号、视频带货、口播视频） */
  if (
    [
      OrderTypeEnum.Subscribe,
      OrderTypeEnum.Service, // 视频带货
      OrderTypeEnum.OralSubscribe,
    ].includes(type)
  ) {
    return getServiceNum(order);
  }

  /** 算力 */
  if (type === OrderTypeEnum.Coin) {
    return `${coin}算力`;
  }

  return '-';
};

const getGoodsModule = (order: OrderItem) => {
  const { goodsModule, redeemModule } = order;
  if (goodsModule === GoodsModuleEnum.RedeemCode && redeemModule) {
    return GoodsModuleMap[redeemModule];
  }
  return GoodsModuleMap[goodsModule];
};

/** 价格转换*/
const transformPrice = (order: OrderItem) => {
  const { goodsModule, paymentMethod, actualPaymentAmount } = order;

  if (goodsModule === GoodsModuleEnum.RedeemCode) {
    return '-';
  }

  const noShowPricePaymentMethod = [
    PaymentMethod.DISTRIBUTOR,
    PaymentMethod.WA_KA_KA,
    PaymentMethod.WECHAT_VIDEO_SHOP,
  ];
  if (noShowPricePaymentMethod.includes(paymentMethod)) {
    return '-';
  }
  return `${actualPaymentAmount / 100}元`;
};

export default function OrderList() {
  const { topDistance } = useStickyTop();
  /** 订单列表数据 */
  const [orderList, setList] = useState<OrderItem[]>([]);

  /** 分页标识 */
  const [hasMore, setHasMore] = useState<boolean>(false);
  /** 加载过程中的动画 */
  const [loading, setLoading] = useState(false);

  /** 是否显示发票弹窗 */
  const [modalShow, setModalShow] = useState(false);

  /* 当前列表使用到的分页信息 */
  const listQuery = useRef<ListQuery>({
    sid: '',
    pageSize: 20,
  });

  /** 获取订单数据 */
  const getOrderList = async () => {
    try {
      setLoading(true);
      const params = {
        ...listQuery.current,
      };
      const { data } = await getMyOrders(params);

      listQuery.current = {
        ...listQuery.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (err) {
      console.warn(err);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    listQuery.current = {
      ...listQuery.current,
      sid: '',
    };
    const data = await getOrderList();
    if (data) {
      setList(data.results);
    }
  };

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getOrderList();
    if (data) {
      setList([...orderList, ...data.results]);
    }
  };

  useLayoutEffect(() => {
    initList();
  }, []);

  return (
    <div className={Styles.main}>
      <div className={Styles.mainTop}>
        <h2>订单记录</h2>
        <button
          className={Styles.mainInvoice}
          onClick={() => {
            setModalShow(true);
          }}
        >
          开发票
        </button>
      </div>
      <div className={Styles.content} id="content">
        <Card
          className={Styles.gray}
          style={{ marginBottom: '30px', position: 'sticky', top: topDistance }}
          bordered={false}
        >
          <div className={Styles.cardGrid}>
            <div>功能模块</div>
            <div>商品权益</div>
            <div>支付金额</div>
            <div>支付方式</div>
            <div>订单状态</div>
          </div>
        </Card>
        {orderList.length === 0 && !loading ? (
          <div className={Styles.empty}>
            <Empty
              imgSrc={assets.common['img-empty-box.png']}
              text="没有记录"
              style={{
                color: '#191919',
              }}
            />
          </div>
        ) : (
          <Spin
            spinning={loading}
            style={{
              width: '100%',
              minHeight: '300px',
            }}
          >
            <LoadMore
              onReachBottom={loadMore}
              className={Styles.loadMore}
              loading={loading}
              hasMore={hasMore}
              finishedText=""
            >
              {orderList.map(item => (
                <Card className={Styles.orderList} key={item.id}>
                  <Card className={Styles.listTitle} bordered={false}>
                    <label>
                      {formatTime(
                        new Date(item.createdAt),
                        'yyyy-mm-dd hh:MM:ss'
                      )}
                    </label>
                    <label className={Styles.idText}>
                      订单号：{item.orderNo}
                    </label>
                  </Card>
                  <div className={`${Styles.cardGrid} ${Styles.listContent}`}>
                    <div>{getGoodsModule(item)}</div>

                    <div>
                      <div>{getGoodsRightsText(item)}</div>
                    </div>

                    <div>{transformPrice(item)}</div>
                    <div>{item.paymentMethodName}</div>
                    <div
                      style={
                        item.status === 'completed'
                          ? { color: 'green' }
                          : { color: 'block' }
                      }
                    >
                      {OrderStatusMap[item.status]}
                    </div>
                  </div>
                </Card>
              ))}
            </LoadMore>
          </Spin>
        )}
      </div>
      <ModalInvoice
        visible={modalShow}
        closeModal={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}
