import { CSSTransition, TransitionGroup } from 'react-transition-group';
import * as ReactDOM from 'react-dom';

import Style from './CustomToast.module.less';
import './transition.less';

interface CustomToastProp {
  toastIdList: string[];
  closeToast: (id: string) => void;
  container?: Element | null;
}

export default function CustomToast(props: CustomToastProp) {
  const { toastIdList, closeToast, container } = props;

  return ReactDOM.createPortal(
    <div className={Style['toast-group']}>
      <TransitionGroup>
        {toastIdList?.map(id => (
          <CSSTransition timeout={300} classNames="toast" key={id}>
            <div className={Style.toast}>
              <div className={Style.loading} />
              <span className={Style.text}>脚本复制中</span>
              <div
                className={Style.close}
                onClick={e => {
                  e.stopPropagation();
                  closeToast(id);
                }}
              >
                <i className="iconfont icon-tost_icon_cancel" />
              </div>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>,
    container || document.body
  );
}
