import { useEffect, useRef } from 'react';
import { Button, Toast } from '@bhb-frontend/lithe-ui/lib';
import clone from '@bhb-frontend/utils/lib/clone';

import { uploadRobotSlice } from '@/api/robot';
import { LiveRobotSliceDetail, LiveRobotUploadParams } from '@/types/live';
import { Uploader } from '@/utils/upload';
import { DefaultRobotSliceData } from '../const';

import Style from './style.module.less';

interface UploadSliceProps {
  className: string;
  showVideoListTab: () => void;
  robotId: string;
  uploadIdList: string[];
  updateUploadIdList: (id: string) => void;
  updateSliceList: (data: LiveRobotSliceDetail) => void;
  coverWidth?: number;
  coverHeight?: number;
}

// 最后上传结果
interface UploadResults {
  url: string;
  duration?: number;
  coverUrl: string;
}

function UploadSlice(props: UploadSliceProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    className,
    showVideoListTab,
    robotId,
    uploadIdList,
    updateUploadIdList,
    updateSliceList,
    coverWidth = 112,
    coverHeight = 112,
  } = props;

  const uploadIdListRef = useRef<string[]>(uploadIdList);

  useEffect(() => {
    uploadIdListRef.current = uploadIdList;
  }, [uploadIdList]);

  // 获取本地视频信息
  const getVideoDetail = (file: any): Promise<UploadResults> =>
    new Promise(resolve => {
      const video = document.createElement('video');
      const url = URL.createObjectURL(file);
      video.src = url;
      video.setAttribute('preload', 'auto');

      video.addEventListener('loadedmetadata', () => {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.width = coverWidth;
        canvas.height = coverHeight;
        canvas
          ?.getContext('2d')
          ?.drawImage(video, 0, 0, coverWidth, coverHeight); // 绘制canvas
        const coverUrl = canvas.toDataURL('image/jpeg'); // 转换为base64
        window.URL.revokeObjectURL(url);
        resolve({
          url,
          duration: Number(video.duration.toFixed(2)),
          coverUrl,
        });
      });
    });

  const uploadStart = (uploadId: string) => {
    showVideoListTab();
    updateUploadIdList(uploadId);
    const defaultSliceData = clone(DefaultRobotSliceData);
    defaultSliceData.uploadId = uploadId;
    defaultSliceData.uploadStatus = 'uploading';
    updateSliceList(defaultSliceData);
  };

  const uploadSuccess = (key: string, data: LiveRobotUploadParams) => {
    if (uploadIdListRef.current.includes(key)) {
      // 如果不存在，说明点击了取消
      uploadRobotSlice(robotId, data).then(({ data }) => {
        data.uploadStatus = 'success';
        data.uploadId = key;
        updateSliceList(data);
      });
    }
  };

  const uploadFail = (uploadId: string) => {
    const defaultSliceData = clone(DefaultRobotSliceData);
    defaultSliceData.uploadId = uploadId;
    defaultSliceData.uploadStatus = 'fail';
    updateSliceList(defaultSliceData);
  };

  const fileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    /**
     * 1.获取所有上传视频
     * 2. 校验符合能够上传条件  10 分钟内 500M内
     * 3. 符合条件进行oss 上传
     * 4. oss 上传成功 开始执行后端接口
     * 5. 后端返回对应的值
     * 6. 上传成功，更新状态
     */
    const fileList = e.target.files;
    let initLen = fileList?.length || 0;
    if (!fileList || initLen === 0) {
      return;
    }
    const files = [...fileList].filter(
      (file: File) => file.size && file.size <= 500 * 1024 * 1024
    );

    if (initLen !== files.length) {
      Toast.warning(
        `上传视频不能超过500M,已过滤${initLen - files.length}个视频`
      );
      e.target.value = '';
      initLen = files.length;
    }

    // 非直接上传到oss，文档编辑模块
    files.forEach(async (file: File) => {
      const { duration } = await getVideoDetail(file);
      if (duration && duration <= 600) {
        const nowDateTime = new Date().getTime().toString();
        // $emit('callCreateUpload', nowDateTime);
        uploadStart(nowDateTime);

        const upload = new Uploader(file, {
          success: uploadSuccess,
          uploadKey: nowDateTime.toString(),
          videoDuration: duration,
        });

        upload.addScene('slice');
        upload.addModule('live');
        e.target.value = '';

        upload.upload().catch(() => {
          uploadFail(nowDateTime);
        });
      } else {
        e.target.value = '';
        Toast.warning('上传视频不能超过10分钟');
      }
    });
  };

  return (
    <>
      <Button
        className={className}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        上传切片
      </Button>
      <input
        className={Style.uploadInput}
        type="file"
        accept=".mp4,.mov"
        multiple
        ref={inputRef}
        onChange={fileChange}
      />
    </>
  );
}

export default UploadSlice;
