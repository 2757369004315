import { Link } from 'react-router-dom';
import Style from './style.module.less';
import assets from '@/assets';

interface LogoProps {
  title?: string;
}
function Logo(props: LogoProps) {
  const { title } = props;
  return (
    <Link to="/">
      <div className={Style.logo}>
        <img
          src={assets.images.common['logo.png']}
          className={Style.logoImage}
          alt="闪剪"
        />
        {title && (
          <>
            <span className={Style.logoLine} />
            <span className={Style.logoTitle}>{title}</span>
          </>
        )}
      </div>
    </Link>
  );
}
export default Logo;
