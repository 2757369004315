/** 智能成片socket status 枚举值 */
export enum IntelligentVideoStatus {
  /** 待处理 */
  PENDING = 'pending',
  /** 处理中 */
  PROCESSING = 'processing',
  /** 完成 */
  COMPLETED = 'completed',
  /** 失败 */
  FAIL = 'fail',

  CANCELED = 'canceled',
}
