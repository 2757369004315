/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/5b59d21f9500a0c37408d42efef7d306.png */
  'img-clip-bg.png': '5b59d21f9500a0c37408d42efef7d306.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/42b845fb1fd267ee45cd5520783161ba.png */
  'live_icon_empty.png': '42b845fb1fd267ee45cd5520783161ba.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/322a71627e7721559523c3f3b7644359.png */
  live_robot_time_def: '322a71627e7721559523c3f3b7644359.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d05b764ef580fceb3234206df0c4d7c8.png */
  goods_empty: 'd05b764ef580fceb3234206df0c4d7c8.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/aca169a9cec4f3ef23cab258a3fea54a.png */
  live_list_empty: 'aca169a9cec4f3ef23cab258a3fea54a.png',
};
