import { createBrowserRouter } from 'react-router-dom';

import Videos from '@/pages/Doc/Videos';
import MyVideo from '@/pages/MyVideo';
import Orders from '@/pages/Orders';
import MyDoc from '@/pages/Doc/MyDoc';

import Live from '@/pages/Live/Live';
import Editor from '@/pages/Editor';
import EditorVideos from '@/pages/Editor/Videos';

import WorkbenchLayout from '@/layout/Workbench';
import DocLayout from '@/layout/Doc';
import LiveLayout from '@/layout/Live';
import EditorLayout from '@/layout/Editor';
import Home from '@/pages/Home';
import TemplateCenter from '@/pages/TemplateCenter';
import CourseImg from '@/pages/CourseImg';

import VideoAccount from '@/pages/VideoAccount';
import Robot from '@/pages/Doc/Robot';
import RobotDetail from '@/pages/Doc/RobotDetail';
import LiveRobot from '@/pages/Live/Robot';
import LiveGoods from '@/pages/Live/Robot/Goods';
import LiveRobotDetail from '@/pages/Live/RobotDetail';
import MyRights from '@/pages/MyRights';
import CustomDigital from '@/pages/CustomDigital';
import CreateCustomDigital from '@/pages/CreateCustomDigital';

import ErrorPage from '@/pages/404';
import EquityComparison from '@/pages/EquityComparison';
import Routes from '@/config/Routes';

const routerData = [
  {
    errorElement: <ErrorPage />,
  },
  {
    ...Routes.CourseImg,
    element: <CourseImg />,
  },

  {
    ...Routes.Root,
    element: <WorkbenchLayout />,
    children: [
      {
        ...Routes.Home,
        element: <Home />,
      },
      {
        ...Routes.Root,
        element: <TemplateCenter />,
      },
      {
        ...Routes.Product,
        element: <MyVideo />,
      },
      {
        ...Routes.Orders,
        element: <Orders />,
      },
      {
        ...Routes.VideoAccount,
        element: <VideoAccount />,
      },
      {
        ...Routes.MyRights,
        element: <MyRights />,
      },
      {
        ...Routes.CustomDigitalList,
        element: <CustomDigital />,
      },
    ],
  },
  {
    ...Routes.Doc,
    element: <DocLayout />,
    children: [
      {
        ...Routes.Doc,
        element: <MyDoc />,
      },
      {
        ...Routes.DocVideos,
        element: <Videos />,
      },
      {
        ...Routes.DocRobot,
        element: <Robot />,
      },
      {
        ...Routes.DocRobotDetail,
        element: <RobotDetail />,
      },
    ],
  },
  {
    ...Routes.Editor,
    element: <EditorLayout />,
    children: [
      {
        ...Routes.Editor,
        element: <Editor />,
      },
      {
        ...Routes.EditorVideos,
        element: <EditorVideos />,
      },
    ],
  },
  {
    ...Routes.Live,
    element: <LiveLayout />,
    children: [
      {
        ...Routes.Live,
        element: <Live />,
      },
      {
        ...Routes.LiveRobot,
        element: <LiveRobot />,
      },
      {
        ...Routes.LiveGoods,
        element: <LiveGoods />,
      },
      {
        ...Routes.LiveRobotDetail,
        element: <LiveRobotDetail />,
      },
    ],
  },

  {
    ...Routes.CustomDigitalCreate,
    element: <CreateCustomDigital />,
  },

  {
    ...Routes.EquityComparison,
    element: <EquityComparison />,
  },
];
export default createBrowserRouter(routerData);
