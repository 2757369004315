import { VIDEO_STATUS } from '@/constants/videos';

const { COMPLETED, FAIL, PENDING, QUEUEING, CANCEL } = VIDEO_STATUS;

/** 视频列表状态 */
export enum PicSpeaksVideoStatusEnum {
  /** 成功 */
  SUCCESS = 'success',
  /** 失败 */
  FAIL = 'fail',
  /** 渲染中 */
  LOADING = 'loading',
  /** 排队中 */
  QUEUEING = 'queueing',
  /** 已取消 */
  CANCELED = 'canceled',
}

/** websocket 视频状态
 *  成功 - succeed 失败 - failed loading-渲染中
 */
export enum SocketPicSpeaksVideoStatusEnum {
  /** 成功 */
  SUCCEED = 'succeed',
  /** 失败 */
  FAILED = 'failed',
  /** 渲染中 */
  LOADING = 'loading',
}

export const figureLipSyncVideoStatusMap = {
  [PicSpeaksVideoStatusEnum.LOADING]: PENDING,
  [PicSpeaksVideoStatusEnum.SUCCESS]: COMPLETED,
  [PicSpeaksVideoStatusEnum.FAIL]: FAIL,
  [PicSpeaksVideoStatusEnum.QUEUEING]: QUEUEING,
  [PicSpeaksVideoStatusEnum.CANCELED]: CANCEL,
};

/** 照片说话跟数字人口播枚举一样 */
export const picSpeaksVideoStatusMap = {
  [SocketPicSpeaksVideoStatusEnum.SUCCEED]: COMPLETED,
  [SocketPicSpeaksVideoStatusEnum.FAILED]: FAIL,
  ...figureLipSyncVideoStatusMap,
};
