import { Toast, Tooltips } from '@bhb-frontend/lithe-ui';
import classNames from 'classnames';
import { Member } from '@bhb-frontend/mtv-navbar';

import { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { userStore } from '@/store';
import { STREAMING_PROTOCOL } from '@/constants/common';
import StreamingProtocol from '../StreamingProtocol';
import VipDialog from '@/components/VipDialog';
import { updateLiveCutTips } from '@/api/user';
import { checkLive, createLive } from '@/api/live';

import {
  CREATED_LIVE,
  CREATE_LIVE_ERROR_CODE,
  CREATE_LIVE_HINT_SWITCH,
} from './const';

import Style from './LiveAddInput.module.less';
import LiveMessage from '../LiveMessage';
import { goToWeb } from '@/utils/doc';
import assets from '@/assets';
import ModuleEnum from '@/constants/ModuleEnum';

enum Fields {
  LiveNum = 'liveNum',
  LiveDuration = 'liveDuration',
}

interface LiveAddInput {
  addSuccess: () => void;
}

function LiveAddInput() {
  // const { addSuccess } = props;

  /** 直播快剪权益 */
  const { liveCreateRiskTip, liveClipRights } = userStore.userInfo;

  /** 直播分享链接 */
  const [shareUrl, setShareUrl] = useState('');
  /* 当前状态 */
  const [status, setStatus] = useState(0);
  /* 提示信息 */
  const [message, setMessage] = useState('');
  /** 显示添加提示 */
  const [showMessage, setShowMessage] = useState(false);
  /** 直播间合规提示 */
  const streamingProtocolText = useMemo(() => STREAMING_PROTOCOL.LIVE, []);
  /** 是否需要定时器 */
  const [isTimer, setTimer] = useState(true);
  /** 提示标题 */
  const [title, setTitle] = useState('添加失败');

  /** 处理输入的直播url */
  const liveUrl = useMemo(() => {
    const reg = /(http|https):\/\/([\w.]+\/?)\S*/;
    const url = shareUrl.match(reg);
    return url && url.length > 0 ? url[0] : '';
  }, [shareUrl]);

  /** 直播快剪引导用户付费 */
  const leadPaid = async (code: number) => {
    if (!liveClipRights) return;
    const { type = 'free', liveNum = 0, liveDuration = 0 } = liveClipRights;
    /** 字段名 */
    let fields;
    /** 字段值 */
    let value;
    /** 开通会员标题 */
    let title;
    /** 开通会员提示内容 */
    let content;
    /** 开通会员权益最高情况提示 */
    let errorMessage;
    if (code === CREATE_LIVE_ERROR_CODE.LIVE_NUM) {
      fields = Fields.LiveNum;
      value = liveNum;
      title = '实时录制位已不足';
      content = '购买会员获取更多录制位';
      errorMessage = '录制位不足，你可以终止部分直播间后创建';
    }

    if (code === CREATE_LIVE_ERROR_CODE.USED_TIME) {
      fields = Fields.LiveDuration;
      value = liveDuration;
      title = '无法录制';
      content = '可用录制时长已不足，购买会员获得更多录制时长';
      errorMessage = '直播录制时长已达上限';
    }

    if (fields && value && title && content && errorMessage) {
      const isHaveRank = await Member.getHigherMember({
        type,
        fields,
        value,
        findVip: true,
        module: ModuleEnum.LIVE,
      });
      if (isHaveRank.isHave) {
        // 非最高等级会员
        VipDialog &&
          VipDialog.show?.({
            title,
            content,
            module: ModuleEnum.LIVE,
            vipParam: {
              tab: isHaveRank.type,
              memberId: isHaveRank.memberId,
              goodsIds: isHaveRank.goodsIds,
            },
          });
      } else {
        Toast.warning(errorMessage);
      }
    }
  };

  /** 处理添加直播时的错误 */
  const addError = (code: number, message: string) => {
    setTitle('添加失败');
    // 创建直播间时错误
    switch (code) {
      case CREATE_LIVE_ERROR_CODE.FAIL: {
        setStatus(CREATED_LIVE.fail);
        break;
      }
      case CREATE_LIVE_ERROR_CODE.LIVE_NUM: {
        // 直播间超出上限
        leadPaid(code);
        break;
      }
      case CREATE_LIVE_ERROR_CODE.USED_TIME: {
        // 直播时长为0
        leadPaid(code);
        break;
      }
      case CREATE_LIVE_ERROR_CODE.LIVE_URL_EXISTING: {
        // 地址已存在
        setStatus(CREATED_LIVE.created);
        break;
      }
      default: // 未检测到直播
        setStatus(CREATED_LIVE.noLive);
    }
    if (
      code === CREATE_LIVE_ERROR_CODE.LIVE_NUM ||
      code === CREATE_LIVE_ERROR_CODE.USED_TIME
    ) {
      return;
    }
    if (message.includes('timeout')) {
      setMessage('地址解析超时，请稍后再试～');
    }
    setMessage(message);
    setShowMessage(true);
  };

  /** 添加直播 */
  const addLive = () => {
    setShowMessage(false);
    setStatus(CREATED_LIVE.creating);
    createLive({ shareUrl: liveUrl })
      .then(({ data }) => {
        setStatus(CREATED_LIVE.success);
        Toast.success('创建成功！');
        setShareUrl('');
        goToWeb(`/clip/live/detail/${data.id}`);
      })
      .catch(err => {
        const { error, message } = err.body;
        addError(error, message);
      });
  };

  const beforeAdd = () => {
    if (!liveUrl) {
      setStatus(CREATED_LIVE.noLink);
      setMessage('请输入直播间地址');
      setShowMessage(true);
      return;
    }

    if (liveCreateRiskTip === CREATE_LIVE_HINT_SWITCH.ENABLE) {
      StreamingProtocol.show();
      return;
    }

    checkLive()
      .then(() => {
        addLive();
      })
      .catch(err => {
        const { error, message } = err.body;
        addError(error, message);
      });
  };

  /** 显示直播间提示 */
  const enterStreaming = () => {
    setTimer(false);
    StreamingProtocol.show(false);
  };

  /** 直播间合规提示 */
  const iKnow = async () => {
    if (liveCreateRiskTip === CREATE_LIVE_HINT_SWITCH.ENABLE) {
      try {
        await updateLiveCutTips({
          liveCreateRiskTip: CREATE_LIVE_HINT_SWITCH.DISABLE,
        });
      } catch (err: any) {
        console.log(err);
      }
      userStore.getUser();
    }
  };

  return (
    <div className={Style.live}>
      <div className={Style['live-input']}>
        <input
          placeholder="输入或粘贴你的直播分享链接"
          value={shareUrl}
          onInput={e => {
            setShareUrl((e.target as HTMLInputElement).value);
          }}
        />
        <div className={Style['live-input-suffix']}>
          {status === CREATED_LIVE.creating ? (
            <p className={Style['live-input-creating']}>
              <img
                className={Style['icon-img']}
                src={assets.images.global['icon-loading.png']}
                alt="img"
              />
              <span>正在校验地址</span>
            </p>
          ) : (
            <button
              className={classNames(Style['live-input-button'], {
                [Style['live-input-active']]: shareUrl,
              })}
              onClick={beforeAdd}
            >
              <span className="iconfont icon-a-fabu_icon_add_green2x" />
              添加直播
            </button>
          )}
        </div>
      </div>
      <span className={Style['input-tips']}>请自觉遵守平台链接导入规范</span>
      <Tooltips placement="bottom" tips="添加直播须知">
        <i
          className={classNames(
            Style['input-tips-streaming'],
            'iconfont',
            'icon-a-speed_icon_tips2x'
          )}
          onClick={enterStreaming}
        />
      </Tooltips>
      <LiveMessage
        title={title}
        message={message}
        visible={showMessage}
        onClose={() => {
          setShowMessage(false);
        }}
      />
      <StreamingProtocol
        streamingProtocolText={streamingProtocolText}
        textAlign="justify"
        isTimer={isTimer}
        iKnow={iKnow}
      />
    </div>
  );
}

export default observer(LiveAddInput);
