export enum PaymentMethod {
  /** 微信 */
  WECHAT = 'wechat',
  /** 支付宝 */
  ALIPAY = 'alipay',
  /** 苹果内购 */
  APPLE = 'apple',
  /** 汇付小程序 */
  WX_LITE = 'wx_lite',

  /**  内部赠送 */
  INNER = 'inner',

  /** 对公转账 */
  TRANSFER_BY_PUBLISH = 'transferByPublish',
  /** 公众号支付 */
  WX_PUB = 'wxpub',
  /** h5公众号支付 */
  H5 = 'h5',
  /** 兑换码 */
  REDEEM_CODE = 'redeemCode',
  /** 服务商开通 */
  DISTRIBUTOR = 'distributor',
  /** 抖店 */
  DOU_DIAN = 'doudian',
  /** 渠道商开通 */
  CHANNEL = 'channel',
  /** 统一支付-微信 服务商使用 */
  CHANNEL_WX = 'channelWx',
  /** 统一支付-支付宝 服务商使用 */
  CHANNEL_ALIPAY = 'channelAlipay',
  /** 小鹅通 */
  XIAO_E = 'xiaoe',

  /** 哇咔咔 wakaka */
  WA_KA_KA = 'wakaka',

  /** 视频小店 */
  WECHAT_VIDEO_SHOP = 'wechatVideoShop',
}
