import { Toast } from '@bhb-frontend/lithe-ui';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import clone from '@bhb-frontend/utils/lib/clone';
import assets from '@/assets';
import { updateRobot, getRobotLiveDetail } from '@/api/live';

import Styles from './style.module.less';

interface IOption {
  label?: string;
  value?: string;
  id?: string;
  name?: string;
  index?: number;
}

interface RobotSelectProps {
  maxLength?: number;
  value?: string[];
  // options: IOption[];
  robotId: string;
  onChange?: (value: string[]) => void;
  isEdit: boolean;
}

function RobotSelect(props: RobotSelectProps) {
  const { maxLength = 5, value = [], robotId, onChange } = props;

  const [tagList, setTagList] = useState(value);
  /* 标签列表 - 标签库 */
  const [options, setOptions] = useState<IOption[]>([]);
  /* 鼠标移入移出用的状态 */
  const [selectStyle, setSelectStyle] = useState(false);
  /* 输入的value值 */
  const [inputValue, setInputValue] = useState('');
  /* 显示隐藏下拉框 */
  const [showDropdown, setShowDropdown] = useState(false);
  /* 是否编辑标签列表 */
  const [editTag, setEditTag] = useState(false);
  /* 显示dropdown里面的input */
  const [showAddTagInput, setShowAddTagInput] = useState(false);
  /* 新增标签的value值 */
  const [addTagValue, setAddTagValue] = useState('');

  const selectRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const suffixRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const addTagValueRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTagList(value);
  }, [value]);

  useEffect(() => {
    onChange && onChange(tagList);
  }, [tagList]);

  /* 检查是否已经存在 */
  const checkTagList = (value: string) =>
    options
      .map(item => item.value)
      .concat(tagList)
      .includes(value);

  /* 获取标签库 */
  const getTags = (robotId: string) => {
    getRobotLiveDetail(robotId).then(res => {
      const { tags } = res.data || {};
      if (tags) {
        const mapTags = tags.map(item => ({ label: item, value: item }));
        setOptions(mapTags);
      }
    });
  };

  useEffect(() => {
    getTags(robotId);
  }, []);

  const dropdownPosition = e => {
    e.stopPropagation();

    setShowDropdown(!showDropdown);

    const self = selectRef.current as HTMLDivElement;
    /* 初始化dropdown的高度 */
    const height = self.offsetHeight;
    /* 初始化dropdown的宽度 */
    const width = self.offsetWidth;

    const { top, bottom } = self.getBoundingClientRect();

    if (dropdownRef.current) {
      if (bottom <= top) {
        dropdownRef.current.style.top = `${height + 8}px`;
      }

      if (bottom >= height) {
        dropdownRef.current.style.bottom = `${height + 8}px`;
      }
      dropdownRef.current.style.width = `${width}px`;
    }
  };

  /* 标签管理按钮点击之后变成编辑状态 */
  const handleEditTag = () => {
    if (editTag) {
      updateRobot(robotId, {
        tags: [...options.map(i => i.value as string)],
      }).then(res => {
        const { tags } = res.data || {};
        const mapTags =
          tags && tags.map((tag: string) => ({ label: tag, value: tag }));
        setOptions(mapTags);
      });
    }
    setEditTag(state => !state);
  };

  /* 选中的tag */
  const selected = (value: string) => {
    const cloneTagList = clone(tagList);
    const index = cloneTagList.indexOf(value);
    if (index !== -1) {
      cloneTagList.splice(index, 1);
      setTagList(cloneTagList);
      return;
    }
    setTagList(state => [...state, value]);
  };

  /* 删除输入框的tag */
  const deleteInputTag = (key: string) => {
    selected(key);
  };

  /* 删除标签 */
  const deleteTag = (key: string) => {
    const index = options.findIndex(item => item.value === key);
    if (index >= 0) {
      options.splice(index, 1);
      setOptions([...options]);
      const cloneTagList = clone(tagList);
      const tagIndex = cloneTagList.indexOf(key);
      if (tagIndex >= 0) {
        cloneTagList.splice(tagIndex, 1);
        setTagList(cloneTagList);
      }
    }
  };

  /* 显示添加标签的框 - input */
  const showInput = () => {
    setShowAddTagInput(true);
    setTimeout(() => {
      addTagValueRef.current?.focus && addTagValueRef.current.focus();
    });
  };

  /* 隐藏下拉 */
  const hiddenDropdown = () => {
    setShowDropdown(false);
    /* 恢复标签管理 */
    setEditTag(false);
    /* 恢复为添加标签 */
    setShowAddTagInput(true);
  };

  /* 处理dropdown的显示隐藏 */
  const handleDropdownShow = (e: Event) => {
    const dropdown = dropdownRef.current;
    const input = inputRef.current as HTMLInputElement;
    const target = e.target as HTMLElement;

    if (dropdown && !dropdown.contains(target) && !input.contains(target)) {
      hiddenDropdown();
    }
  };

  useEffect(() => {
    const suffix = suffixRef.current as HTMLDivElement;
    const input = inputRef.current as HTMLInputElement;

    if (showDropdown) {
      document.addEventListener('click', handleDropdownShow, true);
      suffix.style.transform = 'translateX(100%) rotate(-180deg)';
      /* 加上黑色边框 */
      input.style.border = '1px solid #191919';
    } else {
      document.removeEventListener('click', handleDropdownShow);
      suffix.style.transform = 'translateX(100%) rotate(0)';
      /* 去掉黑色边框 */
      input.style.border = '1px solid #dadce0';
    }
  }, [showDropdown]);

  /* 处理输入框输入的值进行保存 */
  const handleInputValue = e => {
    if (e.key === 'Enter') {
      const target = e.target as EventTarget & {
        value: string;
      };
      const value = target.value;

      setInputValue('');

      const isIncludes = checkTagList(value);
      if (isIncludes) {
        Toast.warning('已有重复的标签');
        return;
      }
      updateRobot(robotId, {
        tags: [...options.map(i => i.value as string), value],
      }).then(() => {
        setOptions(state => [...state, { label: value, value }]);
        setTagList(state => [...state, value]);
      });
    }
  };

  /* 同步inputValue */
  const changeInputValue = e => {
    const target = e.target as EventTarget & {
      value: string;
    };
    setInputValue(target.value);
  };

  /* 同步下拉框中的input */
  const changeAddTagValue = e => {
    const target = e.target as EventTarget & {
      value: string;
    };
    setAddTagValue(target.value);
  };

  /* 下拉框的新增标签 */
  const handleAddTagValue = e => {
    if (e.key === 'Enter' || e.type === 'blur') {
      const target = e.target as EventTarget & {
        value: string;
      };
      const value = target.value;

      if (!value) {
        setShowAddTagInput(false);
        return;
      }

      setAddTagValue('');

      const isIncludes = checkTagList(value);
      if (isIncludes) {
        Toast.warning('已有重复的标签');
        return;
      }
      updateRobot(robotId, {
        tags: [...options.map(i => i.value as string), value],
      }).then(() => {
        setOptions(state => [...state, { label: value, value }]);
      });
    }
  };

  return (
    <div className={Styles['robot-select']} ref={selectRef}>
      <div
        ref={inputRef}
        className={classnames(Styles['robot-select-view'], {
          [Styles.hover]: selectStyle,
        })}
        onMouseEnter={() => {
          setSelectStyle(true);
        }}
        onMouseLeave={() => {
          setSelectStyle(false);
        }}
        onClick={dropdownPosition}
      >
        <div className={Styles['robot-select-inner']}>
          {tagList &&
            tagList.map(tag => (
              <span
                key={tag}
                className={classnames(Styles['robot-select-tag'], {
                  [Styles.hignlight]: selectStyle,
                })}
              >
                <span>{tag}</span>
                <i
                  className={classnames(
                    Styles['robot-select-tag-close'],
                    'iconfont',
                    'icon-dingyue_icon_del'
                  )}
                  onClick={e => {
                    e.stopPropagation();
                    deleteInputTag(tag);
                  }}
                />
              </span>
            ))}
          <input
            type="text"
            value={inputValue}
            onKeyDown={handleInputValue}
            onInput={changeInputValue}
            className={Styles['robot-select-input']}
            maxLength={maxLength}
            placeholder={
              !tagList.length ? '输入标签按回车即可为商品添加标签' : ''
            }
          />
        </div>
        <div
          ref={suffixRef}
          className={Styles['robot-select-suffix']}
          onClick={dropdownPosition}
        >
          <i
            className={classnames(
              Styles['robot-select-suffix-icon'],
              'icon-dingyue_icon_pull-down',
              'iconfont'
            )}
          />
        </div>
      </div>
      <div
        className={Styles['robot-select-dropdown']}
        ref={dropdownRef}
        style={{ display: showDropdown ? 'block' : 'none' }}
      >
        <div className={Styles['robot-select-head']}>
          <p>
            <span>标签</span>
          </p>
          {!editTag ? (
            <p
              className={Styles['robot-select-head-manage']}
              onClick={handleEditTag}
            >
              <span>
                <i className="iconfont icon-dingyue_icon_set" /> 标签管理
              </span>
            </p>
          ) : (
            <p
              className={Styles['robot-select-head-done']}
              onClick={handleEditTag}
            >
              <span>
                <i className="iconfont icon-dingyue_icon_complete" />
                完成
              </span>
            </p>
          )}
        </div>
        <div className={Styles['robot-select-content']}>
          {options.length > 0 || editTag ? (
            <div className={Styles['robot-select-scrollbar']}>
              <div className={Styles['robot-select-warper']}>
                {options &&
                  options.map((option: IOption) => (
                    <span
                      key={option.value}
                      className={classnames(
                        Styles['robot-select-warper-item'],
                        Styles['robot-select-tag'],
                        {
                          [Styles.selected]:
                            option.value && tagList.includes(option.value),
                        }
                      )}
                      onClick={e => {
                        e.stopPropagation();
                        selected(option.value as string);
                      }}
                    >
                      <span>{option.label}</span>
                      {editTag && (
                        <i
                          className={classnames(
                            Styles['robot-select-tag-close'],
                            'icon-dingyue_icon_del',
                            'iconfont'
                          )}
                          onClick={e => {
                            e.stopPropagation();
                            deleteTag(option.value as string);
                          }}
                        />
                      )}
                    </span>
                  ))}
                <div
                  style={{
                    display: editTag && !showAddTagInput ? 'flex' : 'none',
                  }}
                  className={Styles['robot-select-add-value']}
                  onClick={showInput}
                >
                  <i className=" iconfont icon-a-login_icon_add" />
                  <span>添加标签</span>
                </div>
                <div
                  style={{
                    display: editTag && showAddTagInput ? 'flex' : 'none',
                  }}
                  className={Styles['robot-select-input-value']}
                >
                  <input
                    type="text"
                    ref={addTagValueRef}
                    value={addTagValue}
                    placeholder="请填写标签，失焦/回车可添加"
                    maxLength={maxLength}
                    onBlur={handleAddTagValue}
                    onInput={changeAddTagValue}
                    onKeyDown={handleAddTagValue}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={Styles['robot-select-empty']}>
              <img src={assets.images.global['img-empty-box.png']} alt="" />
              <p>没有数据</p>
              <div
                className={Styles['robot-select-empty-button']}
                onClick={handleEditTag}
              >
                创建标签
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RobotSelect;
