import VideoTypeEnum from '@/constants/videos/VideoTypeEnum';
import { VideoItem } from '@/types/videos';
import {
  cancelVideo,
  cancelLinkVideo,
  cancelIntelligentVideo,
  cancelDigitalVideo,
  cancelPhotoVideo,
} from '@/api/videos';

const cancelApiMap = {
  [VideoTypeEnum.VIDEO]: cancelVideo,
  [VideoTypeEnum.LINK_TO_VIDEO]: cancelLinkVideo,
  [VideoTypeEnum.INTELLIGENT_VIDEO]: cancelIntelligentVideo,
  [VideoTypeEnum.LIP_SYNC_PHOTO_FIGURE]: cancelDigitalVideo,
  [VideoTypeEnum.LIP_SYNC_VIDEO_FIGURE]: cancelDigitalVideo,
  [VideoTypeEnum.PIC_SPEAK]: cancelPhotoVideo,
};

export function videoListCancel(data: VideoItem) {
  const { type } = data;
  const api = cancelApiMap[type];
  if (!api) return null;
  if (type === VideoTypeEnum.VIDEO) {
    return api({
      videoRecordId: data.originalId,
    });
  }
  return api(data.originalId);
}
