import { InitOptions, createInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { localeMap, LocaleType } from './config';
// import { event } from '@/utils/event';
import { TFn } from './type';

/**
 * 设置 html 标签上面的语言
 * @param locale LocalType
 */
function setHtmlPageLang(locale: LocaleType) {
  document.querySelector('html')?.setAttribute('lang', locale);
}

/**
 * 获取本地语言
 * @returns LocalType
 */
function getLocalLocale() {
  let locale = navigator.language as LocaleType;
  if (!localeMap[locale]) {
    locale = 'en-US';
  }
  return localeMap[locale] as LocaleType;
}

/**
 * 生成 init 的配置项
 * @returns
 */
async function createI18nOptions(
  locale?: LocaleType
): Promise<InitOptions | null> {
  try {
    const lng = locale || getLocalLocale();
    const defaultLocal = await import(`./lang/${lng}.ts`);

    const message = defaultLocal.default ?? {};
    setHtmlPageLang(lng);
    return {
      lng,
      fallbackLng: localeMap['en-US'],
      resources: {
        [lng]: message as { [key: string]: string },
      },
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}

const i18n = createInstance();

// eslint-disable-next-line import/no-mutable-exports
let initI18nCallback: TFn = str => str;

async function setupI18n(locale?: LocaleType) {
  const options = await createI18nOptions(locale);
  if (options) {
    initI18nCallback = await i18n.use(initReactI18next).init(options);
  }
}

export { setupI18n, initI18nCallback };
export type { LocaleType };
