import { makeAutoObservable } from 'mobx';
import { CustomDigitalConfig } from '@/types/customDigital';
import { getCustomDigitalConfig } from '@/api/customDigital';

export class CustomDigitalStore {
  config: Partial<CustomDigitalConfig> = {};

  constructor() {
    makeAutoObservable(this);
  }

  async initConfig() {
    try {
      const { data } = await getCustomDigitalConfig();
      this.config = data;
    } catch (error) {
      console.error('获取数字人配置失败', error);
    }
  }
}

export const customDigitalStore = new CustomDigitalStore();
