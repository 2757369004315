import { useState } from 'react';
import { Tooltips } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import assets from '@/assets';
import { Ordering } from '@/types/myDoc';
import style from './DetailTableHead.module.less';

interface DetailTableHeadProps {
  /** 是否全部选中 */
  isSelectedAll: boolean;
  /** 选中列表 */
  selectedIdList: string[];
  /** 全选 */
  selectAll: (isSelect: boolean) => void;
  /** 排序 */
  onOrdering: () => void;
}

export default function DetailTableHead(props: DetailTableHeadProps) {
  const { isSelectedAll, selectedIdList, selectAll, onOrdering } = props;
  const [ordering, setOrdering] = useState(Ordering.ascending);

  const changeOrder = () => {
    setOrdering(oldValue =>
      oldValue === Ordering.ascending ? Ordering.descending : Ordering.ascending
    );
    onOrdering();
  };

  const onClickSelectIcon = () => {
    selectAll(!isSelectedAll);
  };

  const selectIcon = (
    <img
      alt=""
      onClick={onClickSelectIcon}
      className={cs(style['select-icon'], {
        [style.selected]: selectedIdList.length > 0,
      })}
      src={
        isSelectedAll && selectedIdList.length > 0
          ? assets.images.doc['icon-choice.png']
          : selectedIdList.length > 0
          ? assets.images.doc['icon-choice2.png']
          : assets.images.doc['icon-nochoice.png']
      }
    />
  );

  return (
    <div className={style['table-head']}>
      <div className={style['select-box']}>{selectIcon}</div>

      <div className={style['table-head-info']}>
        {/* 文档名称 */}
        <div className={cs(style['head-item'], style['plus-item'])}>
          商品信息
        </div>
        <div className={cs(style['head-item'])}>商品信息</div>
        <div className={cs(style['head-item'])}>状态</div>
        <div className={cs(style['head-item'], style['plus-item'])}>
          <Tooltips tips="设置过滤项">
            <span
              onClick={changeOrder}
              className={cs(style['hover-box'], style['hover-content'])}
            >
              添加时间
              <i
                className={cs(
                  'icon-dingyue_icon_pull-down iconfont',
                  style['arrow-icon'],
                  {
                    [style['arrow-icon-rotate']]:
                      ordering === Ordering.descending,
                  }
                )}
              />
            </span>
          </Tooltips>
        </div>
      </div>
    </div>
  );
}
