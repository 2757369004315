import classnames from 'classnames';
import { Tooltips, Popup, Menu } from '@bhb-frontend/lithe-ui';

import Style from './DocTableHead.module.less';
import { Tag } from '../types';
import assets from '@/assets';

interface DocTableHeadProps {
  /** tag 列表 */
  tagList: Tag[];
  /** 排序 */
  onSort: () => void;
  /** 通过tag获取列表 */
  getListByTag: (tag: Tag) => void;
  /** 全选 */
  selectAll: (isSelect: boolean) => void;
  /** 是否全部选中 */
  isSelectedAll: boolean;
  /** 选中列表 */
  selectedIdList: string[];
  /** 当前选中的tag */
  selectedTag: Tag;
  /** tag => 所有标签信息 */
  allTagInfo: Tag;
}

export default function DocTableHead(props: DocTableHeadProps) {
  const {
    onSort,
    tagList,
    getListByTag,
    isSelectedAll,
    selectedIdList,
    selectAll,
    selectedTag,
    allTagInfo,
  } = props;

  const overlay = (
    <Menu
      className={Style['menu-container']}
      onItemClick={({ value }) => {
        getListByTag(value);
      }}
    >
      {[allTagInfo, ...tagList].map(tag => (
        <Menu.Item value={tag} key={tag.id}>
          {tag.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const onClickSelectIcon = () => {
    if (isSelectedAll) {
      selectAll(false);
      return;
    }
    selectAll(true);
  };

  // TODO: lithe-ui 组件库还未实现group功能，暂时使用图片代替。
  const selectIcon = (
    <img
      alt=""
      onClick={onClickSelectIcon}
      className={classnames(Style['select-icon'], {
        [Style.selected]: selectedIdList.length > 0,
      })}
      src={
        isSelectedAll
          ? assets.images.doc['icon-choice.png']
          : selectedIdList.length > 0
          ? assets.images.doc['icon-choice2.png']
          : assets.images.doc['icon-nochoice.png']
      }
    />
  );

  return (
    <div className={Style['doc-table-item']}>
      <div className={Style['select-box']}>{selectIcon}</div>

      <div className={Style['doc-table-head-info']}>
        <div className={classnames(Style['doc-name'], Style['head-item'])}>
          <div>文档名称</div>
        </div>
        <div className={classnames(Style['tag-name'], Style['head-item'])}>
          <Popup
            placement="bottom"
            overlay={overlay}
            trigger="click"
            clickClosable
          >
            <span className={Style['hover-box']}>
              <Tooltips tips="设置过滤项">
                <span className={Style['hover-content']}>
                  {selectedTag.name}
                  <i
                    className={classnames(
                      'icon-dingyue_icon_pull-down iconfont',
                      Style['arrow-icon']
                    )}
                  />
                </span>
              </Tooltips>
            </span>
          </Popup>
        </div>
        <div
          className={classnames(Style['update-time-sort'], Style['head-item'])}
        >
          <Tooltips tips="设置过滤项">
            <span className={Style['hover-box']} onClick={onSort}>
              <span className={Style['hover-content']}>
                最后更新时间
                <i
                  className={classnames(
                    'icon-dingyue_icon_pull-down iconfont',
                    Style['arrow-icon']
                  )}
                />
              </span>
            </span>
          </Tooltips>
        </div>
        <div className={classnames(Style['head-item'], Style.more)} />
      </div>
    </div>
  );
}
