import { DOC_URL, HOME_URL, SUB_URL, WEB_URL, WORKBENCH_URL } from '@/config';
import { addUrlQuery } from '@/utils';

const handleAddInviteId = (url: string) =>
  addUrlQuery(url, 'inviteId', sessionStorage.getItem('inviteId') || '');

/** 跳转新图文快剪 */
export function goToDoc(url: string, target: '_self' | '_blank' = '_blank') {
  const docUrl = `${DOC_URL}${url}`;
  window.open(handleAddInviteId(docUrl), target);
}

/** 跳转就web */
export function goToWeb(url: string) {
  const docUrl = `${WEB_URL}${url}`;
  window.open(handleAddInviteId(docUrl), '_blank');
}

/** 跳转官网 */
export function goToHome(url = '', target = '_blank') {
  const homeUrl = `${HOME_URL}${url}`;
  window.open(handleAddInviteId(homeUrl), target);
}

/** 打开新页面 */
export function openWindow(url: string) {
  const openUrl = `${WORKBENCH_URL}${url}`;
  window.open(handleAddInviteId(openUrl), '_blank');
}

/** 跳转订阅号 */
export function goToSubscription(url: string) {
  const subUrl = `${SUB_URL}${url}`;
  window.open(handleAddInviteId(subUrl), '_blank');
}
