import request from '@/api/init';
import { DetailResponse, ListQuery, CustomListResponse } from '@/types/api';
import { ThirdAccountItem, ThirdAccountPassport } from '@/types/third-account';
import { VideoItem, PublishVideoBody } from '@/types/videos';

/**
 * 视频列表
 * @param param 请求路径
 * @returns
 */
export function apiVideoList(
  param?: ListQuery
): Promise<CustomListResponse<VideoItem>> {
  const uri = '/clip/video/index';
  return request.get(uri, param);
}

/**
 * 第三方平台授权
 * @param data
 */
export function authAccountPassport(
  data: ThirdAccountPassport
): Promise<DetailResponse<ThirdAccountItem>> {
  return request.post('/third_account/passport', data);
}

/**
 * 第三方平台
 * @param param
 * @returns
 */
export function getAccountList(
  param?: ListQuery
): Promise<CustomListResponse<ThirdAccountItem>> {
  const uri = '/third_account';
  return request.get(uri, param);
}

/**
 * 删除第三方账号
 * @param id
 */
export function deleteAccount(id: string): Promise<void> {
  const uri = `/third_account/${id}`;
  return request.delete(uri);
}

/**
 * 删除视频
 */
export function removeVideo(id: string): Promise<void> {
  const uri = `/clip/video/${id}`;
  return request.delete(uri);
}

/**
 *  取消渲染视频
 *  错误码:1014-视频生成中，不能取消
 * @param renderTaskId 渲染任务id
 */
export function cancelVideo(data: {
  videoRecordId?: string;
  renderTaskId?: string;
}): Promise<DetailResponse<void>> {
  const uri = '/clip/video/cancel';
  return request.post(uri, data);
}

/**
 * 视频发布至第三方平台
 * @param params
 */
export function publishVideo(
  data: PublishVideoBody
): Promise<DetailResponse<void>> {
  const uri = '/third_account/video/publish';
  return request.post(uri, data);
}

/**
 *  视频审核不通过申诉
 */
export function appeal(videoRecordId = ''): Promise<DetailResponse<void>> {
  return request.post('/clip/video/appeal', { videoRecordId });
}

/** ----------3.3.0---------- */

/** 获取所有视频 */
export function getAllVideoList(
  param?: ListQuery
): Promise<CustomListResponse<VideoItem>> {
  const uri = '/all_video';
  return request.get(uri, param);
}

/** 获取链接成片视频详情 */
export function getLinkVideoDetail(id: string) {
  return request.get<DetailResponse<VideoItem>>(`/link_to_video/${id}`);
}

/** 批量删除作品 */
export function deleteVideoList(ids: string[]) {
  return request.delete<DetailResponse<VideoItem>>('/delete_video/many', {
    ids,
  });
}

/** 取消链接成片 */
export function cancelLinkVideo(id: string) {
  return request.post<DetailResponse<VideoItem>>(`/link_to_video/${id}/cancel`);
}

/** 取消智能成片 */
export function cancelIntelligentVideo(id: string) {
  return request.put<DetailResponse<VideoItem>>(
    `/intelligent_video/cancel/${id}`
  );
}

/** 取消数字人口播 */
export function cancelDigitalVideo(id: string) {
  return request.put<DetailResponse<VideoItem>>(
    `/figure_lip_sync_video/${id}/cancel`
  );
}

/** 取消照片说话 */
export function cancelPhotoVideo(id: string) {
  return request.put<DetailResponse<VideoItem>>(
    `/cancel/pic_speaks/user/works/${id}`
  );
}
