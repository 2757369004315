import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import assets from '@/assets';
import { VideoItem } from '@/types/videos';

import Styles from './index.module.less';

interface PreviewInfo {
  isPreview: boolean;
  info: VideoItem;
  controls: boolean;
  autoplay: boolean;
  publish: () => void;
  download: () => void;
  remove: (id: string) => void;
  close: () => void;
}

export default function PreviewModal(props: PreviewInfo) {
  const {
    isPreview,
    info,
    controls,
    autoplay,
    publish,
    download,
    remove,
    close,
  } = props;
  /* volume更改  */
  const videoRef = useRef<HTMLVideoElement>();

  const canplay = async () => {
    try {
      await videoRef.current?.play();
    } catch (e) {
      console.log('浏览器禁止自动播放error', e);
      // 不能自动播放，需要静音
      videoRef.current!.volume = 0;
      await videoRef.current?.play();
    }
  };

  const handleRemove = (id: string) => {
    close();
    remove(id);
  };

  return (
    <CSSTransition
      timeout={150}
      in={isPreview}
      unmountOnExit
      classNames="preview"
    >
      <div className={Styles.mask}>
        <div className={Styles.cover}>
          <div className={Styles.warper}>
            <video
              ref={videoRef as React.LegacyRef<HTMLVideoElement>}
              src={info?.videoUrl}
              poster={info?.coverUrl}
              controls={controls}
              autoPlay={autoplay}
              className={Styles.video}
              webkit-playsinline="true"
              playsInline
              preload="true"
              onCanPlay={canplay}
            />
            <div className={Styles.slide}>
              <div className={Styles.close} onClick={close}>
                <img
                  src={assets.images.common['preview_icon_close_white@2x.svg']}
                  alt=""
                />
              </div>
              <div className={Styles.box}>
                <div onClick={publish}>
                  <img
                    src={assets.images.common['dingyue_icon_jisifabu@2x.svg']}
                    alt=""
                  />
                  <span className={Styles.text}>发布</span>
                </div>
                <div onClick={download}>
                  <img
                    src={assets.images.common['preview_icon_dowload.svg']}
                    alt=""
                  />
                  <span className={Styles.text}>下载</span>
                </div>
                <div
                  onClick={() => {
                    handleRemove(info.id);
                  }}
                >
                  <img
                    src={assets.images.common['video_icon_delete.svg']}
                    alt=""
                  />
                  <span className={Styles.text}>删除</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
