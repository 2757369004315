import { useState, useEffect, useRef } from 'react';
import { Modal, Button, Toast, Input } from '@bhb-frontend/lithe-ui';

import { useParams } from 'react-router-dom';

import assets from '@/assets';
import Styles from './index.module.less';
import Select from '@/components/Select';
import { getGoodsList as getGoodsListApi, publishVideo } from '@/api/robot';
import { GoodsResult } from '@/types/robot';
import { LiveRobotSliceDetail } from '@/types/live';

interface PublishModalProps {
  onPublish: (info: LiveRobotSliceDetail) => void;
}

type TPublishModal = React.FC<Record<string, any>> & {
  show?: (info: LiveRobotSliceDetail) => void;
};

let PublishModal: TPublishModal | null = null;

function BaseModal(props: PublishModalProps) {
  const { id: robotId } = useParams();
  const { onPublish } = props;
  const [videoTitle, setVideoTitle] = useState('');

  /* 当前modal框的显隐 */
  const [visible, setVisible] = useState(false);

  /* 当前视频信息 */
  const info = useRef<Partial<LiveRobotSliceDetail>>({});
  /* 是否播放 */
  const [isPlay, setPlay] = useState(false);
  /* 左侧视频播放 */
  const videoRef = useRef<HTMLVideoElement>();

  const [goodsList, setGoodsList] = useState<GoodsResult[]>([]);
  const [goods, setGoods] = useState('');

  /* 点击播放 */
  const handlePlay = () => {
    if (isPlay) {
      videoRef.current?.pause && videoRef.current.pause?.();
      setPlay(false);
    } else {
      videoRef.current?.play && videoRef.current.play?.();
      setPlay(true);
    }
  };

  const cancel = () => {
    setVisible(false);
  };

  // 重置表单状态
  const resetForm = () => {
    setVideoTitle('');
    setGoodsList([]);
  };

  const isValid = () => {
    if (!videoTitle.trim()) {
      Toast.warning('请填写视频标题');
      return false;
    }
    if (!goods) {
      Toast.warning('请选择商品信息');
      return false;
    }
    return true;
  };

  const publish = () => {
    // 重新点击发布，被记作为重新触发发布任务
    if (!info.current.id) return;
    if (!isValid()) return;
    const obj = {
      type: 'robot',
      title: videoTitle,
      goodsInfo: goodsList.filter(item => item.id === goods),
    };
    publishVideo(info.current.id, 'publish', obj).then(() => {
      setVisible(false);
      resetForm();
      onPublish(info.current as LiveRobotSliceDetail);
    });
  };

  const getGoodsList = async () => {
    const {
      data: { results },
    } = await getGoodsListApi({
      /* 机器人id */
      liveRobotId: robotId || '',
      sid: '', // 游标
      pageSize: 99,
    });
    setGoodsList(results);
  };

  useEffect(() => {
    if (PublishModal) {
      PublishModal.show = (detail: LiveRobotSliceDetail) => {
        info.current = detail;
        setVideoTitle('');
        setVisible(true);
        getGoodsList();
      };
    }
  }, []);

  useEffect(() => {
    if (visible) {
      getGoodsList();
    }
  }, [visible]);

  return (
    <Modal
      width={975}
      height="auto"
      visible={visible}
      onClose={cancel}
      timeout={0}
    >
      <div className={Styles.modal}>
        <div className={Styles.title}>
          <span>发布视频</span>
        </div>
        <div className={Styles.publish}>
          {/* 视频组件 */}
          <div className={Styles.player} onClick={handlePlay}>
            <video
              ref={videoRef as React.LegacyRef<HTMLVideoElement>}
              src={info.current?.videoUrl}
              poster={info.current?.coverUrl}
              className={Styles.video}
              webkit-playsinline="true"
              playsInline
              preload="true"
            />
            {!isPlay && (
              <img
                className={Styles.icon}
                src={assets.images.common['common_icon_play@2x.svg']}
                alt=""
              />
            )}
          </div>
          {/* 发布设置 */}
          <div className={Styles.setting}>
            <div className={Styles.inner}>
              {/* 视频描述 */}
              <div className={Styles.subtitle}>视频标题</div>
              <Input
                placeholder="请填写视频标题"
                value={videoTitle}
                onChange={e => {
                  setVideoTitle(e.target.value);
                }}
              />

              {/* 商品信息 */}
              <div className={Styles.subtitle}>商品信息</div>
              <Select
                placeholder="请选择商品信息"
                options={goodsList.map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
                onChange={val => {
                  setGoods(val);
                }}
              />
            </div>
            {/* 底部按钮 */}
            <div className={Styles.bottom}>
              <div className={Styles.wrapper}>
                <Button className={Styles.gray} onClick={cancel}>
                  取消
                </Button>
                <Button className={Styles.primary} onClick={publish}>
                  确认
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

PublishModal = BaseModal as TPublishModal;

export default BaseModal;
