import request from '@/api/init';
import {
  GetDocParmas,
  GetLiveParmas,
  GetVideoProjectParmas,
  DocDetail,
  LiveDetail,
  VideoProjectDetail,
  GetAgentAccessTokenParams,
  GetAgentAccessTokenReturns,
} from '@/types/home';
import {
  CustomListResponse,
  DetailResponse,
  CommonResponse,
} from '@/types/api';

/**
 * 获取文档列表
 */
export function getDocList(
  params: GetDocParmas
): Promise<CustomListResponse<DocDetail>> {
  return request.get('/clip/documents', params);
}

/**
 * 删除文档
 * @param id 文档id
 */
export function removeDoc(id: string) {
  return request.delete<CommonResponse>(`/clip/documents/${id}`);
}

/**
 * 重命名文档
 */
export function renameDoc(id: string, production: string) {
  return request.put<CommonResponse>(`/clip/documents/${id}/production`, {
    production,
  });
}

/**
 * 获取直播列表
 */
export function getLiveList(params: GetLiveParmas) {
  return request.get<CustomListResponse<LiveDetail>>('/live/lives', params);
}

/**
 * 删除直播
 */
export function removeLive(id: string) {
  return request.delete<CommonResponse>(`/live/lives/${id}`);
}

/**
 * 暂停直播
 */
export function stopLive(liveId: string) {
  return request.post('/live/lives/terminate', { liveId });
}

/**
 * 获取视频编辑器列表
 */
export function getVideoProjectList(params: GetVideoProjectParmas) {
  return request.get<CustomListResponse<VideoProjectDetail>>(
    '/editor/projects',
    params
  );
}

/**
 * 删除视频项目
 */
export function removeVideoProject(id: string) {
  return request.delete<DetailResponse<void>>(`/editor/projects/${id}`);
}

/**
 * 重命名视频项目
 */
export function renameVideoProject(id: string, name: string) {
  return request.put<CommonResponse>(`/editor/projects/${id}`, { name });
}

/**
 * 获取分销链接
 */
export function getAgentLink(params: GetAgentAccessTokenParams) {
  return request.get<DetailResponse<GetAgentAccessTokenReturns>>(
    '/agent/linkUrl',
    params
  );
}
