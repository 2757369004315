import Style from './Videos.module.less';
import EditorVideoList from '@/components/EditorVideoList';

function Videos() {
  return (
    <div className={Style['video-content']}>
      <div className={Style.header}>
        <div className={Style.title}>我的视频</div>
      </div>

      <div className={Style.content}>
        <EditorVideoList />
      </div>
    </div>
  );
}

export default Videos;
