/** 动态设置整体页面的高度，不采用vh， 因为safari浏览器的100vh包含地址栏和底部栏的高度，而其他浏览器只是视口高度 */
export function initHtmlHeight() {
  const APP = document.getElementById('root');
  const HTML = document.getElementsByTagName('html')[0];
  const BODY = document.getElementsByTagName('body')[0];

  if (APP && HTML && BODY) {
    APP.style.height = `${window.innerHeight}px`;
    HTML.style.height = `${window.innerHeight}px`;
    BODY.style.height = `${window.innerHeight}px`;
  }
}
