import { Empty } from '@bhb-frontend/lithe-ui';
import { EmptyProps } from '@bhb-frontend/lithe-ui/lib/Empty';
import assets from '@/assets';
import style from './EmptyList.module.less';

export default function EmptyContent(props: EmptyProps) {
  return (
    <Empty
      className={style['empty-list']}
      text="暂无数据"
      imgSrc={assets.images.home['empty-list.png']}
      {...props}
    />
  );
}
