import { Input } from '@bhb-frontend/lithe-ui';
import { store } from '@bhb-frontend/store';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import classNames from 'classnames';
import Menu from '../Menu';
import { liveMenu, robotConfig } from './menuConfig';
import Style from './Live.module.less';
import { configStore } from '@/store/config';
import {
  MY_LIVE_SEARCH_KEY,
  MY_LIVE_SEARCH_NAMESPACE,
} from '@/constants/myLive';
import { userStore } from '@/store';

function LiveLayout() {
  const location = useLocation();
  const { agent } = configStore.config;

  const menu = useMemo(() => {
    if (userStore.subscribeAuthList.indexOf('live') === -1) return liveMenu;
    return [...liveMenu, robotConfig];
  }, [userStore.subscribeAuthList]);

  /** 直播快剪 */
  const content =
    location.pathname === '/live' ? (
      <span className={Style['input-comp']}>
        <Input
          prefix={
            <i
              className={classNames(
                'iconfont icon-a-common_icon_search2x',
                Style['search-input-icon']
              )}
            />
          }
          onPressEnter={event => {
            store.set(
              MY_LIVE_SEARCH_NAMESPACE,
              MY_LIVE_SEARCH_KEY,

              (event.target as HTMLInputElement).value
            );
          }}
          placeholder="搜索"
          contentClassName={Style['input-content']}
        />
      </span>
    ) : null;
  return (
    <Menu
      menuConfig={menu}
      searchBox={content}
      isShowInvite={agent?.switch && agent.liveMainPage.switch}
      inviteText={agent && agent.liveMainPage.text}
      title="直播快剪"
      module="live"
      isShowBeiAn={false}
    />
  );
}

export default observer(LiveLayout);
