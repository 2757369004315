import { Dialog, Form, Input } from '@bhb-frontend/lithe-ui';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import UploadImage from '../Upload';
import { editGood, addGood } from '@/api/live';
import { GoodsResult } from '@/types/live';
import RobotSelect from '../RobotSelect';

import Styles from './style.module.less';

interface AddGoodsModalProps {
  visible: boolean;
  onClose: () => void;
  isEdit: boolean;
  info?: GoodsResult;
  getList: () => void;
}

function AddGoodsModal(props: AddGoodsModalProps) {
  const { visible, onClose, isEdit, info, getList } = props;

  const params = useParams();
  const [form] = Form.useForm();

  const title = useMemo(() => (isEdit ? '编辑商品' : '添加商品'), [isEdit]);
  const robotId = useMemo(() => params.id || '', [params]);

  useEffect(() => {
    if (visible && isEdit) {
      form.setFieldsValue(info);
    }
  }, [visible]);

  const onCancel = () => {
    onClose && onClose();
    form.resetFields();
  };

  const save = () => {
    form.validateFields().then((res: GoodsResult) => {
      const params = {
        liveRobotId: robotId,
        ...res,
      };

      if (isEdit) {
        editGood(info?.id as string, params).then(() => {
          onCancel();
          getList && getList();
        });
      } else {
        addGood(params).then(() => {
          onCancel();
          getList && getList();
        });
      }
    });
  };

  return (
    <Dialog
      visible={visible}
      onCancel={onCancel}
      title={title}
      width={600}
      height={690}
      onOk={save}
      className={Styles.dialog}
    >
      <Form form={form} className={Styles.form}>
        <Form.Item
          label="商品名称"
          name="name"
          rules={[
            {
              required: true,
              message: '请填写商品名称',
            },
            { max: 50, message: '最大输入50字' },
          ]}
        >
          <Input placeholder="请填写商品名称" />
        </Form.Item>
        <Form.Item
          label="商品链接"
          name="link"
          rules={[{ required: true, message: '请填写商品链接' }]}
        >
          <Input placeholder="请填写商品链接" />
        </Form.Item>
        <Form.Item label="商品图片" name="imageKey">
          <UploadImage />
        </Form.Item>
        <Form.Item label="商品价格" name="price">
          <Input type="number" placeholder="请输入价格（元）" />
        </Form.Item>
        <Form.Item label="商品佣金率" name="commissionRate">
          <Input type="number" placeholder="请输入佣金率（0%-100%）" />
        </Form.Item>
        <Form.Item label="商品标签" name="tags">
          <RobotSelect robotId={robotId} isEdit={isEdit} />
        </Form.Item>
      </Form>
    </Dialog>
  );
}

export default AddGoodsModal;
