import Select from 'react-select';
import './style.less';

interface SelectComProps {
  placeholder?: string;
  value?: string;
  onChange?: (val: string) => void;
  options: Array<{
    value: string | number;
    label: string | number;
  }>;
  /** 是否可以输入 */
  isSearchable?: boolean;
}

export default function SelectCom(props: SelectComProps) {
  const {
    value,
    onChange,
    options,
    placeholder = '请选择',
    isSearchable = false,
  } = props;
  const handleChange = e => {
    const { value } = e;
    onChange?.(value);
  };

  return (
    <Select
      onChange={handleChange}
      classNamePrefix="lt-select"
      options={options as any}
      placeholder={placeholder}
      value={options.find(item => item.value === value)}
      isSearchable={isSearchable}
    />
  );
}
