import { ListQuery } from './api';

export enum Ordering {
  /** 升序 */
  ascending = 1,
  /** 降序 */
  descending = -1,
}

export enum SearchType {
  /** 标签 */
  label = 'label',
  /** 标题 */
  title = 'title',
  /** 产品名称 */
  production = 'production',
}

/** 获取文档参数 */
export interface GetDocParmas extends ListQuery {
  /** 根据最后更新时间排序  1表示升序， -1表示降序 */
  orderBy: Ordering;
  /**  搜索类型，如label表示标签，title表示标题 */
  searchType?: SearchType;
  /** 搜索关键字，如输入标签id，或 文档标题 */
  keyword?: string;
}

/** 视频详情 */
interface VideoInfo {
  id: string; // 视频id
  videoUrl: string; // 视频地址
  resolvingPower: number; // 分辨率
  width: string; // 宽
  height: string; // 高
}

/** 文档列表项 */
export interface DocItem {
  /** 文档id */
  id: string;
  /** 已经生成的视频id */
  videoId: string;
  video: VideoInfo;
  /** 缩略图 */
  imageThumbnailUrl: string;
  /** 标题 */
  title: string;
  /** 截取过的内容 */
  showText: string;
  /** 标签名 */
  labelName: string[];
  /** 表示视频生成状态 init-未生成, pending-生成中, completed-已生成，fail-生成失败 */
  status: 'init' | 'pending' | 'completed' | 'fail';
  /** 最后更新时间 */
  updatedTime: string;
  /** 文档大小 字节 */
  size: number;
  /** 是否空白文档 */
  isBlank: boolean;
  /**  private-默认私有  onlyRead-公开只读 */
  permission: 'private' | 'onlyRead';
  /** 文档名字 */
  production: string;
}

export interface CreateNewDocParmas {
  /** 标题 */
  title: string;
  /** 主题id */
  themeId?: string;
  /** 机器人 id 非必传,有值时默认是机器人 */
  subscribeStoreId?: string;
}

export interface TagItem {
  /** 创建时间 */
  createdAt: string;
  /** id */
  id: string;
  /** tag 名称 */
  name: string;
  /** 更新时间 */
  updatedAt: string;
  /** 用户id */
  userId: string;
}
