import request from '@/api/init';
import { DetailResponse, CustomListResponse } from '@/types/api';
import {
  InterUserItems,
  ConsumeRecordParams,
  ConsumeRecordDetail,
} from '@/types/user';

/**
 * 获取用户信息（自己）
 */
export function getUserInfo(): Promise<DetailResponse<InterUserItems>> {
  const uri = '/users/info';
  return request.get(uri);
}

/** 修改直播快剪创建合规提示开关 */
export function updateLiveCutTips(data: { liveCreateRiskTip: string }) {
  const uri = '/users/info/live_create_risk_tip';
  return request.put<DetailResponse<InterUserItems>>(uri, data);
}

/**
 *
 * @param params 兑换码
 * @returns
 */
export function verifyRedeemCode(data: {
  redeemCode: string;
}): Promise<DetailResponse<{ message: string }>> {
  return request.post('/redeem_code/exchange', data);
}

/** 权益消耗明细*/
export function consumeRecord(params: ConsumeRecordParams) {
  return request.get<CustomListResponse<ConsumeRecordDetail>>(
    '/consume_record',
    params
  );
}
