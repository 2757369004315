import classnames from 'classnames';
import assets from '@/assets';
import Style from './index.module.less';

interface TableHeadProps {
  /** 全选函数 */
  selectAllFN: (isSelect: boolean) => void;
  /** 是否全部选中 */
  isSelectedAll: boolean;
  /** 选中列表 */
  selectedIdList: string[];
}

export default function TableHead(props: TableHeadProps) {
  const { isSelectedAll, selectedIdList, selectAllFN } = props;

  /** 选中全部元素 */
  const onClickSelectIconFN = () => {
    if (isSelectedAll) {
      selectAllFN(false);
      return;
    }
    selectAllFN(true);
  };

  // TODO: lithe-ui 组件库还未实现group功能，暂时使用图片代替。
  const selectIcon = (
    <img
      alt=""
      onClick={onClickSelectIconFN}
      className={classnames(Style['select-icon'], {
        [Style.selected]: selectedIdList.length > 0,
      })}
      src={
        isSelectedAll
          ? assets.images.doc['icon-choice.png']
          : selectedIdList.length > 0
          ? assets.images.doc['icon-choice2.png']
          : assets.images.doc['icon-nochoice.png']
      }
    />
  );

  return (
    <div className={Style['table-item']}>
      {false && <div className={Style['select-box']}>{selectIcon}</div>}
      <div className={Style['table-head-info']}>
        <div className={classnames(Style.name, Style['head-item'])}>
          <div>项目名称</div>
        </div>
      </div>
    </div>
  );
}
