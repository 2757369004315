import Style from './Robot.module.less';
import RobotList from './RobotList/RobotList';

export default function Robot() {
  return (
    <div className={Style.container}>
      <RobotList />
    </div>
  );
}
