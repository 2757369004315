import { Input, Modal, Toast } from '@bhb-frontend/lithe-ui';
import cls from 'classnames';
import * as React from 'react';
import Style from './ExchangeCenter.module.less';

import { verifyRedeemCode } from '@/api/user';

interface ExchangeProps {
  visible: boolean;
  onClose: () => void;
}

export default function ExchangeCenter(props: ExchangeProps) {
  const { visible, onClose } = props;

  /** 兑换码 */
  const [redeemCode, setRedeemCode] = React.useState('');

  /** input 抬起 */
  const inputKeyUp = (e: any) => {
    setRedeemCode(e.target.value);
  };

  /** 点击确定兑换 */
  const redeemConfirm = () => {
    if (!redeemCode) return;
    verifyRedeemCode({ redeemCode })
      .then(({ data }) => {
        Toast.success(data.message);
        onClose();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const content = () => (
    <div className={Style['exchange-wrapper']}>
      <p className={Style['exchange-wrapper-title']}> 请输入兑换码</p>
      <Input
        placeholder="请输入兑换码"
        className={cls(Style['exchange-wrapper-input'], {
          [Style.active]: redeemCode,
        })}
        onChange={inputKeyUp}
      />
      <div
        className={cls(Style['exchange-wrapper-button'], {
          [Style.active]: redeemCode,
        })}
        onClick={redeemConfirm}
      >
        兑换
      </div>

      <div className={Style['exchange-wrapper-remind']}>
        <h6 className={Style['exchange-wrapper-remind-title']}>兑换须知</h6>
        <p className={Style['exchange-wrapper-remind-p']}>
          1.兑换成功后权益立即生效
        </p>
        <p className={Style['exchange-wrapper-remind-p']}>
          2.使用多个兑换码，权益有效期则延长对应期限
        </p>
        <p className={Style['exchange-wrapper-remind-p']}>
          3.兑换码一经使用，不予更改，请认真保管您的兑换码
        </p>
      </div>
    </div>
  );

  return (
    <div className={Style.exchange}>
      <Modal
        width={375}
        height={343}
        visible={visible}
        className={Style['exchange-center']}
        closable={false}
        timeout={0}
        onClose={onClose}
      >
        {content()}
      </Modal>
    </div>
  );
}
