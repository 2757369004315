import { HOST } from '../config';

import common, { CommonKey } from './common';
import videos, { VideosKey } from './videos';
import doc, { docKey } from './doc';
import live, { liveKey } from './live';
import home, { homeKey } from './home';
import global, { Global } from './global';
import robot, { Robot } from './robot';
import subscription, { Subscription } from './subscription';
import clip, { Clip } from './clip';
import myRights, { MyRights } from './myRights';

export interface Images {
  common: {
    [P in CommonKey]: string;
  };
  videos: {
    [P in VideosKey]: string;
  };
  doc: {
    [P in docKey]: string;
  };
  live: {
    [P in liveKey]: string;
  };
  home: {
    [P in homeKey]: string;
  };
  global: {
    [P in Global]: string;
  };
  robot: {
    [P in Robot]: string;
  };
  subscription: {
    [P in Subscription]: string;
  };
  clip: {
    [P in Clip]: string;
  };
  myRights: {
    [P in MyRights]: string;
  };
}

/**
 * 处理数据，添加 CDN 的 host 与路径。
 * @param data 数据
 * @returns data
 */
const t = <T extends string>(data: { [P in T]: string }) => {
  Object.keys(data).forEach((k: string) => {
    data[k as T] = `${HOST}/${data[k as T]}`;
  });
  return data;
};

const images: Images = {
  common: t(common),
  videos: t(videos),
  doc: t(doc),
  live: t(live),
  home: t(home),
  global: t(global),
  robot: t(robot),
  subscription: t(subscription),
  clip: t(clip),
  myRights: t(myRights),
};

export default images;
