import { makeAutoObservable, action, observe } from 'mobx';
import { LoginType } from '@bhb-frontend/mtv-login';
import {
  setCookie,
  removeCookie,
  getCookie,
  expiresDate,
} from '@/utils/cookie';
import { getUserInfo } from '@/api/user';

import { InterUserItems } from '@/types/user';
import { getSubscriptionAuth } from '@/api/subscription';
import { getToken } from '@/utils/token';

class UserStore {
  userInfo: Partial<InterUserItems> = {
    socketLink: '',
  };

  loginType: LoginType | '' = '';

  /** 订阅号权限 */
  subscribeAuth = false;

  /* 订阅号权限列表 */
  subscribeAuthList: string[] = [];

  constructor() {
    makeAutoObservable(this);

    // 登录之后 再开始请求接口
    observe(this, 'userInfo', this.getSubAuth, false);
  }

  /** 设置用户信息 */
  @action
  public setUserInfo(userInfo: InterUserItems) {
    const { sessionToken, socketLink } = userInfo;
    const param = {};
    try {
      const isRememberToken = JSON.parse(
        getCookie('isRememberToken') as string
      );
      if (isRememberToken) {
        Object.assign(param, { expires: expiresDate });
      }
    } catch (error) {
      console.warn(error);
    }
    sessionToken && setCookie('token', sessionToken, param);
    socketLink && setCookie('socketLink', socketLink);
    this.userInfo = userInfo;
  }

  /** 设置订阅号权限 */
  @action
  public setSubscribeAuth(val: boolean) {
    this.subscribeAuth = val;
  }

  /** 设置订阅号权限列表 */
  @action
  public setSubscribeAuthList(val: string[]) {
    this.subscribeAuthList = val;
  }

  public login(userInfo: InterUserItems, type: LoginType) {
    this.setUserInfo(userInfo);
    this.loginType = type;
  }

  public loginOut() {
    this.removeInfo();
  }

  public removeInfo() {
    this.userInfo = {} as Partial<InterUserItems>;
    removeCookie('token');
    removeCookie('socketLink');
  }

  public async getUser(): Promise<Partial<InterUserItems>> {
    try {
      const { data } = await getUserInfo();
      this.setUserInfo(data);
      return data;
    } catch (error) {
      console.log(error);
      // 请求用户信息失败
      // this.removeInfo();
    }
    return this.userInfo;
  }

  /** 获取用户订阅号权限 */
  public getSubAuth = async () => {
    try {
      const { data } = await getSubscriptionAuth();
      this.setSubscribeAuth(Boolean(data.result.subscribe));
      /* 获取用户订阅号权限列表 - 新版本用这个 */
      this.setSubscribeAuthList(data.result.permissions);
    } catch (error) {
      console.log(error);
    }
  };

  /** 获取登录状态 */
  public isLogin() {
    return !!getToken();
  }
}

export const userStore = new UserStore();
