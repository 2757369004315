import { observer } from 'mobx-react';
import { formatTime } from '@bhb-frontend/utils/lib/format';
import { ShanJianMember } from '@bhb-frontend/mtv-navbar';
import Style from './UserRightsCard.module.less';
import { userStore } from '@/store';

function UserRightsCard() {
  if (!userStore.userInfo.shanjianMemberRights) return null;

  const { isMember, expiredAt } = userStore.userInfo.shanjianMemberRights;

  return (
    <div className={Style.rights}>
      <div className={Style.info}>
        <p className={Style.text}>
          <span>闪剪</span>
          <span className={Style.vip}>VIP</span>
        </p>
        <p className={Style.desc}>
          {isMember
            ? `${formatTime(new Date(expiredAt), 'yyyy.mm.dd')} 到期`
            : '开通会员，解锁更多权益'}
        </p>
      </div>
      <div
        className={Style.btn}
        onClick={() => {
          ShanJianMember?.show();
        }}
      >
        {isMember ? '续费会员' : ' 开通会员'}
      </div>
    </div>
  );
}
export default observer(UserRightsCard);
