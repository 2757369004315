import { IMenuConfig } from '@/types/menu';
/** 海外版路由 */
export const liveMenu: IMenuConfig = [
  {
    path: '/live',
    key: '/live',
    name: '我的直播',
    iconClass: 'icon-a-tab_icon_live2x',
    activeIconClass: 'icon-a-tab_icon_live2x',
  },
];

export const robotConfig = {
  path: '/live/robot',
  key: '/live/robot',
  name: '机器人',
  iconClass: 'icon-a-tab_icon_robot_def2x1',
  activeIconClass: 'icon-a-tab_icon_robot_def2x1',
};
