const Routes = {
  /** 根目录 */
  Root: {
    key: '/',
    path: '/',
  },

  /** 首页 */
  Home: {
    key: 'home',
    path: '/home',
  },

  /** 作品页 */
  Product: {
    key: 'product',
    path: '/product',
  },

  /** 订单 */
  Orders: {
    key: 'orders',
    path: '/orders',
  },

  /** 视频账号 */
  VideoAccount: {
    key: 'videoAccount',
    path: '/video-account',
  },

  /** 我的权益 */
  MyRights: {
    key: 'myRights',
    path: '/my-rights',
  },

  /** 定制数字人列表 */
  CustomDigitalList: {
    key: 'customDigitalList',
    path: '/child-custom-digital/list',
  },

  // =============== doc ================

  /** 口播 */
  Doc: {
    key: 'doc',
    path: '/doc',
  },

  /** 口播视频页 */
  DocVideos: {
    key: 'docVideos',
    path: '/doc/videos',
  },

  /** 口播机器人 */
  DocRobot: {
    key: 'docRobot',
    path: '/doc/robot',
  },

  /** 口播机器人详情 */
  DocRobotDetail: {
    key: 'docRobotDetail',
    path: '/doc/robot/detail/:id',
  },

  // =============== editor ================
  /** 编辑器 */
  Editor: {
    key: 'editor',
    path: '/editor',
  },

  /** 编辑器视频页 */
  EditorVideos: {
    key: 'editorVideos',
    path: '/editor/videos',
  },

  // =============== live ================
  /** 直播 */
  Live: {
    key: 'live',
    path: '/live',
  },

  /** 直播机器人 */
  LiveRobot: {
    key: 'liveRobot',
    path: '/live/robot',
  },

  /** 直播商品 */
  LiveGoods: {
    key: 'LiveGoods',
    path: '/live/robot/detail/goods/:id/:name',
  },

  /** 直播机器人详情 */
  LiveRobotDetail: {
    key: 'liveRobotDetail',
    path: '/live/robot/detail/:id',
  },

  // =============== 其他 ================
  /** 定制数字人创建页 */
  CustomDigitalCreate: {
    key: 'CustomDigitalCreate',
    path: '/child-custom-digital/create',
  },

  /** 权益页 */
  EquityComparison: {
    key: 'equityComparison',
    path: '/equity-comparison',
  },
  /** 课程图片 */
  CourseImg: {
    key: 'courseImg',
    path: '/course-img',
  },
};

export default Routes;
