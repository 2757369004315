import * as React from 'react';
import { Dialog, Spin } from '@bhb-frontend/lithe-ui';

import classNames from 'classnames';
import Styles from './index.module.less';

interface TipProps {
  visible: boolean;
  loading?: boolean;
  option: {
    title?: string;
    content: string;
    okText?: string;
    cancelText?: string;
  };
  onOk?: () => void;
  onCancel?: () => void;
  width?: number;
}

export default function TipDialog(props: TipProps) {
  const { visible, loading, option, onOk, onCancel, width = 283 } = props;
  const { title, content, okText, cancelText } = option;

  return (
    <Dialog
      timeout={0}
      width={width}
      height="auto"
      visible={visible}
      title=""
      footer={null}
      onCancel={onCancel}
      placementOffset={100}
      className={Styles['tip-dialog']}
    >
      <div className={Styles.wrapper}>
        <Spin spinning={!!loading}>
          <div className={Styles.title}>{title}</div>
          <div className={Styles.content}>
            <span>{content}</span>
          </div>
          <div className={Styles.btns}>
            {cancelText && (
              <div
                className={classNames(Styles.cancel, Styles.btn)}
                onClick={onCancel}
              >
                {cancelText}
              </div>
            )}
            {okText && (
              <div className={classNames(Styles.ok, Styles.btn)} onClick={onOk}>
                {okText}
              </div>
            )}
          </div>
        </Spin>
      </div>
    </Dialog>
  );
}
