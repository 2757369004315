import {
  Modal,
  Popup,
  Menu,
  Tooltips,
  LoadMore,
  Toast,
} from '@bhb-frontend/lithe-ui';
import { useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import cloneDeep from '@bhb-frontend/utils/lib/clone';
import { useParams } from 'react-router-dom';
import Style from './RobotDocsDialog.module.less';
import assets from '@/assets/images';
import {
  TagItem,
  Ordering,
  DocItem,
  GetDocParmas,
  SearchType,
} from '@/types/myDoc';
import { getDocList, asyncDocs } from '@/api/myDoc';

export type Tag = Pick<TagItem, 'name' | 'id'>;

interface RobotDocsDialogProps {
  visible: boolean;
  modalOpenCloseFN: () => void;
  tagList: Tag[];
  allTagInfo: Tag;
  updateList: () => void;
}

interface Item extends DocItem {
  isSelected?: boolean;
}

enum SelectItems {
  ALL = 'all',
  NONE = 'none',
  SELECTED = 'selected',
}

function RobotDocsDialog(props: RobotDocsDialogProps) {
  const { visible, modalOpenCloseFN, tagList, allTagInfo, updateList } = props;
  const [status, setStatus] = useState(SelectItems.NONE);
  const [selectedTag, setSelectTag] = useState<Tag>(allTagInfo);
  const [list, setList] = useState<Item[]>([]);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [iconTransforml, setIconTransform] = useState(false);
  const params = useRef<GetDocParmas>({
    sid: '',
    pageSize: 20,
    orderBy: Ordering.descending,
  });
  const failIdsRef = useRef<string[]>([]);
  const { id } = useParams();

  /** 选择全部 */
  const selectAll = (isSelect: boolean) => {
    const docs = cloneDeep(list);
    docs.map(doc => {
      doc.isSelected = isSelect;
      return doc;
    });
  };

  const selectIcon = status => {
    switch (status) {
      case SelectItems.ALL:
        return (
          <img
            src={assets.robot['icon-choice.png']}
            className={`${Style.iconSelect} ${Style.selected}`}
            alt="选择图标"
            onClick={() => {
              setStatus(SelectItems.NONE);
              selectAll(false);
            }}
          />
        );
      case SelectItems.SELECTED:
        return (
          <img
            src={assets.robot['icon-choice2.png']}
            className={`${Style.iconSelect} ${Style.selected}`}
            alt="选择图标"
            onClick={() => {
              setStatus(SelectItems.ALL);
              selectAll(true);
            }}
          />
        );

      default:
        return (
          <img
            src={assets.robot['icon-nochoice.png']}
            className={`${Style.iconSelect}`}
            alt="选择图标"
            onClick={() => {
              setStatus(SelectItems.ALL);
              selectAll(true);
            }}
          />
        );
    }
  };

  /** 获取列表 */
  const getList = async () => {
    try {
      setLoading(true);
      const { data } = await getDocList({
        ...params.current,
      });
      params.current = {
        ...params.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (err) {
      console.warn(err);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    params.current = { ...params.current, sid: '' };
    const data = await getList();
    if (data) {
      setList(data.results);
      setIsEmpty(!data.results.length);
    }
  };

  const tagOverlay = (
    <Menu
      className={Style['menu-container']}
      onItemClick={({ value }) => {
        setSelectTag(value);
        if (!value.id) {
          params.current = {
            sid: '',
            pageSize: 20,
            orderBy: Ordering.ascending,
          };
          initList();
          return;
        }
        params.current = {
          ...params.current,
          searchType: SearchType.label,
          keyword: value.id,
        };
        initList();
      }}
    >
      {[allTagInfo, ...tagList].map(tag => (
        <Menu.Item value={tag} key={tag.id}>
          {tag.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getList();
    if (data) {
      setList([...list, ...data.results]);
    }
  };

  const onSort = () => {
    params.current.orderBy =
      params.current.orderBy === Ordering.ascending
        ? Ordering.descending
        : Ordering.ascending;
    initList();
    setIconTransform(!iconTransforml);
  };

  const selectDoc = id => {
    const docs = cloneDeep(list);
    const index = docs.findIndex(doc => doc.id === id);
    if (index > -1) {
      docs[index].isSelected = !docs[index].isSelected;
      setList(docs);
    }
  };

  /** 文档名称 */
  const infoTitle = (info: Item) => {
    if (info) {
      // 移除所有空格
      const newTitle = info?.title?.replaceAll(/(\s|\n)+/g, '');
      // 内容截取前15个字数
      const interceptTitle = info?.showText?.substr(0, 15);
      return newTitle || interceptTitle || '未命名文档';
    }
    return '未命名文档';
  };

  /** 时间筛选 */
  const formatDate2 = time => {
    const diff = new Date(time).getTime() - new Date().setHours(0, 0, 0, 0);
    let day = '';
    if (diff >= 0) {
      day = '今天';
    } else if (diff >= -24 * 60 * 60 * 1000) {
      day = '昨天';
    } else {
      const t = new Date(time);
      const m = t.getMonth() + 1;
      const d = t.getDate();
      return `${t.getFullYear()}-${m > 9 ? m : `0${m}`}-${d > 9 ? d : `0${d}`}`;
    }
    const hour = new Date(time).getHours();
    const minutes = new Date(time).getMinutes();
    return `${day} ${hour}:${minutes < 10 ? `0${minutes}` : minutes}`;
  };

  /** 分批次添加 */
  const addDocs = async (list: string[]) => {
    let ids: string[] = [];
    if (list.length > 10) {
      ids = list.splice(0, 10);
      const {
        data: { failIds },
      } = await asyncDocs(id as string, ids);
      failIdsRef.current = failIdsRef.current.concat(failIds);
      return addDocs(list);
    }
    const {
      data: { failIds },
    } = await asyncDocs(id as string, list);
    failIdsRef.current = failIdsRef.current.concat(failIds);
    return Promise.resolve();
  };

  /** 确认选择文档 */
  const confirm = () => {
    const ids = list.filter(doc => doc.isSelected).map(doc => doc.id);
    if (ids.length < 1) {
      return;
    }
    addDocs(ids).then(() => {
      Toast.success(
        `添加完成，有 ${failIdsRef.current.length} 个文档添加失败！`
      );
      selectAll(false);
      failIdsRef.current.length = 0;
      updateList();
      modalOpenCloseFN();
    });
  };

  /** 空渲染 */
  const EmptyRender = () => (
    <div className={Style.empty}>
      <img src={assets.common['img-empty-box.png']} alt="空数据" />
      暂时没有文档
    </div>
  );

  /** 列表渲染 */
  const listRender = () => (
    <LoadMore
      className={Style.tableList}
      onReachBottom={loadMore}
      loading={loading}
      hasMore={hasMore}
    >
      {list.map(item => (
        <div
          key={item.id}
          className={Style.item}
          onClick={() => {
            selectDoc(item.id);
          }}
        >
          <div className={`${Style.itemColumn} ${Style.itemName}`}>
            {item.isSelected ? (
              <img
                src={assets.robot['icon-choice.png']}
                className={`${Style.iconSelect} ${Style.selected}`}
                alt="选择图标"
              />
            ) : (
              <img
                src={assets.robot['icon-nochoice.png']}
                className={`${Style.iconSelect}`}
                alt="选择图标"
              />
            )}
            <i
              className={cs('iconfont icon-a-add_icon_doc2x', Style.itemIcon)}
            />
            <span>{item.production || infoTitle(item)}</span>
          </div>
          <div className={`${Style.itemColumn} ${Style.itemType}`}>
            {item.labelName[0] || '-'}
          </div>
          <div className={`${Style.itemColumn} ${Style.itemTime}`}>
            {formatDate2(item.updatedTime)}
          </div>
        </div>
      ))}
    </LoadMore>
  );

  const footerRender = () => (
    <footer className={Style.footer}>
      <button
        className={`${Style.footerButton} ${Style.footerButtonDefault}`}
        onClick={modalOpenCloseFN}
      >
        取消
      </button>
      <button
        className={`${Style.footerButton} ${Style.footerButtonPrimary} ${
          list.filter(doc => doc.isSelected).map(doc => doc.id).length < 1
            ? Style.footerButtonDisabled
            : ''
        }`}
        disabled={
          list.filter(doc => doc.isSelected).map(doc => doc.id).length < 1
        }
        onClick={confirm}
      >
        确定
      </button>
    </footer>
  );

  useEffect(() => {
    if (visible) {
      initList();
    }
  }, [visible]);

  useEffect(() => {
    if (list.length > 0 && list.every(doc => !!doc.isSelected)) {
      setStatus(SelectItems.ALL);
    } else if (list.length > 0 && list.some(doc => !!doc.isSelected)) {
      setStatus(SelectItems.SELECTED);
    } else {
      setStatus(SelectItems.NONE);
    }
  }, [list]);

  return (
    <Modal
      width={1008}
      height="auto"
      visible={visible}
      className={Style.dialog}
      onClose={modalOpenCloseFN}
      closable={false}
      timeout={0}
    >
      <div className={Style.dialogHeader}>
        <div className={Style.dialogTitle}>添加文档至当前机器人</div>
        <i
          className={`iconfont icon-a-search_icon_delete2x ${Style.dialogClose}`}
          onClick={() => {
            modalOpenCloseFN();
          }}
        />
      </div>
      <div className={Style.table}>
        <div className={Style.tableHeader}>
          <div className={Style.tableHeaderItem}>
            {selectIcon(status)}
            文档名称
          </div>
          {/* tag 标签 */}
          <div
            className={`${Style.tableHeaderItem} ${Style.tableHeaderItemTagname}`}
          >
            <Popup
              overlay={tagOverlay}
              trigger="click"
              clickClosable
              placement="bottom"
            >
              <span className={Style.hoverBox}>
                <Tooltips tips="设置过滤项">
                  <span className={Style.hoverContent}>
                    {selectedTag?.name}
                    <i
                      className={cs(
                        'icon-dingyue_icon_pull-down iconfont',
                        Style.arrowIcon
                      )}
                    />
                  </span>
                </Tooltips>
              </span>
            </Popup>
          </div>
          {/* 发布时间 */}
          <div className={Style.tableHeaderItem} onClick={onSort}>
            <Tooltips tips="设置过滤项">
              <span className={cs(Style.hoverBox, Style.hoverContent)}>
                发布时间
                <i
                  style={{
                    transform: iconTransforml
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                  }}
                  className={cs(
                    'icon-dingyue_icon_pull-down iconfont',
                    Style.arrowIcon
                  )}
                />
              </span>
            </Tooltips>
          </div>
        </div>
        {isEmpty ? EmptyRender() : listRender()}
      </div>
      {footerRender()}
    </Modal>
  );
}

export default RobotDocsDialog;
