import { Modal } from '@bhb-frontend/lithe-ui';
import { useEffect, useRef, useState } from 'react';
import Style from './RobotManage.module.less';
import { RobotItem, AuthItem, SearchQuery } from '@/types/robot';
import { CommonResponse, CustomListResponse } from '@/types/api';

interface RobotManageProps<T> {
  visible: boolean;
  modalOpenCloseFN: () => void;
  unbindSuccess: () => void;
  robotInfo?: T;
  getList: (
    id: string,
    params: SearchQuery
  ) => Promise<CustomListResponse<AuthItem>>;
  removeAuth: (storeId: string, id: string) => Promise<CommonResponse>;
}

function RobotManage<T extends Record<string, any> = RobotItem>(
  props: RobotManageProps<T>
) {
  const {
    visible,
    robotInfo,
    modalOpenCloseFN,
    unbindSuccess,
    getList,
    removeAuth,
  } = props;

  const [list, setList] = useState<AuthItem[]>([]);

  const [confirm, setConfirm] = useState(false);

  const curryRobotId = useRef<string>();

  const init = () => {
    getList(robotInfo?.id as string, {
      pageSize: 100,
      sid: '',
    }).then(data => {
      setList(data.data.results);
    });
  };

  useEffect(() => {
    if (visible) {
      init();
      return;
    }
    setList([]);
  }, [visible]);

  const remove = () => {
    removeAuth(robotInfo?.id as string, curryRobotId.current as string).then(
      () => {
        setConfirm(false);
        init();
        unbindSuccess();
      }
    );
  };

  /** 确认弹窗 */
  const removeDialog = () => (
    <Modal
      width={420}
      height="auto"
      visible={confirm}
      onClose={() => {
        setConfirm(false);
      }}
      closable={false}
    >
      <div className={Style.confirmHeader}>
        <i
          className={`iconfont icon-a-search_icon_delete2x ${Style.manageClose}`}
          onClick={() => {
            setConfirm(false);
          }}
        />
      </div>
      <p className={Style.confirmTitle}>取消授权？</p>
      <p className={Style.confirmContent}>
        取消授权后，当前机器人无法再为订阅号提供服务
      </p>
      <div className={Style.confirmFooter}>
        <button
          className={`${Style.confirmButton} ${Style.confirmButtonDefault}`}
          onClick={() => {
            setConfirm(false);
          }}
        >
          我再想想
        </button>
        <button
          className={`${Style.confirmButton} ${Style.confirmButtonPrimary}`}
          onClick={() => {
            remove();
          }}
        >
          取消授权
        </button>
      </div>
    </Modal>
  );

  return (
    <>
      <Modal
        width={738}
        height="auto"
        visible={visible}
        className={Style.manage}
        onClose={() => {
          modalOpenCloseFN();
        }}
        closable={false}
        timeout={0}
      >
        <div className={Style.manageHeader}>
          <div className={Style.manageTitle}>{robotInfo?.name} 授权管理</div>
          <i
            className={`iconfont icon-a-search_icon_delete2x ${Style.manageClose}`}
            onClick={() => {
              modalOpenCloseFN();
            }}
          />
        </div>
        <div className={Style.manageForm}>
          <header>
            <span>账号名称</span>
            <span>账号状态</span>
            <span>操作</span>
          </header>
          {list.map(item => (
            <div className={Style.manageFormBody} key={item.id}>
              <span>{item.name}</span>
              <span>{item.isShow ? '上架' : '下架'}</span>
              <span
                className={Style.manageFormButton}
                onClick={() => {
                  setConfirm(true);
                  curryRobotId.current = item.id;
                }}
              >
                解除绑定
              </span>
            </div>
          ))}
        </div>
      </Modal>
      {removeDialog()}
    </>
  );
}

export default RobotManage;
