import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CONTEXT_ENV } from '@/config';
import { ENV } from '@/utils';

const sentryConfig = {
  dsn: '',
  integrations: [new BrowserTracing()],
};

export function initSentry() {
  switch (ENV) {
    case CONTEXT_ENV.prod:
      Object.assign(sentryConfig, {
        dsn: 'https://50ca1051811246a9bf03480392fc4363@sentry.bhbapp.cn/39',
        release: process.env.RELEASE_VERSION,
      });
      break;
    default:
      return;
  }

  // if (ENV === CONTEXT_ENV.prod) {
  //   Object.assign(sentryConfig, { tracesSampleRate: 1.0 });
  // }

  Sentry.init(sentryConfig);
}
