import { EffectCallback, useEffect, useRef } from 'react';

export function useMount(fn: EffectCallback) {
  const didMoment = useRef(false);
  useEffect(() => {
    if (didMoment.current === true) return;
    didMoment.current = true;
    fn?.();
  }, []);
}
