import { Modal, Button, Input, Spin } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import React, { useState, useEffect } from 'react';
import { EditorProjectsApiModal as EApimodal } from '@/types/editorProjectsApi';
import { putEditorProjectsAPI } from '@/api/editorProjects';

import Style from './index.module.less';

interface ModalWrapperProps extends ModalProps {
  selectProjects: EApimodal.DgetEditorProjectsAPI[];
  onConfirmFN: (data: EApimodal.DgetEditorProjectsAPI) => void;
  modalActionFN: (data: boolean) => void;
}

export default function Removedialog(props: ModalWrapperProps) {
  const { visible, selectProjects, modalActionFN, onConfirmFN } = props;

  /**
   * 创建loading
   */
  const [btnLoading, setBtnLoadingFN] = useState(false);

  /** 作品名称 */
  const [title, setTitleFN] = useState('');

  const onChangeFN = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleFN(e.target.value);
  };

  /** 控制弹窗开关函数 */
  const modalOpenCloseFN = (data: boolean) => {
    !data && setBtnLoadingFN(false);
    modalActionFN(data);
  };

  /** 确定按钮函数 */
  const onOkFN = async () => {
    setBtnLoadingFN(true);
    const project = selectProjects[0];
    const { data } = await putEditorProjectsAPI(project.id, {
      name: title,
    });
    onConfirmFN(data);
    modalOpenCloseFN(false);
  };

  useEffect(() => {
    const project = selectProjects[0];
    if (project) {
      setTitleFN(project.name);
    }
  }, [selectProjects]);

  return (
    <Modal
      visible={visible}
      width={420}
      height="auto"
      timeout={0}
      onClose={() => {
        modalOpenCloseFN(false);
      }}
      closable={false}
      contentClassName={Style['modal-container']}
    >
      <Spin spinning={btnLoading} className={Style['btn-loading']}>
        <div className={Style['modal-header']}>输入项目名称</div>
        <div className={Style['modal-body']}>
          <Input
            placeholder="0-30个字符"
            maxLength={30}
            onChange={onChangeFN}
            value={title}
          />
        </div>
        <div className={Style['modal-footer']}>
          <span>
            <Button
              className={Style['cancel-btn']}
              onClick={() => {
                modalOpenCloseFN(false);
              }}
            >
              取消
            </Button>
            <Button className={Style['confirm-btn']} onClick={onOkFN}>
              确定
            </Button>
          </span>
        </div>
      </Spin>
    </Modal>
  );
}
