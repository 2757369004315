import VideoList from '@/components/VideoList';
import { userStore } from '@/store';
import NotLogin from '../NotLogin';
// import Style from './product.module.less';

export default function Product() {
  if (!userStore.isLogin()) {
    return <NotLogin />;
  }

  return (
    // <div className={Style.product}>
    <VideoList isHomeStyle />
    // </div>
  );
}
