
      function getConfig() {
        if (MTV_CONFIG.env === undefined) {
          setTimeout(() => {
            getConfig();
          }, 300);
        } else {
          MTV_CONFIG.env.isI18n && embeddedCode();
        }
      }

      function addMeta() {
        // <!-- 灰度、测试环境禁止搜索引擎搜录 -->
        if (['dev', 'test', 'gray'].includes(import.meta.env.MODE)) {
          const meta = document.createElement('meta');
          meta.content = 'noindex,nofollow,nosnippet,noarchive,noodp';
          meta.name = 'robots';
          document.getElementsByTagName('head')[0].appendChild(meta);
        }
      }

      // 设置百度
      function setBaiDuHm() {
        const hm = document.createElement('script');
        const src = ['dev', 'test', 'gray'].includes(import.meta.env.MODE)
          ? 'https://hm.baidu.com/hm.js?ae9e654c16f1ef087c4551b6b2c59a0d'
          : 'https://hm.baidu.com/hm.js?a7be99870d70a701b14fbe173ddaa491';
        hm.src = src;
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hm, s);
      }
      setBaiDuHm();
      addMeta();

      getConfig();
    