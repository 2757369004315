import { useState, useRef, useEffect } from 'react';
import { Spin, Toast } from '@bhb-frontend/lithe-ui';
import { useNavigate } from 'react-router-dom';
import style from './style.module.less';
import assets from '@/assets';
import { RobotItem } from '@/types/robot';
import {
  authRobot,
  getRobotAuthList,
  getRobotDetail,
  getRobotList,
  removeAuth,
  removeRobot,
} from '@/api/robot';
import Item from '@/components/Robot/RobotItem';
import RobotSetting from './RobotSetting';
import RobotAuth from '@/components/Robot/RobotAuth';
import RobotManage from '@/components/Robot/RobotManage';
import ConfirmMoal from '@/components/Confirm';

export default function RobotList() {
  const [loading, setLoading] = useState<boolean>(false);

  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const [list, setList] = useState<RobotItem[]>([]);

  /** 当前编辑的机器人 */
  const curRobotItem = useRef<RobotItem>();

  /** 设置弹窗 */
  const [settingVisible, setSettingVisible] = useState<boolean>(false);

  /** 授权弹窗 */
  const [authVisible, setAuthVisible] = useState<boolean>(false);

  /** 授权管理弹窗 */
  const [unbindVisible, setUnbindVisible] = useState<boolean>(false);

  const bodyBottom = useRef<HTMLDivElement>(null);

  const [removeModalVisible, setRemoveModalVisible] = useState(false);

  const page = useRef({
    sid: '',
    pageSize: 40,
  });

  const navigate = useNavigate();

  /** 获取授权号列表 */
  const getList = async () => {
    setLoading(true);
    page.current = {
      sid: '',
      pageSize: 40,
    };
    const {
      data: { results, sid },
    } = await getRobotList({
      ...page.current,
    });
    setList(results);
    page.current.sid = sid;
    setLoading(false);
    setIsEmpty(results.length === 0);
  };

  /** 选中机器人 */
  const handleSelect = item => {
    navigate(`/doc/robot/detail/${item.id}`);
  };

  /** 新建 */
  const createRobot = () => {
    setSettingVisible(true);
    curRobotItem.current = undefined;
  };

  /** 编辑 */
  const handleEdit = async item => {
    const res = await getRobotDetail(item.id);
    curRobotItem.current = res.data;
    setSettingVisible(true);
  };

  /** 编辑成功 */
  const handleSettingOk = () => {
    setSettingVisible(false);
    getList();
  };

  /** 打开授权 */
  const handleAuth = item => {
    setAuthVisible(true);
    curRobotItem.current = item;
  };

  /** 授权成功 */
  const authSuccess = () => {
    Toast.success('操作成功');
    curRobotItem.current = undefined;
    getList();
  };

  /** 操作删除 */
  const handleDelete = item => {
    setRemoveModalVisible(true);
    curRobotItem.current = item;
  };

  /** 删除 */
  const onRemove = async () => {
    const id = curRobotItem.current?.id || '';
    await removeRobot(id);
    setRemoveModalVisible(false);
    const targetList = [...list];
    const i = targetList.findIndex(r => r.id === id);
    if (i > -1) {
      targetList.splice(i, 1);

      targetList.length === 0 ? getList() : setList(targetList);
    }
  };

  /** 授权 */
  const onAuth = async (token: string) => {
    authRobot(curRobotItem.current?.id || '', token)
      .then(() => {
        setAuthVisible(false);
        authSuccess();
      })
      .catch(() => {
        //
      });
  };

  /** 监听滚动 */
  const listenInBottom = () => {
    const observer = new IntersectionObserver(async nodes => {
      const tgt = nodes[0];
      if (tgt.isIntersecting) {
        if (page.current.sid) {
          // 滚动加载只加载20条数据
          page.current.pageSize = 20;
          const {
            data: { results, sid },
          } = await getRobotList({
            ...page.current,
          });

          setList(list => {
            const curList = [...list];
            // 去重
            const obj: Record<string, string> = {};
            for (const item of curList) {
              const { id } = item;
              obj[id] = item.id;
            }
            results.forEach(i => {
              const item = obj[i.id];
              if (!item) {
                curList.push(i);
              }
            });
            return curList;
          });

          page.current.sid = sid;
        }
      }
    });
    bodyBottom.current && observer.observe(bodyBottom.current);
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (!list.length) return;
    listenInBottom();
  }, [list.length]);

  const renderEmpty = (
    <div className={style.empty}>
      <img src={assets.images.global['img-empty-box.png']} alt="img" />
      <p>暂时没有机器人</p>
      <div className={style.create} onClick={createRobot}>
        创建机器人
      </div>
    </div>
  );

  const renderList = (
    <Spin spinning={loading} style={{ width: '100%' }}>
      <div className={style['list-wrapper']}>
        <div className={style.list}>
          {list.map(info => (
            <Item
              key={info.id}
              info={info}
              onEdit={handleEdit}
              showAuth={handleAuth}
              showManage={info => {
                curRobotItem.current = info;
                setUnbindVisible(true);
              }}
              onSelect={handleSelect}
              onDelete={handleDelete}
            />
          ))}
        </div>
        <div ref={bodyBottom} className="bodyBottom" />
      </div>
    </Spin>
  );

  const renderHeader = (
    <div className={style.header}>
      <h1 className={style.title}>我的机器人</h1>
      <div className={`button-primary ${style.button}`} onClick={createRobot}>
        创建机器人
      </div>
    </div>
  );

  return (
    <>
      {renderHeader}
      {isEmpty ? renderEmpty : renderList}
      <RobotSetting
        visible={settingVisible}
        onCancel={() => setSettingVisible(false)}
        robotInfo={curRobotItem.current}
        onOk={handleSettingOk}
      />
      <RobotAuth
        visible={authVisible}
        modalOpenCloseFN={() => {
          setAuthVisible(false);
        }}
        onAuth={onAuth}
      />
      <RobotManage
        visible={unbindVisible}
        robotInfo={curRobotItem.current}
        modalOpenCloseFN={() => {
          setUnbindVisible(false);
        }}
        unbindSuccess={() => {
          Toast.success('操作成功');

          getList();
        }}
        getList={getRobotAuthList}
        removeAuth={removeAuth}
      />

      <ConfirmMoal
        visible={removeModalVisible}
        title="删除机器人？"
        content="删除后，该机器人将不再给绑定的订阅号服务"
        onCancel={() => {
          setRemoveModalVisible(false);
        }}
        onOk={onRemove}
      />
    </>
  );
}
