import { useGetQuery } from '@bhb-frontend/hooks/lib';
import { useNavigate } from 'react-router-dom';

import Style from './CourseImg.module.less';

function CourseImg() {
  const navigate = useNavigate();
  const [query] = useGetQuery();
  console.log({ query });

  if (!(query && query.img && query.title)) {
    navigate('/');
  } else {
    document.title = decodeURIComponent(query.title);
  }

  return (
    <div className={Style['course-img']}>
      <div className={Style.content}>
        <img src={decodeURIComponent(query?.img)} alt="img" />
      </div>
    </div>
  );
}

export default CourseImg;
