import { Modal, Button, Spin } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import * as React from 'react';
import { deleteEditorProjectsAPI } from '@/api/editorProjects';

import Style from './index.module.less';

const { useState } = React;

interface ModalWrapperProps extends ModalProps {
  selectedIdList: string[];
  onConfirmFN: (data: string[]) => void;
  modalActionFN: (data: boolean) => void;
}

export default function RemoveDialog(props: ModalWrapperProps) {
  const { visible, onConfirmFN, modalActionFN, selectedIdList } = props;

  /**
   * 创建loading
   */
  const [btnLoading, setBtnLoadingFN] = useState(false);

  /** 控制弹窗开关函数 */
  const modalOpenCloseFN = (data: boolean) => {
    !data && setBtnLoadingFN(false);
    modalActionFN(data);
  };

  /** 确定按钮函数 */
  const onOkFN = async () => {
    setBtnLoadingFN(true);
    const projectId = selectedIdList[0];
    deleteEditorProjectsAPI(projectId);
    onConfirmFN([projectId]);
    modalOpenCloseFN(false);
  };

  return (
    <Modal
      visible={visible}
      width={420}
      height="auto"
      timeout={0}
      onClose={() => {
        modalOpenCloseFN(false);
      }}
      closable={false}
      contentClassName={Style['modal-container']}
    >
      <Spin spinning={btnLoading} className={Style['btn-loading']}>
        <div className={Style['modal-header']}>注意</div>
        <div className={Style['modal-body']}>
          <i
            className="icon-a-fabu_icon_fail2x1 iconfont"
            style={{
              color: 'rgb(255, 23, 92)',
              marginRight: '5px',
            }}
          />
          删除项目后将无法重新找回，请谨慎操作！
        </div>
        <div className={Style['modal-footer']}>
          <span>
            <Button
              className={Style['cancel-btn']}
              onClick={() => {
                modalOpenCloseFN(false);
              }}
            >
              取消
            </Button>
            <Button className={Style['confirm-btn']} onClick={onOkFN}>
              确定
            </Button>
          </span>
        </div>
      </Spin>
    </Modal>
  );
}
