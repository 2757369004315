import request from '@/api/init';
import { DetailResponse, ListQuery, CustomListResponse } from '@/types/api';
import {
  LiveDurationTip,
  LiveCreateParams,
  LiveDetail,
  RobotDetail,
  IAddRobot,
  IRobot,
  AddGoodsList,
  GoodsResult,
  VideoActions,
} from '@/types/live';

/**
 * 开播检测
 * @param params
 */
export function checkLive() {
  const uri = '/live/lives/start/check';
  return request.get<DetailResponse<LiveDurationTip>>(uri);
}

/**
 * 创建直播
 * @param shareUrl 直播分享地址
 * @param platform 直播平台
 * @returns
 */
export function createLive(
  data: LiveCreateParams
): Promise<DetailResponse<LiveDetail>> {
  const uri = '/live/lives';
  return request.post(uri, data, {
    timeout: 25000,
  });
}

/**
 * 获取直播列表
 * @param params
 * @returns
 */
export function getLives(params: ListQuery) {
  const uri = '/live/lives';
  return request.get<CustomListResponse<LiveDetail>>(uri, params);
}

/**
 * 视频操作记录
 * @param videoId
 * @param data
 * @returns
 */
export function videoActions(
  videoId: string,
  data: VideoActions
): Promise<DetailResponse<void>> {
  return request.post(`/live/videos/${videoId}/actions`, data);
}

/**
 * 删除直播
 * @param liveId 相关直播直播id
 * @returns
 */
export function removeLive(liveId: string) {
  return request.delete<DetailResponse<void>>(`/live/lives/${liveId}`);
}

/**
 * 终止直播
 * @param data
 * @returns
 */
export function liveTerminate(data: { liveId: string }) {
  return request.post<DetailResponse<void>>('/live/lives/terminate', data);
}

/**
 * 获取机器人详情
 * @param id 机器人id
 */
export function getLiveRobotDetail(robotId: string) {
  return request.get<DetailResponse<RobotDetail>>(`/live/robots/${robotId}`);
}

/**
 * 获取机器人直播列表
 * @param params
 * @returns
 */
export function getLiveRobotList(robotId: string, params: ListQuery) {
  return request.get<CustomListResponse<LiveDetail>>(
    `/live/robots/${robotId}/lives`,
    params
  );
}

/**
 * 修改机器人
 */
export function updateRobot(id: string, data: IAddRobot) {
  const uri = `/live/robots/${id}`;
  return request.put(uri, data);
}

/**
 *
 * @param id 机器人id
 * @returns
 */
export function getRobotLiveDetail(
  id: string
): Promise<DetailResponse<IRobot>> {
  const uri = `/live/robots/${id}`;
  return request.get(uri);
}

/**
 * 添加商品
 */
export function addGood(
  data: AddGoodsList
): Promise<DetailResponse<GoodsResult>> {
  const uri = '/live/robot/goods';
  return request.post(uri, data);
}

/**
 * 修改商品
 */
export function editGood(
  id: string,
  data: AddGoodsList
): Promise<DetailResponse<GoodsResult>> {
  const uri = `/live/robot/goods/${id}`;
  return request.put(uri, data);
}
