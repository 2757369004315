import { observer } from 'mobx-react';
import { ShanJianMember } from '@bhb-frontend/mtv-navbar';
import {
  formatTime,
  formatDurationScence,
} from '@bhb-frontend/utils/lib/format';
import Style from './MyRights.module.less';
import { userStore } from '@/store';
import RightsImages from '@/assets/images/myRights';
import { convertBytes } from '@/utils/UnitConversion';
import Consume from './Consume';
import Images from '@/assets/images/';

function MyRights() {
  if (!userStore.userInfo) return null;
  const { name, avatar, shanjianMemberRights, isUsedShanjianMember } =
    userStore.userInfo;

  const isMember = isUsedShanjianMember && shanjianMemberRights?.isMember;

  const rightsList = () => {
    if (!shanjianMemberRights) return [];
    const {
      balanceOralVideoDuration,
      balanceAiDocumentsNumber,
      balanceStorage,
    } = shanjianMemberRights;

    return [
      {
        key: 'koubo',
        icon: RightsImages.head_koubo,
        name: '口播时长',
        value: formatDurationScence(balanceOralVideoDuration || 0),
      },
      {
        key: 'text',

        icon: RightsImages.head_text,
        name: '文案创作',
        value: `${balanceAiDocumentsNumber || 0}次`,
      },
      {
        key: 'cloud',

        icon: RightsImages.head_cloud,
        name: '存储空间',
        value: convertBytes(balanceStorage || 0, 2),
      },
    ];
  };

  return (
    <div className={Style.rights}>
      <div className={Style.content}>
        <div className={Style.header}>
          <div className={Style.info}>
            <div className={Style.head}>
              <img src={avatar} alt={name} />
              <div className={Style.name}>
                <p>
                  {name}
                  {isMember && (
                    <img
                      src={Images.common['common-icon-video-member']}
                      alt=""
                    />
                  )}
                </p>
                <p className={Style.desc}>
                  {isMember
                    ? `${formatTime(
                        new Date(shanjianMemberRights.expiredAt),
                        'yyyy.mm.dd'
                      )}到期`
                    : '开通会员享超值权益'}
                </p>
              </div>
            </div>
            <div
              className={Style.btn}
              onClick={() => {
                ShanJianMember?.show();
              }}
            >
              {isMember ? '立即续费' : '立即开通'}
            </div>
          </div>

          <div className={Style['my-rights']}>
            <p className={Style.heading}>我的权益</p>
            <div className={Style.list}>
              {rightsList().map(rights => {
                const { key, icon, name, value } = rights;
                return (
                  <div key={key} className={Style.item}>
                    <div className={Style.wrap}>
                      <img src={icon} alt={name} />
                      <span> {name}</span>
                    </div>
                    <span className={Style.value}>{value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={Style.consume}>
          <p className={Style.title}>权益明细</p>

          <Consume />
        </div>
      </div>
    </div>
  );
}

export default observer(MyRights);
