import cls from 'classnames';
import Modal from '../Modal';

import Style from './LiveMessage.module.less';

interface LiveMessageProps {
  message: string;
  visible: boolean;
  title: string;
  onClose: () => void;
  children?: React.ReactNode;
}

export default function LiveMessage(props: LiveMessageProps) {
  const { message, visible, onClose, title, children } = props;
  const renderTitle = () => {
    if (title) {
      return (
        <div className={Style['message-title']}>
          <i
            className={cls('iconfont icon-a-zhibo_icon_tips2x', Style.iconfont)}
          />
          <span>{title}</span>
        </div>
      );
    }
    return null;
  };
  return (
    <Modal
      visible={visible}
      onClose={onClose}
      style={{
        backgroundColor: '#fff',
        width: '335px',
        padding: '30px',
        borderRadius: '4px',
      }}
    >
      {renderTitle()}
      <div className={Style['message-content']}>{message}</div>
      {children}
    </Modal>
  );
}
