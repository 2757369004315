import { Spin, Empty } from '@bhb-frontend/lithe-ui';
import React, { useState, useRef, useLayoutEffect } from 'react';
import Style from './VideoAccount.module.less';
import { getAccountList, deleteAccount } from '@/api/videos';
import { ThirdAccountItem } from '@/types/videos';
import assets from '@/assets/images';
import AccountItems from './AccountItems/AccountItems';
import AddAccountDialog from '@/components/AddAccountDialog';
import { PlatformEnum } from '@/types/third-account';
import {
  douyinAuthUrl,
  kuaishouAuthUrl,
} from '@/components/AddAccountDialog/const';
import ModalWrapper from '@/components/ModalWrapper';

function VideoAccount() {
  /** 加载过程中的动画 */
  const [loading, setLoading] = useState(false);
  /** 是否展示添加对话框 */
  const [dialog, setDialog] = useState(false);
  /** 是否展示删除对话框 */
  const [removeDialog, setRemoveDialog] = useState(false);
  /** 第三方账号数据列表 */
  const [list, setList] = useState<ThirdAccountItem[]>([]);
  /** accountId */
  const accountId = useRef<string>('');

  /**
   * 获取第三方账号里列表【注意：目前每种第三方平台账号只能绑定一个，故没做分页功能】
   */
  const getList = async () => {
    setLoading(true);
    try {
      const { data } = await getAccountList({ pageSize: 100, sid: '' });
      setList(data.results);
    } finally {
      setLoading(false);
    }
  };

  const addVideoAccount = () => {
    setDialog(true);
  };

  /** 重新授权 */
  const reAuth = type => {
    const domain = window.location.href;

    let authUrl = '';

    switch (type) {
      case PlatformEnum.DOU_YIN:
        authUrl = douyinAuthUrl;
        break;
      case PlatformEnum.KUAI_SHOU:
        authUrl = kuaishouAuthUrl;
        break;
      default:
        authUrl = '';
    }
    window.location.href = `${authUrl}=${domain}`;
  };

  /** 删除账号 */
  const removeAuth = async () => {
    await deleteAccount(accountId.current);
    getList();
  };

  useLayoutEffect(() => {
    getList();
  }, []);

  /** 空数据节点 */
  const EmptyElement = () => (
    <div className={Style.empty}>
      <Empty
        imgSrc={assets.common['img-empty-box.png']}
        text="暂无视频账号"
        style={{
          color: '#191919',
        }}
      />
      <button
        className={Style.emptyButton}
        onClick={() => {
          addVideoAccount();
        }}
      >
        添加账号
      </button>
    </div>
  );

  /** 视频账号列表 */
  const ListElement = () => (
    <div className={Style.accountList}>
      {list.map(item => (
        <AccountItems
          account={item}
          reAuth={reAuth}
          remove={id => {
            accountId.current = id;
            setRemoveDialog(true);
          }}
          key={item.id}
        />
      ))}
    </div>
  );

  return (
    <div className={Style.account}>
      <div className={Style.accountTop}>
        <h2>视频账号</h2>
        {list.length > 0 && (
          <button
            className={Style.accountAdd}
            onClick={() => {
              addVideoAccount();
            }}
          >
            添加账号
          </button>
        )}
      </div>
      <Spin spinning={loading} className={Style.spin}>
        {!loading && list.length === 0 ? EmptyElement() : ListElement()}
      </Spin>
      <AddAccountDialog
        visible={dialog}
        close={() => {
          setDialog(false);
        }}
      />
      <ModalWrapper
        title="确定删除视频账号吗？"
        content="删除后将无法使用，需要重新授权绑定"
        visible={removeDialog}
        onClose={() => {
          setRemoveDialog(false);
        }}
        onConfirm={() => {
          removeAuth();
        }}
      />
    </div>
  );
}

export default VideoAccount;
