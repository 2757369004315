/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/fae4989b31d93b91642cfc0051ac457c.png */
  'icon-common-play.png': 'fae4989b31d93b91642cfc0051ac457c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/a2c631c15ffc3357d18c04988a84027c.png */
  'common_icon_close.png': 'a2c631c15ffc3357d18c04988a84027c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/c29769d65c3e0cbbf69564f27fd10280.svg */
  'video_icon_delete.svg': 'c29769d65c3e0cbbf69564f27fd10280.svg',

  /* https://bhb-frontend.bhbcdn.com/static/files/86a7c9fd29cc9120bfd4e7e7cba38dbb.svg */
  'dingyue_icon_jisifabu@2x.svg': '86a7c9fd29cc9120bfd4e7e7cba38dbb.svg',

  /* https://bhb-frontend.bhbcdn.com/static/files/62fb0f1eac566e6d5fb736fa59ac8aa3.svg */
  'preview_icon_dowload.svg': '62fb0f1eac566e6d5fb736fa59ac8aa3.svg',

  /* https://bhb-frontend.bhbcdn.com/static/files/435c46ce1a9ff34bd5a62ffb4bb7964b.svg */
  'preview_icon_close_white@2x.svg': '435c46ce1a9ff34bd5a62ffb4bb7964b.svg',

  /* https://bhb-frontend.bhbcdn.com/static/files/b7adf16b08ebd3cd939835725a32b724.svg */
  'common_icon_play@2x.svg': 'b7adf16b08ebd3cd939835725a32b724.svg',

  /* https://bhb-frontend.bhbcdn.com/static/files/47b69f2a6c308a65f2a0fc4bcd7151a4.png  */
  'logo.png': '47b69f2a6c308a65f2a0fc4bcd7151a4.png ',

  /* https://bhb-frontend.bhbcdn.com/static/files/fe5edfb3b0512ac5e6139e987f3568e3.png */
  'home_icon_Graphic Cut.png': 'fe5edfb3b0512ac5e6139e987f3568e3.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ab8f7899ca769b1fc38d970317478cad.png */
  'img-empty-box.png': 'ab8f7899ca769b1fc38d970317478cad.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/66fb45338596f5be2652da553a2daded.png */
  'menu-boltcut': '66fb45338596f5be2652da553a2daded.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d8c3ce5f06216f2b7dc025831940fc77.png */
  'menu-live': 'd8c3ce5f06216f2b7dc025831940fc77.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/c0933c8b34d0b9f6a186235c4d77f490.png */
  'menu-doc': 'c0933c8b34d0b9f6a186235c4d77f490.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/58f572dedb1284928aa5b8613acaa369.png */
  'menu-subscription': '58f572dedb1284928aa5b8613acaa369.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ef7f93e7614245b7f22841b1d92b525d.png */
  'download-app-code': 'ef7f93e7614245b7f22841b1d92b525d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e37bb221fbab22f8a72752377c230a4a.png */
  'icon-fabu-bufen.png': 'e37bb221fbab22f8a72752377c230a4a.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/2df0e3cc1b99719aff2994b879151bdf.png */
  'icon-fabu-fail.png': '2df0e3cc1b99719aff2994b879151bdf.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/24a9cfdef29be8f699e6d303eb5af0a8.png */
  'icon-fabu-loading.png': '24a9cfdef29be8f699e6d303eb5af0a8.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/c85a47569c323e93182754c3cc8b4982.png */
  'icon-fabu-success.png': 'c85a47569c323e93182754c3cc8b4982.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ef7f93e7614245b7f22841b1d92b525d.png */
  'mobile-logo.png': 'ef7f93e7614245b7f22841b1d92b525d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/b515c6cbc2061dc27e4682eab555fdf4.png */
  'img-mobile-phone.png': 'b515c6cbc2061dc27e4682eab555fdf4.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d916ace5db9f39c911b7f78b33fa9855.png */
  'icon-mobile-1.png': 'd916ace5db9f39c911b7f78b33fa9855.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/9d4b49bd48aca13bd45021d3bbd82779.png */
  'icon-mobile-2.png': '9d4b49bd48aca13bd45021d3bbd82779.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/bb53f59fc8b87b38b1c60e92394d1723.png */
  'icon-mobile-3.png': 'bb53f59fc8b87b38b1c60e92394d1723.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/f6601841cc16c1c01123755df5d03784.png */
  'img-mobile-bg.png': 'f6601841cc16c1c01123755df5d03784.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/9f49a2ad69e149788a52bab3c0b85c10.png */
  'common-icon-video-member': 'mtv/images/9f49a2ad69e149788a52bab3c0b85c10.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/1347429febc5418f951b33bbb8af7fee.png */
  'common-icon-live-member': 'mtv/images/1347429febc5418f951b33bbb8af7fee.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/37575877003844368ae79fa082f3af94.png */
  'common-icon-gold': 'mtv/images/37575877003844368ae79fa082f3af94.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/0af9cb4f29b74078baa46d5747eb4816.png */
  common_shanjian_vip_text_icon:
    'mtv/images/0af9cb4f29b74078baa46d5747eb4816.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/fd2053a1651c4f7e956fb7bfbbf4137c.png */
  common_empty_transparent_empty_icon:
    'mtv/images/fd2053a1651c4f7e956fb7bfbbf4137c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/7cb4dfe8109e4fd59a73ae9dea9e67d3.jpeg */
  'common-rights-background':
    'mtv/images/7cb4dfe8109e4fd59a73ae9dea9e67d3.jpeg',

  'agent_rule.png': '6c3e1f5dde054a15b41c5d62a950c021.png',
  'agent_money.png': 'd59cee7d5eb94a98b2fba2d4e2544dfd.png',
  'agent_title.png': 'a1bd3c9343434f27b648908913162cfb.png',
  'agent_platform.png': 'd1e1d9b7c4fb4093832e7824932bed2c.png',
};
