import { getLinkVideoDetail } from '@/api/videos';
import { SOCKET_EVENTS, VIDEO_STATUS } from '@/constants/videos';
import { IntelligentVideoStatus } from '@/constants/videos/IntelligentVideo';
import {
  picSpeaksVideoStatusMap,
  figureLipSyncVideoStatusMap,
} from '@/constants/videos/PicSpeaksStatus';
import VideoTypeEnum from '@/constants/videos/VideoTypeEnum';
import { SocketVideoItem, VideoItem } from '@/types/videos';

const { COMPLETED, FAIL, PENDING, QUEUEING, CANCEL } = VIDEO_STATUS;

const {
  CREATE_VIDEO_SUCCESS,
  CREATE_VIDEO_FAILED,
  VIDEO_STATUS_CHANGE,
  PIC_SPEAKS_VCR_VIDEO_RESULT,
  INTELLIGENT_VIDEO_RESULT,
  LINK_TO_VIDEO_RESULT,
  FIGURE_LIP_SYNC_VIDEO_RESULT,
} = SOCKET_EVENTS;

const {
  PIC_SPEAK,
  LIP_SYNC_PHOTO_FIGURE,
  LIP_SYNC_VIDEO_FIGURE,
  INTELLIGENT_VIDEO,
  LINK_TO_VIDEO,
} = VideoTypeEnum;

/** 智能成片跟链接成片状态映射 */
const intelligentVideoStatusMap = {
  [IntelligentVideoStatus.COMPLETED]: COMPLETED,
  [IntelligentVideoStatus.PENDING]: QUEUEING,
  [IntelligentVideoStatus.PROCESSING]: PENDING,
  [IntelligentVideoStatus.FAIL]: FAIL,
  [IntelligentVideoStatus.CANCELED]: CANCEL,
};

const socketVideoStatusMap = {
  [CREATE_VIDEO_SUCCESS]: (_: string) => COMPLETED,
  [PIC_SPEAKS_VCR_VIDEO_RESULT]: (status: string) =>
    picSpeaksVideoStatusMap[status],
  [FIGURE_LIP_SYNC_VIDEO_RESULT]: (status: string) =>
    figureLipSyncVideoStatusMap[status],
  [INTELLIGENT_VIDEO_RESULT]: (status: string) =>
    intelligentVideoStatusMap[status],
  [LINK_TO_VIDEO_RESULT]: (status: string) => intelligentVideoStatusMap[status],
};

/** socket状态转换 每次socket 只能有一次状态命中 */
function statusTransform(event: string, status: string): VIDEO_STATUS {
  return socketVideoStatusMap[event]?.(status) || (status as VIDEO_STATUS);
}

/** 图文快剪成功&状态变更 */
function videoSucAndChange(data: SocketVideoItem, status: string) {
  const { videoRecordId, ...others } = data;
  return {
    ...others,
    status,
    originalId: videoRecordId,
  };
}

/** 照片数字人 */
function picSpeaksVideoResult(data: SocketVideoItem, status: string) {
  const { id, snapshotUrl, taskId, ...others } = data;
  return {
    ...others,
    status,
    originalId: id,
    coverUrl: snapshotUrl,
  };
}
/** 视频数字人 */
function figureLipSyncVideoResult(data: SocketVideoItem, status: string) {
  if (!data.result) {
    return data;
  }
  const { id, ...others } = data.result;
  return {
    ...others,
    originalId: id,
    status,
  };
}

/** 智能成片 */
function intelligentVideoResult(data: SocketVideoItem, status: string) {
  const { id, ...others } = data;
  return {
    ...others,
    originalId: id,
    status,
  };
}

/** 链接成片 */
async function linkToVideoResult(data: SocketVideoItem, status: string) {
  try {
    const {
      data: { id, ...others },
    } = await getLinkVideoDetail(data.id);
    return {
      ...others,
      originalId: id,
      status,
    };
  } catch (error) {
    console.error('获取详情失败', error);
    return data;
  }
}

const callbackMap = {
  [CREATE_VIDEO_SUCCESS]: videoSucAndChange,
  /** 图文失败 */
  [CREATE_VIDEO_FAILED]: (data: SocketVideoItem, status: string) => {
    /** 状态有可能为 */
    const { videoRecordId, rejectContent, appealable, errorMsg } = data;
    return {
      originalId: videoRecordId,
      rejectContent,
      appealable,
      status,
      errorMsg,
    };
  },
  [VIDEO_STATUS_CHANGE]: videoSucAndChange,

  [PIC_SPEAKS_VCR_VIDEO_RESULT]: picSpeaksVideoResult,

  [FIGURE_LIP_SYNC_VIDEO_RESULT]: figureLipSyncVideoResult,

  [INTELLIGENT_VIDEO_RESULT]: intelligentVideoResult,

  [LINK_TO_VIDEO_RESULT]: linkToVideoResult,
};

/** 数据转换 数据统一以图文为标准 */
export async function dataTransform(event: string, data: SocketVideoItem) {
  const status = statusTransform(event, data?.status || data.result?.status);
  return (await callbackMap[event]?.(data, status)) || data;
}

/** 列表状态数据转换 */
export function listStatusTransform(list: VideoItem[]) {
  return list.map(item => {
    const { type } = item;

    /** 照片说话、数字人类型  */
    const figureArr = [PIC_SPEAK, LIP_SYNC_PHOTO_FIGURE, LIP_SYNC_VIDEO_FIGURE];
    if (figureArr.includes(type)) {
      return {
        ...item,
        status: picSpeaksVideoStatusMap[item.status],
      };
    }

    /** 智能成片、链接成片 */
    const intelligentArr = [INTELLIGENT_VIDEO, LINK_TO_VIDEO];
    if (intelligentArr.includes(type)) {
      return {
        ...item,
        status: intelligentVideoStatusMap[item.status],
      };
    }
    return item;
  });
}
