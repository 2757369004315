import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import md5 from 'md5';
import Login from '@bhb-frontend/mtv-login';
import { Member, Coin, ShanJianMember } from '@bhb-frontend/mtv-navbar';
import { InterUserItems } from '@/types/user';

import socket from '@/utils/socket';
import { SocketEvent } from '@/constants/socketEvents';

import { SOCKET_EVENTS } from '@/constants/videos';
import { SESSION_STORAGE } from '@/constants/session-storage';
import { OrderTypeEnum } from './constants/OrderTypeEnum';
import { userStore, configStore, customDigitalStore } from '@/store';
import VipDialog from './components/VipDialog';

import Router from './router';
import { authAccountPassport } from './api/videos';
import { PlatformEnum } from './types/third-account';
import { enableLoginSuccess } from '@/constants/common';
import { clientSide } from '@/utils/common';
import { showLoginAndRemoveToken } from './utils/login';
import { notEmptyObj } from './utils/notEmptyObj';
import Mobile from '@/components/Mobile';
import MemberUpgradeModal from './components/MemberUpgradeModal';
import { getToken } from '@/utils/token';

import style from './style.module.less';
import ModuleEnum from './constants/ModuleEnum';

function App() {
  const isMobile = useMemo(() => clientSide(), []);

  /** 获取配置信息 */
  const initConfig = async () => {
    configStore.getConfigs();
    customDigitalStore.initConfig();
  };

  /** 获取用户信息 */
  const initUser = async () => {
    if (userStore.isLogin()) {
      const { isUsedShanjianMember } = await userStore.getUser();
      /** 如果不是闪剪会员，需要弹出升级提示 */
      if (isUsedShanjianMember !== undefined && !isUsedShanjianMember) {
        MemberUpgradeModal.show?.();
      }
    }
  };

  /** 验证第三方授权  */
  const thirdAccountAuth = async () => {
    const url = new URL(window.location.href);
    const query = url.searchParams;
    const [code, state] = [query.get('code'), query.get('state')];

    // 无授权参数则走默认路由行为
    if (!code || !state) {
      return;
    }
    // 账号授权请求
    await authAccountPassport({
      code,
      platform: state as PlatformEnum,
    }).catch(() => {
      console.log('授权成功');
    });
    query.delete('code');
    query.delete('state');
    const redirectUrl = `${url.origin}${url.pathname}?${query.toString()}`;
    window.location.href = redirectUrl;
  };

  useEffect(() => {
    thirdAccountAuth();
    initConfig();
    initUser();
  }, []);

  /** 监听支付 */
  const listenSocket = (res: any) => {
    const { event, data } = res;

    /** 支付成功 */
    if (SOCKET_EVENTS.PAY_RESULT === event) {
      if (data.success) {
        if (data.orderType === OrderTypeEnum.Coin) {
          Coin.paySuccessDialog(data.orderNo);
          userStore.getUser();
          return;
        }

        if (data.orderType === OrderTypeEnum.Shanjian) {
          ShanJianMember.paySuccessDialog(data.orderNo);
          return;
        }
        Member.paySuccessDialog(data.orderNo);
      }
    }

    /** 会员升级成功 */
    if (SocketEvent.TRANSLATE_MEMBER === event) {
      if (data.isTranslated) {
        /** 升级成功 更新会员权益*/
        userStore.getUser();
      }
    }

    /** 账号过期 */
    if (SocketEvent.LOGIN_TOKEN_EXPIRED === event) {
      const token = getToken();
      /** 如果存在token，并且token 与 服务端返回的 加密token不一致，说明账号过期了，需要移除 */
      if (token && md5(token) !== data.tag) {
        showLoginAndRemoveToken();
      }
    }
  };

  useEffect(() => {
    if (userStore.userInfo.socketLink) {
      socket.on(listenSocket);
    }
    return () => {
      socket.off(listenSocket);
    };
  }, [userStore.userInfo.socketLink]);

  /** 移动端渲染 */
  const MobileRender = () => <Mobile />;

  return isMobile ? (
    MobileRender()
  ) : (
    <>
      <Login
        appConfig={configStore.config}
        className={style.login}
        loginSuccess={async (userInfo, type) => {
          userStore.login(userInfo as unknown as InterUserItems, type);
          /** 打开会员中心 */
          const memberCenterModule = sessionStorage.getItem(
            SESSION_STORAGE.MEMBER_CENTER
          );

          if (memberCenterModule) {
            const timer = setTimeout(() => {
              if (memberCenterModule === ModuleEnum.LIVE) {
                Member.show({ module: memberCenterModule as ModuleEnum });
              }
              if (memberCenterModule === ModuleEnum.VIDEO) {
                ShanJianMember?.show();
              }

              clearTimeout(timer);
            });

            sessionStorage.removeItem(SESSION_STORAGE.MEMBER_CENTER);
            return;
          }
          if (!enableLoginSuccess) return;
          //* 因为loginSuccess事件在onSuccess 前执行，所以此处添加一个定时器特殊处理.
          setTimeout(() => {
            window.location.reload();
          }, 10);
        }}
      />

      {notEmptyObj(configStore.config) && userStore.userInfo.id && (
        <>
          <ShanJianMember
            configApp={configStore.config as any}
            userInfo={userStore.userInfo as any}
            onOpenRightsCompare={() => {
              const origin = window.location.origin;
              window.open(`${origin}/equity-comparison`, '_blank');
            }}
            onCourseImg={(img: string, title: string) => {
              const origin = window.location.origin;
              window.open(
                `${origin}/course-img?img=${encodeURIComponent(
                  img
                )}&title=${encodeURIComponent(title)}`,
                '_blank'
              );
            }}
          />
          <Member
            configApp={configStore.config as any}
            userInfo={userStore.userInfo as any}
          />

          <Coin
            configApp={configStore.config as any}
            userInfo={userStore.userInfo as any}
          />
        </>
      )}
      <VipDialog />
      <div className={style.root}>
        <Router />
      </div>

      <MemberUpgradeModal />
    </>
  );
}

export default observer(App);
