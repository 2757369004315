import { ProjectName } from '@/types/editorProjectsApi';
import assets from '@/assets';
import { ProductItem } from '@/types/home';
import { ProductEnum } from './ProductEnum';
import Routes from '@/config/Routes';
import { LINK_TO_VIDEO_URL } from '@/config';

export enum CreateType {
  /** 图文快剪 */
  doc = 'doc',
  live = 'live',
  editor = 'editor',
}

export const createTypeListData = [
  {
    name: CreateType.doc,
    title: '口播视频',
    describe: 'AI生成数字人视频',
    image: assets.images.home['doc-icon.png'],
  },
  {
    name: CreateType.live,
    title: '直播快剪',
    describe: 'AI智能完成直播切片',
    image: assets.images.home['live-icon.png'],
  },
  // {
  //   name: CreateType.editor,
  //   title: '视频编辑器',
  //   describe: '在线视频编辑器',
  //   image: assets.images.home['video-clip-icon.png'],
  // },
];
export const createTypeList = createTypeListData;

/**
 * 可以选择项目的种类
 */
export const projectList = [
  {
    name: ProjectName.ULTRA,
    style: {
      width: 67,
      height: 45,
    },
    scale: {
      width: 1280,
      height: 720,
    },
  },
  {
    name: ProjectName.HIGH_DEFINITION,
    style: {
      width: 45,
      height: 67,
    },
    scale: {
      width: 720,
      height: 1280,
    },
  },
  {
    name: ProjectName.LOW,
    style: {
      width: 45,
      height: 57,
    },
    scale: {
      width: 960,
      height: 1280,
    },
  },
  {
    name: ProjectName.REGULAR,
    style: {
      width: 57,
      height: 45,
    },
    scale: {
      width: 1280,
      height: 960,
    },
  },
  {
    name: ProjectName.SQUARE,
    style: {
      width: 57,
      height: 57,
    },
    scale: {
      width: 720,
      height: 720,
    },
  },
];

/**
 * lego 编辑器地址
 */
export const legoURL = import.meta.env.VITE_APP_LEGO_URL;

/** 产品列表 */
export const productList: ProductItem[] = [
  {
    name: '链接成片',
    desc: '链接一键转视频',
    path: LINK_TO_VIDEO_URL,
    key: ProductEnum.LINK_TO_VIDEO,
    showNew: true,
    iconfont: 'icon-a-web-home-linkcut-fil',
  },
  {
    name: '口播视频',
    desc: 'AI生成数字人视频',
    path: Routes.Doc.path,
    key: ProductEnum.DOC,
    iconfont: 'icon-a-web-home-smartcut-fil',
  },
  {
    name: '直播快剪',
    desc: '直播智能切片带货',
    path: Routes.Live.path,
    key: ProductEnum.LIVE,
    iconfont: 'icon-a-3-web-home-cutlive-fil',
  },
  {
    name: '定制数字人',
    desc: '定制你的专属分身',
    path: Routes.CustomDigitalList.path,
    key: ProductEnum.DIGITAL_PERSON,
    jumpType: '_self',
    iconfont: 'icon-a-3-web-home-customavatar-fil',
  },
  {
    name: '视频订阅号',
    desc: 'AI自动推送视频',
    path: '/subscription/list',
    key: ProductEnum.SUBSCRIPTION,
    iconfont: 'icon-a-3-web-home-subscription-fil',
  },
];
