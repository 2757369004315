import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { observer } from 'mobx-react';
import {
  EditorProjectsApiModal as Emodal,
  VideoAction,
} from '@/types/editorVideo';
import { getEditorVideosAPI } from '@/api/editorVideo';
import { userStore } from '@/store/user';
import { SOCKET_EVENTS } from '@/constants/videos';
import { VideoItem as IVideoItem } from '@/types/videos';
import Style from './Videos.module.less';
import VideoItem from './VideoItem';
import socket from '@/utils/socket';
import RemoveVideoDialog from './RemoveVideoDialog';
import PublishModal from '@/components/VideoList/PublishModal';
import EmptyList from '@/components/EmptyList';
import PreviewModal from '@/components/VideoList/PreviewModal';

function Videos() {
  /** 每页数量 / 上一页最后id */
  const listQuery = useRef<Emodal.PListQuery>({
    sid: '',
    pageSize: 20,
  });

  /* 视频列表 */
  const [videoList, setVideoListFN] = useState<Emodal.DgetEditorVideosAPI[]>(
    () => []
  );

  /* 视频列表是否还有 */
  const [hasMore, setHasMoreFN] = useState(true);

  /** 加载loading */
  const [loading, setLoadingFN] = useState<boolean>(false);

  /** 滚动元素存储 */
  const [scrollRef, setScrollRefFN] = useState<HTMLDivElement | undefined>(
    undefined
  );

  /** 选中id列表 */
  const [selectedIdList, setSelectedIdListFN] = useState<string[]>([]);

  /** 选中视频列表 */
  const [selectVideo, setSelectVideo] =
    useState<Emodal.DgetEditorVideosAPI | null>(null);

  /** 控制删除项目弹窗 */
  const [removeVisible, setRemoveVisibleFN] = useState(false);

  /* 预览当前点击视频 */
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  /* 获取视频列表 */
  const getListFN = async () => {
    try {
      setLoadingFN(true);
      const params = {
        ...listQuery.current,
      };

      const { data } = await getEditorVideosAPI(params);
      listQuery.current = {
        ...listQuery.current,
        sid: data.sid,
      };

      setHasMoreFN(!!data.sid);
      setLoadingFN(false);
      return data;
    } finally {
      setLoadingFN(false);
    }
  };

  /** 加载更多 */
  const loadMoreFN = async () => {
    if (!hasMore) return;
    const { results } = await getListFN();
    setVideoListFN([...videoList, ...results]);
  };

  /** 单个元素操作回调 */
  const onOperationFN = (
    type: VideoAction,
    itemData: Emodal.DgetEditorVideosAPI
  ) => {
    switch (type) {
      case VideoAction.delete: {
        setSelectedIdListFN([...selectedIdList, itemData.id]);
        setRemoveVisibleFN(true);
        break;
      }
      case VideoAction.preview: {
        const newVideoData = itemData as unknown as IVideoItem;
        newVideoData.videoUrl = itemData.renderVideoUrl;
        newVideoData.coverUrl = itemData.imageUrl;
        newVideoData.videoName = itemData.name;
        setSelectVideo(newVideoData as unknown as Emodal.DgetEditorVideosAPI);
        setPreviewVisible(true);
        break;
      }
      default:
        break;
    }
  };

  /** 删除元素弹窗 */
  const deleteModalConfirmFN = (ids: string[]) => {
    setVideoListFN(videoList.filter(item => !ids.includes(item.id)));
    setSelectedIdListFN(selectedIdList.filter(id => !ids.includes(id)));
  };

  /** 取消删除弹窗 */
  const deleteModalCancelFN = (data: string[]) => {
    setSelectedIdListFN(
      selectedIdList.filter(item => {
        const judgeIn = data.findIndex(id => id === item);
        return judgeIn === -1;
      })
    );
  };

  /** 元素滚动获取数据 */
  const onScrollHandleFN = () => {
    if (!scrollRef || loading) return;
    const scrollTop = scrollRef.scrollTop;
    const clientHeight = scrollRef.clientHeight;
    const scrollHeight = scrollRef.scrollHeight;
    const isBottom = Math.ceil(scrollTop + clientHeight) === scrollHeight;
    if (isBottom) {
      loadMoreFN();
    }
  };

  /** 发布函数 */
  const publishFN = (item: Emodal.DgetEditorVideosAPI) => {
    const newVideoData = item as unknown as IVideoItem;
    newVideoData.videoUrl = item.renderVideoUrl;
    newVideoData.coverUrl = item.imageUrl;
    newVideoData.videoName = item.name;
    setSelectVideo(null);
    (
      PublishModal as unknown as React.FC<object> & {
        show: (info: IVideoItem) => void;
      }
    ).show(newVideoData);
  };

  const convertVideoStatus = () => {
    if (userStore.userInfo.socketLink) {
      socket.on((res: any) => {
        const { data, event, module } = res;
        /** 视频导出成功 */
        if (
          module === SOCKET_EVENTS.EDITOR &&
          event === SOCKET_EVENTS.EDITOR_RENDER_RESULT
        ) {
          const newList = [...videoList];
          setVideoListFN(
            newList.map(item => {
              if (item.id === data.videoId) {
                item.status = data.status;
                item.imageUrl = data.imageUrl;
                item.renderVideoUrl = data.renderVideoUrl;
                item.duration = data.duration;
                item.createdAt = data.createdAt;
              }
              return item;
            })
          );
        }
      });
    }
  };

  /* 下载 */
  const downloadFN = () => {
    if (!selectVideo) return;
    const el = document.createElement('a');
    document.body.append(el);
    el.href = selectVideo.renderVideoUrl;
    el.download = selectVideo.name;
    el.rel = 'noopener noreferrer';
    el.click();
    document.body.removeChild(el);
  };

  /**
   * 删除视频
   */
  const removeVideoFN = () => {
    if (!selectVideo) return;
    const projectData = selectVideo;
    setSelectedIdListFN([...selectedIdList, projectData.id]);
    setRemoveVisibleFN(true);
    setSelectVideo(null);
  };

  /* 关闭预览 */
  const closePreviewFN = () => {
    setPreviewVisible(false);
  };

  useEffect(() => {
    onScrollHandleFN();
  }, []);

  useLayoutEffect(() => {
    loadMoreFN();
  }, []);

  useEffect(() => {
    onScrollHandleFN();
  }, [scrollRef, videoList, loading]);

  /** 监听列表中视频的状态并更新 */
  useEffect(() => {
    convertVideoStatus();
  }, [userStore.userInfo.socketLink]);

  return (
    <>
      <div className={Style.content}>
        {!videoList.length && !loading ? (
          <div className={`${Style.empty} empty`}>
            <EmptyList />
          </div>
        ) : (
          <div
            className={`${Style['load-more-wrapper']} list`}
            ref={c => {
              setScrollRefFN(c as HTMLDivElement);
            }}
            onScrollCapture={() => onScrollHandleFN()}
          >
            {videoList.map(item => (
              <VideoItem
                info={item}
                key={item.id}
                onOperationFN={onOperationFN}
                publishFN={publishFN}
              />
            ))}
            {loading && <p>数据加载中...</p>}
          </div>
        )}
      </div>

      {/* 删除项目弹窗 */}
      <RemoveVideoDialog
        visible={removeVisible}
        selectedIdList={selectedIdList}
        modalActionFN={setRemoveVisibleFN}
        onConfirmFN={deleteModalConfirmFN}
        onCancelFN={deleteModalCancelFN}
      />

      {/* 预览弹窗 */}
      <PreviewModal
        isPreview={isPreviewVisible}
        info={selectVideo as unknown as IVideoItem}
        controls
        autoplay
        publish={() => {
          setPreviewVisible(false);
          selectVideo && publishFN(selectVideo);
        }}
        download={() => {
          downloadFN();
        }}
        remove={() => {
          setPreviewVisible(false);
          removeVideoFN();
        }}
        close={closePreviewFN}
      />

      {/* 发布弹窗 */}
      <PublishModal />
    </>
  );
}

export default observer(Videos);
