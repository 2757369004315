import { useState, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import cls from 'classnames';

import { formatTime } from '@bhb-frontend/utils/lib/format';
import { formatDuration } from '@/utils/date';
import assets from '@/assets';

import Styles from './index.module.less';
import '../transition.less';
import { LiveRobotSliceDetail } from '@/types/live';
import {
  PublishStatus,
  PublishStatusText,
  PUBLISH_STATUS_TITLE,
  SliceStatus,
} from '../../const';

interface PropsInfo {
  info: LiveRobotSliceDetail;
  preview: () => void;
  publish: () => void;
  download: () => void;
  remove: () => void;
  cancelPublish: () => void;
  cancelUpload: (id: string) => void;
}

export default function VideosItem(props: PropsInfo) {
  const {
    info,
    preview,
    publish,
    download,
    remove,
    cancelPublish,
    cancelUpload,
  } = props;
  const [shopBtns, setShopBtns] = useState(false);

  /* 失败状态的详情按钮 */
  /**
   * 视频生成中、等待生成（排队）、生成完成需要显示封面
   */
  const coverVisible = useMemo(() => {
    const { uploadStatus } = info;
    if (uploadStatus) {
      return ![SliceStatus.FAIL, SliceStatus.UPLOADING].includes(uploadStatus);
    }
    return !!info.coverUrl;
  }, [info.uploadStatus]);

  const isUploading = useMemo(
    () => info.uploadStatus && info.uploadStatus === SliceStatus.UPLOADING,
    [info.uploadStatus]
  );

  const isUploadFail = useMemo(() => {
    const { uploadStatus } = info;
    if (uploadStatus) {
      return [SliceStatus.FAIL].includes(uploadStatus);
    }
    return false;
  }, [info.uploadStatus]);

  /** 发布中 */
  const publishingStatus = useMemo(() => {
    const { publishStatus } = info;
    if (publishStatus) {
      return publishStatus === PublishStatus.ING;
    }
    return false;
  }, [info.publishStatus]);

  const publishSuccessStatus = useMemo(() => {
    const { publishStatus } = info;
    if (publishStatus) {
      return publishStatus === PublishStatus.SUCCESS;
    }
    return false;
  }, [info.publishStatus]);

  const currentVideoPublishTitle = () => {
    const { publishStatus } = info;
    if (publishStatus) {
      return PublishStatusText[publishStatus];
    }
    return PUBLISH_STATUS_TITLE.AWAIT;
  };

  return (
    <div className={Styles.item}>
      <div
        onMouseEnter={() => {
          setShopBtns(true);
        }}
        onMouseLeave={() => {
          setShopBtns(false);
        }}
        className={Styles.cover}
      >
        {coverVisible && (
          <img
            className={Styles.image}
            src={info.coverUrl || assets.images.clip['live_icon_empty.png']}
            onClick={preview}
            alt=""
          />
        )}

        {!isUploading &&
          info.publishStatus !== PublishStatus.AWAIT &&
          !isUploadFail && (
            <div
              className={cls(Styles.itemPublishStatus, {
                [Styles.itemPublishStatusSuccess]:
                  info.publishStatus === PublishStatus.SUCCESS,
                [Styles.itemPublishStatusIng]:
                  info.publishStatus === PublishStatus.ING,
              })}
            >
              {currentVideoPublishTitle()}
            </div>
          )}

        {isUploadFail && (
          <div className={Styles.fail}>
            <i className="iconfont icon-video_icon_broken" />
            <p>视频上传失败</p>
          </div>
        )}
        {isUploading && (
          <div className={Styles.pending}>
            <div className={Styles.content}>
              <div className={Styles.loading} />
              <p>视频上传中</p>
              <button onClick={() => cancelUpload(info.uploadId || '')}>
                取消
              </button>
            </div>
          </div>
        )}

        <CSSTransition
          timeout={150}
          in={shopBtns}
          unmountOnExit
          classNames="slide"
        >
          <div
            style={{
              display: shopBtns && !isUploading ? 'block' : 'none',
            }}
            className={Styles.buttonGroup}
          >
            {publishingStatus && (
              <div className={Styles.opBtn} onClick={cancelPublish}>
                <i className="iconfont icon-live_icon_issue-cancel" />
              </div>
            )}
            {!publishSuccessStatus && !publishingStatus && (
              <div className={Styles.opBtn} onClick={publish}>
                <img
                  src={assets.images.common['dingyue_icon_jisifabu@2x.svg']}
                  alt=""
                />
              </div>
            )}
            <div className={Styles.opBtn} onClick={download}>
              <img
                src={assets.images.common['preview_icon_dowload.svg']}
                alt=""
              />
            </div>
            <div className={Styles.opBtn} onClick={remove}>
              <img src={assets.images.common['video_icon_delete.svg']} alt="" />
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          timeout={200}
          in={!shopBtns}
          unmountOnExit
          classNames="date"
        >
          <div className={Styles.duration}>{formatDuration(info.duration)}</div>
        </CSSTransition>
      </div>
      <p className={Styles.title}>
        {isUploadFail ? '视频上传失败' : info.name || '未命名标题'}
      </p>
      <p
        className={Styles.time}
        style={{ display: !isUploading && !isUploadFail ? '' : 'none' }}
      >
        {formatTime(new Date(info.createdAt), 'yyyy.mm.dd hh:MM')}
      </p>
    </div>
  );
}
