import { Dialog, Tabs, Checkbox, Toast } from '@bhb-frontend/lithe-ui/lib';
import { useEffect, useInsertionEffect, useRef, useState } from 'react';
import { IntroThemes, ThemeCategory, ThemeCollapseList } from '@/types/robot';

import style from './style.module.less';
import { Percent, TEMPLATE_MODULE } from '@/constants/myDoc';
import { getOfficialThemes, getThemesCategory } from '@/api/robot';

interface TemplateModalProps {
  value?: IntroThemes[];
  onChange?: (val: IntroThemes[]) => void;
  visible: boolean;
  onOk?: () => void;
  onCancel?: () => void;
}

export default function TemplateModal(props: TemplateModalProps) {
  const { value = [], onChange, visible, onOk, onCancel } = props;

  const ids = value.map(item => item.id);

  const activeTab = useRef<Percent>(Percent.NINETOSIXTEEN);
  /** 分类列表 */
  const [category, setCategory] = useState<ThemeCategory[]>([]);
  /** 当前选中的分类 */
  const [activeCategory, setActiveCategory] = useState<string>();
  const activeRef = useRef<string>();
  /** 模版列表 */
  const [tempList, setTempList] = useState<ThemeCollapseList[]>([]);

  const sid = useRef<string>('');

  const bodyBottom = useRef<HTMLDivElement>(null);

  const initValue = useRef<IntroThemes[]>([]);

  useInsertionEffect(() => {
    activeRef.current = activeCategory;
  }, [activeCategory]);

  /** 获取模版列表 */
  const getTempList = async (
    activeTab,
    activeCategory,
    isListenInBottom?: boolean
  ) => {
    if (!activeCategory) return;
    const res = await getOfficialThemes(activeCategory, {
      percent: activeTab,
      pageSize: 18,
      sid: sid.current,
    });

    const {
      data: { results = [] },
    } = res;
    sid.current = res?.data?.sid;
    if (!isListenInBottom) {
      setTempList(results);
    } else {
      setTempList(val => [...val, ...results]);
    }
  };

  /** 获取分类 */
  const getCategory = async () => {
    const {
      data: { results },
    } = await getThemesCategory(TEMPLATE_MODULE.ROBOT);
    setCategory(results);
    setActiveCategory(results[0]?.id);
    getTempList(activeTab.current, results[0]?.id);
  };

  const handleChange = activeKey => {
    activeTab.current = activeKey;
    sid.current = '';
    getTempList(activeKey, activeCategory);
  };

  const handleCategory = (item: ThemeCategory) => {
    if (activeCategory === item.id) return;
    setActiveCategory(item.id);
    sid.current = '';
    getTempList(activeTab.current, item.id);
  };

  const onCheck = (item: ThemeCollapseList) => {
    const targetIndex = value.findIndex(v => v.id === item.id);
    if (targetIndex < 0) {
      // 添加
      onChange?.([
        ...value,
        {
          id: item.id,
          coverUrl: item.coverUrl,
        },
      ]);
      return;
    }
    // 取消选中
    const curVlaue = [...value];
    curVlaue.splice(targetIndex, 1);
    onChange?.(curVlaue);
  };

  const items = [
    {
      label: '竖版',
      key: Percent.NINETOSIXTEEN,
      children: null,
    },
    {
      label: '横版',
      key: Percent.SIXTEENTONINE,
      children: null,
    },
  ];

  const handleOk = () => {
    if (value.length > 5) {
      Toast.warning('最多只能选择5个推荐主题');
      return;
    }
    onOk?.();
  };

  const handleCancel = () => {
    onChange?.(initValue.current);
    onCancel?.();
  };

  /** 监听滚动到底部 */
  const listenInBottom = () => {
    const observer = new IntersectionObserver(nodes => {
      const tgt = nodes[0];
      if (tgt.isIntersecting) {
        if (sid.current) {
          getTempList(activeTab.current, activeRef.current, true);
        }
      }
    });
    bodyBottom.current && observer.observe(bodyBottom.current);
  };

  useEffect(() => {
    if (visible) {
      getCategory();
      listenInBottom();
      initValue.current = value;
      return;
    }
    initValue.current = [];
  }, [visible]);

  return (
    <Dialog
      className={style.dialog}
      visible={visible}
      width={915}
      height={770}
      title="选择模版"
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Tabs
        tabPosition="top"
        items={items}
        defaultActiveKey={activeTab.current}
        onChange={handleChange}
      />

      <div className="ratio-content">
        <div className="ratio-content-themeDetail">
          {category.map(item => (
            <div
              key={item.id}
              className={`ratio-content-btn ${
                activeCategory === item.id ? 'ratio-active' : ''
              }`}
              onClick={() => handleCategory(item)}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${style['theme-content']} ${
          activeTab.current === Percent.NINETOSIXTEEN ? '' : style.horizontal
        }`}
      >
        {tempList.map(item => (
          <div
            className={style['theme-item']}
            key={item.id}
            onClick={() => onCheck(item)}
          >
            <Checkbox checked={ids.includes(item.id)} />
            <div
              className={style['item-pic']}
              style={{
                background: `url(${item.coverUrl})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        ))}
        {/* 触底el */}
        <div ref={bodyBottom} className="bodyBottom" />
      </div>
    </Dialog>
  );
}
