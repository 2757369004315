import request from './init';
import { UrlPath, DetailResponse } from '@/types/api';
import { ConfigApp, TopAdItems } from '@/types/config';

export const url: UrlPath = {
  config: '/config/app',
  ad: 'marketing_content',
};

/**
 * 获取客户端配置信息
 * @returns
 */
export function getConfigs(): Promise<DetailResponse<ConfigApp>> {
  const uri = url.config;
  return request.get(uri);
}

/**
 * 获取顶部广告
 * @returns
 */
export function getTopAd(params): Promise<DetailResponse<TopAdItems>> {
  const uri = url.ad;
  return request.get(uri, params);
}
