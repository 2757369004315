import { Modal } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import { QRCodeCanvas } from 'qrcode.react';
import Style from './ModalInvoice.module.less';
import { configStore } from '@/store';

interface ModalInvoiceProps extends ModalProps {
  closeModal?: () => void;
}

function ModalInvoice(props: ModalInvoiceProps) {
  const { visible, closeModal } = props;
  const { config } = configStore;
  return (
    <Modal
      visible={visible}
      width={244}
      height="auto"
      timeout={0}
      closable={false}
      onClose={() => {
        closeModal?.();
      }}
      contentClassName={Style.modalContainer}
    >
      <p>电脑端暂不支持发票功能</p>
      <p>您可以前往app进行操作</p>
      <QRCodeCanvas
        style={{ margin: '20px' }}
        value={config.appDownloadLink || 'https://shanjian.tv'}
        size={144}
        level="H"
      />
      <p>您可以前往app进行操作</p>
    </Modal>
  );
}

export default observer(ModalInvoice);
