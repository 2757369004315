export function getListStyle() {
  return {
    display: 'flex',
  };
}

// a little function to help us with reordering the result
export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function getItemStyle(draggableStyle) {
  return {
    // some basic styles to make the items look a bit nicer
    // userSelect: 'none',
    ...draggableStyle,
  };
}
