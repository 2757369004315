export function getWindow() {
  return typeof window !== 'undefined' ? window : undefined;
}

/* 识别客户端 */
export function clientSide() {
  if (getWindow()) {
    return /Mobi|Android|iPhone/i.test(window.navigator.userAgent);
  }
  return false;
}
const u = navigator.userAgent;

export const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // 判断是否是 android终端

/**
 * 判断是否微信浏览器
 */
export const isWeixinBrowser = () => {
  const ua = u.toLowerCase();
  return /micromessenger/i.test(ua);
};

/** 判断是否IOS */
export function judgeClient() {
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 iOS终端
  if (isAndroid) {
    return 'Android';
  }
  if (isIOS) {
    return 'IOS';
  }
  return 'PC';
}
