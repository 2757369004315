import { observer } from 'mobx-react';
import cls from 'classnames';
import { useNavigate } from 'react-router-dom';
import Contents from './Contents';
import { productList } from '@/constants/Home';
import LoginPermission from '@/components/LoginPermission';
import { createNewDoc } from '@/api/myDoc';
import { goToDoc, goToSubscription } from '@/utils/doc';
import Style from './Home.module.less';
import { ProductItem } from '@/types/home';
import { ProductEnum } from '@/constants/ProductEnum';
import Images from '@/assets/images';

function Creation() {
  const navigate = useNavigate();
  const handleClickProduct = async (item: ProductItem) => {
    const { key, path, jumpType = '_blank' } = item;
    /**  视频订阅号特殊处理 */
    if (key === ProductEnum.SUBSCRIPTION) {
      goToSubscription('/');
      return;
    }

    if (key === ProductEnum.DIGITAL_PERSON) {
      navigate(path);
      return;
    }

    window.open(path, jumpType);
  };

  const createDoc = async () => {
    try {
      const { data } = await createNewDoc({ title: '' });
      goToDoc(`/?id=${data.documentId}`, '_self');
    } catch (error) {
      console.error('创建文档失败', error);
    }
  };

  return (
    <div className={Style.home}>
      {/* banner */}
      <div className={Style['creation-banner']} onClick={createDoc}>
        <i
          className={cls(
            'iconfont',
            'icon-web-btn_icon_creation',
            Style['add-icon']
          )}
        />
        <div className={Style['start-text']}>开始创作</div>
      </div>

      {/* 创建入口 */}
      <div className={Style['product-wrapper']}>
        {productList.map(item => (
          <LoginPermission key={item.key}>
            <div
              className={Style.product}
              onClick={() => handleClickProduct(item)}
            >
              <div className={Style['icon-box']}>
                <i className={cls('iconfont', item.iconfont, Style.icon)} />
              </div>
              <div className={Style.info}>
                <div className={Style.name}>
                  {item.name}

                  {item.showNew && (
                    <img src={Images.home.new_icon} alt="新产品" />
                  )}
                </div>
                <div className={cls(Style.desc, 'single-line-omit')}>
                  {item.desc}
                </div>
              </div>
            </div>
          </LoginPermission>
        ))}
      </div>

      {/* 内容 */}
      <Contents />
    </div>
  );
}

export default observer(Creation);
