import { useState, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import cls from 'classnames';
import { formatTime } from '@bhb-frontend/utils/lib/format';

import { formatDuration } from '@/utils/date';
import { VIDEO_STATUS, VideoStatusTextMap } from '@/constants/videos';
import { VideoItem } from '@/types/videos';
import ReasonModal from '../ReasonModal/ReasonModal';
import Styles from './index.module.less';
import '../transition.less';
import { isI18n } from '@/utils';

const {
  FAIL,
  REJECT,
  REVIEW_FAIL: REVIEWFAIL,
  APPEAL,
  APPEAL_REJECT,
  PENDING,
  QUEUEING,
  REVIEW,
  COMPLETED,
} = VIDEO_STATUS;

interface PropsInfo {
  /** 是否是home 作品展示的样式 */
  isHomeStyle?: boolean;
  info: VideoItem;
  preview: () => void;
  publish: () => void;
  download: () => void;
  remove: () => void;
  handleRejectInfo: (info: VideoItem) => void;
  cancel: (data: VideoItem) => void;
  // clipEditorFN: (data: VideoItem) => void /** 视频二次剪辑 */;
  handleAppeal: (info: VideoItem) => void;
}

export default function VideosItem(props: PropsInfo) {
  const {
    info,
    preview,
    publish,
    handleRejectInfo,
    cancel,
    // clipEditorFN,
    download,
    remove,
    handleAppeal,
    isHomeStyle = false,
  } = props;
  const [shopBtns, setShopBtns] = useState(false);
  const [pendingLoopTexts] = useState([
    '正在加急为您生成视频，请耐心等待',
    '云端生成，请放心创作下一个内容',
    '编辑视频时，切换不同数字人或配音试试',
    '更短的文案或素材，生成视频更快',
  ]);

  /** 失败原因弹窗 */
  const [reasonModalShow, setReasonModalShow] = useState(false);
  /* 是否失败 */
  const isFail = useMemo(
    () =>
      [FAIL, REJECT, REVIEWFAIL, APPEAL, APPEAL_REJECT].includes(info.status),
    [info.status]
  );
  /* 是否暂停 */
  const isAwait = useMemo(
    () => [PENDING, QUEUEING, REVIEW].includes(info.status),
    [info.status]
  );
  /* 失败状态的详情按钮 */
  const DetailBtn = useMemo(
    () => [REVIEW, QUEUEING].includes(info.status),
    [info.status]
  );
  /**
   * 视频生成中、等待生成（排队）、生成完成需要显示封面
   */
  const coverVisible = useMemo(
    () =>
      ![FAIL, REJECT, REVIEWFAIL, APPEAL, APPEAL_REJECT].includes(
        info.status
      ) && info.coverUrl,
    [info.status]
  );

  const buttonGroupVisible = useMemo(
    () =>
      shopBtns &&
      [COMPLETED, FAIL, REJECT, REVIEWFAIL, APPEAL_REJECT].includes(
        info.status
      ),
    [info.status, shopBtns]
  );

  /** 等待文案 */
  const pendingTitle = useMemo(() => {
    switch (info.status) {
      case PENDING:
        return '视频生成中';
      case QUEUEING:
        return '正在等待生成';
      // case REVIEW:
      //   return '审核中';
      default:
        return '';
    }
  }, [info.status]);

  /** 是否展示详情 */
  const isShowInfo = useMemo(() => {
    const { status } = info;
    return [REJECT, APPEAL_REJECT].includes(status);
  }, [info.status]);

  /** 是否审核不通过 */
  const isReject = useMemo(() => {
    const { status } = info;
    return status === REJECT;
  }, [info.status]);

  const stepStyle: any = {
    '--step': pendingLoopTexts.length,
  };

  /** 等待文案 */
  const getTime = () => {
    const { estimateConsumeDuration } = info;
    if (estimateConsumeDuration !== undefined) {
      let time = Math.floor(estimateConsumeDuration / 60);
      if (estimateConsumeDuration % 60 > 0) {
        time += 1;
      }
      return `大约等待${time}分钟`;
    }
    return '';
  };

  return (
    <>
      <div
        className={isHomeStyle ? Styles['home-style-video-item'] : Styles.item}
      >
        <div
          onMouseEnter={() => setShopBtns(true)}
          onMouseLeave={() => setShopBtns(false)}
          className={Styles.cover}
        >
          {coverVisible && (
            <img
              loading="lazy"
              className={Styles.image}
              src={info.coverUrl}
              onClick={preview}
              alt=""
            />
          )}
          {isFail && (
            <div className={Styles.fail}>
              <i className="iconfont icon-video_icon_broken" />
              <p>
                {/* 优先取本地状态枚举值 */}
                {VideoStatusTextMap[info.status] || info.msg || ''}

                {isReject && info.appealable && (
                  <>
                    ，
                    <span
                      onClick={() => handleAppeal(info)}
                      className={Styles.appeal}
                    >
                      申诉
                    </span>
                  </>
                )}
              </p>

              {/* 只有生成失败展示查看原因按钮 */}
              {info.status === FAIL && (
                <button
                  className={Styles.reason}
                  onClick={() => {
                    setReasonModalShow(true);
                  }}
                >
                  查看原因
                </button>
              )}

              <button
                style={{
                  display: isShowInfo ? 'block' : 'none',
                  textAlign: 'center',
                }}
                className={Styles.reason}
                onClick={() => {
                  handleRejectInfo(info);
                }}
              >
                详情
              </button>
            </div>
          )}

          {isAwait && (
            <div className={Styles.pending}>
              <div
                className={cls(Styles.content, {
                  [Styles.dev]: !isI18n,
                  [Styles['dev-i18n']]: isI18n,
                })}
              >
                <div
                  className={cls(Styles.loading, {
                    [Styles['loading-queueing']]: info.status === QUEUEING,
                  })}
                />
                <p>{pendingTitle}</p>
                <div className={Styles.line} />
                {info.status === PENDING ? (
                  <div
                    style={{
                      color: 'rgba(255, 255, 255, 0.6)',
                    }}
                  >
                    {getTime()}
                  </div>
                ) : (
                  <div className={Styles.scroll} style={stepStyle}>
                    <ul className={Styles.ul}>
                      {pendingLoopTexts
                        .concat(pendingLoopTexts[0])
                        .map((item, index) => (
                          <li
                            key={`${item}${index.toString()}`}
                            className={Styles.li}
                          >
                            {item}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                {DetailBtn && (
                  <button onClick={() => cancel(info)}>取消</button>
                )}
              </div>
            </div>
          )}
          <CSSTransition timeout={150} in={shopBtns} unmountOnExit cls="slide">
            <div
              style={{
                display: buttonGroupVisible ? 'block' : 'none',
              }}
              className={Styles.buttonGroup}
            >
              {info.status === COMPLETED && (
                <>
                  <div className={Styles.opBtn} onClick={publish}>
                    <i
                      className={cls(
                        'iconfont',
                        'icon-a-web-Mywork-share',
                        Styles.icon
                      )}
                    />
                  </div>

                  <div className={Styles.opBtn} onClick={download}>
                    <i
                      className={cls(
                        'iconfont',
                        'icon-a-web-Mywork-Download',
                        Styles.icon
                      )}
                    />
                  </div>
                </>
              )}
              <div className={Styles.opBtn} onClick={remove}>
                <i
                  className={cls(
                    'iconfont',
                    'icon-a-web-Mywork-delete',
                    Styles.icon
                  )}
                />
              </div>
            </div>
          </CSSTransition>
          {!!info.duration && (
            <CSSTransition timeout={50} in={!shopBtns} unmountOnExit cls="date">
              <div className={Styles.duration}>
                {formatDuration(info.duration || 0)}
              </div>
            </CSSTransition>
          )}
        </div>
        <div className={Styles['info-box']}>
          <p className={Styles.title}>{info.videoName || '未命名标题'}</p>
          <p className={Styles.time}>
            {formatTime(new Date(info.createdAt), 'yyyy.mm.dd hh:MM')}
          </p>
        </div>
      </div>
      <ReasonModal
        visible={reasonModalShow}
        desc={info.errorMsg || '视频生成遇到问题，请稍后再试吧~'}
        onClose={() => {
          setReasonModalShow(false);
        }}
      />
    </>
  );
}
