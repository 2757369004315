/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/c4c3e4cfb883c00e1f33550d2f20ef82.png */
  'subscription-icon.png': 'c4c3e4cfb883c00e1f33550d2f20ef82.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/f3ee6450544b4cb59e2d9ade89f16d0c.png */
  'doc-icon.png': 'f3ee6450544b4cb59e2d9ade89f16d0c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/2f9fd30065416dec38803cccafe35faa.png */
  'live-icon.png': '2f9fd30065416dec38803cccafe35faa.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ecfaf56d9ac535a2602543de1142a866.png */
  'distribution-icon.png': 'ecfaf56d9ac535a2602543de1142a866.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e416107b84923565c72dad773a86eee0.png */
  'video-clip-icon.png': 'e416107b84923565c72dad773a86eee0.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/33d76ba1dc7c12e31a43f0d265257034.png */
  'doc_icon_empty.png': '33d76ba1dc7c12e31a43f0d265257034.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/bc4f32149b1f64677599ffadaf556b0c.webp */
  'figureSpt.webp': 'bc4f32149b1f64677599ffadaf556b0c.webp',

  /* https://bhb-frontend.bhbcdn.com/static/files/f4709f653769589824e2d7d7f061d68c.png */
  'home_tips_beta.png': 'f4709f653769589824e2d7d7f061d68c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ab8f7899ca769b1fc38d970317478cad.png */
  'empty-list.png': 'ab8f7899ca769b1fc38d970317478cad.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/b78cc92a5c5fdadda7f0df29551ff2cc.png */
  'not-login.png': 'b78cc92a5c5fdadda7f0df29551ff2cc.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/9171f7454997f340476c32073457dbce.png */
  'douyin.png': '9171f7454997f340476c32073457dbce.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/fdff825754f8aa6c4e5fa96fc7524c2f.png */
  'kuaishou.png': 'fdff825754f8aa6c4e5fa96fc7524c2f.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/c949d3518054c9ca51603b82babee807.png */
  'tuwen_icon_empty.png': 'c949d3518054c9ca51603b82babee807.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/487da2c9db5d70306e28c3c252a99bba.png  */
  'video_icon_empty.png': '487da2c9db5d70306e28c3c252a99bba.png ',

  /* https://bhb-frontend.bhbcdn.com/static/files/742eb5574740285e95296975f7ba99c6.png  */
  'zhibo_icon_empty@2x.png': '742eb5574740285e95296975f7ba99c6.png ',

  /* https://bhb-frontend.bhbcdn.com/static/files/91f5a638bb044f51a57f25dd2af8e225.png */
  'custom-digital-person.png': '91f5a638bb044f51a57f25dd2af8e225.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/949fc4242d524e1a922ccf3aa4c45c8a.png */
  'digital_icon_new.png': '949fc4242d524e1a922ccf3aa4c45c8a.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/9a6a41ae013b4889a74e7bc695596156.webp */
  'custom-digital-feature.webp': '9a6a41ae013b4889a74e7bc695596156.webp',

  /* https://bhb-frontend.bhbcdn.com/static/files/383d7272d0144388a00e790eefb675a4.png */
  'home_btn_main_button-bkg.png': '383d7272d0144388a00e790eefb675a4.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ebdf8a36531c43018595beecad3d0291.png */
  'drafts-back.png': 'ebdf8a36531c43018595beecad3d0291.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/b41c568b88314be09327132cb8e858e5.png */
  new_icon: 'mtv/images/b41c568b88314be09327132cb8e858e5.png',
};
