import { outletContext } from '@/layout/Menu';
import { NAVBAR_HEIGHT, TOP_AD_HEIGHT } from '@/constants/Layout';

export function useStickyTop() {
  const { showTopAd } = outletContext();
  /** 顶部距离 */
  const topDistance = showTopAd ? TOP_AD_HEIGHT + NAVBAR_HEIGHT : NAVBAR_HEIGHT;

  return { showTopAd, topDistance };
}
