/**
 * 直播状态枚举映射
 */
export enum LIVE_STATUS {
  init = '创建中',
  running = '录制中',
  disconnect = '直播中断',
  finished = '已结束',
  expired = '已过期',
  terminated = '已终止',
}

/**
 * 直播相关列表操作
 */
export enum LIVE_LIST_OPERATION {
  /** 删除 */
  DELETE = 'delete',
  /** 停止录制 */
  STOP_RECORD = 'stop',
  /** 编辑商品 */
  EDIT = 'edit',
}
