import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Spin, Toast, LoadMore, Empty } from '@bhb-frontend/lithe-ui';
import { Member } from '@bhb-frontend/mtv-navbar';
import { store } from '@bhb-frontend/store';
import cls from 'classnames';
import LiveAddInput from './LiveAddInput';
import LiveTable from './LiveTable';
import Style from './style.module.less';
import LiveTableHead from './LiveTable/LiveTableHead';
import LiveMessage from './LiveMessage';
import {
  HANDLE_MODAL_DATA,
  MY_LIVE_SEARCH_KEY,
  MY_LIVE_SEARCH_NAMESPACE,
  Positive,
} from '@/constants/myLive';
import {
  LiveDetail,
  LiveQuery,
  LiveVideoRecordUpdate,
  VideoItems,
} from '@/types/live';
import { SocketResponse } from '@/types/api';
import { getLives, removeLive, liveTerminate } from '@/api/live';
import socket from '@/utils/socket';
import { userStore } from '@/store';

import { LIVE_LIST_OPERATION } from '@/constants/liveStatusEnum';
import Image from '@/assets/images';

/** 每次请求的页数 */
const PAGE_SIZE = 20;

function Live() {
  const [loading, setLoading] = useState<boolean>(false);
  /* 直播列表 */
  const [list, setList] = useState<LiveDetail[]>([]);
  /* 搜索关键字 */
  const [keyword, setKeyword] = useState(() => '');

  /* 弹窗显示隐藏 */
  const [showMessage, setShowMessage] = useState(false);
  /* 弹窗标题 */
  const [title, setTitle] = useState('');
  /* 弹窗信息 */
  const [message, setMessage] = useState('');
  /* 表格操作类型 */
  const [handleTableType, setHandleTableType] = useState('delete');

  /** 当前选中的直播间id */
  const [currentLiveId, setCurrentLiveId] = useState('');

  /**  默认是向下箭头的 */
  const [ordering, setOrdering] = useState<string>(Positive);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const listQuery = useRef({
    sid: '',
    pageSize: PAGE_SIZE,
  });

  /** 监听搜索 */
  const refreshListBySearch = ({ newValue }) => {
    const value = newValue.replace(/\s/g, '');
    setKeyword(value);
  };
  useEffect(() => {
    store.listen(
      MY_LIVE_SEARCH_NAMESPACE,
      MY_LIVE_SEARCH_KEY,
      refreshListBySearch
    );
    return () => {
      store.unlisten(
        MY_LIVE_SEARCH_NAMESPACE,
        MY_LIVE_SEARCH_KEY,
        refreshListBySearch
      );
    };
  }, []);

  /** 操作类型发生改变 */
  useEffect(() => {
    const { title, message } = HANDLE_MODAL_DATA[handleTableType];
    setTitle(title);
    setMessage(message);
  }, [handleTableType]);

  const onSort = (value: string) => {
    if (ordering === value) return;
    setOrdering(value);
    const newList = list.reverse();
    setList(newList);
  };

  /* 更新录制时长 */
  const updateRecordDu = (data: LiveVideoRecordUpdate) => {
    const currLive = list.find(live => live.id === data.liveId);
    if (!currLive) return;
    if (currLive.recordDuration && data.recordDuration) {
      currLive.recordDuration = data.recordDuration;
    }
  };

  /* 获取列表 */
  const getList = async () => {
    setLoading(true);
    const obj: Partial<LiveQuery> = {
      ...listQuery.current,
      keyword,
    };

    try {
      const { data } = await getLives(obj);
      listQuery.current = {
        ...listQuery.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (error) {
      console.log('获取直播列表失败', error);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /** 加载更多数据 */
  const loadMore = async () => {
    const data = await getList();
    data && setList([...list, ...data.results]);
  };

  /* 直播列表查询 */
  const search = async () => {
    listQuery.current = {
      ...listQuery.current,
      sid: '',
    };
    const data = await getList();
    data && setList(data.results);
  };

  /** 搜索刷新列表 */
  useEffect(() => {
    search();
  }, [keyword]);

  useEffect(() => {
    socket.on((res: SocketResponse<VideoItems & LiveVideoRecordUpdate>) => {
      if (res.event === 'live.status.change' || res.event === 'live.close') {
        search();
      } else if (res.event === 'pay.result') {
        if (res.data.success) {
          Member.paySuccessDialog(res.data.orderNo);
        }
      } else if (res.event === 'live.duration.tips') {
        userStore.getUser();
      } else if (res.event === 'live.record.update') {
        updateRecordDu(res.data);
      }
    });
  }, []);

  useLayoutEffect(() => {
    search();
  }, []);

  /** 保存点击的id跟操作类型 */
  const tableHandle = (id: string, type: string) => {
    setShowMessage(true);
    setHandleTableType(type);
    setCurrentLiveId(id);
  };

  /** 停止直播 或者删除直播
   * @params
   * - id 直播id
   * - type 操作类型，有终止直播跟删除直播
   */
  const stopOrDeleteLive = (id: string, type: string) => {
    const { DELETE } = LIVE_LIST_OPERATION;
    const isDelete = type === DELETE;

    let operation: (arg: { liveId: string } & string) => Promise<any>;
    let params: string | { liveId: string };
    let tips: string;

    if (isDelete) {
      operation = removeLive;
      params = id;
      tips = '删除成功';
    } else {
      operation = liveTerminate;
      params = { liveId: id };
      tips = '终止成功';
    }

    operation(params as { liveId: string } & string).then(() => {
      setShowMessage(false);
      const targetList = [...list];
      const i = targetList.findIndex(r => r.id === id);
      if (i > -1) {
        targetList.splice(i, 1);
        setList(!targetList.length ? [] : targetList);
      }
      Toast.info(tips);
    });
  };

  return (
    <div className={Style.page}>
      <div className={Style.live}>
        <div className={Style['live-top']}>
          <div className={Style['live-top-wrapper']}>
            <LiveAddInput />
          </div>
          <div className={Style['live-title']}>我的直播</div>
        </div>
        <Spin spinning={loading} className={Style['live-table-spin']}>
          <div
            className={Style['live-table-wrapper']}
            style={{ height: '100%' }}
          >
            <LiveTableHead ordering={ordering} onSort={onSort} />
            <div className={Style['table-body']}>
              <div className={Style['table-body-info']}>
                {!list.length && !loading ? (
                  <Empty
                    imgSrc={Image.live.live_list_empty}
                    text="暂无直播列表"
                    style={{ marginTop: 250, color: '#191919' }}
                  />
                ) : (
                  <LoadMore
                    loading={loading}
                    onReachBottom={loadMore}
                    hasMore={hasMore}
                    className={Style['table-body-info-more']}
                  >
                    {list.map(item => (
                      <LiveTable
                        data={item}
                        key={item.id}
                        handle={tableHandle}
                      />
                    ))}
                  </LoadMore>
                )}
              </div>
            </div>
          </div>
        </Spin>
        {/*  消息提示 */}
        <LiveMessage
          title={title}
          message={message}
          visible={showMessage}
          onClose={() => {
            setShowMessage(false);
          }}
        >
          <div className={Style['live-message-bottom']}>
            <button
              className={cls(Style['message-button'], Style.gray)}
              onClick={() => setShowMessage(false)}
            >
              取消
            </button>
            <button
              className={cls(Style['message-button'], Style.primary)}
              onClick={() => stopOrDeleteLive(currentLiveId, handleTableType)}
            >
              {handleTableType === LIVE_LIST_OPERATION.DELETE ? '删除' : '确定'}
            </button>
          </div>
        </LiveMessage>
      </div>
    </div>
  );
}

export default Live;
