import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { store } from '@bhb-frontend/store';
import { Button } from '@bhb-frontend/lithe-ui';
import classnames from 'classnames';
import { EditorProjectsApiModal as EApimodal } from '@/types/editorProjectsApi';
import { EditorProjectsModal as Emodal } from '@/types/editorProjects';
import { getEditorProjectsAPI } from '@/api/editorProjects';
import EditorPojectItemAction from '@/types/editorPojectItemAction';
import EmptyList from '@/components/EmptyList';

import Style from './Projects.module.less';
import TableHead from './TableHead';
import AddProjectDialog from './AddProjectDialog';
import TableItem from './TableItem';
import RemoveProjectDialog from './RemoveProjectDialog';
import RenameProjectDialog from './RenameProjectDialog';
import { userStore } from '@/store/user';
import socket from '@/utils/socket';
import { SOCKET_EVENTS } from '@/constants/videos';
import { EDITOR_SEARCH_KEY, EDITOR_SEARCH_NAMESPACE } from '@/constants/Editor';

function Projects() {
  /** 控制添加项目弹窗 */
  const [addVisible, setAddVisibleFN] = useState(false);

  /** 控制删除项目弹窗 */
  const [removeVisible, setRemoveVisibleFN] = useState(false);

  /** 控制更改项目名弹窗 */
  const [renameVisible, setrenameVisibleFN] = useState(false);

  /* 功能落后 */
  const selectedIdListLength = false;

  /** 每页数量 / 上一页最后id */
  const listQuery = useRef<Emodal.PListQuery>({
    sid: '',
    pageSize: 10,
    name: '',
  });

  /** 元素列表 */
  // const [projectList, setProjectListFN] = useState<
  //   EApimodal.DgetEditorProjectsAPI[]
  // >([]);
  const projectList = useRef<EApimodal.DgetEditorProjectsAPI[]>([]);
  const [socketStepNum, setSocketStepNumFN] = useState<number>(1);

  /** 加载loading */
  const [loading, setLoadingFN] = useState<boolean>(false);

  /** 判断是否还有下一页 */
  const [hasMore, setHasMoreFN] = useState<boolean>(true);

  /** 滚动元素存储 */
  const [scrollRef, setScrollRefFN] = useState<HTMLDivElement | undefined>(
    undefined
  );

  /** 选中id列表 */
  const [selectedIdList, setSelectedIdListFN] = useState<string[]>([]);

  /** 选中项目列表 */
  const [selectedItemList, setSelectedItemListFN] = useState<
    EApimodal.DgetEditorProjectsAPI[]
  >([]);

  /** 全选函数 */
  const selectAllFN = (isSelect: boolean) => {
    if (!isSelect) {
      setSelectedIdListFN([]);
      return;
    }
    setSelectedIdListFN(projectList.current.map(({ id }) => id));
  };

  /** 单选函数 */
  const selectSingleFN = (id: string, isSelect: boolean) => {
    if (isSelect) {
      setSelectedIdListFN([...selectedIdList, id]);
      return;
    }
    setSelectedIdListFN(selectedIdList.filter(item => item !== id));
  };

  /** 获取列表数据 */
  const getListFN = async () => {
    try {
      setLoadingFN(true);
      const params = {
        ...listQuery.current,
      };

      const { data } = await getEditorProjectsAPI(params);
      listQuery.current = {
        ...listQuery.current,
        sid: data.sid,
      };
      setHasMoreFN(!!data.sid);
      setLoadingFN(false);
      return data;
    } finally {
      setLoadingFN(false);
    }
  };

  /** 加载更多 */
  const loadMoreFN = async () => {
    if (!hasMore) return;
    const { results } = await getListFN();
    projectList.current.push(...results);
  };

  /** 单个元素操作回调 */
  const onOperationFN = (
    type: EditorPojectItemAction,
    itemData: EApimodal.DgetEditorProjectsAPI
  ) => {
    switch (type) {
      case EditorPojectItemAction.remove: {
        setSelectedIdListFN([...selectedIdList, itemData.id]);
        setRemoveVisibleFN(true);
        break;
      }
      case EditorPojectItemAction.rename: {
        setSelectedItemListFN([itemData]);
        setrenameVisibleFN(true);
        break;
      }
      default:
        break;
    }
  };

  /** 添加元素成功执行函数 */
  const addModalConfirmFN = (item: EApimodal.DgetEditorProjectsAPI) => {
    projectList.current.unshift(item);
  };

  /** 删除项目 */
  const projectListRemoveFN = (idList: string[]) => {
    idList.forEach(id => {
      const pIndex = projectList.current.findIndex(item => item.id === id);
      if (pIndex !== -1) {
        projectList.current.splice(pIndex, 1);
      }
    });
  };

  /** 删除元素成功执行函数 */
  const removeModalConfirmFN = async (idList: string[]) => {
    projectListRemoveFN(idList);
    setSelectedIdListFN(
      selectedIdList.filter(item => {
        const judgeIn = idList.findIndex(id => id === item);
        return judgeIn === -1;
      })
    );
  };

  /** 更改项目名称成功执行函数 */
  const renameModalConfirmFN = async (
    data: EApimodal.DgetEditorProjectsAPI
  ) => {
    setSelectedItemListFN([]);
    const pIndex = projectList.current.findIndex(item => item.id === data.id);
    projectList.current[pIndex] &&
      (projectList.current[pIndex].name = data.name);
  };

  /** 元素滚动获取数据 */
  const onScrollHandleFN = () => {
    if (!scrollRef || loading) return;
    const scrollTop = scrollRef.scrollTop;
    const clientHeight = scrollRef.clientHeight;
    const scrollHeight = scrollRef.scrollHeight;
    const isBottom = Math.ceil(scrollTop + clientHeight) === scrollHeight;
    if (isBottom) {
      loadMoreFN();
    }
  };

  /** 监听二次编辑功能消息 */
  const monitorSecondaryEditingFN = () => {
    if (!userStore.userInfo.socketLink) return;
    socket.on((res: any) => {
      const { data, event, module } = res;
      const judgeModule = module === SOCKET_EVENTS.EDITOR;
      const judgeEvent = event === SOCKET_EVENTS.EDITOR_TWICE_EDITING_CHANGE;
      if (!judgeModule && !judgeEvent) return;
      const pIndex = projectList.current.findIndex(
        item => item.id === data.projectId
      );
      projectList.current[pIndex] &&
        (projectList.current[pIndex].projectStatus = data.status);
      projectList.current[pIndex] &&
        (projectList.current[pIndex].loadingProgress =
          data.loadingProgress || 100);
      setSocketStepNumFN(num => num + 1);
    });
  };

  /** nav搜索 */
  const nameSearchFN = (name: string) => {
    listQuery.current = {
      ...listQuery.current,
      name,
      sid: '',
    };
    projectList.current = [];
    loadMoreFN();
  };
  /** 搜索监听函数 */
  const navSearchFN = () => {
    store.listen(EDITOR_SEARCH_NAMESPACE, EDITOR_SEARCH_KEY, val => {
      const { newValue } = val;
      nameSearchFN(newValue);
    });
    return () => {
      store.unlisten(EDITOR_SEARCH_NAMESPACE, EDITOR_SEARCH_KEY, val => {
        const { newValue } = val;
        nameSearchFN(newValue);
      });
    };
  };

  useEffect(() => {
    onScrollHandleFN();
  }, [scrollRef, projectList.current, loading]);

  useEffect(() => {
    loadMoreFN();
    navSearchFN();
  }, []);

  useEffect(() => {
    monitorSecondaryEditingFN();
  }, [userStore.userInfo.socketLink]);

  return (
    <div className={Style.project}>
      <div className={Style.header}>
        <div className={Style.title}>我的项目</div>
        {selectedIdListLength ? (
          // {selectedIdList.length > 0 ? (
          <div>
            <Button
              onClick={() => {
                selectAllFN(false);
              }}
              size="large"
              type="default"
            >
              取消
            </Button>
            <Button
              size="large"
              type="warning"
              style={{
                marginLeft: 10,
              }}
            >
              删除
            </Button>
          </div>
        ) : (
          <Button
            onClick={() => {
              setAddVisibleFN(true);
            }}
            size="large"
            type="default"
            className={Style['add-project']}
          >
            创建项目
          </Button>
        )}
      </div>

      {/* table */}
      <div className={Style['table-box']}>
        <TableHead
          selectedIdList={selectedIdList}
          isSelectedAll={selectedIdList.length === projectList.current.length}
          selectAllFN={selectAllFN}
        />
        <div
          className={classnames(Style['table-body'], {
            [Style['table-body-null']]: projectList.current.length === 0,
          })}
        >
          {projectList.current.length > 0 ? (
            <div
              className={Style['load-more-wrapper']}
              ref={c => {
                setScrollRefFN(c as HTMLDivElement);
              }}
              onScrollCapture={() => onScrollHandleFN()}
            >
              {projectList.current.map(item => (
                <TableItem
                  itemData={item}
                  onOperationFN={onOperationFN}
                  selectSingleFN={selectSingleFN}
                  selectedIdList={selectedIdList}
                  projectListStep={socketStepNum}
                  key={item.id}
                />
              ))}
              {loading && <p>数据加载中...</p>}
            </div>
          ) : (
            <EmptyList text="暂时没有项目" />
          )}
        </div>
      </div>

      {/* 添加项目弹窗 */}
      <AddProjectDialog
        visible={addVisible}
        modalActionFN={setAddVisibleFN}
        onConfirmFN={addModalConfirmFN}
      />

      {/* 删除项目弹窗 */}
      <RemoveProjectDialog
        visible={removeVisible}
        selectedIdList={selectedIdList}
        modalActionFN={setRemoveVisibleFN}
        onConfirmFN={removeModalConfirmFN}
      />

      {/* 更改项目名称 */}
      <RenameProjectDialog
        visible={renameVisible}
        selectProjects={selectedItemList}
        modalActionFN={setrenameVisibleFN}
        onConfirmFN={renameModalConfirmFN}
      />
    </div>
  );
}

export default observer(Projects);
