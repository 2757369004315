/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/825c7c737894d6c6a0be9b16dcb99c32.png */
  'icon-choose-hover.png': '825c7c737894d6c6a0be9b16dcb99c32.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/5b47efb2c92bd111ea169d28093ad029.png */
  'icon-common-tips.png': '5b47efb2c92bd111ea169d28093ad029.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/83a1e4573908b4d72cdec44226035452.png */
  'icon-loading.png': '83a1e4573908b4d72cdec44226035452.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/585029de83533e6825102fe29eda3441.png */
  'icon-question.png': '585029de83533e6825102fe29eda3441.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/a47251b8881721360a60c15bbf86740f.png */
  'icon-video-play.png': 'a47251b8881721360a60c15bbf86740f.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ab8f7899ca769b1fc38d970317478cad.png */
  'img-empty-box.png': 'ab8f7899ca769b1fc38d970317478cad.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/77ccba1cd302a2a3c721ae700147fb50.png */
  'dingyue_icon_empty@2x.png': '77ccba1cd302a2a3c721ae700147fb50.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/3f1ed976f1b7f7cb8375dfce8d47de6b.png */
  'img-empty-order.png': '3f1ed976f1b7f7cb8375dfce8d47de6b.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/9c633c27941e28d8d09f2fe124e91430.png */
  'img-login-bg.png': '9c633c27941e28d8d09f2fe124e91430.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/943e77d250b71b2ee7ce7d7c0bb0a176.png */
  'img-pay-wx.png': '943e77d250b71b2ee7ce7d7c0bb0a176.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/5c4473cc9f234392a4ead301e3827593.png */
  'ai_text.png': '5c4473cc9f234392a4ead301e3827593.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/e00fab84ad774d498926b8419a651a5c.png */
  'coin.png': 'e00fab84ad774d498926b8419a651a5c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/1f08490c5da54e3689533d49970605f9.png */
  'koubo.png': '1f08490c5da54e3689533d49970605f9.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ead44b193f9a4ca09b1021cf4f3d561d.png */
  'common_icon_shenji.png': 'ead44b193f9a4ca09b1021cf4f3d561d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/4c56aa5231564e5480b8b41b62d880b7.png */
  'vip_icon_quit.png': '4c56aa5231564e5480b8b41b62d880b7.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/ffd393d834f34d8b9e67c2397be7502b.png */
  img_guide_mobile_png: 'mtv/images/ffd393d834f34d8b9e67c2397be7502b.png',

  'icon-member-header-close': '3ae8786ef469f69148d3bce0585a60be.png',
};
