import { observer } from 'mobx-react';
import Menu from '../Menu';

import { baseMenu } from './menuConfig';
import { configStore } from '@/store/config';
import style from './style.module.less';

export default observer(() => {
  const { agent } = configStore.config;
  return (
    <Menu
      menuConfig={baseMenu}
      isShowInvite={agent?.switch && agent?.myPage.switch}
      inviteText={(agent && agent?.myPage.entryText) || '推广中心'}
      contentClassName={style.outlet}
    />
  );
});
