import cls from 'classnames';
import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Toast } from '@bhb-frontend/lithe-ui';
import AddGoodsModal from './AddGoodsModal/AddGoodsModal';
import Style from './Goods.module.less';
import GoodsTable from './GoodsTable';
import Image from '@/assets/images';
import { deleteGood } from '@/api/robot';
import { GoodsResult } from '@/types/live';

const { useState } = React;
export default function Goods() {
  /** 删除确认显示 */
  const [deleteVisible, setDeleteVisible] = React.useState(false);

  const currentIdRef = React.useRef('');

  React.useEffect(
    () => () => {
      currentIdRef.current = '';
    },
    []
  );

  /** 路由参数 */
  const params = useParams();
  /** 路由跳转 */
  const navigate = useNavigate();
  /* 添加商品/编辑商品 */
  const [goodsVisible, setGoodsVisible] = useState(false);
  /* 添加/编辑状态 */
  const [isEdit, setIsEdit] = useState(false);
  /* 当前item */
  const [info, setInfo] = useState<GoodsResult>();

  const deleteModal = (id: string) => {
    setDeleteVisible(true);
    currentIdRef.current = id;
  };

  const deleteConfirm = () => {
    deleteGood(currentIdRef.current).then(() => {
      Toast.info('删除成功');
      setDeleteVisible(false);
      GoodsTable.getList();
    });
  };

  /** 进入机器人 */
  const enterRobot = () => {
    navigate('/live/robot');
  };

  /** 进入机器人详情 */
  const enterRobotDetail = () => {
    navigate(`/live/robot/detail/${params.id}`);
  };

  /* 添加商品弹窗 */
  const openAddGoods = () => {
    setIsEdit(false);
    setGoodsVisible(true);
  };

  /* 编辑商品弹窗 */
  const openEditGoods = (info: GoodsResult) => {
    setIsEdit(true);
    setInfo(info);
    setGoodsVisible(true);
  };

  /* 关闭商品弹窗 */
  const closeAddGoods = () => {
    setGoodsVisible(false);
  };

  /** 头部 */
  const Header = (
    <div className={Style.header}>
      <div className={Style['header-path']}>
        <span className={Style['header-path-title']} onClick={enterRobot}>
          我的机器人
        </span>
        <i
          className={cls(
            'iconfont icon-a-common_icon_arrow_next2x',
            Style['header-path-icon']
          )}
        />
        <span className={Style['header-path-title']} onClick={enterRobotDetail}>
          {params.name}
        </span>
        <i
          className={cls(
            'iconfont icon-a-common_icon_arrow_next2x',
            Style['header-path-icon']
          )}
        />
        <span className={Style['header-path-current']}>商品管理</span>
      </div>

      <div className={Style['header-button']}>
        <div className={Style['header-button-btn']} onClick={openAddGoods}>
          添加商品
        </div>
      </div>
    </div>
  );

  return (
    <div className={Style.goods}>
      {Header}
      <GoodsTable
        liveRobotId={params.id as string}
        onDelete={id => {
          deleteModal(id);
        }}
        openEditGoods={openEditGoods}
      />
      <Modal
        width={420}
        height={150}
        visible={deleteVisible}
        closable={false}
        timeout={0}
        onClose={() => {
          setDeleteVisible(false);
        }}
      >
        <div className={Style.modal}>
          <div className={Style['modal-header']}>
            <span>提示</span>
            <img
              src={Image.common['common_icon_close.png']}
              alt="关闭"
              onClick={() => setDeleteVisible(false)}
            />
          </div>
          <div className={Style['modal-content']}>确认删除该商品？ </div>
          <div className={Style['modal-button']}>
            <div
              className={cls(Style['modal-button-btn'], Style.cancel)}
              onClick={() => setDeleteVisible(false)}
            >
              取消
            </div>
            <div
              className={cls(Style['modal-button-btn'], Style.confirm)}
              onClick={deleteConfirm}
            >
              确定
            </div>
          </div>
        </div>
      </Modal>
      {goodsVisible && (
        <AddGoodsModal
          visible={goodsVisible}
          onClose={closeAddGoods}
          isEdit={isEdit}
          getList={() => {
            GoodsTable.getList();
          }}
          info={info}
        />
      )}
    </div>
  );
}
