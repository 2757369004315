import * as React from 'react';
import { Button, Modal } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';

import { PublishStatusEnum } from '@/constants/videos';
import { PublishCustomMessage } from '@/types/videos';
import Styles from './index.module.less';
import assets from '@/assets';

interface IPublishModal {
  visible: boolean;
  status?: string;
  close: () => void;
}

const { useMemo, useEffect, useState } = React;

export default function PublishModal(props: IPublishModal) {
  const { visible, status, close } = props;
  const [isClose, setClose] = useState(false);

  let timer = 0;

  const customMessage = useMemo(() => {
    const defaultMessage: PublishCustomMessage = {
      icon: assets.images.common['icon-fabu-loading.png'],
      title: '正在发布',
      message: '视频账号正在调取第三方发布接口',
      isRotate: true,
    };
    const choices = {
      [PublishStatusEnum.PUBLISHPARTSUCCEED]: {
        icon: assets.images.common['icon-fabu-bufen.png'],
        title: '部分发布成功',
        message: '部分账号调取第三方发布接口失败',
        isButton: true,
      },
      [PublishStatusEnum.PUBLISHFAILED]: {
        icon: assets.images.common['icon-fabu-fail.png'],
        title: '发布失败',
        message: '视频账号调取第三方发布接口失败',
        isButton: true,
      },
      [PublishStatusEnum.PUBLISHSUCCEED]: {
        icon: assets.images.common['icon-fabu-success.png'],
        title: '发布成功',
        message: '视频账号调取第三方发布接口成功',
        isButton: true,
      },
    };
    return choices[status as PublishStatusEnum] || defaultMessage;
  }, [status]);

  useEffect(() => {
    // 正在发布状态下，10秒内如果还没结束就展示按钮
    if (status === PublishStatusEnum.PUBLISHLOADING && visible) {
      timer = window.setTimeout(() => {
        setClose(true);
      }, 10000);
    } else {
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [visible]);

  return (
    <Modal
      width={283}
      height="auto"
      visible={visible}
      closable={false}
      onClose={() => {
        setClose(false);
        close();
      }}
    >
      <div className={Styles.title}>
        <img
          src={customMessage.icon}
          className={cs({
            [Styles.rotate]: customMessage.isRotate,
          })}
          alt=""
        />
        {customMessage.title}
      </div>
      {isClose ? (
        status === PublishStatusEnum.PUBLISHLOADING && (
          <div className={Styles.content}>关闭弹窗不会影响发布进程</div>
        )
      ) : (
        <div className={Styles.content}>{customMessage.message}</div>
      )}

      {customMessage.isButton && (
        <Button type="primary" className={Styles.button} onClick={close}>
          知道了
        </Button>
      )}
      {isClose && status === PublishStatusEnum.PUBLISHLOADING && (
        <Button className={Styles.button} onClick={close} type="primary">
          关闭弹窗
        </Button>
      )}
    </Modal>
  );
}
