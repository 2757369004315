import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { IntroThemes } from '@/types/robot';
import { getItemStyle, getListStyle, reorder } from '@/utils/reactDnd';

import style from './style.module.less';
import TemplateModal from '../TemplateModal';

interface TemplateSelectProps {
  value?: IntroThemes[];
  onChange?: (val: IntroThemes[]) => void;
}

export default function TemplateSelect(props: TemplateSelectProps) {
  const { value = [], onChange } = props;

  const [visible, setVisible] = useState<boolean>(false);

  /** 删除 */
  const handleRemove = (theme: IntroThemes) => {
    const themeList = Array.from(value);
    const index = themeList.findIndex(item => item.id === theme.id);
    themeList.splice(index, 1);
    onChange?.(themeList);
  };

  /** 新增模版 */
  const addTheme = () => {
    setVisible(true);
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      value,
      result.source.index,
      result.destination.index
    ) as IntroThemes[];
    onChange?.(items);
  };

  const renderPic = item => (
    <div
      key={item.id}
      className={`${style['drag-item']} ${style.box}`}
      style={{
        backgroundImage: `url(${item.coverUrl})`,
        backgroundSize: '100%',
        backgroundColor: '#f3f3f3',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <div
        className={style['drag-item__close']}
        onClick={() => handleRemove(item)}
      />
    </div>
  );

  const renderItem = (item, index) => (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...getItemStyle(provided.draggableProps.style),
          }}
        >
          {renderPic(item)}
        </div>
      )}
    </Draggable>
  );

  const renderList = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId="droppable"
        direction="horizontal"
        renderClone={(provided, _, rubric) => {
          const item = value[rubric.source.index];
          return (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {renderPic(item)}
            </div>
          );
        }}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            style={getListStyle()}
            {...provided.droppableProps}
          >
            {value.map((scene, index) => renderItem(scene, index))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  return (
    <>
      <div className={style.introTheme}>
        <div className={style['figure-list']}>
          {renderList()}
          {value.length < 5 ? (
            <div className={style.uploader} onClick={addTheme}>
              <div id="uploadFile">
                <i
                  className={`icon-a-home_add2x iconfont ${style.uploader__plus} ${style.box}`}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <TemplateModal
        value={value}
        visible={visible}
        onChange={onChange}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      />
    </>
  );
}
