import * as React from 'react';
import { Tooltips, Popup, Menu } from '@bhb-frontend/lithe-ui';
import classnames from 'classnames';
import { formatTime } from '@bhb-frontend/utils/lib/format';
import { EditorProjectsApiModal as EApimodal } from '@/types/editorProjectsApi';
import EditorPojectItemAction from '@/types/editorPojectItemAction';
import EditorTransitionStatus from '@/types/editorTransitionStatus';
import editorMoreMenuList from './editorMoreMenuList';
import { MoreMenuList } from '@/types/editorMoreMenuList';
import { postEditorTwiceEditingRetryAPI } from '@/api/editorConvertVideo';
import Style from './index.module.less';
import assets from '@/assets';
import assetsImages from '@/assets/images';

interface TableItemProps {
  /** 单条数据详情 */
  itemData: EApimodal.DgetEditorProjectsAPI;
  /** 是否已经被选中 */
  selectedIdList: string[];
  /** 操作回调 */
  onOperationFN: (
    tpye: EditorPojectItemAction,
    itemData: EApimodal.DgetEditorProjectsAPI
  ) => void;
  /** 选中单个 */
  selectSingleFN: (id: string, isSelect: boolean) => void;
  /** 数组数据变动 */
  projectListStep: number;
}

const { useState, useEffect, useRef } = React;

export default function TableItem({
  itemData,
  onOperationFN,
  selectedIdList,
  selectSingleFN,
  projectListStep,
}: TableItemProps) {
  /**
   * lego 编辑器地址
   */
  const legoURL = import.meta.env.VITE_APP_LEGO_URL;

  /** 进度状态 */
  const [status, setStatusFN] = useState(itemData.projectStatus);
  const [coverUrl] = useState(itemData.coverUrl || '');

  /** 操作对象 */
  const [moreMenuList, setMoreMenuList] = useState<MoreMenuList.MoreMenuItem[]>(
    [
      editorMoreMenuList.edit,
      editorMoreMenuList.rename,
      editorMoreMenuList.remove,
    ]
  );

  /** 是否选中 */
  const [isSelected, setIsSelectedFN] = useState<boolean>(false);

  const TableItemInfoRef = useRef<HTMLDivElement>(null);

  /** 去编辑项目 */
  const toEditFN = (id: string) => {
    if (status === EditorTransitionStatus.sucess) {
      window.open(`${legoURL}/${id}`, '_blank');
    }
  };

  /** 重试二次编辑 */
  const retrySecondaryEditingFN = () => {
    if (status !== EditorTransitionStatus.fail) return;
    setStatusFN(EditorTransitionStatus.loading);
    postEditorTwiceEditingRetryAPI({
      projectId: itemData.id,
      videoId: itemData.videoId,
    });
  };

  /** 操作功能方法归类 */
  const overlayActionFN = (
    type: EditorPojectItemAction,
    itemData: EApimodal.DgetEditorProjectsAPI
  ) => {
    switch (type) {
      case EditorPojectItemAction.edit:
        toEditFN(itemData.id);
        break;
      case EditorPojectItemAction.retry:
        retrySecondaryEditingFN();
        break;
      default:
        onOperationFN(type, itemData);
        break;
    }
  };

  const overlayItemChange = () => {
    switch (status) {
      case EditorTransitionStatus.loading /** 转换中 */:
        setMoreMenuList(() => [editorMoreMenuList.remove]);
        break;
      case EditorTransitionStatus.fail /** 失败 */:
        setMoreMenuList(() => [
          editorMoreMenuList.retry,
          editorMoreMenuList.remove,
        ]);
        break;
      default: /** 成功 */
        setMoreMenuList(() => [
          editorMoreMenuList.edit,
          editorMoreMenuList.rename,
          editorMoreMenuList.remove,
        ]);
        break;
    }
  };

  /** 操作功能 */
  const overlay = (
    <Menu
      className={Style['menu-container']}
      onItemClick={({ value }) => {
        overlayActionFN(value, itemData);
      }}
    >
      {moreMenuList.map(item => (
        <Menu.Item
          key={item.value}
          value={item.value}
          className={classnames(
            item.value === EditorPojectItemAction.remove
              ? Style['menu-remove']
              : ''
          )}
        >
          <i
            className={classnames('iconfont', item.icon, Style['menu-icon'])}
          />
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  /** 选中当前项目 */
  const onClickSelectIconFN = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isSelected) {
      selectSingleFN(itemData.id, false);
      return;
    }
    selectSingleFN(itemData.id, true);
  };

  useEffect(() => {
    setIsSelectedFN(selectedIdList.includes(itemData.id));
  }, [selectedIdList]);

  useEffect(() => {
    setStatusFN(itemData.projectStatus);
    overlayItemChange();
  }, [projectListStep]);

  useEffect(() => {
    overlayItemChange();
  }, [status]);

  return (
    <div className={Style['table-item']} id={`more-${itemData.id}`}>
      {/* 选中icon */}
      {false && (
        <div className={Style['select-box']}>
          <img
            alt=""
            onClick={onClickSelectIconFN}
            src={
              isSelected
                ? assets.images.doc['icon-choice.png']
                : assets.images.doc['icon-nochoice.png']
            }
            className={classnames(Style['select-icon'], {
              [Style.selected]: selectedIdList.length > 0,
            })}
          />
        </div>
      )}

      <div
        ref={TableItemInfoRef}
        className={classnames(Style['table-item-info'])}
        onMouseUp={e => {
          /** 防止点击 more 以及 popup面板 触发 */
          const isContains = TableItemInfoRef.current?.contains(
            e.target as Node
          );
          isContains && toEditFN(itemData.id);
        }}
      >
        <div
          className={classnames(
            Style['item-name'],
            Style['name-column'],
            Style['item-column']
          )}
        >
          <div className={Style['image-box']}>
            {coverUrl ? (
              <img alt="" src={coverUrl} className={Style.cover} />
            ) : (
              <img
                alt=""
                className={Style['empty-image']}
                src={assets.images.doc['doc_icon_empty.png']}
              />
            )}

            {/* 转换中 */}
            {status === EditorTransitionStatus.loading && (
              <div className={Style.converting}>
                <img
                  className={Style['circle-loading']}
                  src={assetsImages.doc['common_icon_loading.png']}
                  alt=""
                />
                <div className={Style.text}>转换中</div>
              </div>
            )}
            {/* 转换失败 */}
            {status === EditorTransitionStatus.fail && (
              <div className={Style.converting}>
                <img
                  className={Style['title-icon']}
                  src={assetsImages.doc['fabu_icon_fail.png']}
                  alt=""
                />
                <div className={Style.text}>转换失败</div>
              </div>
            )}
          </div>

          <div>
            <div className={Style.name}>{itemData.name}</div>
            <div className={Style.size}>时长：{itemData.duration}</div>
            <div className={Style.size}>
              最近编辑：
              {formatTime(new Date(itemData.updatedAt), 'yyyy.mm.dd hh:MM')}
            </div>
          </div>
        </div>

        <Popup
          clickClosable
          placement="bottomRight"
          overlay={overlay}
          trigger="click"
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          container={() => document.querySelector(`#more-${itemData.id}`)!}
        >
          <div
            onMouseUp={e => {
              e.stopPropagation();
            }}
            className={classnames(Style.more)}
          >
            <Tooltips tips="更多操作">
              <img
                alt=""
                className={Style['more-img']}
                src={assets.images.doc['document_icon_more.png']}
              />
            </Tooltips>
          </div>
        </Popup>
      </div>
    </div>
  );
}
