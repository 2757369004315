import { formatTime } from '@bhb-frontend/utils/lib/format';
import { Tooltips } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import assets from '@/assets';
import style from './DraftsItem.module.less';

export interface MenuItem<T = any> {
  label: string;
  icon: React.ReactNode;
  value: T;
  labelStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
}
interface CreationItemProps<T = any> {
  /** 封面 */
  coverUrl?: string;
  /** 名称 */
  name: string;
  /** 描述 */
  desc: string;
  /** 时间 */
  time: string;
  id: string;
  /** item 的点击事件 */
  onClick?: (e: React.MouseEvent) => void;
  /** 点击more Menu */
  onChangeMenu?: (val: T) => void;
  /** more Menu 配置项 */
  menuItems?: MenuItem<T>[];
  /** 是否是空url */
  isEmptyImg: boolean;
}

export default function DraftsItem<T = any>(props: CreationItemProps<T>) {
  const {
    coverUrl,
    name,
    desc,
    time,
    id,
    onClick,
    menuItems,
    onChangeMenu,
    isEmptyImg,
  } = props;

  const renderPoint = Array.from({ length: 3 }, (_, index) => {
    const id = String(index);
    return <i className={style.point} key={id} />;
  });

  const tips = (
    <ul>
      {menuItems?.map(item => (
        <li
          onClick={() => {
            onChangeMenu?.(item.value);
          }}
          key={item.label}
          className={style['menu-item']}
        >
          <span style={item.iconStyle} className={style['menu-icon']}>
            {item.icon}
          </span>
          <span style={item.labelStyle}>{item.label}</span>
        </li>
      ))}
    </ul>
  );

  const itemId = `menu-${id}`;
  return (
    <div id={itemId} className={cs(style['drafts-item'])}>
      <div className={style['cover-box']} onClick={onClick}>
        <img
          className={cs(
            isEmptyImg ? style['cover-img-empty'] : style['cover-img']
          )}
          src={coverUrl || assets.images.home['doc_icon_empty.png']}
          alt=""
        />
      </div>

      <div className={style['info-box']} onClick={onClick}>
        <div className={style.name}>{name}</div>
        <div className={style.desc}>
          {desc}
          <i className={style.line} />
          {formatTime(new Date(time), 'yyyy.mm.dd hh:MM')}
        </div>
      </div>

      {/* 更多菜单 */}
      {menuItems && (
        <Tooltips
          className={style['tooltips-component']}
          clickClosable
          trigger="click"
          tips={tips}
          placement="bottomRight"
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          container={() => document.getElementById(itemId)!}
        >
          <div className={style['more-menu']}>{renderPoint}</div>
        </Tooltips>
      )}
    </div>
  );
}
