import ModalWrapper from '../ModalWrapper';

export default function RemoveDocModal(props) {
  return (
    <ModalWrapper {...props} title="注意" width={420} height="auto">
      <>
        <i
          className="icon-a-fabu_icon_fail2x1 iconfont"
          style={{
            color: 'rgb(255, 23, 92)',
            marginRight: '5px',
          }}
        />
        你确定要删除该文档吗？删除后文档无法找回
      </>
    </ModalWrapper>
  );
}
