/**
 * 付费引导
 */

import { Modal } from '@bhb-frontend/lithe-ui';
import VipDialog from '@/components/VipDialog';
import ModuleEnum from '@/constants/ModuleEnum';

/** 正在显示modal，就不要再开启了 */
let showingModal = false;

/**
 * 图文快剪存储空间付费引导
 * @param isUpgrade 是否升级
 */
export async function onEnoughSpace(isUpgrade = false) {
  if (!isUpgrade) {
    if (showingModal) return;
    showingModal = true;
    Modal.confirm({
      title: '存储空间不足',
      content: '请清除历史数据，以释放空间',
      okText: '确定',
      closable: false,
      onOk: () => {
        showingModal = false;
      },
    });
    return;
  }

  VipDialog?.show?.({
    title: '存储空间不足',
    content: '开通会员解锁更多存储空间',
    module: ModuleEnum.VIDEO,
  });
}
