export enum EVENT_NAME {
  /** 创建文档 */
  CREATE_DOC = 'CREATE_DOC',
  CREATE_DOC_TEMPLATE = 'CREATE_DOC_TEMPLATE',
  /** 返回  */
  BACK = 'BACK',
  /** 跳转创作路由 */
  JUMP_CREATE_ROUTER = 'JUMP_CREATE_ROUTER',

  /** 重新训练 */
  RETRAINING = 'RETRAINING',
}
