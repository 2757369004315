import { useState } from 'react';
import { initI18nCallback } from '@/locales';
// import { event } from '@/utils/event';
import { TFn } from '@/locales/type';

export function useTranslation() {
  const [i18n] = useState<TFn>(initI18nCallback);
  const t = i18n || initI18nCallback;

  return { t };
}
