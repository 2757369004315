import Login from '@bhb-frontend/mtv-login';
import { removeCookie } from '@/utils/cookie';

/**
 * 弹出登入框并移除token
 *  */
export function showLoginAndRemoveToken() {
  removeCookie('token');
  removeCookie('socketLink');
  Login.show({ toast: true });
}
