import {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useLayoutEffect,
  useEffect,
} from 'react';
import { useGetState } from '@bhb-frontend/hooks';
import { LoadMore, Toast } from '@bhb-frontend/lithe-ui';
import { getDocList, removeDoc, renameDoc } from '@/api/home';
import { Ordering, DocDetail, SearchType } from '@/types/home';
import { bit2Mb } from '@/utils/utils';
import CreationItem, { MenuItem } from '../DraftsItem';
import ConfirmModal from '@/components/Confirm';
import RenameModal from '../RenameModal';
import Empty from '@/components/EmptyList';
import { goToDoc } from '@/utils/doc';
import itemCommonStyle from '../HomeItemCommon.module.less';
import assets from '@/assets';
import { userStore } from '@/store';
import NotLogin from '../NotLogin';
import { TabRef } from '../Drafts/Drafts';
import { copyDoc } from '@/api/myDoc';
import socket from '@/utils/socket';
import { SocketEvent } from '@/constants/socketEvents';
import { SocketResponse } from '@/types/api';
import CustomToast from '@/pages/Doc/MyDoc/CustomToast';
import style from './DocList.module.less';

enum MenuType {
  RENAME = 'rename',
  COPY = 'copy',
  REMOVE = 'remove',
}

const menuItems: MenuItem[] = [
  {
    label: '重命名',
    icon: <i className="iconfont icon-a-document_icon_mingming2x" />,
    value: MenuType.RENAME,
  },

  {
    label: '复制',
    icon: <i className="iconfont  icon-a-web-Mywork-copy" />,
    value: MenuType.COPY,
  },
  {
    label: '删除',
    icon: <i className="iconfont icon-a-document_icon_delete2x" />,
    value: MenuType.REMOVE,
    iconStyle: { color: '#FF0D3A' },
    labelStyle: { color: '#FF0D3A' },
  },
];

function DocList(props: { searchValue: string }, ref: React.Ref<TabRef>) {
  const { searchValue } = props;

  const [list, setList] = useState<DocDetail[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [renameVisible, setRenameVisible] = useState(false);
  const [renameValue, setRenameValue] = useState('');
  const operationItem = useRef<DocDetail | null>(null);

  const params = useRef({ sid: '', pageSize: 20, orderBy: Ordering.ascending });

  /* 创建副本 Toast队列 */
  const [toastIdList, setToastIdList, getToastIdListRef] = useGetState<
    string[]
  >([]);

  /** 获取列表 */
  const getList = async () => {
    try {
      setLoading(true);
      const { data } = await getDocList({
        ...params.current,
        keyword: searchValue,
        searchType: SearchType.production,
        orderBy: -1,
      });
      params.current = {
        ...params.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (err) {
      console.warn(err);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const addToastIdList = (taskId: string) => {
    const list = getToastIdListRef();
    setToastIdList([...list, taskId]);
  };

  const onCopy = async (item: DocDetail) => {
    try {
      const { data } = await copyDoc(item.id);
      data.taskId && addToastIdList(data.taskId);
    } catch (err) {
      console.error('复制失败', err);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    params.current = { ...params.current, sid: '' };
    const data = await getList();
    if (data) {
      setList(data.results);
    }
  };

  useImperativeHandle(ref, () => ({ initList }));

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getList();
    if (data) {
      setList([...list, ...data.results]);
    }
  };

  const onChangeMenu = (val: MenuType, item: DocDetail) => {
    operationItem.current = item;
    if (val === MenuType.REMOVE) {
      setRemoveVisible(true);
      return;
    }

    if (val === MenuType.RENAME) {
      setRenameValue(item.production);
      setRenameVisible(true);
      return;
    }

    onCopy(item);
  };

  /* 关闭Toast */
  const closeToast = (id: string) => {
    const list = getToastIdListRef();
    setToastIdList(list.filter(item => item !== id));
  };

  useLayoutEffect(() => {
    initList();
  }, []);

  useEffect(() => {
    const listenSocket = (e: SocketResponse<any>) => {
      const { event, data } = e;
      if (event === SocketEvent.DOCUMENT_COPY_RESULT) {
        closeToast(data.taskId);
        if (data.code === 0) {
          initList();
          Toast.success('复制成功');
        } else {
          Toast.error('复制失败');
        }
      }
    };

    if (userStore.userInfo.socketLink) {
      socket.on(listenSocket);
    }

    return () => {
      socket.off(listenSocket);
    };
  }, [userStore.userInfo.socketLink]);

  /** 删除 */
  const onRemove = async () => {
    try {
      const data = operationItem.current;
      if (!data) return;
      await removeDoc(data.id);
      setList(list.filter(item => item.id !== data.id));
      setRemoveVisible(false);
    } catch (err) {
      console.error('删除失败', err);
    }
  };

  /** 重命名 */
  const onRename = async () => {
    if (!operationItem.current) return;
    await renameDoc(operationItem.current.id, renameValue);
    initList();
    setRenameVisible(false);
  };

  if (!userStore.isLogin()) {
    return <NotLogin />;
  }

  if (!list.length && !loading) {
    return <Empty />;
  }
  const handleClick = (id: string) => {
    goToDoc(`/?id=${id}`);
  };

  return (
    <div className={style['doc-list']}>
      <LoadMore
        className={style['load-more']}
        onReachBottom={loadMore}
        loading={loading}
        hasMore={hasMore}
        finishedText={null}
      >
        {list.map(item => (
          <div className={itemCommonStyle.item} key={item.id}>
            <CreationItem
              onClick={() => {
                handleClick(item.id);
              }}
              id={item.id}
              name={item.production}
              coverUrl={
                item.imageThumbnailUrl ||
                assets.images.home['tuwen_icon_empty.png']
              }
              isEmptyImg={!item.imageThumbnailUrl}
              time={item.updatedTime}
              desc={bit2Mb(item.size)}
              menuItems={menuItems}
              onChangeMenu={val => {
                onChangeMenu(val, item);
              }}
            />
          </div>
        ))}
      </LoadMore>

      <ConfirmModal
        title="注意"
        content="确定删除该文档吗？"
        visible={removeVisible}
        onCancel={() => {
          setRemoveVisible(false);
        }}
        onOk={onRemove}
      />

      <RenameModal
        value={renameValue}
        visible={renameVisible}
        onClose={() => {
          setRenameVisible(false);
        }}
        onOk={onRename}
        onChange={val => {
          setRenameValue(val);
        }}
      />

      <CustomToast toastIdList={toastIdList} closeToast={closeToast} />
    </div>
  );
}

export default forwardRef(DocList);
