import { Modal, Toast } from '@bhb-frontend/lithe-ui';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Style from './CreateLiveModal.module.less';
import {
  CREATED_LIVE,
  CREATE_LIVE_ERROR_CODE,
} from '@/pages/Live/Live/LiveAddInput/const';
import { createLive } from '@/api/live';
import MessageModal from '../MessageModal';
import { goToWeb } from '@/utils/doc';

interface CreateLiveModalProps {
  visible: boolean;
  modalOpenCloseFN: () => void;
  update?: () => void;
}

function CreateLiveModal(props: CreateLiveModalProps) {
  const { visible, modalOpenCloseFN, update } = props;

  const [loading, setLoading] = useState(false);

  const [canClick, setCanClick] = useState(false);

  const [status, setStatus] = useState(CREATED_LIVE.noLink);

  const [title, setTitle] = useState('添加失败');

  const [message, setMessage] = useState('');

  const [showMessage, setShowMessage] = useState(false);

  const link = useRef('');

  const { id } = useParams();

  useEffect(() => {
    if (!visible) {
      link.current = '';
      setCanClick(false);
      setLoading(false);
      setStatus(CREATED_LIVE.noLink);
    }
  }, [visible]);
  const verify = () => {
    const reg = /(http|https):\/\/([\w.]+\/?)\S*/;
    const url = link.current.match(reg);
    return url && url.length > 0 ? url[0] : '';
  };

  /** 错误提示 */
  const addError = (code: number, msg: string) => {
    setTitle('添加失败');
    // 创建直播间时错误
    switch (code) {
      case CREATE_LIVE_ERROR_CODE.FAIL: {
        setStatus(CREATED_LIVE.fail);
        break;
      }
      // case CREATE_LIVE_ERROR_CODE.LIVE_NUM: {
      //   // 直播间超出上限
      //   this.leadPaid(code);
      //   break;
      // }
      // case CREATE_LIVE_ERROR_CODE.USED_TIME: {
      //   // 直播时长为0
      //   this.leadPaid(code);
      //   break;
      // }
      case CREATE_LIVE_ERROR_CODE.LIVE_URL_EXISTING: {
        // 地址已存在
        setStatus(CREATED_LIVE.created);
        break;
      }
      default: // 未检测到直播
        setStatus(CREATED_LIVE.noLive);
    }
    if (
      code === CREATE_LIVE_ERROR_CODE.LIVE_NUM ||
      code === CREATE_LIVE_ERROR_CODE.USED_TIME
    ) {
      return;
    }
    if (message.includes('timeout')) {
      msg = '地址解析超时，请稍后再试～';
    }
    setMessage(msg);
    setShowMessage(true);
  };

  /** 添加直播 */
  const addLive = () => {
    if (!link.current) return;
    if (!verify()) {
      Toast.warning('请输入正确的直播间网址');
      return;
    }
    setStatus(CREATED_LIVE.creating);
    setLoading(true);
    createLive({ shareUrl: verify(), type: 'robot', robotId: id })
      .then(({ data }) => {
        modalOpenCloseFN();
        setStatus(CREATED_LIVE.success);
        Toast.success('创建成功！');
        link.current = '';
        update?.();
        goToWeb(`/clip/robot/slicing/${data.id}`);
      })
      .catch(err => {
        addError(err.error, err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        width={460}
        height="auto"
        visible={visible}
        className={Style.modal}
        onClose={modalOpenCloseFN}
        timeout={0}
        closable={false}
        contentClassName={Style.create}
      >
        <div className={Style.createContainer}>
          <div className={Style.createHeard}>
            <div className={Style.createTitle}>通过链接新建直播</div>
            <i
              className={`iconfont icon-a-search_icon_delete2x ${Style.createClose}`}
              onClick={modalOpenCloseFN}
            />
          </div>
          <div className={Style.createContent}>
            <div className={Style.createContentDesc}>
              目前仅支持抖音、快手的链接
            </div>
            <textarea
              rows={4}
              placeholder="请输入授权号"
              className={Style.createContentTextare}
              onKeyUp={e => {
                const target = e.target as HTMLTextAreaElement;
                link.current = target.value;
                if (link.current) {
                  setCanClick(true);
                } else {
                  setCanClick(false);
                }
                if (e.key === 'Enter') {
                  addLive();
                }
              }}
            />
            <button
              className={`${Style.createContentAdd} ${
                canClick ? Style.createContentAddFocus : ''
              } ${
                status === CREATED_LIVE.creating
                  ? Style.createContentAddCreating
                  : ''
              }`}
              onClick={addLive}
            >
              {loading && <div className={Style.createContentLoading} />}
              添加直播
            </button>
          </div>
        </div>
      </Modal>

      <MessageModal
        visible={showMessage}
        title={title}
        message={message}
        modalOpenCloseFN={() => {
          setShowMessage(false);
        }}
      />
    </>
  );
}

export default CreateLiveModal;
