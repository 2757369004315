import { Tabs } from '@bhb-frontend/lithe-ui';
import Drafts from '../Drafts';
import Product from '../Product';
import Style from './Contents.module.less';
import { useStickyTop } from '@/hooks';

enum TabEnum {
  PRODUCT = 'product',
  DRAFTS = 'drafts',
}

const items = [
  {
    label: '作品',
    key: TabEnum.PRODUCT,
    children: <Product />,
  },
  {
    label: '草稿',
    key: TabEnum.DRAFTS,
    children: <Drafts />,
  },
];

export default function Contents() {
  const { topDistance } = useStickyTop();

  return (
    <div className={Style.contents}>
      <Tabs
        tabBarStyle={{
          position: 'sticky',
          top: topDistance,
          zIndex: 66,
          background: '#fff',
        }}
        items={items}
        tabPosition="top"
        defaultActiveKey={TabEnum.PRODUCT}
      />
    </div>
  );
}
