import request from '@/api/init';
import { ListQuery, CustomListResponse } from '@/types/api';
import { OrderItem } from '@/types/orders';

/**
 * 我的订单列表
 * @param params 列表参数
 * @returns
 */
export function getMyOrders(
  param?: ListQuery
): Promise<CustomListResponse<OrderItem>> {
  const uri = '/order';
  return request.get(uri, param);
}
