/** 消耗类型枚举 */
export enum OperationType {
  VIDEO = 'video',
  CREATION = 'creation',
  MEMBER_EXPIRE = 'memberExpire',
}

/** 消耗类型便利 */
export const OPERATION_TYPE = {
  [OperationType.VIDEO]: '视频导出',
  [OperationType.CREATION]: '文案创作',
  [OperationType.MEMBER_EXPIRE]: '会员到期',
};
