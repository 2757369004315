export enum ServiceUnitEnum {
  Year = 'year',
  Month = 'month',
  Week = 'week',
  Day = 'day',
}

export const ServiceUnitMap = {
  [ServiceUnitEnum.Year]: '年',
  [ServiceUnitEnum.Month]: '月',
  [ServiceUnitEnum.Week]: '周',
  [ServiceUnitEnum.Day]: '天',
};
