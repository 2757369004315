import { Modal, Button } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';

import Style from './index.module.less';

interface ModalWrapperProps extends ModalProps {
  /** content 内容 */
  children: React.ReactNode;
  /** 标题 */
  title: string;
  /** 关闭事件 */
  onClose: () => void;
  /** 确认事件 */
  onConfirm: () => void;
}

export default function ModalWrapper(props: ModalWrapperProps) {
  const { children, title, onClose, onConfirm, ...otherProps } = props;
  return (
    <Modal
      {...otherProps}
      timeout={0}
      onClose={onClose}
      closable={false}
      contentClassName={Style['modal-container']}
    >
      <div className={Style['modal-header']}>{title}</div>
      <div className={Style['modal-body']}>{children}</div>
      <div className={Style['modal-footer']}>
        <span>
          <Button className={Style['cancel-btn']} onClick={onClose}>
            取消
          </Button>
          <Button
            className={Style['confirm-btn']}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            确认
          </Button>
        </span>
      </div>
    </Modal>
  );
}
