import { useState, useEffect } from 'react';
import cs from 'classnames';
import { Tooltips, Popup, Menu } from '@bhb-frontend/lithe-ui';
import { formatDuration } from '@/utils/date';

import { LiveDetail } from '@/types/live';
import { PLATFORM_LIST } from '@/constants/myLive';
import assets from '@/assets';
import { LIVE_STATUS } from '@/constants/liveStatusEnum';
import style from './DetailTableItem.module.less';
import { goToWeb } from '@/utils/doc';

enum MoreMenuType {
  remove = 'remove',
  stopRecord = 'stopRecord',
}

interface DetailTableItemProps {
  data: LiveDetail;
  /** 选中列表 */
  selectedIdList: string[];
  /** 选中单个 */
  selectSingle: (id: string, isSelect: boolean) => void;
  /** 删除 */
  onRemove: (id: string) => void;
  /** 停止录制 */
  onStopRecord: (id: string) => void;
}

export default function DetailTableItem(props: DetailTableItemProps) {
  const { data, selectedIdList, selectSingle, onRemove, onStopRecord } = props;

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const moreMenuList = [
    {
      icon: 'icon-a-document_icon_delete2x',
      label: '删除直播间',
      value: MoreMenuType.remove,
    },
  ];

  if (data.status === 'running') {
    moreMenuList.unshift({
      icon: 'icon-a-zhibo_icon_stop2x',
      label: '停止录制',
      value: MoreMenuType.stopRecord,
    });
  }

  useEffect(() => {
    setIsSelected(selectedIdList.includes(data.id));
  }, [selectedIdList]);

  const onItemClick = ({ value }: { value: MoreMenuType }) => {
    switch (value) {
      case MoreMenuType.remove:
        onRemove(data.id);
        break;
      case MoreMenuType.stopRecord:
        onStopRecord(data.id);
        break;
      default:
        break;
    }
  };

  const onClickSelectIcon = () => {
    selectSingle(data.id, !isSelected);
  };

  const overlay = (
    <Menu className={style['menu-container']} onItemClick={onItemClick}>
      {moreMenuList.map(item => (
        <Menu.Item value={item.value} key={item.value}>
          <i className={cs('iconfont', item.icon, style['menu-icon'])} />
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const selectIcon = (
    <img
      alt=""
      onClick={onClickSelectIcon}
      src={
        isSelected
          ? assets.images.doc['icon-choice.png']
          : assets.images.doc['icon-nochoice.png']
      }
      className={cs(style['select-icon'], {
        [style.selected]: selectedIdList.length > 0,
      })}
    />
  );

  /**
   * @desc 去直播切片
   */
  const toSlice = () => {
    goToWeb(`/clip/robot/slicing/${data.id}`);
  };

  const nodeId = `more-${data.id}`;

  return (
    <div className={style['detail-table-item']} id={nodeId}>
      <div className={style['select-box']}>{selectIcon}</div>

      <div onClick={toSlice} className={style['detail-table-item-content']}>
        {/* 商品信息 */}
        <div
          className={cs(
            style['plus-item'],
            style['item-column'],
            style['goods-info']
          )}
        >
          <img
            className={style['item-image']}
            src={data.coverUrl || assets.images.live['live_icon_empty.png']}
            alt=""
          />
          <div>
            <span className={style['item-title']}>
              {data.title}
              {data.isSample && <span className={style['item-tag']}>示例</span>}
            </span>
            <p className={style['record-time']}>
              录制时长：
              {formatDuration(data.recordDuration, 'hh:mm:ss')}
            </p>
          </div>
        </div>

        {/* 平台 */}
        <div className={style['item-column']}>
          {PLATFORM_LIST[data.platform]?.label}
        </div>

        {/* 状态 */}
        <div className={style['item-column']}>
          <span
            className={cs(style.status, {
              [style.running]: data.status === 'running',
            })}
          >
            {LIVE_STATUS[data.status]}
          </span>
        </div>

        {/* 添加时间 */}
        <div className={cs(style['item-column'], style['plus-item'])}>
          {data.createdAt}
        </div>
      </div>

      {/* 更多操作 */}
      <Popup
        clickClosable
        placement="bottomRight"
        overlay={overlay}
        trigger="click"
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        container={() => document.querySelector(`#${nodeId}`)!}
      >
        <div className={cs(style.more)}>
          <Tooltips tips="更多操作">
            <img
              alt=""
              className={style['more-img']}
              src={assets.images.doc['document_icon_more.png']}
            />
          </Tooltips>
        </div>
      </Popup>
    </div>
  );
}
