/* eslint-disable @typescript-eslint/no-namespace */
import { ListQuery } from './api';
import { DocItem } from '@/types/myDoc';
import EditorTransitionStatus from '@/types/editorTransitionStatus';

export enum ProjectName {
  /** 标清屏 */
  LOW = '3:4',

  /** 常规屏 */
  REGULAR = '4:3',

  /** 竖向长方向 */
  HIGH_DEFINITION = '9:16',

  /** 横向长方形 */
  ULTRA = '16:9',

  /** 正方形 */
  SQUARE = '1:1',
}

export declare namespace EditorProjectsApiModal {
  interface RpostEditorProjectsApi {
    name: string;
    size: string;
    basicInfo: string;
  }

  interface DpostEditorProjectsApi {
    id: string;
  }

  interface RgetEditorProjectsAPI extends ListQuery {
    /** 搜索关键字，如输入标签id，或 文档标题 */
    name?: string;
  }

  interface DgetEditorProjectsAPI extends DocItem {
    loadingProgress: number;
    projectStatus: EditorTransitionStatus;
    name: string;
    duration: string;
    updatedAt: string;
    coverUrl: string;
  }

  interface RputEditorProjectsAPI {
    name: string;
  }
}
