import { Modal } from '@bhb-frontend/lithe-ui';
import Style from './MessageModal.module.less';

interface MessageModalProps {
  visible: boolean;
  modalOpenCloseFN: () => void;
  title: string;
  message: string;
}

function MessageModal(props: MessageModalProps) {
  const { visible, modalOpenCloseFN, title, message } = props;
  return (
    <Modal
      width={335}
      height="auto"
      visible={visible}
      className={Style.message}
      onClose={modalOpenCloseFN}
      closable={false}
      contentStyle={{ padding: '20px 30px' }}
    >
      {title && (
        <div className={Style.messageTitle}>
          <span
            className={`iconfont icon-a-zhibo_icon_tips2x ${Style.messageIconfont}`}
          />
          {title}
          <i
            className={`iconfont icon-a-search_icon_delete2x ${Style.messageClose}`}
            onClick={modalOpenCloseFN}
          />
        </div>
      )}
      <div className={Style.messageContent}>{message}</div>
    </Modal>
  );
}

export default MessageModal;
