import { RouteModule } from '@/types/menu';

export enum RouteModuleEnum {
  VIDEO = 'video',
  LIVE = 'live',
}

/** 温馨提示弹窗 */
export const STREAMING_PROTOCOL = {
  DIGITAL: {
    title: '温馨提示',
    content:
      '欢迎您使用闪剪数字人服务，请确保您上传的内容严格遵守法律法规及相关规定；请勿将数字人用于任何违法、违规或违背公序良俗的场景，如：侵权、丑化、低俗等。',
    confirm: '我已知晓',
  },
  LIVE: {
    title: '添加直播须知',
    content:
      '坚持创作有质量有温度的原创内容，请勿搬运或发布侵权他人、违反国家法律法规、公序良俗的不良内容；如因违反本条导致任何问题，相关责任由用户自行承担。',
    confirm: '我已知晓',
  },
  UNABLE: {
    title: '无法开通',
    content: '暂不支持开通，如有疑问，请联系客服',
    confirm: '知道了',
  },
};

/** 子模块 */
export const PATH_NAME: RouteModule[] = [
  'video',
  'live',
  'subscribe',
  'editor',
];

/** 侧边栏左边顶部文字 */
export const SLIDE_LEFT_TITLE = {
  doc: '图文快剪',
  live: '直播快剪',
  subscribe: '订阅号',
  editor: '视频编辑',
};

/** 是否开启通用登录成功回调，默认开启 */
// eslint-disable-next-line import/no-mutable-exports
export let enableLoginSuccess = true;

export const setEnableLoginSuccess = enable => {
  enableLoginSuccess = enable;
};
