import * as React from 'react';
import { observer } from 'mobx-react';
import { Modal, Popup } from '@bhb-frontend/lithe-ui/lib';
import cls from 'classnames';
// import { useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import Login from '@bhb-frontend/mtv-login/lib/login';
import { Member, ShanJianMember } from '@bhb-frontend/mtv-navbar';
import { useRef } from 'react';
import Style from './NavbarRight.module.less';
import { userStore, configStore } from '@/store';
// import MemberCenter from '@/components/Member';
import { RouteModule } from '@/types/menu';
import images from '@/assets/images';

import { getAgentLink } from '@/api/home';
import { goToHome } from '@/utils/doc';
import commonImages from '@/assets/images/common';
import { RouteModuleEnum } from '@/constants/common';
import ModuleEnum from '@/constants/ModuleEnum';
import { ERROR_CODE } from '@/constants/error-code';

interface NavbarRightProps {
  module: RouteModule;
  /** 是否显示邀请有礼 */
  isShowInvite?: boolean;
  isHiddenMember: boolean;
  /** 邀请有礼文案，app 显示图片，web 显示文案 */
  inviteText?: string;
}

const { useState } = React;

function NavbarRight(props: NavbarRightProps) {
  const {
    module,
    isShowInvite = false,
    isHiddenMember = false,
    inviteText,
  } = props;

  /** 设置下载悬浮类名 */
  const [downloadHoverStyle, setDownloadHoverStyle] = useState(false);

  const [open, setOpen] = useState(false);

  /** 下载APP Ref */
  const downloadRef = useRef<HTMLDivElement>(null);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  /**
   * 是否展示「分销」入口
   */
  const isShowExpandEntry = () => {
    // IAN 添加角色权限， 如果角色权限属于tester / beta 就无视后台控制一定要打开
    const innerRole = ['tester', 'beta'];
    const roleString = userStore.userInfo.innerRole || 'noHaveRole';
    if (innerRole.includes(roleString)) {
      return true;
    }
    return false;
  };

  /**
   * 前往分销推广页
   */
  const toAgentCenter = async () => {
    if (!userStore.isLogin()) {
      Login.show?.();
      return;
    }
    const res = await getAgentLink({ page: 'homePage' }).catch(error => {
      if (
        error?.body?.error ===
        ERROR_CODE.AGENT_INVITE_NOT_ENOUGH_THRESHOLD_NEED_MEMBER
      ) {
        console.log('门槛不够，需要打开会员弹框');
        openModal();
      }
    });

    if (!res) return;

    const {
      data: { linkUrl },
    } = res;
    window.open(linkUrl, '_blank');
  };

  /** 进入官网 */
  const GoOfficialWebsite = (
    <div className={cls(Style.enter, Style.btn)} onClick={() => goToHome()}>
      前往官网
    </div>
  );
  const downloadContent = () => (
    <>
      <QRCodeSVG
        value={configStore.config.appDownloadLink as string}
        size={144}
        imageSettings={{
          src: images.common['download-app-code'],
          width: 40,
          height: 40,
          excavate: false,
        }}
      />

      <span className={Style['download-content-desc']}>扫码下载手机APP</span>
    </>
  );

  /** 下载APP */
  const DownloadAPP = (
    <Popup
      overlay={downloadContent()}
      container={downloadRef.current || document.body}
      placement="bottom"
      onClose={() => {
        setDownloadHoverStyle(false);
      }}
      onOpen={() => {
        setDownloadHoverStyle(true);
      }}
      className={Style['download-content']}
    >
      <div
        className={cls(Style.download, Style.btn, {
          [Style.hover]: downloadHoverStyle,
        })}
        ref={downloadRef}
      >
        下载APP
      </div>
    </Popup>
  );

  /** 会员按钮 */
  const MemberCenter = (
    <div
      className={cls(Style['member-center'], {
        [Style.live]: module === RouteModuleEnum.LIVE,
      })}
      onClick={() => {
        if (!userStore.isLogin()) {
          Login.show?.();
          sessionStorage.setItem('memberCenterModule', module);
          return;
        }
        userStore.getUser();

        module === ModuleEnum.LIVE
          ? Member?.show({
              module: ModuleEnum.LIVE,
            })
          : ShanJianMember?.show();
      }}
    >
      <img
        src={
          module !== RouteModuleEnum.LIVE
            ? commonImages['common-icon-video-member']
            : commonImages['common-icon-live-member']
        }
        alt="会员"
      />
      <span>会员</span>
    </div>
  );

  const agentList = [
    {
      text: '高额佣金等你拿，收入无上限',
      img: images.common['agent_money.png'],
    },
    {
      text: '推广规则简单，轻松上手',
      img: images.common['agent_rule.png'],
    },
    {
      text: '便捷的移动推广管理平台',
      img: images.common['agent_platform.png'],
    },
  ];

  /** 是否展示入口
   * 1. 根据用户角色判断，如果符合则展示，忽略后面的判断
   * 2. 后台配置、且已经登录、且是中国手机号
   */
  const showInvite =
    isShowExpandEntry() ||
    (isShowInvite &&
      userStore.isLogin() &&
      !!userStore.userInfo.mobilePhoneNumber &&
      userStore.userInfo.areaCode === '86');

  return (
    <>
      {/* 会员中心 */}
      {!isHiddenMember && MemberCenter}
      {/* 邀请好友 */}
      {showInvite && (
        <div
          className={cls(Style['invite-text'], Style.btn)}
          onClick={toAgentCenter}
        >
          {inviteText}
        </div>
      )}
      {/* 下载APP */}
      {DownloadAPP}
      {/* 分割线 */}
      <i className={Style.divider} />
      {/* 未登录，前往官网 */}
      {!userStore.isLogin() && GoOfficialWebsite}
      <Modal
        visible={open}
        width={466}
        height={366}
        timeout={0}
        onClose={closeModal}
        closable={false}
        contentClassName={Style['modal-container']}
      >
        <img
          className={Style.close}
          src={images.global['icon-member-header-close']}
          onClick={closeModal}
          alt="知道了"
        />
        <img
          className={Style['title-img']}
          src={images.common['agent_title.png']}
          alt="title"
        />
        <div className={Style['agent-list']}>
          {agentList.map(item => (
            <div className={Style['list-item']} key={item.img + item.text}>
              <img src={item.img} alt="img" />
              <span>{item.text}</span>
            </div>
          ))}
        </div>

        <div
          className={Style.btn}
          onClick={() => {
            closeModal();
            ShanJianMember.show?.({});
          }}
        >
          开通会员成为推广达人
        </div>
      </Modal>
    </>
  );
}

export default observer(NavbarRight);
