import request from './init';
import { DetailResponse } from '@/types/api';
import {
  OssTokenParams,
  InterFileData,
  OssCopyParams,
  OssCopyResult,
} from '@/types/file';

/**
 * 获取上传文件的token
 * @param {*} params
 * @return {Promise}
 */
export function getOssToken(params: OssTokenParams) {
  return request.post<DetailResponse<InterFileData>>('/file/oss/sts', {
    fileConfig: [{ ...params }],
  });
}

/**
 * 文件拷贝
 * @param {*} params
 * @return {Promise}
 */
export function getOssCopy(data: OssCopyParams) {
  return request.post<DetailResponse<OssCopyResult>>('/file/oss/copy', data);
}
