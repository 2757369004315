import { Dialog } from '@bhb-frontend/lithe-ui';

import Style from './style.module.less';

export interface CountDownProps {
  visible: boolean;
  title?: string;
  content?: string;
  okText?: React.ReactNode | null;
  cancelText?: React.ReactNode | null;
  onOk?: () => void;
  onCancel?: () => void;
  width?: number;
}

export default function ConfirmMoal(props: CountDownProps) {
  const {
    visible,
    title,
    okText = '确定',
    cancelText = '取消',
    content,
    onOk,
    onCancel,
    width = 335,
  } = props;

  return (
    <Dialog
      visible={visible}
      onCancel={onCancel}
      timeout={0}
      title=""
      maskStyle={{ backdropFilter: 'none' }}
      contentClassName={Style.confirmModal}
      contentStyle={{ padding: '20px 38px 25px' }}
      width={width}
      height="auto"
      footer={null}
    >
      {title && <h3 className={Style.confirmModalTitle}>{title}</h3>}
      {content && <p className={Style.confirmModalContent}>{content}</p>}

      <div className={Style.confirmModalFooter}>
        {cancelText && <div onClick={onCancel}>{cancelText}</div>}
        {okText && <div onClick={onOk}>{okText}</div>}
      </div>
    </Dialog>
  );
}
