import { Spin } from '@bhb-frontend/lithe-ui';
import { useState } from 'react';
import assets from '@/assets';

import style from './style.module.less';
import Uploader from '@/utils/upload';

interface UploadImageProps {
  value?: string;
  onChange?: (value?: any) => void;
  type?: 'cover' | 'image';
}

export default function UploadImage(props: UploadImageProps) {
  const { value, onChange } = props;

  const [loading, setLoading] = useState<boolean>(false);

  // 将base64转换为文件
  const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',');
    const mime = (arr[0].match(/:(.*?);/) as RegExpMatchArray)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const upload = (file: File) => {
    // 上传
    const uploader = new Uploader(file);
    uploader.addModule('live');
    uploader.addScene('robotGoods');
    uploader
      .upload()
      .then(({ url }) => {
        setLoading(false);
        onChange && onChange(url);
      })
      .catch(e => e);
  };

  const fileChange = async e => {
    const file = e.target.files[0];
    if (!file) return;
    setLoading(true);
    const reader = new FileReader();
    const image = new Image();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      image.src = e.target.result;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let width = image.width;
        let height = image.height;
        if (width > height) {
          if (width > 300) {
            height = Math.round((300 / width) * height);
            width = 300;
          }
        } else if (height > 300) {
          width = Math.round((300 / height) * width);
          height = 300;
        }
        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/jpeg', 0.7);
        const files = dataURLtoFile(dataUrl, file.name);

        // 上传
        upload(files);
      };
    };
  };

  return (
    <Spin spinning={loading}>
      <div className={style['upload-image']}>
        <input
          type="file"
          accept=".jpg,.jpeg,.png,.gif,.webp"
          onChange={fileChange}
        />
        <div className={style.image}>
          {value ? (
            <>
              <img key="cover" className={style.cover} src={value} alt="" />
              <div className={style.text}>替换</div>
            </>
          ) : (
            <img
              key="icon"
              className={style.icon}
              src={assets.images.subscription['icon-add-pic.png']}
              alt=""
            />
          )}
        </div>
      </div>
    </Spin>
  );
}
