import { useState } from 'react';
import Style from './AccountItems.module.less';
import { ThirdAccountItem } from '@/types/videos';

interface AccountItemsProps {
  account: ThirdAccountItem;
  /** 重新授权 */
  reAuth: (platform) => void;
  /** 删除 */
  remove: (id: string) => void;
}

function AccountItems(props: AccountItemsProps) {
  const { account, reAuth, remove } = props;
  const [showMask, setShowMask] = useState(false);

  return (
    <div
      className={`${Style.accountItem} ${
        account.isExpired ? Style.accountItemExpired : ''
      }`}
      onMouseEnter={() => {
        setShowMask(true);
      }}
      onMouseLeave={() => {
        setShowMask(false);
      }}
    >
      <div className={Style.accountAvatar}>
        <img
          src={account.avatar}
          alt="头像"
          className={Style.accountAvatarImage}
        />
        <span className={account.isExpired ? Style.accountAvatarExpired : ''}>
          {account.isExpired ? '已过期' : '已授权'}
        </span>
      </div>
      <span className={Style.accountName}>{account.name}</span>
      <div className={`${Style.accountIcon} ${Style[account.platform]}`}>
        <i
          className={`iconfont ${
            account.platform === 'douyin' ? 'icon-a-tiktok' : 'icon-a-kuaishou'
          }`}
        />
      </div>
      {showMask && (
        <div className={Style.accountMask}>
          <p className={Style.accountMaskName}>{account.name}</p>
          <button
            className={Style.accountMaskButton}
            onClick={() => {
              reAuth(account.platform);
            }}
          >
            重新授权
          </button>
          <button
            className={Style.accountMaskButton}
            onClick={() => {
              remove(account.id);
            }}
          >
            删除
          </button>
        </div>
      )}
    </div>
  );
}

export default AccountItems;
