import request from '@/api/init';
import { DetailResponse, CustomListResponse } from '@/types/api';
import {
  EditorProjectsApiModal as Emodal,
  EditorProjectsApiModal as EApimodal,
} from '@/types/editorProjectsApi';

/**
 * 创建项目
 */
export function postEditorProjectsAPI(
  data: Emodal.RpostEditorProjectsApi
): Promise<DetailResponse<EApimodal.DgetEditorProjectsAPI>> {
  return request.post('/editor/projects', data);
}

/**
 * 获取项目列表
 */
export function getEditorProjectsAPI(
  params: Emodal.RgetEditorProjectsAPI
): Promise<CustomListResponse<Emodal.DgetEditorProjectsAPI>> {
  return request.get('/editor/projects', params);
}

/**
 * 删除项目
 */
export function deleteEditorProjectsAPI(
  id: string
): Promise<DetailResponse<Emodal.DpostEditorProjectsApi>> {
  return request.delete(`/editor/projects/${id}`);
}

/**
 * 项目更改名称
 */
export function putEditorProjectsAPI(
  id: string,
  data: Emodal.RputEditorProjectsAPI
): Promise<DetailResponse<EApimodal.DgetEditorProjectsAPI>> {
  return request.put(`/editor/projects/${id}`, data);
}
