import { Modal, Button } from '@bhb-frontend/lithe-ui';
import Style from './ReasonModal.module.less';

interface ReasonModalProps {
  desc: string;
  visible: boolean;
  onClose: () => void;
}

export default function ReasonModal(props: ReasonModalProps) {
  const { desc, visible, onClose } = props;
  return (
    <Modal
      visible={visible}
      timeout={0}
      closeIcon={null}
      maskClosable={false}
      onClose={onClose}
      closable={false}
      contentClassName={Style.container}
    >
      <p className={Style.header}>生成视频失败</p>
      <p className={Style.body}>{desc}</p>
      <Button className={Style.button} onClick={onClose}>
        好的
      </Button>
    </Modal>
  );
}
