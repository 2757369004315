import { ENV } from '@/config';
import { AppModel } from '@/types/apps';

/**
 * 子应用枚举
 */
export enum APP_NAME {
  menu = 'MENU',
  navbar = 'NAVBAR',
  banner = 'BANNER',
  template = 'TEMPLATE',
  customDigital = 'CUSTOM_DIGITAL',
  createCustomDigital = 'CREATE_CUSTOM_DIGITAL',
}

/**
 * 子应用信息
 * 可以是独立git仓库，也可以说子页面应用
 */
export const APP_INFO: Record<APP_NAME, AppModel> = {
  /**
   * 菜单模块
   */
  MENU: {
    name: 'child-menu',
    host: 'http://localhost:6001',
    path: '/child/menu/',
  },

  /**
   * 导航栏模块
   */
  NAVBAR: {
    name: 'child-navbar',
    host: 'http://localhost:4005',
    path: '/child/navbar/',
  },

  /**
   * 横幅模块
   */
  BANNER: {
    name: 'child-banner',
    host: 'http://localhost:4007',
    path: '/child/banner/',
  },

  /**
   * 模版模块
   */
  TEMPLATE: {
    name: 'child-template',
    // host: 'https://mtv-i18n-mtv-template.test.bhbapp.cn',
    // host: 'http://localhost:12000',
    host: {
      // dev: 'http://192.168.40.11:4173',
      // dev: ' http://localhost:12000',
      dev: 'https://mtv-mtv-template.test.bhbapp.cn',
      // dev: 'http://192.168.107.176:3200',
      // dev: 'http://192.168.104.104:12001',
      test: 'https://mtv-mtv-template.test.bhbapp.cn',
      gray: 'https://mtv-mtv-template.bjb.gray.bhbapp.cn',
      prod: 'https://template.shanjian.tv',
      i18nDev: 'https://mtv-i18n-mtv-template.test.bhbapp.cn',
      i18nTest: 'https://mtv-i18n-mtv-template.test.bhbapp.cn',
      i18nGray: 'https://boltcut-template-gray.boltcut.ai',
      i18nProd: 'https://template.boltcut.ai',
    },
    path: '/',
  },

  CUSTOM_DIGITAL: {
    name: 'child-custom-digital',

    host: {
      // dev: 'https://mtv-mtv-custom-digital.test.bhbapp.cn',
      dev: 'http://localhost:12100',
      test: 'https://mtv-mtv-custom-digital.test.bhbapp.cn',
      gray: 'https://mtv-mtv-custom-digital.bjb.gray.bhbapp.cn',
      prod: 'https://digital.shanjian.tv',
    },
    path: '/custom-digital',
  },

  CREATE_CUSTOM_DIGITAL: {
    name: 'child-custom-digital',

    host: {
      // dev: 'https://mtv-mtv-custom-digital.test.bhbapp.cn',
      dev: 'http://localhost:12100',
      test: 'https://mtv-mtv-custom-digital.test.bhbapp.cn',
      gray: 'https://mtv-mtv-custom-digital.bjb.gray.bhbapp.cn',
      prod: 'https://digital.shanjian.tv',
    },

    path: '/create-custom-digital',
  },
};

export function getAppInfo(name: APP_NAME) {
  const host = APP_INFO[name].host;
  const path = APP_INFO[name].path;
  const domian = host === 'string' ? host : host[ENV];
  return {
    url: `${domian}${path}`,
    host: domian,
    path,
  };
}
