import request from '@/api/init';
import { DetailResponse } from '@/types/api';

interface SubscriptionAuth {
  result: {
    subscribe: 0 | 1;
    permissions: string[];
  };
}

/**
 * 判断用户是否有订阅号权限
 * @returns subscribe: 0: 无 | 1: 有订阅权限
 */
export function getSubscriptionAuth() {
  return request.get<DetailResponse<SubscriptionAuth>>(
    '/subscribe/is/access/user'
  );
}
