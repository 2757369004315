import { useEffect, useState } from 'react';
import { Tooltips, Popup, Menu } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { DocItem, ReleaseType } from '@/types/robot';
import assets from '@/assets';
import { bit2Mb, formatDate } from '@/utils/utils';
import style from './DetailTableItem.module.less';
import { goToDoc } from '@/utils/doc';

enum MoreMenuType {
  publish = 'publish',
  display = 'display',
  remove = 'remove',
}

interface DetailTableItemProps {
  data: DocItem;
  /** 选中单个 */
  selectSingle: (id: string, isSelect: boolean) => void;
  /** 选中列表 */
  selectedIdList: string[];
  /** 即时发布 */
  onPublish: (releaseType: ReleaseType, id: string) => void;
  /** 上架/下架 */
  onDisplay: (isShow: boolean, id: string) => void;
  /** 删除 */
  onRemove: (id: string) => void;
}

export default function DetailTableItem(props: DetailTableItemProps) {
  const { data, onPublish, onDisplay, onRemove, selectedIdList, selectSingle } =
    props;

  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsSelected(selectedIdList.includes(data.id));
  }, [selectedIdList]);

  const moreMenuList = [
    {
      icon: 'icon-a-dingyue_icon_jisifabu2x',
      label:
        data.releaseType === ReleaseType.normal ? '即时发布' : '取消即时发布',
      value: MoreMenuType.publish,
    },
    {
      icon: 'icon-a-dingyue_icon_xiajia2x',
      label: data.isShow ? '下架' : '上架',
      value: MoreMenuType.display,
    },
    {
      icon: 'icon-a-document_icon_delete2x',
      label: '删除',
      value: MoreMenuType.remove,
    },
  ];

  const onItemClick = ({ value }: { value: MoreMenuType }) => {
    switch (value) {
      case MoreMenuType.display:
        onDisplay(!data.isShow, data.id);
        break;
      case MoreMenuType.publish: {
        const type =
          data.releaseType === ReleaseType.normal
            ? ReleaseType.timely
            : ReleaseType.normal;
        onPublish(type, data.id);
        break;
      }
      case MoreMenuType.remove:
        onRemove(data.id);
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    goToDoc(`/?id=${data.id}`);
  };

  const overlay = (
    <Menu className={style['menu-container']} onItemClick={onItemClick}>
      {moreMenuList.map(item => (
        <Menu.Item value={item.value} key={item.value}>
          <i className={cs('iconfont', item.icon, style['menu-icon'])} />
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const onClickSelectIcon = () => {
    selectSingle(data.id, !isSelected);
  };

  const selectIcon = (
    <img
      alt=""
      onClick={onClickSelectIcon}
      src={
        isSelected
          ? assets.images.doc['icon-choice.png']
          : assets.images.doc['icon-nochoice.png']
      }
      className={cs(style['select-icon'], {
        [style.selected]: selectedIdList.length > 0,
      })}
    />
  );

  const nodeId = `more-${data.id}`;

  return (
    <div className={style['detail-table-item']} id={nodeId}>
      <div className={style['select-box']}>{selectIcon}</div>

      <div onClick={handleClick} className={style['detail-table-item-content']}>
        {/* 文档名称 */}
        <div className={cs(style['doc-name-column'], style['item-column'])}>
          <div className={style['image-box']}>
            <img
              src={
                data.imageThumbnailUrl ||
                assets.images.robot['icon-robot-doc.png']
              }
              className={style.cover}
              alt=""
            />
            {data.releaseType === ReleaseType.timely && (
              <span className={style['publish-tips']}>即时发布</span>
            )}
          </div>

          <div>
            <div className={style.name}>{data.production || '未命名文档'}</div>
            <div className={style.size}>{bit2Mb(data.size)}</div>
          </div>
        </div>

        {/* 标签 */}
        <div className={style['item-column']}>{data.labelName[0] || '-'}</div>

        {/* 发布时间 */}
        <div className={style['item-column']}>
          {formatDate(data.createdAt, {
            yestetdayLable: '昨天',
            toDayLable: '今天',
          })}
        </div>

        {/* 音乐 */}
        <div className={style['item-column']}>
          {data.musicInfo?.name || '无'}
        </div>

        {/* 状态 */}
        <div className={style['item-column']}>
          <div
            className={cs(style['icon-status'], data.isShow && style.show)}
          />
          {data.isShow ? '已上架' : '下架中'}
        </div>
      </div>

      <Popup
        clickClosable
        placement="bottomRight"
        overlay={overlay}
        trigger="click"
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        container={() => document.querySelector(`#${nodeId}`)!}
      >
        <div className={cs(style.more)}>
          <Tooltips tips="更多操作">
            <img
              alt=""
              className={style['more-img']}
              src={assets.images.doc['document_icon_more.png']}
            />
          </Tooltips>
        </div>
      </Popup>
    </div>
  );
}
