import { useRef, useState } from 'react';
import cls from 'classnames';
import { Tabs, Input } from '@bhb-frontend/lithe-ui';
import { ProductEnum } from '@/constants/ProductEnum';
import DocList from '../DocList';
import LiveList from '../LiveList';
import { useStickyTop } from '@/hooks';
import Style from './Drafts.module.less';

export interface TabRef {
  initList: () => void;
}

export default function Drafts() {
  const { topDistance } = useStickyTop();

  const [activeTab, setActiveTab] = useState(ProductEnum.DOC);
  const [docSearchValue, setDocSearchValue] = useState('');
  const [liveSearchValue, setLiveSearchValue] = useState('');

  const docRef = useRef<TabRef | null>(null);
  const liveRef = useRef<TabRef | null>(null);

  const onChangeValue = (_: React.ChangeEvent, val: string) => {
    const setHandle =
      activeTab === ProductEnum.DOC ? setDocSearchValue : setLiveSearchValue;

    setHandle(val);
  };

  const onPressEnter = () => {
    const refs = {
      [ProductEnum.DOC]: docRef,
      [ProductEnum.LIVE]: liveRef,
    };

    refs[activeTab].current?.initList();
  };

  const items = [
    {
      label: '口播视频',
      key: ProductEnum.DOC,
      children: <DocList ref={docRef} searchValue={docSearchValue} />,
    },
    {
      label: '直播快剪',
      key: ProductEnum.LIVE,
      children: <LiveList ref={liveRef} searchValue={liveSearchValue} />,
    },
  ];

  const valueMap = {
    [ProductEnum.DOC]: docSearchValue,
    [ProductEnum.LIVE]: liveSearchValue,
  };

  const value = valueMap[activeTab];

  const renderTabBar = () => (
    <div
      className={Style['tab-bar-wrap']}
      style={{
        position: 'sticky',
        top: topDistance + 80,
        zIndex: 66,
        background: '#fff',
      }}
    >
      {items.map(item => (
        <div
          className={cls(Style['tab-bar'], {
            [Style['tab-bar-active']]: activeTab === item.key,
          })}
          key={item.key}
          onClick={() => setActiveTab(item.key)}
        >
          {item.label}
        </div>
      ))}

      <Input
        value={value}
        prefix={
          <i
            className={cls(
              'iconfont icon-a-common_icon_search2x',
              Style['search-input-icon']
            )}
          />
        }
        placeholder="搜索"
        className={Style['search-input']}
        onChange={onChangeValue}
        onPressEnter={onPressEnter}
      />
    </div>
  );

  return (
    <div className={Style.drafts}>
      <Tabs
        activeKey={activeTab}
        items={items}
        renderTabBar={renderTabBar}
        tabPosition="top"
      />
    </div>
  );
}
