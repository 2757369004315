import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Button, LoadMore, Spin } from '@bhb-frontend/lithe-ui';
import cs from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getLiveRobotDetail,
  getLiveRobotList,
  removeRobotLiveList,
  liveTerminate,
} from '@/api/robot';
import { LiveRobotDetail } from '@/types/robot';
import { LiveDetail, LiveVideoRecordUpdate } from '@/types/live';
import { ListQuery, SocketResponse } from '@/types/api';
import DetailTableHead from './DetailTableHead';
import DetailTableItem from './DetailTableItem';
import ConfirmMoal from '@/components/Confirm';
import CreateLiveModal from '@/components/Robot/CreateLiveModal';
import { UploadSlice } from './UploadSlice';
import DetailVideoList from './DetailVideoList';
import { VideoListRefFn } from './DetailVideoList/VideoList';
import RobotSetting from '../Robot/RobotList/RobotSetting';
import EmptyList from '@/components/EmptyList';
import { userStore } from '@/store/user';
import socket from '@/utils/socket';
import { SocketEvent } from '@/constants/socketEvents';

import style from './RobotDetail.module.less';

enum TabEnum {
  live = 'live',
  video = 'video',
}

export default function RobotDetail() {
  const { id: robotId } = useParams();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<TabEnum>(TabEnum.live);

  const [detailInfo, setDetailInfo] = useState<LiveRobotDetail>();

  const [list, setList] = useState<LiveDetail[]>([]);

  const [selectedIdList, setSelectedIdList] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);

  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [stopRecordingModalVisible, setStopRecordingModalVisible] =
    useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [uploadIds, setUploadIds] = useState<string[]>([]);
  const detailVideoListRef = useRef<VideoListRefFn>(null);

  const removeId = useRef('');
  const stopRecordId = useRef('');

  const listQuery = useRef<ListQuery>({
    sid: '',
    pageSize: 10,
  });

  /** 设置弹窗 */
  const [settingVisible, setSettingVisible] = useState<boolean>(false);

  /** 进入机器人商品管理 */
  const enterRobotGoods = () => {
    const { id = '', name = '' } = detailInfo || {};
    navigate(`/live/robot/detail/goods/${id}/${name}`);
  };

  /** 获取详情信息 */
  const getDetailInfo = async () => {
    const { data } = await getLiveRobotDetail(robotId || '');
    setDetailInfo(data);
  };

  const getListParmas = () => {
    const params = {
      ...listQuery.current,
    };

    return params;
  };

  /** 获取列表 */
  const getList = async () => {
    try {
      setLoading(true);
      const params = getListParmas();
      const { data } = await getLiveRobotList(robotId || '', params);

      listQuery.current = {
        ...listQuery.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (err) {
      console.error('获取机器人列表失败', err);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    listQuery.current = {
      ...listQuery.current,
      sid: '',
    };
    const data = await getList();
    data && setList(data.results);
  };

  useEffect(() => {
    getDetailInfo();
  }, []);

  useLayoutEffect(() => {
    initList();
  }, []);

  /** 排序 */
  const onOrdering = () => {
    setList(oldList => [...oldList.reverse()]);
  };

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getList();
    data && setList([...list, ...data.results]);
  };

  /** 单选 */
  const selectSingle = (id: string, isSelect: boolean) => {
    if (isSelect) {
      setSelectedIdList([...selectedIdList, id]);
      return;
    }
    setSelectedIdList(selectedIdList.filter(item => item !== id));
  };

  /** 全选 */
  const selectAll = (isSelect: boolean) => {
    if (!isSelect) {
      setSelectedIdList([]);
      return;
    }
    setSelectedIdList(list.map(({ id }) => id));
  };

  /** 唤起移除弹框 */
  const remove = (id: string) => {
    removeId.current = id;
    setRemoveModalVisible(true);
  };

  /** 删除 */
  const onRemove = async () => {
    const ids = selectedIdList.length ? selectedIdList : [removeId.current];
    await removeRobotLiveList(robotId || '', ids);
    initList();
    setRemoveModalVisible(false);
  };

  /** 唤起停止录制弹框 */
  const stopRecord = (id: string) => {
    stopRecordId.current = id;
    setStopRecordingModalVisible(true);
  };

  /** 停止录制 */
  const onStopRecord = async () => {
    await liveTerminate(stopRecordId.current);
    initList();
    setStopRecordingModalVisible(false);
  };

  /** 编辑成功 */
  const handleSettingOk = () => {
    setSettingVisible(false);
    getDetailInfo();
    initList();
  };

  /** 去我的机器人页面 */
  const goMyRobot = () => {
    navigate('/live/robot');
  };

  /** 实时更新录制时长  */
  const updateRecordDu = (data: LiveVideoRecordUpdate & { id: string }) =>
    setList(oldList =>
      oldList.map(item => {
        if (item.id === data.id) {
          item.recordDuration = data.recordDuration;
        }
        return item;
      })
    );

  /** 监听列表中视频的状态并更新 */
  const socketCallback = (
    res: SocketResponse<LiveVideoRecordUpdate & { id: string }>
  ) => {
    if (userStore.userInfo.socketLink) {
      const { data } = res;
      switch (res.event) {
        case SocketEvent.LIVE_CLOSE:
        case SocketEvent.LIVE_STATUS_CHANGE:
          initList();
          break;
        case SocketEvent.LIVE_RECORD_UPDATE:
          updateRecordDu(data);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    socket.on(socketCallback);
    return () => {
      socket.off(socketCallback);
    };
  }, [userStore.userInfo.socketLink]);

  return (
    <div className={style['robot-detail-container']}>
      <div className={style.header}>
        <div className={style.title}>
          <span onClick={goMyRobot} className={style['title-path']}>
            我的机器人
          </span>
          <i
            className={cs(
              'iconfont icon-a-common_icon_arrow_next2x',
              style['arrow-icon'],
              style['title-path']
            )}
          />
          {detailInfo?.name}
        </div>

        {selectedIdList.length ? (
          <div>
            <Button onClick={() => setSelectedIdList([])} className={style.btn}>
              取消
            </Button>
            <Button
              onClick={() => setRemoveModalVisible(true)}
              className={style.btn}
            >
              删除
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <Button
              className={`${style.btn} ${style.btnDefault}`}
              onClick={enterRobotGoods}
            >
              商品管理
            </Button>
            <Button
              className={`${style.btn} ${style.btnDefault}`}
              onClick={() => setSettingVisible(true)}
            >
              机器人设置
            </Button>
            <UploadSlice
              className={`${style.btn} ${style.btnDefault}`}
              showVideoListTab={() => setActiveTab(TabEnum.video)}
              robotId={robotId || ''}
              uploadIdList={uploadIds}
              updateUploadIdList={id => {
                setUploadIds([...uploadIds, id]);
              }}
              updateSliceList={data => {
                if (detailVideoListRef.current) {
                  detailVideoListRef.current.updateVideoList(data);
                }
              }}
            />
            <Button
              className={`${style.btn} ${style.btnDefault}`}
              onClick={() => {
                setShowAddModal(true);
              }}
            >
              新建直播
            </Button>
          </div>
        )}
      </div>

      <div>
        <Button
          onClick={() => setActiveTab(TabEnum.live)}
          type={activeTab === TabEnum.live ? 'primary' : 'default'}
          className={style.btn}
        >
          直播列表
        </Button>

        <Button
          onClick={() => setActiveTab(TabEnum.video)}
          type={activeTab === TabEnum.video ? 'primary' : 'default'}
        >
          视频列表
        </Button>
      </div>

      {/* 直播列表 */}
      {activeTab === TabEnum.live && (
        <div>
          <DetailTableHead
            onOrdering={onOrdering}
            selectAll={selectAll}
            selectedIdList={selectedIdList}
            isSelectedAll={selectedIdList.length === list.length}
          />

          {!list.length && !loading ? (
            <EmptyList
              text="暂无直播列表"
              style={{ marginTop: 250, color: '#191919' }}
            />
          ) : (
            <div className={style['table-body']}>
              <Spin
                spinning={loading}
                style={{
                  width: '100%',
                  minHeight: '300px',
                }}
              >
                <LoadMore
                  onReachBottom={loadMore}
                  className={style['load-more']}
                  loading={loading}
                  hasMore={hasMore}
                  finishedText={null}
                >
                  {list.map(item => (
                    <DetailTableItem
                      selectSingle={selectSingle}
                      selectedIdList={selectedIdList}
                      data={item}
                      key={item.id}
                      onRemove={remove}
                      onStopRecord={stopRecord}
                    />
                  ))}
                </LoadMore>
              </Spin>
            </div>
          )}
        </div>
      )}

      <DetailVideoList
        style={{ display: activeTab === TabEnum.video ? '' : 'none' }}
        ref={detailVideoListRef}
        cancelUpload={id => {
          setUploadIds([...uploadIds.filter(item => item !== id)]);
        }}
      />

      <ConfirmMoal
        visible={removeModalVisible}
        title="确定删除直播内容吗？"
        content="删除后内容将无法恢复，且消耗的录制时长不会返还
"
        onCancel={() => {
          setRemoveModalVisible(false);
        }}
        onOk={onRemove}
      />

      <ConfirmMoal
        visible={stopRecordingModalVisible}
        title="确定终止录制？"
        content="终止录制后将无法重新开启，你可以重新创建该直播进行录制
"
        onCancel={() => {
          setStopRecordingModalVisible(false);
        }}
        onOk={onStopRecord}
      />
      <CreateLiveModal
        visible={showAddModal}
        modalOpenCloseFN={() => {
          setShowAddModal(false);
        }}
        update={initList}
      />
      <RobotSetting<LiveRobotDetail>
        visible={settingVisible}
        onCancel={() => setSettingVisible(false)}
        robotInfo={detailInfo}
        onOk={handleSettingOk}
      />
    </div>
  );
}
