import React from 'react';
import ReactDOM from 'react-dom/client';
import microApp from '@micro-zoe/micro-app';
import { MICRO_APP_TYPE } from './micro-app-type';
// import { LocaleType, setupI18n } from './locales';
import App from './App';
import './assets/css/index.less';
import { initProject } from '@/utils';
import { initSentry } from './sentry';

initProject();
initSentry();

// const lang = window?.MTV_CONFIG?.lang || 'zh-CN';

microApp.start(MICRO_APP_TYPE);

const init = async () => {
  document.addEventListener('DOMContentLoaded', () => {
    // await setupI18n(lang as LocaleType);
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <App />
    );
  });
};
init();
