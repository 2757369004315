import { useEffect, useState, useRef } from 'react';
import { LoadMore, Toast } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import { getRobotList } from '@/api/robot';
import { asyncDocs } from '@/api/myDoc';
import { RobotItem } from '@/types/robot';
import ModalWrapper from '../ModalWrapper';
import assets from '@/assets';
import style from './AddToRobotModal.module.less';

interface AddToRobotModalProps extends ModalProps {
  /** 关闭事件 */
  onClose: () => void;
  /** 添加成功回调 */
  addRobotSuccess: () => void;
  /** 选中的doc id */
  selectedDocIds: string[];
}
export default function AddToRobotModal(props: AddToRobotModalProps) {
  const { addRobotSuccess, selectedDocIds, ...otherProps } = props;

  const [list, setList] = useState<RobotItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedRobotIds, setSelectedRobotIds] = useState<string[]>([]);

  const params = useRef({
    sid: '',
    pageSize: 10,
  });

  const failIds = useRef<string[]>([]);

  /** 获取列表 */
  const getList = async () => {
    try {
      setLoading(true);
      const { data } = await getRobotList({
        ...params.current,
      });
      params.current = {
        ...params.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (err) {
      console.warn(err);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    params.current = { ...params.current, sid: '' };
    const data = await getList();
    if (data) {
      setList(data.results);
    }
  };

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getList();
    if (data) {
      setList([...list, ...data.results]);
    }
  };

  const handleClick = (id: string) => {
    if (selectedRobotIds.includes(id)) {
      setSelectedRobotIds(oldIds => oldIds.filter(curId => curId !== id));
      return;
    }
    setSelectedRobotIds(oldIds => [...oldIds, id]);
  };

  /** 分批次添加 */
  const asyncDocsLoop = async (
    id: string,
    list: string[],
    startPos: number
  ) => {
    const ids = list.slice(startPos, startPos + 10);

    if (ids.length === 10) {
      const { data } = await asyncDocs(id, ids);
      failIds.current = [...failIds.current, ...data.failIds];
      return asyncDocsLoop(id, list, startPos + 10);
    }
    const { data } = await asyncDocs(id, ids);
    failIds.current = [...failIds.current, ...data.failIds];
    return Promise.resolve();
  };

  /** 遍历每个机器人，分别添加文档 */
  const robotLoop = async (robotList: string[]) => {
    const id = robotList.shift();
    if (id) {
      await asyncDocsLoop(id, selectedDocIds, 0);
      return robotLoop(robotList);
    }
    return Promise.resolve();
  };

  const handleConfirm = async () => {
    if (!selectedRobotIds.length) return;
    await robotLoop(selectedRobotIds);
    setSelectedRobotIds([]);
    addRobotSuccess();
    if (failIds.current.length) {
      Toast.success(`添加完成，有 ${failIds.current.length} 个文档添加失败！`);
      failIds.current = [];
      return;
    }
    Toast.success('添加完成');
  };

  useEffect(() => {
    initList();
  }, []);

  return (
    <ModalWrapper
      {...otherProps}
      width={375}
      height={436}
      onConfirm={handleConfirm}
      title="添加至机器人"
      confirmStyle={{ opacity: selectedRobotIds.length ? 1 : 0.5 }}
    >
      <div className={style['robot-list']}>
        <LoadMore
          className={style['load-more']}
          onReachBottom={loadMore}
          loading={loading}
          hasMore={hasMore}
          finishedText={null}
        >
          {list.map(item => (
            <div
              onClick={() => {
                handleClick(item.id);
              }}
              key={item.id}
              className={style['robot-item']}
            >
              <div className={style.info}>
                <img
                  className={style.avatar}
                  src={assets.images.robot['icon-robot.png']}
                  alt=""
                />
                <div className={style.meta}>
                  <span className={style.name}>{item.name}</span>
                  <p>文档：{item.documentCount}</p>
                </div>
              </div>

              <img
                alt=""
                src={
                  selectedRobotIds.includes(item.id)
                    ? assets.images.robot['icon-choice.png']
                    : assets.images.robot['icon-nochoice.png']
                }
                className={style['icon-select']}
              />
            </div>
          ))}
        </LoadMore>
      </div>
    </ModalWrapper>
  );
}
