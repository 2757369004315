import { Popup, Toast, Tooltips } from '@bhb-frontend/lithe-ui';
import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import assets from '@/assets';

import Style from './style.module.less';
import { LiveDetail } from '@/types/live';

import { PLATFORM_LIST } from '@/constants/myLive';
import { goToWeb } from '@/utils/doc';
import { LIVE_STATUS, LIVE_LIST_OPERATION } from '@/constants/liveStatusEnum';
import { formatDuration } from '@/utils/date';
import { LIVE_ROOM_STATUS } from '@/types/home';

interface IProps {
  data: LiveDetail;
  handle: (id: string, type: string) => void;
}

/** 操作结构体 */
interface Operation {
  icon: string;
  label: string;
  key: string;
}

const defaultOperation: Operation[] = [
  {
    icon: 'icon-a-document_icon_delete2x',
    label: '删除直播间',
    key: LIVE_LIST_OPERATION.DELETE,
  },
];

function LiveTable(props: IProps) {
  const { data, handle } = props;

  const [visible, setVisible] = React.useState(false);

  const [moreMenuList, setMoreMenuList] =
    useState<Operation[]>(defaultOperation);

  const moreRef = useRef(null);

  useEffect(() => {
    if (data.status === LIVE_ROOM_STATUS.RUNNING) {
      const list = [...moreMenuList];
      list.unshift({
        icon: 'icon-a-document_icon_delete2x',
        label: '停止录制',
        key: LIVE_LIST_OPERATION.STOP_RECORD,
      });
      setMoreMenuList(list);
    }
  }, []);

  // const handleClick = (event: Event) => {
  //   const target = event.target as HTMLElement;
  //   if (moreRef.current && target.contains(moreRef.current as Node)) {
  //     return;
  //   }
  //   goToDoc(`/clip/live/detail/${data.id}`);
  // };

  const handleClick = e => {
    const target = e.target as HTMLElement;
    if (moreRef.current && target.contains(moreRef.current as Node)) {
      return;
    }
    const { id, status } = data;
    if (status === 'expired') {
      Toast.error('直播已过期');
      return;
    }
    goToWeb(`/clip/live/detail/${id}?back=live`);
  };

  const overlay = (
    <div className={Style.menu}>
      {moreMenuList.map(item => (
        <div
          key={item.key}
          onClick={e => {
            setVisible(false);
            e.stopPropagation();
            handle(data.id, item.key);
          }}
          className={Style.menuItem}
        >
          <i
            className={classNames('iconfont', item.icon, Style['menu-icon'])}
          />
          {item.label}
        </div>
      ))}
    </div>
  );

  const renderData = () => {
    const {
      id,
      coverUrl,
      title,
      platform,
      isSample,
      recordDuration,
      status,
      createdAt,
    } = data;
    const nodeId = `more-img-${id}`;

    return (
      <div
        className={Style['table-body-info-item']}
        id={nodeId}
        key={id}
        onClick={handleClick}
      >
        <div className={Style['table-body-info-item-live']}>
          <img
            className={Style['table-body-info-item-live-image']}
            src={coverUrl || assets.images.live['live_icon_empty.png']}
            alt=""
          />
          <div className={Style['table-body-info-item-live-info']}>
            <p>
              <span className={Style['table-body-info-title']}>{title}</span>
              {isSample && (
                <span className={Style['table-body-info-item-live-info-tag']}>
                  示例
                </span>
              )}
            </p>
            <p className={Style['table-body-info-record']}>
              录制时长：
              {formatDuration(recordDuration, 'hh:mm:ss')}
            </p>
          </div>
        </div>
        <div className={Style['table-body-info-item-platform']}>
          {PLATFORM_LIST[platform].label}
        </div>
        <div className={classNames(Style['table-body-info-item-status'])}>
          <span
            className={classNames(Style.status, {
              [Style.running]: status === LIVE_ROOM_STATUS.RUNNING,
            })}
          >
            {LIVE_STATUS[status]}
          </span>
        </div>
        <div className={Style['table-body-info-item-time']}>{createdAt}</div>
        <div className={Style['table-body-info-item-operation']}>
          <Popup
            clickClosable
            placement="bottomRight"
            overlay={overlay}
            trigger="click"
            visible={visible}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            container={() => document.querySelector(`#${nodeId}`)!}
          >
            <div className={classNames(Style.more)}>
              <Tooltips tips="更多操作">
                <img
                  ref={moreRef}
                  className={Style['more-img']}
                  src={assets.images.doc['document_icon_more.png']}
                  alt=""
                  onClick={() => {
                    setVisible(true);
                  }}
                />
              </Tooltips>
            </div>
          </Popup>
        </div>
      </div>
    );
  };

  return renderData();
}

export default LiveTable;
