import {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useLayoutEffect,
} from 'react';
import { LoadMore } from '@bhb-frontend/lithe-ui';
import { getLiveList, removeLive, stopLive } from '@/api/home';
import { LiveDetail, LIVE_ROOM_STATUS, LivePlatform } from '@/types/home';
import CreationItem, { MenuItem } from '../DraftsItem';
import style from './LiveList.module.less';
import ConfirmModal from '@/components/Confirm';
import Empty from '@/components/EmptyList';
import { formatDuration } from '@/utils/date';
import assets from '@/assets';
import { goToWeb } from '@/utils/doc';
import itemCommonStyle from '../HomeItemCommon.module.less';
import { userStore } from '@/store';
import NotLogin from '../NotLogin';
import { TabRef } from '../Drafts/Drafts';

enum MenuType {
  stopRecording = 'stopRecording',
  remove = 'remove',
}

const getMenuItems = (item: LiveDetail) => {
  const menuItems: MenuItem[] = [
    {
      label: '删除',
      icon: <i className="iconfont icon-a-document_icon_delete2x" />,
      value: MenuType.remove,
      iconStyle: { color: '#FF0D3A' },
      labelStyle: { color: '#FF0D3A' },
    },
  ];
  if (
    [LIVE_ROOM_STATUS.RUNNING, LIVE_ROOM_STATUS.DISCONNECT].includes(
      item.status
    )
  ) {
    menuItems.unshift({
      label: '停止录制',
      icon: <i className="iconfont icon-a-zhibo_icon_stop2x" />,
      value: MenuType.stopRecording,
      iconStyle: { color: '#FF0D3A' },
    });
  }
  return menuItems;
};

function LiveDoc(props: { searchValue: string }, ref: React.Ref<TabRef>) {
  const { searchValue } = props;
  const [list, setList] = useState<LiveDetail[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [removeVisible, setRemoveVisible] = useState(false);
  const [stopRecordingVisible, setStopRecordingVisible] = useState(false);
  const operationItem = useRef<LiveDetail | null>(null);
  const params = useRef({
    sid: '',
    pageSize: 20,
  });

  /** 获取列表 */
  const getList = async () => {
    try {
      setLoading(true);
      const { data } = await getLiveList({
        ...params.current,
        keyword: searchValue,
      });
      params.current = {
        ...params.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (err) {
      console.warn(err);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    params.current = { ...params.current, sid: '' };
    const data = await getList();
    if (data) {
      setList(data.results);
    }
  };

  useImperativeHandle(ref, () => ({ initList }));

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getList();
    if (data) {
      setList([...list, ...data.results]);
    }
  };

  const onChangeMenu = (val: MenuType, item: LiveDetail) => {
    operationItem.current = item;
    if (val === MenuType.remove) {
      setRemoveVisible(true);
      return;
    }
    setStopRecordingVisible(true);
  };

  /** 删除 */
  const onRemove = async () => {
    try {
      const data = operationItem.current;
      if (!data) return;
      await removeLive(data.id);
      setList(list.filter(item => item.id !== data.id));
      setRemoveVisible(false);
    } catch (err) {
      console.error('删除失败', err);
    }
  };

  /** 停止录制 */
  const onStopRcording = async () => {
    if (!operationItem.current) return;
    await stopLive(operationItem.current.id);
    initList();
    setStopRecordingVisible(false);
  };

  useLayoutEffect(() => {
    initList();
  }, []);

  if (!userStore.isLogin()) {
    return <NotLogin />;
  }

  if (!list.length && !loading) {
    return <Empty />;
  }

  const handleClick = (item: LiveDetail) => {
    goToWeb(`/clip/live/detail/${item.id}`);
  };

  return (
    <div className={style['live-list']}>
      <LoadMore
        className={style['load-more']}
        onReachBottom={loadMore}
        loading={loading}
        hasMore={hasMore}
        finishedText={null}
      >
        {list.map(item => (
          <div key={item.id} className={itemCommonStyle.item}>
            <CreationItem<MenuType>
              onClick={() => {
                handleClick(item);
              }}
              id={item.id}
              name={item.title}
              coverUrl={
                item.coverUrl || assets.images.home['zhibo_icon_empty@2x.png']
              }
              isEmptyImg={!item.coverUrl}
              time={item.createdAt}
              desc={formatDuration(item.recordDuration, 'hh:mm:ss')}
              menuItems={getMenuItems(item)}
              onChangeMenu={val => {
                onChangeMenu(val, item);
              }}
            />

            {item.status === LIVE_ROOM_STATUS.RUNNING && (
              <div className={style.recording}>
                <i className="iconfont icon-a-zhibo_icon_stop2x" />
                录制中
              </div>
            )}

            <img
              className={style['platform-icon']}
              src={
                item.platform === LivePlatform.douyin
                  ? assets.images.home['douyin.png']
                  : assets.images.home['kuaishou.png']
              }
              alt=""
            />
          </div>
        ))}
      </LoadMore>

      <ConfirmModal
        visible={removeVisible}
        title="确定删除直播内容?"
        content="删除后内容将无法恢复，且消耗的录制时长不会返还"
        onCancel={() => {
          setRemoveVisible(false);
        }}
        onOk={onRemove}
      />

      <ConfirmModal
        visible={stopRecordingVisible}
        title="确定终止录制？"
        content="终止录制后将无法重新开启，你可以重新创建该直播进行录制"
        onCancel={() => {
          setStopRecordingVisible(false);
        }}
        onOk={onStopRcording}
      />
    </div>
  );
}

export default forwardRef(LiveDoc);
