import ModalWrapper from './ModalWrapper';

export default function RemoveDoc(props) {
  return (
    <ModalWrapper {...props} title="注意" width={420} height="auto">
      <>
        <i
          className="icon-a-fabu_icon_fail2x1 iconfont"
          style={{
            color: 'rgb(255, 23, 92)',
            marginRight: '5px',
          }}
        />
        删除后内容将无法恢复，是否确定删除？
      </>
    </ModalWrapper>
  );
}
