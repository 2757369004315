import { LiveRobotSliceDetail } from '@/types/live';

/** 切片视频默认数据 */
export const DefaultRobotSliceData: LiveRobotSliceDetail = {
  id: '',
  name: '',
  robotId: '',
  publishStatus: '',
  publishMessage: '',
  duration: 0,
  publishTaskId: '',
  GoodsInfo: [],
  isDeleted: false,
  videoUrl: '',
  size: 0,
  createdAt: '',
  uploadStatus: '',
  uploadId: '',
  videoKey: '',
};

/** 切片视频上传状态 */
export const SliceStatus = {
  SUCCESS: 'success',
  FAIL: 'fail',
  UPLOADING: 'uploading',
};

/** 发布状态 */
export const PublishStatus = {
  /** 未发布 */
  AWAIT: 'publishAwait',
  /** 发布中 */
  ING: 'publishing',
  /** 发布成功 */
  SUCCESS: 'publishSucceed',
  /** 发布失败 */
  FAIL: 'publishFailed',
  /** 取消发布 */
  CANCEL: 'publishCancel',
};

/** 发布状态文案枚举 */
export enum PUBLISH_STATUS_TITLE {
  AWAIT = '未发布',
  ING = '发布中',
  SUCCESS = '发布成功',
  FAIL = '发布失败',
  CANCEL = '发布取消',
}

/** 发布状态对象 */
export const PublishStatusText = {
  [PublishStatus.AWAIT]: PUBLISH_STATUS_TITLE.AWAIT,
  [PublishStatus.ING]: PUBLISH_STATUS_TITLE.ING,
  [PublishStatus.SUCCESS]: PUBLISH_STATUS_TITLE.SUCCESS,
  [PublishStatus.FAIL]: PUBLISH_STATUS_TITLE.FAIL,
  [PublishStatus.CANCEL]: PUBLISH_STATUS_TITLE.CANCEL,
};
