import { observer } from 'mobx-react';
import Style from './EquityComparison.module.less';
import { configStore } from '@/store';

function EquityComparison() {
  const url =
    configStore.config.productPageConfig?.shanjianMemberDetailedComparison;

  return (
    <div className={Style.equity}>
      <div className={Style.content}>
        {url && <img src={url} alt="权益对比" />}
      </div>
    </div>
  );
}
export default observer(EquityComparison);
