import { Dialog, Form, Input } from '@bhb-frontend/lithe-ui';
import { useEffect } from 'react';
import style from './style.module.less';
import { RobotItem } from '@/types/robot';
import { addLiveRobot, editLiveRobot } from '@/api/robot';

interface RobotSettingProps<T> {
  visible: boolean;
  onCancel: () => void;
  robotInfo?: T;
  onOk: () => void;
}

export default function RobotSetting<T extends Record<string, any> = RobotItem>(
  props: RobotSettingProps<T>
) {
  const { visible, onCancel, robotInfo, onOk } = props;

  const [form] = Form.useForm();

  const isCreate = !robotInfo?.id;

  const init = () => {
    if (!robotInfo?.id) return;
    const { name } = robotInfo;
    form.setFieldsValue({
      name,
    });
  };

  const handleSub = async () => {
    const res = await form.validateFields();
    const param = {
      ...res,
    };
    if (!isCreate) {
      await editLiveRobot(robotInfo.id, param);
    } else {
      await addLiveRobot(param);
    }

    onOk();
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      return;
    }
    init();
  }, [visible]);

  return (
    <Dialog
      visible={visible}
      title={isCreate ? '创建机器人' : '机器人设置'}
      className={style.modale}
      height={200}
      onOk={handleSub}
      onCancel={onCancel}
      timeout={0}
    >
      <Form form={form}>
        <Form.Item
          name="name"
          label="机器人名称"
          rules={[
            {
              required: true,
              message: '名称必填',
            },
            {
              max: 15,
              message: '名称不得超过15个字',
            },
          ]}
        >
          <Input
            placeholder="请输入机器人名称，字数不超过15个字符"
            contentClassName={style.input}
          />
        </Form.Item>
      </Form>
    </Dialog>
  );
}
