import service, { FullResponse } from '@bhb-frontend/request';
// import { userStore } from '@/store';
import { Toast } from '@bhb-frontend/lithe-ui';
import md5 from 'md5';
import { BASE_URL_MAP, CONTEXT_ENV } from '@/config';
import { ERROR_CODE } from '@/constants/error-code';
import { getCookie } from '@/utils/cookie';
import { ENV, getSecret } from '@/utils';
import { showLoginAndRemoveToken } from '@/utils/login';
import { onEnoughSpace } from '@/utils/storagePaidGuidance';

const {
  NO_LOGIN,
  TOKEN_EXPIRE,
  NOT_SUPPORT_PLATFORM,
  SHARE_URL_PARSE_FAILED,
  SOURCE_LIVE_CLOSED,
  INVALID_SHARE_URL,
  LIVE_CREATE_CONST_LIMIT,
  DUPLICATE_START_LIVE,
  NOT_FIND,
  CREATE_VIDEO_NUM_LIMIT,
  RENDER_LIMIT,
  LIVE_TIME_DEFICIENCY,
  INSUFFICIENT_STORAGE_SPACE_GUIDELINES,
  INSUFFICIENT_STORAGE_SPACE,
  AGENT_INVITE_NOT_ENOUGH_THRESHOLD_NEED_MEMBER,
} = ERROR_CODE;

// 不需要全局toast提示的错误码集合
const noToastCode: Array<ERROR_CODE> = [
  NO_LOGIN,
  TOKEN_EXPIRE,
  NOT_SUPPORT_PLATFORM,
  SHARE_URL_PARSE_FAILED,
  SOURCE_LIVE_CLOSED,
  INVALID_SHARE_URL,
  LIVE_CREATE_CONST_LIMIT,
  DUPLICATE_START_LIVE,
  NOT_FIND,
  CREATE_VIDEO_NUM_LIMIT,
  RENDER_LIMIT,
  LIVE_TIME_DEFICIENCY,
  INSUFFICIENT_STORAGE_SPACE_GUIDELINES,
  INSUFFICIENT_STORAGE_SPACE,
  AGENT_INVITE_NOT_ENOUGH_THRESHOLD_NEED_MEMBER,
];

export const baseUrl: string =
  BASE_URL_MAP[ENV] || BASE_URL_MAP[CONTEXT_ENV.dev];

// service基础配置
service.setGlobalConfig({
  baseUrl,
  timeout: 10000, // 请求超时时间
});

// request interceptor
service.interceptors.request(config => {
  const date = Date.now();

  if (!config.headers) return config;

  // 跨域
  config.headers['Access-Control-Allow-Origin'] = '*';

  // 客户端信息
  const bhbClientInfo = {
    platform: 'web', // 应用平台 ios,android,web,wechat
    appVersion: window?.MTV_CONFIG?.version || '3.5.0',
    // deviceId: String, // 设备唯一标识符
    // osVersion: String // 系统版本， android例如：9.0.0
    requestTime: date, // 请求时间，发起请求的时候拿到的客户端时间
  };
  config.headers['bhb-client-info'] = JSON.stringify(bhbClientInfo);

  // 接口鉴权（bhb-api-auth），计算规则：md5(secret + origin + requestTime)，其中secret为固定值

  // 在浏览器环境
  const origin = window.location.origin;

  config.headers['bhb-api-auth'] = md5(getSecret() + origin + date);

  // headers 携带token
  const token: string = getCookie('token') || '';

  if (token) {
    config.headers['bhb-session-token'] = token;
  }

  return config;
});

// response interceptor
service.interceptors.response((response: FullResponse) => {
  const { error } = response.body;
  if (error && error !== 0) {
    /** 登录过期 */
    if ([NO_LOGIN, TOKEN_EXPIRE].includes(error)) {
      showLoginAndRemoveToken();
    }

    /** 统一提示用户开通会员 */
    if (
      [
        INSUFFICIENT_STORAGE_SPACE_GUIDELINES,
        INSUFFICIENT_STORAGE_SPACE,
      ].includes(error)
    ) {
      onEnoughSpace(error === INSUFFICIENT_STORAGE_SPACE_GUIDELINES);
    }

    /** 统一提示 */
    if (!noToastCode.includes(error)) {
      Toast.error(response.body.message);
    }
  }

  return response;
});

export default service;
