import { useState, useRef, useEffect } from 'react';
import { Empty, LoadMore, Spin } from '@bhb-frontend/lithe-ui/lib';
import { formatTime } from '@bhb-frontend/utils/lib/format';
import { consumeRecord } from '@/api/user';
import { ConsumeRecordParams, ConsumeRecordDetail } from '@/types/user';
import assets from '@/assets';
import Style from './Consume.module.less';
import { OPERATION_TYPE } from '@/constants/coin';

const PAGH_SIZE = 10;
export default function Consume() {
  const [consumeList, setConsumeList] = useState<ConsumeRecordDetail[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const listQuery = useRef<ConsumeRecordParams>({
    sid: '',
    pageSize: PAGH_SIZE,
    type: 'member',
  });

  /* 获取视频列表 */
  const getConsumeList = async () => {
    try {
      setLoading(true);
      const params = {
        ...listQuery.current,
      };
      const { data } = await consumeRecord(params);

      listQuery.current = {
        ...listQuery.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (err) {
      console.error('GetConsumeList fail!', err);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    listQuery.current = {
      ...listQuery.current,
      sid: '',
    };
    const data = await getConsumeList();
    data && setConsumeList(data.results);
  };

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getConsumeList();
    data && setConsumeList([...consumeList, ...data.results]);
  };

  useEffect(() => {
    initList();
  }, []);

  return (
    <div className={Style.list}>
      {!consumeList.length && !loading ? (
        <Empty
          text="暂无权益明细"
          style={{ marginTop: 125, color: '#191919', marginBottom: 125 }}
          imgSrc={assets.images.common.common_empty_transparent_empty_icon}
        />
      ) : (
        <Spin
          spinning={loading}
          style={{
            width: '100%',
            minHeight: '300px',
          }}
        >
          <LoadMore
            onReachBottom={loadMore}
            className={Style.loadMore}
            loading={loading}
            hasMore={hasMore}
            finishedText=""
          >
            {consumeList.map(consume => {
              const { id, operationType, deductedRights, createdAt } = consume;

              return (
                <div key={id} className={Style.wrap}>
                  <div className={Style.left}>
                    <span className={Style.type}>
                      {OPERATION_TYPE[operationType]}
                    </span>
                    <span className={Style.time}>
                      {formatTime(new Date(createdAt), 'yyyy.mm.dd hh:MM')}
                    </span>
                  </div>
                  <div className={Style.right}>
                    {deductedRights && (
                      <>
                        {!!deductedRights.aiDocumentsNumber && (
                          <span>
                            -{deductedRights.aiDocumentsNumber}次文案创作
                          </span>
                        )}
                        {!!deductedRights.oralVideoDuration && (
                          <span>
                            -{deductedRights.oralVideoDuration || 0}秒口播时长
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </LoadMore>
        </Spin>
      )}
    </div>
  );
}
