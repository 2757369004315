import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  Ref,
  useImperativeHandle,
  CSSProperties,
  useLayoutEffect,
} from 'react';
import { Empty, LoadMore, Spin, Toast } from '@bhb-frontend/lithe-ui/lib';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react';
import { userStore } from '@/store/user';
import socket from '@/utils/socket';
import assets from '@/assets';
// import usePublishYouTube from '@/hooks/usePublishYouTube';

import PreviewModal from './PreviewModal';
import PublishModal from './PublishModal';
import VideoItem from './VideoItem';
import DeleteModal from './DeleteModal';
import Styles from './index.module.less';
import { ListQuery } from '@/types/api';
import { deleteSliceVideo, getRobotSliceList, publishVideo } from '@/api/robot';
import { LiveRobotSliceDetail } from '@/types/live';
import { PublishStatus, SliceStatus } from '../const';
import { videoActions } from '@/api/live';
import Confirm from '@/components/Confirm';

interface VideoListProps {
  uploadIdList?: string[];
  style?: CSSProperties;
  cancelUpload: (id: string) => void;
}

export interface VideoListRefFn {
  updateVideoList: (item: LiveRobotSliceDetail) => void;
}

/** 不允许发布的状态集合 */
const notAllowPublishList = [
  PublishStatus.AWAIT,
  PublishStatus.CANCEL,
  PublishStatus.FAIL,
];

function VideoList(props: VideoListProps, ref: Ref<VideoListRefFn>) {
  const { id: robotId } = useParams();
  const { cancelUpload: cancelUploadFn, style } = props;
  /* 视频列表 */
  const [videoList, setVideoList] = useState<LiveRobotSliceDetail[]>([]);
  /* 用户点击某个视频存储起来的item */
  const info = useRef<LiveRobotSliceDetail>();
  /* 预览当前点击视频 */
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  const [cancelPublishModalVisible, setCancelPublishModalVisible] =
    useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    updateVideoList: item => {
      if (item.uploadStatus === SliceStatus.UPLOADING) {
        setVideoList([item, ...videoList]);
      } else {
        const list = videoList.map(v =>
          v.uploadId && v.uploadId === item.uploadId ? item : v
        );

        setVideoList([...list]);
      }
    },
  }));

  const listQuery = useRef<ListQuery>({
    sid: '',
    pageSize: 10,
  });

  /* 删除视频 - 弹窗 */
  const [deleteModal, setDeleteModal] = useState(false);

  /** 监听列表中视频的状态并更新 */
  const onupdateVideoStatus = () => {
    if (userStore.userInfo.socketLink) {
      socket.on((res: any) => {
        const { data } = res;
        switch (res.event) {
          case 'live.robot.video.publish.status': {
            const list = videoList.map(v =>
              v.id === data.id ? { ...v, ...data } : v
            );
            setVideoList([...list]);
            break;
          }
          default:
            break;
        }
      });
    }
  };

  /* 获取视频列表 */
  const getVideoList = async () => {
    try {
      setLoading(true);
      const params = {
        ...listQuery.current,
      };
      const { data } = await getRobotSliceList(robotId || '', params);

      listQuery.current = {
        ...listQuery.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (err) {
      console.error('直播机器人获取视频列表失败', err);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    listQuery.current = {
      ...listQuery.current,
      sid: '',
    };
    const data = await getVideoList();
    data && setVideoList(data.results);
  };

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getVideoList();
    data && setVideoList([...videoList, ...data.results]);
  };

  useLayoutEffect(() => {
    initList();
  }, []);

  /* 关闭预览 */
  const close = () => {
    setPreviewVisible(false);
  };

  /* 删除 */
  const remove = (item?: LiveRobotSliceDetail) => {
    setDeleteModal(true);
    info.current = item;
  };

  const removeConfirm = (id: string) => {
    deleteSliceVideo(id, { type: 'robot' }).then(() => {
      Toast.success('删除成功！');
      const list = videoList.filter(v => v.id !== id);
      setVideoList([...list]);
    });
  };

  /* 下载 */
  const download = (info: LiveRobotSliceDetail) => {
    const el = document.createElement('a');
    document.body.append(el);
    el.href = info.videoUrl || '';
    el.download = info.name;
    el.rel = 'noopener noreferrer';
    el.click();
    document.body.removeChild(el);
  };

  /**
   * 取消视频上传
   */
  const cancelUpload = (id: string) => {
    // 更新 ids，
    cancelUploadFn(id);
    // 更新 videoList；
    const list = videoList.filter(v => v.uploadId !== id);
    setVideoList([...list]);
  };

  const cancelPublish = () => {
    setCancelPublishModalVisible(true);
  };

  const confirmCancel = async () => {
    setCancelPublishModalVisible(false);
    if (!info.current) return;
    const obj = {
      type: 'robot',
    };
    await publishVideo(info.current.id, 'publishCancel', obj);
    const list = videoList.map(v =>
      v.id === info.current?.id
        ? { ...info.current, publishStatus: PublishStatus.CANCEL }
        : v
    );
    setVideoList([...list]);
  };

  useEffect(() => {
    onupdateVideoStatus();
  }, [userStore.userInfo.socketLink, videoList]);

  return (
    <>
      <div className={Styles.videos} style={style || {}}>
        <div className={Styles.content}>
          {!videoList.length && !loading ? (
            <Empty
              text="暂无视频列表"
              style={{ marginTop: 250, color: '#191919' }}
              imgSrc={assets.images.common['img-empty-box.png']}
            />
          ) : (
            <Spin
              spinning={loading}
              style={{
                width: '100%',
                minHeight: '300px',
              }}
            >
              <LoadMore
                onReachBottom={loadMore}
                className={Styles.loadMore}
                loading={loading}
                hasMore={hasMore}
                finishedText=""
              >
                {videoList.map(item => (
                  <VideoItem
                    info={item}
                    key={item.id}
                    publish={() => {
                      const cancelStatus = notAllowPublishList.includes(
                        item.publishStatus
                      );
                      if (!item.publishStatus || !cancelStatus) return;
                      (
                        PublishModal as unknown as React.FC<
                          Record<string, any>
                        > & {
                          show: (info: LiveRobotSliceDetail) => void;
                        }
                      ).show(item);
                    }}
                    preview={() => {
                      info.current = item;
                      if (
                        [SliceStatus.UPLOADING, SliceStatus.FAIL].includes(
                          item.uploadStatus || ''
                        )
                      ) {
                        return;
                      }
                      setPreviewVisible(true);
                    }}
                    download={() => {
                      info.current = item;
                      download(item);
                    }}
                    remove={() => {
                      info.current = item;
                      remove(item);
                    }}
                    cancelPublish={() => {
                      info.current = item;
                      cancelPublish();
                    }}
                    cancelUpload={cancelUpload}
                  />
                ))}
              </LoadMore>
            </Spin>
          )}
        </div>
      </div>
      <PublishModal
        onPublish={async data => {
          if (!data.id) return;
          await videoActions(data.id, { actions: 'publish' });

          const list = videoList.map(v =>
            v.id === data.id ? { ...data, publishStatus: PublishStatus.ING } : v
          );

          setVideoList([...list]);
        }}
      />
      <PreviewModal
        isPreview={isPreviewVisible}
        info={info.current as LiveRobotSliceDetail}
        controls
        autoplay
        close={close}
      />
      <DeleteModal
        visible={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={() => {
          removeConfirm(info.current?.id as string);
        }}
      />
      <Confirm
        title="取消发布"
        content="取消发布后，将不会推送此条视频给订阅者。"
        visible={cancelPublishModalVisible}
        cancelText="暂不取消"
        okText="取消发布"
        onCancel={() => setCancelPublishModalVisible(false)}
        onOk={confirmCancel}
      />
      {/* <TipDialog /> */}
    </>
  );
}

export default observer(forwardRef(VideoList));
