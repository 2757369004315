import classnames from 'classnames';
import { Tooltips } from '@bhb-frontend/lithe-ui';

import Style from './style.module.less';

import { Reverse, Positive } from '@/constants/myLive';

interface DocTableHeadProps {
  /** 状态 */
  ordering: string;
  /** 排序 */
  onSort: (val: string) => void;
}

/**
 * 直播状态描述
 */
export const LIVE_STATUS_DESC = [
  { name: '录制中', desc: '直播间有实时推流内容' },
  { name: '直播中断', desc: '直播间网络不稳定' },
  { name: '已结束', desc: '直播间无实时推流内容' },
  { name: '已终止', desc: '手动停止录制直播内容' },
  { name: '已过期', desc: '录制内容存储已过期' },
];

export default function DocTableHead(props: DocTableHeadProps) {
  const { onSort, ordering } = props;

  const renderLiveRenderDesc = () =>
    LIVE_STATUS_DESC.map((item, index) => (
      <p key={item.name} style={{ textAlign: 'left' }}>
        <span>
          {index + 1}.{item.name}:
        </span>
        <span>{item.desc}</span>
      </p>
    ));

  return (
    <div className={Style['table-head']}>
      <div className={Style['table-head-info']}>
        <div className={classnames(Style.title, Style['table-head-info-item'])}>
          <div>直播信息</div>
        </div>
        <div
          className={classnames(
            Style.title,
            Style['table-head-info-item'],
            Style.platform
          )}
        >
          <div>直播平台</div>
        </div>

        <div
          className={classnames(
            Style.title,
            Style['table-head-info-item'],
            Style.status
          )}
        >
          <div>
            <span style={{ marginRight: 4 }}>状态</span>
            <Tooltips tips={renderLiveRenderDesc()} placement="bottom">
              <i className={classnames('icon-a-zhibo_time_tips2x iconfont')} />
            </Tooltips>
          </div>
        </div>
        <div
          className={classnames(
            Style.title,
            Style['table-head-info-item'],
            Style.time
          )}
        >
          <span className={Style['hover-box']}>
            <span className={Style['hover-content']}>最后更新时间</span>
            <span className={Style['arrow-icon']}>
              <i
                className={classnames(
                  Style['arrow-icon-font'],
                  Style['arrow-icon-bottom'],
                  {
                    [Style['arrow-icon-active']]: ordering === Reverse,
                  }
                )}
                onClick={() => {
                  onSort(Reverse);
                }}
              />
              <i
                className={classnames(
                  Style['arrow-icon-font'],
                  Style['arrow-icon-top'],
                  {
                    [Style['arrow-icon-active']]: ordering === Positive,
                  }
                )}
                onClick={() => {
                  onSort(Positive);
                }}
              />
            </span>
          </span>
        </div>
        <div
          className={classnames(
            Style.title,
            Style['table-head-info-item'],
            Style.operation
          )}
        >
          <div>操作</div>
        </div>
      </div>
    </div>
  );
}
