import cls from 'classnames';
import { Button } from '@bhb-frontend/lithe-ui';
import Login from '@bhb-frontend/mtv-login';
import assets from '@/assets';
import style from './NotLogin.module.less';

interface NotLoginProps {
  classNames?: string;
}

export default function NotLogin(props: NotLoginProps) {
  const { classNames } = props;

  return (
    <div className={cls(style['not-login'], classNames)}>
      <img
        className={style.image}
        src={assets.images.home['not-login.png']}
        alt=""
      />
      <p className={style['tip-not-login']}>还没有登录哟</p>
      <p className={style['tip-more-content']}>
        登录后即可查看内容，以及体验更多功能
      </p>
      <Button
        type="primary"
        onClick={() => {
          Login.show?.();
        }}
        style={{ borderRadius: '2px' }}
      >
        注册登录
      </Button>
    </div>
  );
}
