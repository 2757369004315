import Socket from '@bhb-frontend/socket';
import { userStore } from '@/store/user';
import { getCookie } from '@/utils/cookie';

const getWSLink = () => getCookie('socketLink') || '';

const socket = new Socket({
  getSocketLink: getWSLink,
  jwtExpired: async () => {
    await userStore.getUser();
  },
  reconnect: 60,
});

export default socket;
