/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/f1107b999ffd4ae1ace3eb3756b7abf1.png */
  'shenji_icon_koubo@2x.png': 'mtv/images/f1107b999ffd4ae1ace3eb3756b7abf1.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/3677c3cd4c6942c8b070b1cfa3f78a38.png */
  'shenji_icon_ai_text@2x.png':
    'mtv/images/3677c3cd4c6942c8b070b1cfa3f78a38.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/dc30938ace9e4b41b8a745c4084f8f7d.png */
  'shenji_icon_coin@2x.png': 'mtv/images/dc30938ace9e4b41b8a745c4084f8f7d.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/854c8503cab64cedbd8c8896ae51dd11.png */
  head_background: 'mtv/images/854c8503cab64cedbd8c8896ae51dd11.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/e0fe21827d7f4fe694048863803d6e8a.png */
  head_text: 'mtv/images/e0fe21827d7f4fe694048863803d6e8a.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/a46111b6abc74803afe7365c523a60a9.png */
  head_koubo: 'mtv/images/a46111b6abc74803afe7365c523a60a9.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/mtv/images/787c8e08ce4349209a0f44fc6ab19b7c.png */
  head_cloud: 'mtv/images/787c8e08ce4349209a0f44fc6ab19b7c.png',
};
