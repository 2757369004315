import { TEMPLATE_MODULE } from '@/constants/myDoc';
import {
  CustomListResponse,
  DetailResponse,
  ListQuery,
  Page,
  CommonResponse,
} from '@/types/api';
import {
  DocSearchQuery,
  DocItem,
  ListItem,
  RobotItem,
  SearchQuery,
  ThemeCategory,
  AuthItem,
  ThemeCollapseList,
  GoodsParams,
  GoodsResult,
  LiveRobotDetail,
  RobotPublishVideo,
} from '@/types/robot';
import {
  LiveDetail,
  LiveRobotSliceDetail,
  LiveRobotUploadParams,
} from '@/types/live';

import request from './init';

/**
 * 获取机器人列表
 */
export function getRobotList(params: SearchQuery) {
  return request.get<CustomListResponse<RobotItem>>('subscribe/stores', params);
}

/**
 * 获取直播快剪机器人列表
 */
export function getLiveRobotsList(params: ListQuery) {
  return request.get<CustomListResponse<LiveRobotDetail>>(
    '/live/robots',
    params
  );
}

/**
 * 获取机器人详情
 */
export function getRobotDetail(id: string) {
  return request.get<DetailResponse<RobotItem>>(`/subscribe/store/${id}`);
}

/**
 * 获取机器人文档列表
 */
export function getRobotDocList(id: string, params: DocSearchQuery) {
  return request.get<CustomListResponse<DocItem>>(
    `/subscribe/store/${id}/documents`,
    params
  );
}

/**
 * 编辑图文机器人
 */
export function editRobot(id: string, data: RobotItem) {
  return request.put<DetailResponse<CommonResponse>>(
    `/subscribe/store/${id}`,
    data
  );
}

/**
 * 编辑直播机器人
 */
export function editLiveRobot(id: string, data) {
  return request.put<DetailResponse<CommonResponse>>(
    `/live/robots/${id}`,
    data
  );
}

/**
 * 添加图文机器人
 */
export function addRobot(data: RobotItem) {
  return request.post<DetailResponse<CommonResponse>>('/subscribe/store', data);
}

/**
 * 添加直播机器人
 */
export function addLiveRobot(data) {
  return request.post<DetailResponse<CommonResponse>>('/live/robots', data);
}

/**
 * 验证授权码
 */
export function verifyToken(token: string) {
  return request.get<DetailResponse<{ result: ListItem }>>(
    'subscribe/token/verify',
    {
      subscribeToken: token,
    }
  );
}

/**
 * 授权订阅号
 */
export function authRobot(id: string, subscribeToken: string) {
  return request.put<DetailResponse<CommonResponse>>(
    `/subscribe/store/${id}/auth`,
    {
      subscribeToken,
    }
  );
}

/**
 * 授权直播快剪订阅号
 */
export function authLiveRobot(id: string, token) {
  return request.post<DetailResponse<CommonResponse>>(
    `/live/robots/${id}/band/token`,
    {
      token,
    }
  );
}
/**
 * 获取官方主题分类
 * @param type 模板分类类型 theme:图文模板|subscribeTheme:订阅号模板(默认值, theme)
 * @returns
 */
export function getThemesCategory(type: string = TEMPLATE_MODULE.VIDEO) {
  return request.get<DetailResponse<Page<ThemeCategory>>>('/theme/category', {
    type,
  });
}

/**
 * 获取官方主题列表
 * @param params
 * @returns
 */
export function getOfficialThemes<T extends object>(id: string, params: T) {
  return request.get<DetailResponse<Page<ThemeCollapseList>>>(
    `/theme/category/${id}`,
    params
  );
}
/**
 * 获取机器人授权订阅号列表
 */
export function getRobotAuthList(id: string, params: SearchQuery) {
  return request.get<CustomListResponse<AuthItem>>(
    `/subscribe/store/${id}/auths`,
    params
  );
}

/**
 * 获取直播快剪机器人授权订阅号列表
 */
export function getRobotManageList(id: string, params: SearchQuery) {
  return request.get<CustomListResponse<AuthItem>>(
    `/live/robots/${id}/tokens`,
    params
  );
}

/**
 * 解除授权
 */
export function removeAuth(storeId: string, id: string) {
  return request.delete<DetailResponse<CommonResponse>>(
    `/subscribe/store/${storeId}/auth`,
    {
      subscribeToken: id,
    }
  );
}

/**
 * 解除直播快剪授权
 */
export function removeLiveAuth(id: string, token: string) {
  return request.delete<DetailResponse<CommonResponse>>(
    `/live/robots/${id}/token`,
    {
      token,
    }
  );
}

/**
 * 文档修改发布类型
 */
export function changePublishType(
  storeId: string,
  documentIds: string[],
  releaseType: 'normal' | 'timely'
) {
  return request.put('/subscribe/store/document/release', {
    storeId,
    documentIds,
    releaseType,
  });
}

/**
 * 文档上下架
 */
export function toggleDisplay(
  storeId: string,
  documentIds: string[],
  isShow: boolean
) {
  return request.put('/subscribe/store/document/show', {
    storeId,
    documentIds,
    isShow,
  });
}

/**
 * 批量删除文档
 */
export function removeDocs(storeId: string, documentIds: string[]) {
  return request.delete('/subscribe/store/document', { storeId, documentIds });
}

/**
 * 删除机器人
 */
export function removeRobot(id: string) {
  return request.delete<DetailResponse<CommonResponse>>(
    `/subscribe/store/${id}`
  );
}

/**
 * 删除直播机器人
 */
export function removeLiveRobot(id: string) {
  return request.delete<DetailResponse<CommonResponse>>(`/live/robots/${id}`);
}

/**
 * 获取机器人详情
 * @param id 机器人id
 */
export function getLiveRobotDetail(robotId: string) {
  return request.get<DetailResponse<LiveRobotDetail>>(
    `/live/robots/${robotId}`
  );
}

/**
 * 获取机器人直播列表
 */
export function getLiveRobotList(robotId: string, params: ListQuery) {
  return request.get<CustomListResponse<LiveDetail>>(
    `/live/robots/${robotId}/lives`,
    params
  );
}

/**
 * 批量删除直播列表
 */
export function removeRobotLiveList(robotId: string, ids: string[]) {
  return request.delete(`/live/robots/${robotId}/lives`, { ids });
}

/**
 * 终止直播
 */
export function liveTerminate(
  liveId: string
): Promise<DetailResponse<CommonResponse>> {
  return request.post('/live/lives/terminate', { liveId });
}

/** 获取直播机器人商品管理列表 */
export function getLiveRobotGoodsList(params: GoodsParams) {
  return request.get<CustomListResponse<GoodsResult>>(
    '/live/robot/goods',
    params
  );
}

/** 删除商品 */
export function deleteGood(id: string) {
  return request.delete<DetailResponse<GoodsResult>>(`/live/robot/goods/${id}`);
}
/**
 * @desc 直播机器人上传视频
 * @params
 * - robotId 机器人id
 * - data 视频的上传参数 LiveRobotUploadParams
 */
export function uploadRobotSlice(robotId: string, data: LiveRobotUploadParams) {
  return request.post<DetailResponse<LiveRobotSliceDetail>>(
    `/live/robots/slice/${robotId}/videos`,
    data
  );
}

/**
 * @desc 获取直播机器人切片列表
 * @params 请求页数等
 */
export function getRobotSliceList(robotId: string, params: ListQuery) {
  return request.get<CustomListResponse<LiveRobotSliceDetail>>(
    `/live/robots/slice/${robotId}/videos`,
    params
  );
}

/**
 * @desc 删除切片视频
 * @params id 切片视频id
 */
export function deleteSliceVideo(id: string, params: { type: string }) {
  return request.delete(`/live/robots/slice/${id}`, params);
}

/**
 * 机器人直播切片发布视频
 */
export function publishVideo(
  id: string,
  action: string,
  data?: RobotPublishVideo
) {
  return request.post(`/live/robots/video/publish/${id}/${action}`, data);
}

/**
 *  获取商品列表
 */
export function getGoodsList(
  params: GoodsParams
): Promise<CustomListResponse<GoodsResult>> {
  return request.get('/live/robot/goods', params);
}
