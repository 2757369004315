import { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Tooltips, Popup, Menu } from '@bhb-frontend/lithe-ui';
import { userStore } from '@/store';
import { DocItem } from '@/types/myDoc';
import { formatDate, bit2Mb } from '@/utils/utils';
import assets from '@/assets';
import { MoreMenusType } from '../types';
import Style from './DocTableItem.module.less';

interface DocTableItemProps {
  /** doc 详情 */
  docInfo: DocItem;
  /** 点击 doc 回调 */
  onMouseUp: (e: React.MouseEvent) => void;
  /** 操作回调 */
  onOperation: (tpye: MoreMenusType, docInfo: DocItem) => void;
  /** 选中单个 */
  selectSingle: (id: string, isSelect: boolean) => void;
  /** 是否已经被选中 */
  selectedIdList: string[];
}

function DocTableItem({
  docInfo,
  onMouseUp,
  onOperation,
  selectedIdList,
  selectSingle,
}: DocTableItemProps) {
  const moreMenuList = [
    {
      icon: 'icon-a-document_icon_tips2x',
      label: '添加标签',
      value: MoreMenusType.addTab,
    },
    {
      icon: 'icon-a-btn_icon_copy_def1xpng',
      label: '创建副本',
      value: MoreMenusType.copy,
    },
    {
      icon: 'icon-a-document_icon_mingming2x',
      label: '重命名',
      value: MoreMenusType.rename,
    },
    {
      icon: 'icon-a-document_icon_delete2x',
      label: '删除',
      value: MoreMenusType.remove,
    },
  ];

  if (userStore.subscribeAuthList.includes('video')) {
    moreMenuList.unshift({
      icon: 'icon-a-tab_icon_robot_def2x1',
      label: '添加至机器人',
      value: MoreMenusType.addRobot,
    });
  }

  const docTableItemInfoRef = useRef<HTMLDivElement>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  useEffect(() => {
    setIsSelected(selectedIdList.includes(docInfo.id));
  }, [selectedIdList]);

  const overlay = (
    <Menu
      className={Style['menu-container']}
      onItemClick={({ value }) => {
        onOperation(value, docInfo);
      }}
    >
      {moreMenuList.map(item => (
        <Menu.Item value={item.value} key={item.value}>
          <i
            className={classnames('iconfont', item.icon, Style['menu-icon'])}
          />
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const onClickSelectIcon = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isSelected) {
      selectSingle(docInfo.id, false);
      return;
    }
    selectSingle(docInfo.id, true);
  };

  const selectIcon = (
    <img
      alt=""
      onClick={onClickSelectIcon}
      src={
        isSelected
          ? assets.images.doc['icon-choice.png']
          : assets.images.doc['icon-nochoice.png']
      }
      className={classnames(Style['select-icon'], {
        [Style.selected]: selectedIdList.length > 0,
      })}
    />
  );

  return (
    <div className={Style['doc-table-item']} id={`more-${docInfo.id}`}>
      <div className={Style['select-box']}>{selectIcon}</div>
      <div
        ref={docTableItemInfoRef}
        className={classnames(Style['doc-table-item-info'])}
        onMouseUp={e => {
          /** 防止点击 more 以及 popup面板 触发 */
          const isContains = docTableItemInfoRef.current?.contains(
            e.target as Node
          );
          isContains && onMouseUp(e);
        }}
      >
        <div
          className={classnames(
            Style['doc-name'],
            Style['doc-name-column'],
            Style['item-column']
          )}
        >
          <div className={Style['image-box']}>
            {docInfo.imageThumbnailUrl ? (
              <img
                src={docInfo.imageThumbnailUrl}
                className={Style.cover}
                alt=""
              />
            ) : (
              <img
                alt=""
                className={Style['empty-image']}
                src={assets.images.doc['doc_icon_empty.png']}
              />
            )}
          </div>

          <div>
            <div className={Style.name}>{docInfo.production}</div>
            <div className={Style.size}>{bit2Mb(docInfo.size)}</div>
          </div>
        </div>

        <div className={classnames(Style['tag-name'], Style['item-column'])}>
          {docInfo.labelName[0] || '-'}
        </div>
        <div
          className={classnames(
            Style['update-time-sort'],
            Style['item-column']
          )}
        >
          {formatDate(docInfo.updatedTime, {
            toDayLable: '今天',
            yestetdayLable: '昨天',
          })}
        </div>

        <Popup
          clickClosable
          placement="bottomRight"
          overlay={overlay}
          trigger="click"
          container={() =>
            document.querySelector(`#more-${docInfo.id}`) || document.body
          }
        >
          <div
            onMouseUp={e => {
              e.stopPropagation();
            }}
            className={classnames(Style.more)}
          >
            <Tooltips tips="更多操作">
              <img
                alt=""
                className={Style['more-img']}
                src={assets.images.doc['document_icon_more.png']}
              />
            </Tooltips>
          </div>
        </Popup>
      </div>
    </div>
  );
}

export default observer(DocTableItem);
