import { Dialog } from '@bhb-frontend/lithe-ui/lib';

import { douyinAuthUrl, kuaishouAuthUrl } from './const';

import Style from './style.module.less';

enum Platform {
  Douyin = 'douyin',
  Kuaishou = 'kuaishou',
}

interface Props {
  visible: boolean;
  close: () => void;
}

export default function AddAccountDialog({ visible, close }: Props) {
  const handleClick = (type: Platform) => {
    const domain = window.location.href;

    let authUrl = '';

    switch (type) {
      case Platform.Douyin:
        authUrl = douyinAuthUrl;
        break;
      case Platform.Kuaishou:
        authUrl = kuaishouAuthUrl;
        break;
      default:
        authUrl = '';
    }
    window.location.href = `${authUrl}=${domain}`;
  };

  return (
    <Dialog
      visible={visible}
      timeout={0}
      onCancel={close}
      title=""
      width={488}
      height={262}
      closeIcon={
        <i
          style={{ lineHeight: 1, color: '#191919', marginTop: 10 }}
          className="iconfont icon-a-search_icon_delete2x"
        />
      }
      footer={null}
    >
      <div className={Style.header}>
        <div className={Style.headerTitle}>授权账号</div>
        <div className={Style.headerDesc}>请选择视频平台</div>
      </div>
      <div className={Style.accountGroup}>
        <div
          className={`${Style.accountGroupItem} ${Style.douyin} ${Style.disabled}`}
        >
          <i
            className={`iconfont icon-a-tiktok ${Style.accountGroupItemIcon}`}
          />
          <p className={Style.accountGroupItemDesc}>抖音已停止对外发布能力</p>
        </div>
        <div
          className={Style.accountGroupItem}
          onClick={() => handleClick(Platform.Kuaishou)}
        >
          <span className={Style.kuaishouIconWrapper}>
            <i
              className={`iconfont icon-a-kuaishou2x ${Style.accountGroupItemIcon} ${Style.kuaishou}`}
            />
            快手
          </span>
        </div>
      </div>
    </Dialog>
  );
}
