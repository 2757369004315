import style from './style.module.less';
import RobotList from './RobotList/RobotList';

export default function Robot() {
  return (
    <div className={style.container}>
      <RobotList />
    </div>
  );
}
