/* 数据源只修改每个文件对应的 data.ts 即可，添加数据后，执行一下 npm run assets */

export default {
  /* https://bhb-frontend.bhbcdn.com/static/files/33d76ba1dc7c12e31a43f0d265257034.png */
  'doc_icon_empty.png': '33d76ba1dc7c12e31a43f0d265257034.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/10036f0e908352990674a3f135a0be5c.png */
  'document_icon_more.png': '10036f0e908352990674a3f135a0be5c.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/d9ab942eea0ce34c960cdc117fda6ee7.png */
  'icon-choice.png': 'd9ab942eea0ce34c960cdc117fda6ee7.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/4e8902d17f63c0909092d694cbcd882f.png */
  'icon-choice2.png': '4e8902d17f63c0909092d694cbcd882f.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/496d042119d03f99764f3772911e7614.png */
  'icon-nochoice.png': '496d042119d03f99764f3772911e7614.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/690582ac4df5c187e509df2021237337.png */
  'skeleton.png': '690582ac4df5c187e509df2021237337.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/b342e198eb7fb387f3535774f8dd0e9b.png */
  'fabu_icon_fail.png': 'b342e198eb7fb387f3535774f8dd0e9b.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/4ec1c3c71d5795c62183d794e0b97af3.png */
  'fabu_icon_success.png': '4ec1c3c71d5795c62183d794e0b97af3.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/ec8c182f3986c49f8d2d63b72f356242.png */
  'common_icon_close_white.png': 'ec8c182f3986c49f8d2d63b72f356242.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/506185e2b24e1fd8062d12574474914f.png */
  'common_icon_loading2.png': '506185e2b24e1fd8062d12574474914f.png',

  /* https://bhb-frontend.bhbcdn.com/static/files/8576a22c8a6a568cd6627bce0eed18c9.png */
  'common_icon_loading.png': '8576a22c8a6a568cd6627bce0eed18c9.png',
};
