import { IMenuConfig } from '@/types/menu';
/** 海外版路由 */
export const docMenu: IMenuConfig = [
  {
    path: '/doc',
    key: '/doc',
    name: '我的文档',
    iconClass: 'icon-a-tab_icon_doc_def2x',
    activeIconClass: 'icon-a-tab_icon_doc_def2x',
  },
  {
    path: '/doc/videos',
    key: '/doc/videos',
    name: '我的视频',
    iconClass: 'icon-a-tab_icon_video2x',
    activeIconClass: 'icon-a-tab_icon_video2x',
  },
];

export const robotConfig = {
  name: '机器人',
  iconClass: 'icon-a-tab_icon_robot_def2x1',
  activeIconClass: 'icon-a-tab_icon_robot_def2x1',
  path: '/doc/robot',
  key: '/doc/robot',
};
