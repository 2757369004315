/* eslint-disable react/prop-types */
import cls from 'classnames';
import * as React from 'react';
import { formatTime } from '@bhb-frontend/utils/lib/format';
import { Popup, Tooltips, Spin, LoadMore } from '@bhb-frontend/lithe-ui';
import Style from './Goods.module.less';
import { GoodsParams, GoodsResult } from '@/types/robot';
import { getLiveRobotGoodsList } from '@/api/robot';
import Empty from '@/components/EmptyList';
import { LIVE_LIST_OPERATION } from '@/constants/liveStatusEnum';

import Images from '@/assets/images';

const { useEffect, useState, useRef } = React;

const { EDIT, DELETE } = LIVE_LIST_OPERATION;

/** 商品菜单 */
const moreMenuList = [
  {
    key: EDIT,
    text: '编辑',
    icon: 'icon-a-program_icon_edit2x',
  },
  {
    key: DELETE,
    text: '删除商品',
    icon: 'icon-a-document_icon_delete2x',
  },
];

interface GoodsTableProp {
  liveRobotId: string;
  openEditGoods: (info: GoodsResult) => void;
  onDelete: (id: string) => void;
}

const PAGE_SIZE = 20;

type GoodsTableProps = React.FC<GoodsTableProp> & {
  getList: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const GoodsTable: GoodsTableProps = props => {
  const { liveRobotId, onDelete, openEditGoods } = props;
  /** 商品列表 */
  const [goodsList, setGoodsList] = useState<GoodsResult[]>([]);

  /** 是否到最后 */
  const finishedRef = useRef(false);

  /** 列表游标 */
  const listSidRef = useRef('');

  /** 商品加载状态 */
  const [goodsListLoading, setGoodsListLoading] = useState(false);

  /** 时间排序 */
  const [isTimeSort, setIsTimeSort] = useState(false);

  /** 操作回调 */
  const actionHandle = (id: string, key: string, info: GoodsResult) => {
    if (key === DELETE) {
      onDelete(id);
    }

    if (key === EDIT) {
      openEditGoods(info);
    }
  };

  /** 更换时间排序 */
  const changeTimeSort = () => {
    setIsTimeSort(!isTimeSort);
    setGoodsList(goodsList.reverse());
  };

  const overlay = (id: string, goods: GoodsResult) => (
    <div className={Style['more-menu']}>
      {moreMenuList.map(menu => {
        const { key, text, icon } = menu;
        return (
          <div
            className={Style['more-menu-item']}
            key={key}
            onClick={() => {
              actionHandle(id, key, goods);
            }}
          >
            <i className={cls('iconfont', icon, Style.icon)} />
            {text}
          </div>
        );
      })}
    </div>
  );

  /**
   * 获取商品列表
   * isMore
   */
  const getGoodsList = async (isMore = false) => {
    setGoodsListLoading(true);
    const params: GoodsParams = {
      liveRobotId,
      sid: listSidRef.current, // 游标
      pageSize: PAGE_SIZE,
    };
    try {
      const {
        data: { sid, results },
      } = await getLiveRobotGoodsList(params);
      listSidRef.current = sid;
      finishedRef.current = !sid;
      const list = !isMore ? [...results] : [...goodsList, ...results];
      setGoodsList(list);
    } catch (error) {
      console.error('获取商品列表', error);
      finishedRef.current = true;
    } finally {
      setGoodsListLoading(false);
    }
  };

  useEffect(() => {
    GoodsTable.getList = getGoodsList;
    getGoodsList();
  }, []);

  /** 表头 */
  const Header = (
    <div className={Style['table-header']}>
      <div
        className={cls(Style['table-header-goods'], Style['table-header-tr'])}
      >
        商品信息
      </div>
      <div
        className={cls(Style['table-header-price'], Style['table-header-tr'])}
      >
        商品价格
      </div>
      <div
        className={cls(
          Style['table-header-commission'],
          Style['table-header-tr']
        )}
      >
        佣金率
      </div>
      <div
        className={cls(Style['table-header-time'], Style['table-header-tr'])}
        onClick={changeTimeSort}
      >
        <div
          className={cls(Style.wrapper, {
            [Style.isSort]: isTimeSort,
          })}
        >
          <span className={Style.text}>
            添加时间
            <i
              className={cls(
                'icon-dingyue_icon_pull-down iconfont',
                Style.icon
              )}
            />
          </span>
        </div>
      </div>
      <div
        className={cls(Style['table-header-action'], Style['table-header-tr'])}
      >
        操作
      </div>
    </div>
  );

  const loadMore = () => {
    if (finishedRef.current) return;
    getGoodsList(true);
  };

  /** 表格内容 */
  const Content = (
    <div className={Style['table-content']}>
      {goodsList.length > 0 ? (
        <LoadMore
          onReachBottom={loadMore}
          hasMore={!finishedRef.current}
          loading={goodsListLoading}
          className={Style['more-title']}
        >
          {goodsList.map(goods => {
            const {
              imageUrl,
              id,
              name,
              price = '-',
              commissionRate = '-',
              createdAt,
            } = goods;
            return (
              <div className={Style['table-content-wrapper']} key={id}>
                <div
                  className={cls(
                    Style['table-content-goods'],
                    Style['table-content-tr']
                  )}
                >
                  <img src={imageUrl || Images.live.goods_empty} alt={name} />
                  <span className={Style.name}>{name}</span>
                </div>

                <div
                  className={cls(
                    Style['table-content-price'],
                    Style['table-content-tr']
                  )}
                >
                  {price}
                </div>
                <div
                  className={cls(
                    Style['table-content-commission'],
                    Style['table-content-tr']
                  )}
                >
                  {commissionRate}
                </div>
                <div
                  className={cls(
                    Style['table-content-time'],
                    Style['table-content-tr']
                  )}
                >
                  {formatTime(new Date(createdAt), 'yyyy.mm.dd hh:MM:ss')}
                </div>

                <div
                  className={cls(
                    Style['table-content-action'],
                    Style['table-content-tr']
                  )}
                >
                  <Popup
                    clickClosable
                    placement="bottomRight"
                    overlay={<>{overlay(id, goods)}</>}
                    trigger="click"
                  >
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      style={{
                        width: '26px',
                      }}
                    >
                      <Tooltips tips="更多操作">
                        <div className={Style.more}>
                          <img
                            src={Images.doc['document_icon_more.png']}
                            alt=""
                          />
                        </div>
                      </Tooltips>
                    </div>
                  </Popup>
                </div>
              </div>
            );
          })}
        </LoadMore>
      ) : (
        <div
          style={{
            marginTop: '100px',
          }}
        >
          <Empty />
        </div>
      )}
    </div>
  );

  return (
    <div className={Style.table}>
      {Header}
      {goodsListLoading ? (
        <Spin
          spinning={goodsListLoading}
          iconStyle={{
            width: '100%',
            margin: '40px',
          }}
        />
      ) : (
        Content
      )}
    </div>
  );
};

export default GoodsTable;
