import MtvNavbar, { Coin } from '@bhb-frontend/mtv-navbar';
import { observer } from 'mobx-react';
import { useState, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cls from 'classnames';
import { InterUserItems } from '@/types/user';
import { configStore, userStore } from '@/store';

import { RouteModule } from '@/types/menu';
import NavbarRightContentArea from '@/layout/Navbar/NavbarRightContentArea';
import ExchangeCenter from '../ExchangeCenter';
import { TOP_AD_HEIGHT } from '@/constants/Layout';
import { goToHome } from '@/utils/doc';
import Style from './index.module.less';
import UserRightsCard from './UserRightsCard';
import { getCustomDigitalCard } from '@/api/customDigital';

interface Props {
  isHiddenMember: boolean;
  module: RouteModule;
  /** 是否显示邀请有礼 */
  isShowInvite?: boolean;
  /** 左侧搜索框 */
  searchBox?: ReactNode;
  /** 邀请有礼文案，app 显示图片，web 显示文案 */
  inviteText?: string;
  /** 是否显示顶部广告位 */
  showTopAd?: boolean;
}

export default observer((props: Props) => {
  const {
    isHiddenMember,
    module = '',
    isShowInvite = false,
    searchBox,
    inviteText,
    showTopAd = false,
  } = props;
  const navigate = useNavigate();
  /** 兑换中心 */
  const [exchangeVisible, setExchangeVisible] = useState(false);
  const updateUserInfo = (user: unknown) => {
    userStore.setUserInfo(user as InterUserItems);
  };

  /** 数字人卡位 */
  const [cardNumber, setCardNumber] = useState(0);

  /** 退出登录 */
  const loginOut = () => {
    userStore.loginOut();
    // 退出登录，返回官网首页
    navigate('/');
    window.location.reload();
  };

  /** 解除绑定微信,更新用户信息*/
  const requestUserInfo = () => {
    userStore.getUser();
  };

  /** navbar右侧区域 */
  const rightContent = (
    <NavbarRightContentArea
      isHiddenMember={isHiddenMember}
      module={module}
      isShowInvite={isShowInvite}
      inviteText={inviteText}
    />
  );

  /** 用户菜单 */
  const UserMenu = [
    // {
    //   title: '我的权益',
    //   leftIcon: (
    //     <i className="iconfont icon-a-web-PersonalCenter-rightsandinterests" />
    //   ),
    //   rightIcon: (
    //     <div className={Style.custom}>
    //       <span>
    //         {`剩余  ${formatSeconds(
    //           userStore.userInfo.shanjianMemberRights
    //             ?.balanceOralVideoDuration || 0
    //         )}`}
    //       </span>
    //       <i className={cls('iconfont icon-web-4-icon-arrow-R', Style.right)} />
    //     </div>
    //   ),
    //   handle: () => navigate('/my-rights'),
    // },

    {
      title: '我的数字人',
      leftIcon: <i className="iconfont icon-a-3-web-home-picavatar" />,
      rightIcon: (
        <div className={Style.custom}>
          <span>{`共  ${cardNumber} 卡位`}</span>
          <i className={cls('iconfont icon-web-4-icon-arrow-R', Style.right)} />
        </div>
      ),
      handle: () => navigate('/child-custom-digital/list'),
    },
    {
      leftIcon: (
        <i className="iconfont icon-a-web-PersonalCenter-Mycomputingpower" />
      ),
      title: '我的算力',
      rightIcon: (
        <div className={Style.custom}>
          <span>{`剩余  ${userStore.userInfo.coin || 0} 算力`}</span>
          <i className={cls('iconfont icon-web-4-icon-arrow-R', Style.right)} />
        </div>
      ),
      divider: <i className={Style.divider} />,
      handle: () => {
        Coin.show();
      },
    },
    {
      leftIcon: (
        <i className="iconfont icon-a-web-PersonalCenter-Officialwebsite" />
      ),
      title: '前往官网',
      handle: () => goToHome(),
    },
    {
      leftIcon: <i className="iconfont icon-a-web-PersonalCenter-orders" />,
      title: '我的订单',
      handle: () => navigate('/orders'),
    },

    {
      leftIcon: <i className="iconfont icon-a-web-PersonalCenter--1" />,
      title: '兑换中心',
      handle: () => setExchangeVisible(true),
    },
  ];

  const MtvNavbarStyle: React.CSSProperties = {
    padding: '0 40px',
    position: 'fixed',
    top: showTopAd ? TOP_AD_HEIGHT : 0,
    zIndex: 99,
    left: 248,
    background: '#fff',
    fontFamily: 'PingFang SC, PingFangSC-Regular, sans-serif',
  };

  /** 数字人卡位 */
  const getCardNumber = async () => {
    try {
      const {
        data: { total },
      } = await getCustomDigitalCard();
      setCardNumber(total);
    } catch (error) {
      console.error('获取数字人卡位失败', error);
    }
  };

  useEffect(() => {
    if (userStore.isLogin() && userStore.userInfo?.id) {
      getCardNumber();
    }
  }, [userStore.userInfo]);

  return (
    <>
      <MtvNavbar
        style={MtvNavbarStyle}
        rightContentExceptLogoArea={rightContent}
        // memberCenter={member}
        logoShow={false}
        userInfo={userStore.isLogin() ? (userStore.userInfo as any) : {}}
        configApp={configStore.config as any}
        loginOut={loginOut}
        searchLocation={searchBox}
        updateUserInfo={updateUserInfo}
        userMenu={UserMenu}
        requestUserInfo={requestUserInfo}
        userRightsCard={<UserRightsCard />}
        goToBoltCut={() => {
          goToHome();
        }}
        goToGraphicCut={() => {
          navigate('/doc');
        }}
        loginClassName={Style.login}
      />

      <ExchangeCenter
        visible={exchangeVisible}
        onClose={() => setExchangeVisible(false)}
      />
    </>
  );
});
