import { useEffect, useState } from 'react';
import Login from '@bhb-frontend/mtv-login';
import { ShanJianMember } from '@bhb-frontend/mtv-navbar';
import { setCookie } from '@/utils/cookie';
import { getTopAd } from '@/api/config';
import { TopAdItems } from '@/types/config';
import { userStore } from '@/store';
import { getToken } from '@/utils/token';
import Style from './TopAdvansiting.module.less';
import { Protocol, SchemeMap } from '@/constants/UrlScheme';

interface TopAdProps {
  module: string;
  updateHaveAd: (val: boolean) => void;
  closeAdCallback: () => void;
}

function TopAdvansiting(props: TopAdProps) {
  const { module, updateHaveAd, closeAdCallback } = props;
  const [advansiting, setAdvansiting] = useState<TopAdItems>();

  useEffect(() => {
    if (module) {
      getTopAd({ module })
        .then(({ data }) => {
          updateHaveAd(!!data.banner);
          setAdvansiting(data);
        })
        .catch(e => {
          console.error('获取顶部广告失败', e);
        });
    }
  }, [module]);

  const clickAdvansiting = () => {
    if (!advansiting?.redirectUrl) return;

    const urlObj = new URL(advansiting.redirectUrl);
    const queryStr = urlObj.searchParams.get('query');

    /**
     * 处理登录和重定向相关逻辑
     */
    const handleLoginAndRedirect = url => {
      if (!userStore.isLogin()) {
        Login.show?.();
      } else {
        window.open(url, '_blank');
      }
    };

    /**
     * 如果是urlScheme模式则处理应用内部逻辑
     */
    if (
      urlObj.protocol === Protocol &&
      urlObj.pathname.includes(SchemeMap.Member)
    ) {
      if (!userStore.isLogin()) {
        Login.show?.();
      } else {
        ShanJianMember?.show();
      }
      return;
    }

    /**
     * 如需携带token，那必须登录
     */
    if (queryStr === 'token') {
      handleLoginAndRedirect(`${urlObj.origin}?token=${getToken()}`);
      return;
    }

    window.open(advansiting.redirectUrl, '_blank');
  };

  if (!advansiting?.banner) return null;

  return (
    <div className={Style.advansiting}>
      <img
        className={Style.advansitingImage}
        src={advansiting?.banner}
        alt="顶部广告图"
        onClick={clickAdvansiting}
      />
      <i
        className={`${Style.advansitingIcon} icon-a-banner_ad_close2x iconfont`}
        onClick={() => {
          const expiresDate = new Date(
            new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
          );
          setCookie('closeAd', 'true', { expires: expiresDate });
          closeAdCallback();
        }}
      />
    </div>
  );
}

export default TopAdvansiting;
