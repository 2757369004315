import * as React from 'react';
import { store } from '@bhb-frontend/store';
import { LoadMore, Toast } from '@bhb-frontend/lithe-ui';

import { observer } from 'mobx-react';
import { useLayoutEffect } from 'react';
import { useGetState } from '@bhb-frontend/hooks/lib';
import { ShanJianMember } from '@bhb-frontend/mtv-navbar/lib/index';
import { SOCKET_EVENTS, GLOBAL_EVENTS, VIDEO_STATUS } from '@/constants/videos';
import { userStore } from '@/store/user';
import { appeal, getAllVideoList, deleteVideoList } from '@/api/videos';
import { VideoItem as IVideoItem, SocketVideoItem } from '@/types/videos';
import socket from '@/utils/socket';
// import ConvertVideoDialog from '@/components/ConvertVideoDialog';

import PreviewModal from './PreviewModal';
import PublishModal from './PublishModal';
import VideoItem from './VideoItem';
import TipDialog from './TipDialog';
import DeleteModal from './DeleteModal';
import Styles from './index.module.less';
import { ListQuery, SocketResponse } from '@/types/api';
import EmptyList from '@/components/EmptyList';
import {
  dataTransform,
  listStatusTransform,
} from '@/helpers/videoListDataTransform';
import { videoListCancel } from '@/helpers/videoListCancel';
import VideoTypeEnum from '@/constants/videos/VideoTypeEnum';
import { DOWNLOAD_TIP_KEY } from '@/constants/StorageKey';

const { useEffect, useState, useRef } = React;
const {
  CREATE_VIDEO_SUCCESS,
  CREATE_VIDEO_FAILED,
  VIDEO_STATUS_CHANGE,
  VIDEO_PUBLISH_RESULT,
  PIC_SPEAKS_VCR_VIDEO_RESULT,
  INTELLIGENT_VIDEO_RESULT,
  LINK_TO_VIDEO_RESULT,
  FIGURE_LIP_SYNC_VIDEO_RESULT,
} = SOCKET_EVENTS;

/** 视频列表需要监听的所有socket 事件 */
const SOCKET_EVENTS_LIST = [
  /** 图文生成成功 */
  CREATE_VIDEO_SUCCESS,
  /** 图文生成失败 */
  CREATE_VIDEO_FAILED,
  /** 图文其他状态变更 */
  VIDEO_STATUS_CHANGE,
  /** 图文视频发布结果 */
  VIDEO_PUBLISH_RESULT,
  /** 照片说话视频生成结果 */
  PIC_SPEAKS_VCR_VIDEO_RESULT,
  /** 智能成片生成结果 */
  INTELLIGENT_VIDEO_RESULT,
  /** 链接成片生成结果 */
  LINK_TO_VIDEO_RESULT,
  /** 视频数字人 */
  FIGURE_LIP_SYNC_VIDEO_RESULT,
];

interface VideoListProps {
  isHomeStyle?: boolean;
  /** 筛选类型 */
  type?: VideoTypeEnum;
}

function VideoList(props: VideoListProps) {
  const { isHomeStyle, type } = props;
  /* 视频列表 */
  const [videoList, setVideoList, getList] = useGetState<IVideoItem[]>([]);
  /* 用户点击某个视频存储起来的item */
  const info = useRef<IVideoItem>();
  /* 预览当前点击视频 */
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  /* 加载过程中的动画 */
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  /* 内容审核不通过 */
  const [visible, setVisible] = useState(false);
  /* 内容审核不通过文案 */
  const [tipContent, setTipContent] = useState('');
  /* 审核弹窗 */
  const [appealVisible, setAppealVisible] = useState(false);
  // /* 判断加载弹窗是否加载 */
  // const [judgeConvertVideoDialog, setJudgeConvertVideoDialog] = useState(false);

  /* 当前列表使用到的分页信息 */
  const listQuery = useRef<
    ListQuery & {
      type?: VideoTypeEnum;
    }
  >({
    sid: '',
    pageSize: 20,
  });

  /* 删除视频 - 弹窗 */
  const [deleteModal, setDeleteModal] = useState(false);

  /** 监听执行触发回调 */
  const listenVideoCallBack = async (res: SocketResponse<SocketVideoItem>) => {
    const { event, data } = res;
    if (!SOCKET_EVENTS_LIST.includes(event)) return;
    /** 视频发布结果不做处理 */
    if (event === VIDEO_PUBLISH_RESULT) {
      if (data.code === 1201) {
        localStorage.removeItem('oauth2-access-token');
      }
      store.set(GLOBAL_EVENTS.PUBLISH_VIDEO, GLOBAL_EVENTS.PUBLISH_VIDEO, data);
      return;
    }
    const newData = await dataTransform(event, data);
    const target = videoList.find(
      (v: IVideoItem) => v.originalId === newData.originalId
    );
    if (!target) {
      return;
    }
    Object.assign(target, newData);
    setVideoList([...videoList]);
    userStore.getUser();
  };

  /* 获取视频列表 */
  const getVideoList = async () => {
    try {
      setLoading(true);
      const params = {
        ...listQuery.current,
      };
      if (type) {
        params.type = type;
      }
      const { data } = await getAllVideoList(params);
      listQuery.current.sid = data.sid;
      setHasMore(!!data.sid);
      return listStatusTransform(data.results);
    } catch (err) {
      console.error('获取视频列表失败', err);
      setHasMore(false);
      return [];
    } finally {
      setLoading(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    listQuery.current = {
      ...listQuery.current,
      sid: '',
    };

    const list = await getVideoList();
    setVideoList([...list]);
  };

  /** 加载更多 */
  const loadMore = async () => {
    const list = await getVideoList();
    setVideoList([...getList(), ...list]);
  };

  /* 关闭预览 */
  const close = () => {
    setPreviewVisible(false);
  };

  /* 删除 */
  const remove = (item?: IVideoItem) => {
    setDeleteModal(true);
    info.current = item;
  };

  const deleteConfirm = async (id: string) => {
    try {
      await deleteVideoList([id]);
      Toast.success('删除成功');
      const newList = videoList.filter(item => item.id !== id);
      setVideoList([...newList]);
    } catch (error) {
      console.error('删除失败', error);
    }
  };

  /* 下载 */
  const download = (item?: IVideoItem) => {
    const el = document.createElement('a');
    document.body.append(el);
    if (item) {
      el.href = item.videoUrl;
      el.download = item.videoName;
    } else {
      el.href = info.current?.videoUrl as string;
      el.download = info.current?.videoName as string;
    }
    el.rel = 'noopener noreferrer';
    el.click();
    document.body.removeChild(el);
  };

  /** 下载视频前置判断 */
  const beforeDownload = (item?: IVideoItem) => {
    /**
     * 判断当天是否出现过
     */
    const val = localStorage.getItem(DOWNLOAD_TIP_KEY);
    const isMember = !!userStore.userInfo.shanjianMemberRights?.isMember;
    /**
     * 直接关闭的条件
     * 是会员
     * 免费用户，但当天已经触发过了
     */
    if (isMember || (val && new Date().toLocaleDateString() === val)) {
      download(item);
    } else {
      ShanJianMember?.show({
        onClose: () => {
          download(item);
        },
        onPaySuccessCb: () => {
          download(item);
        },
      });
      localStorage.setItem(DOWNLOAD_TIP_KEY, new Date().toLocaleDateString());
    }
  };

  /**
   * 取消视频生成，也是直接删掉视频
   */
  const cancel = async (detail: IVideoItem) => {
    try {
      const res = await videoListCancel(detail);
      if (!res) {
        console.error('没有找到相对应类型的取消函数');
        return;
      }
      setVideoList(videoList.filter(item => item.id !== detail.id));
    } catch (err) {
      console.error('取消视频生成失败', err);
    }
  };

  /** 内容审核不通过详情 */
  const handleRejectInfo = (info: IVideoItem) => {
    setVisible(true);
    function getRejectContent() {
      if (!Array.isArray(info?.rejectContent)) return '';
      const objText = {
        text: '正文',
        picture: '图片',
        video: '视频',
        audio: '录音音频',
      };
      return info?.rejectContent.map(item => objText[item]).join('、');
    }
    setTipContent(
      `文档模块中${getRejectContent()}存在违规内容。请先清除违规内容后重试。`
    );
  };

  const cancelTip = () => {
    setVisible(false);
  };

  // const clipEditorFN = (data: IVideoItem) => {
  //   if (data.id && data.status === VIDEO_STATUS.COMPLETED) {
  //     (
  //       ConvertVideoDialog as unknown as React.FC<object> & {
  //         showFN: (info: IVideoItem) => void;
  //       }
  //     ).showFN(data);
  //   }
  // };

  // const judgeConvertVideoDialogFN = () => {
  //   if (userStore.userInfo.socketLink) {
  //     setJudgeConvertVideoDialog(true);
  //   }
  // };

  /** 操作申诉 */
  const handleAppeal = (curInfo: IVideoItem) => {
    info.current = curInfo;
    setAppealVisible(true);
  };

  /** 确认申诉 */
  const handleAppealOk = async () => {
    try {
      const res = await appeal(info.current?.originalId);
      if (res.error === 0) {
        Toast.success('操作成功');
      }
    } catch (error) {
      console.error('申诉失败', error);
    } finally {
      setAppealVisible(false);
    }
  };

  useLayoutEffect(() => {
    initList();
  }, []);

  useEffect(() => {
    if (userStore.userInfo.socketLink) {
      socket.on(listenVideoCallBack);
    }
    return () => {
      socket.off(listenVideoCallBack);
    };
  }, [userStore.userInfo.socketLink, videoList]);

  // useEffect(() => {
  //   judgeConvertVideoDialogFN();
  // }, [userStore.userInfo.socketLink]);

  return (
    <>
      <div className={Styles.videos}>
        <div className={Styles.content}>
          {videoList.length === 0 && !loading ? (
            <div className={`${Styles.empty} empty`}>
              <EmptyList />
            </div>
          ) : (
            <LoadMore
              onReachBottom={loadMore}
              className={Styles.loadMore}
              loading={loading}
              hasMore={hasMore}
              finishedText=""
            >
              {videoList.map(item => (
                <VideoItem
                  isHomeStyle={isHomeStyle}
                  info={item}
                  key={item.id}
                  // clipEditorFN={clipEditorFN}
                  handleAppeal={handleAppeal}
                  publish={() => {
                    (
                      PublishModal as unknown as React.FC<
                        Record<string, any>
                      > & {
                        show: (info: IVideoItem) => void;
                      }
                    ).show(item);
                  }}
                  preview={() => {
                    info.current = item;
                    if (
                      [VIDEO_STATUS.QUEUEING, VIDEO_STATUS.PENDING].includes(
                        item.status
                      )
                    ) {
                      return;
                    }
                    setPreviewVisible(true);
                  }}
                  download={() => {
                    info.current = item;
                    beforeDownload(item);
                  }}
                  remove={() => {
                    info.current = item;
                    remove(item);
                  }}
                  cancel={cancel}
                  handleRejectInfo={() => {
                    handleRejectInfo(item);
                  }}
                />
              ))}
            </LoadMore>
          )}
        </div>
      </div>
      <PublishModal />
      <PreviewModal
        isPreview={isPreviewVisible}
        info={info.current as IVideoItem}
        controls
        autoplay
        publish={() => {
          setPreviewVisible(false);
          (
            PublishModal as unknown as React.FC<Record<string, any>> & {
              show: (info: IVideoItem) => void;
            }
          ).show(info.current as IVideoItem);
        }}
        download={() => {
          download();
        }}
        remove={() => {
          remove(info.current);
        }}
        close={close}
      />
      <TipDialog
        visible={visible}
        option={{
          title: '内容审核不通过',
          content: tipContent,
          okText: '好的',
        }}
        onCancel={cancelTip}
        onOk={cancelTip}
        width={335}
      />
      <DeleteModal
        visible={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={() => {
          deleteConfirm(info.current?.id as string);
        }}
      />
      {/* 二次编辑弹窗 */}
      {/* {judgeConvertVideoDialog && <ConvertVideoDialog />} */}

      <TipDialog
        visible={appealVisible}
        option={{
          title: '审核申述结果',
          content:
            '对于审核结果不满意，是否发起申诉？将会在1个工作日内处理完成，人工在线审核时间：工作日 9:00～18:30',
          okText: '申诉',
          cancelText: '取消',
        }}
        onCancel={() => {
          setAppealVisible(false);
        }}
        onOk={handleAppealOk}
        width={335}
      />
    </>
  );
}

export default observer(VideoList);
