import * as React from 'react';
import Styles from './Editor.module.less';
import Projects from './Projects';

function Editor() {
  return (
    <div className={Styles.Editor}>
      <Projects />
    </div>
  );
}

export default Editor;
