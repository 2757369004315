import Routes from '@/config/Routes';
import { IMenuConfig } from '@/types/menu';
/** 基础路由 */
export const baseMenu: IMenuConfig = [
  {
    ...Routes.Home,
    name: '首页',
    iconClass: 'icon-a-3-web-home',
  },
  {
    ...Routes.Root,
    name: '模板市场',
    iconClass: 'icon-a-3-web-home-template',
  },
  {
    ...Routes.Product,
    name: '我的作品',
    iconClass: 'icon-a-3-web-home-mine',
  },
  {
    ...Routes.CustomDigitalList,
    name: '我的数字人',
    iconClass: 'icon-a-3-web-home-customavatar-fil',
  },
];
