/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import jsxCustomEvent from '@micro-zoe/micro-app/polyfill/jsx-custom-event';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Login from '@bhb-frontend/mtv-login/lib/login';
import { APP_INFO, APP_NAME, getAppInfo } from '@/constants/Apps';
import { configStore, userStore, customDigitalStore } from '@/store';
import { EVENT_NAME } from '@/constants/event-name';
import { goToDoc } from '@/utils/doc';
import { notEmptyObj } from '@/utils/notEmptyObj';
import { showLoginAndRemoveToken } from '@/utils/login';
import { createNewDoc } from '@/api/myDoc';

const url = getAppInfo(APP_NAME.customDigital).host;

function CustomDigital() {
  const { config } = configStore;
  const { userInfo } = userStore;

  const navigate = useNavigate();

  const createDoc = async (id: string) => {
    try {
      const { data } = await createNewDoc({ title: '' });
      goToDoc(`/?id=${data.documentId}&customDigitalId=${id}`);
    } catch (error) {
      console.error('创建文档失败：', error);
    }
  };

  const handleDataChange = (e: CustomEvent) => {
    const { type, customDigitalId, retrainingType } = e.detail.data;

    if (type === EVENT_NAME.CREATE_DOC) {
      createDoc(customDigitalId);
    }

    if (type === EVENT_NAME.RETRAINING) {
      navigate(
        `/child-custom-digital/create?customDigitalId=${customDigitalId}&retraining=${retrainingType}`
      );
    }

    if (type === EVENT_NAME.JUMP_CREATE_ROUTER) {
      navigate(
        `/child-custom-digital/create?customDigitalId=${customDigitalId}`
      );
    }
  };

  const isLogin = () => {
    if (!userStore.isLogin()) {
      Login.show?.();
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!userStore.isLogin()) {
      Login.show?.();
    }
  }, []);

  return (
    <div style={{ paddingTop: 18 }}>
      {notEmptyObj(config) && notEmptyObj(customDigitalStore.config) && (
        <micro-app
          name={APP_INFO.CUSTOM_DIGITAL.name}
          url={url}
          data={{
            config,
            userInfo,
            getUser: () => userStore.getUser(),
            isLogin,
            customDigitalconfig: customDigitalStore.config,
            showLoginAndRemoveToken,
          }}
          onDataChange={handleDataChange}
        />
      )}
    </div>
  );
}

export default observer(CustomDigital);
