import { Modal, Input } from '@bhb-frontend/lithe-ui';
import { useState, useEffect, useCallback } from 'react';
import debounce from '@bhb-frontend/utils/lib/debounce';
import Style from './RobotAuth.module.less';
import { verifyToken } from '@/api/robot';
import { ListItem } from '@/types/robot';
import { goToSubscription } from '@/utils/doc';

interface RobotAuthProps {
  visible: boolean;
  modalOpenCloseFN: () => void;
  onAuth: (token: string) => void;
}

enum TYPE {
  VALID = 1,
  INVALID = 2,
  DEFAULT = 3,
}

function RobotAuth(props: RobotAuthProps) {
  const { visible, modalOpenCloseFN, onAuth } = props;

  const [status, setStatus] = useState(TYPE.DEFAULT);

  const [token, setToken] = useState('');

  const [error, setError] = useState('');

  const [value, setValue] = useState('');

  const [subscriptionInfo, setSubscriptionInfo] = useState<ListItem>();

  useEffect(() => {
    if (visible) return;
    setStatus(TYPE.DEFAULT);
    setToken('');
    setError('');
    setValue('');
    setSubscriptionInfo(undefined);
  }, [visible]);

  /** 获取授权码 */
  const goSubscription = () => {
    goToSubscription('/');
  };

  /** 验证授权订阅号 */
  const verify = useCallback(
    debounce(e => {
      const token = e.target.value;
      if (!token) return;
      verifyToken(token)
        .then(({ data: { result } }) => {
          setStatus(TYPE.VALID);
          setSubscriptionInfo(result);
          setToken(token);
        })
        .catch((e: any) => {
          setError(e.message);
          setStatus(TYPE.INVALID);
        });
    }, 1000),
    []
  );

  /** 授权 */
  const confirm = () => {
    onAuth(token);
  };

  return (
    <Modal
      width={738}
      height="auto"
      visible={visible}
      className={Style.auth}
      onClose={modalOpenCloseFN}
      closable={false}
      timeout={0}
    >
      <div className={Style.authHeader}>
        <div className={Style.authTitle}>输入授权号</div>
        <div className={Style.authDisplay}>
          <button className={Style.authHeaderButton} onClick={goSubscription}>
            获取授权码
          </button>
          <i
            className={`iconfont icon-a-search_icon_delete2x ${Style.authClose}`}
            onClick={modalOpenCloseFN}
          />
        </div>
      </div>
      <div className={Style.authBody}>
        <Input.TextArea
          rows={4}
          placeholder="请输入授权号"
          className={Style.authInput}
          onInput={verify}
          value={value}
          onChange={val => setValue(val.target.value)}
        />
      </div>
      <div className={Style.authFooter}>
        <div className={Style.authDetail}>
          {status === TYPE.VALID && (
            <div>
              <img src={subscriptionInfo?.logoUrl} alt="订阅号logo" />
              <span>{subscriptionInfo?.name}</span>
            </div>
          )}
          {status === TYPE.INVALID && <p>{error}</p>}
        </div>
        <button
          className={`${Style.authButton} ${
            status !== TYPE.VALID ? Style.authButtonDisabled : ''
          }`}
          disabled={status !== TYPE.VALID}
          onClick={confirm}
        >
          关联订阅号
        </button>
      </div>
    </Modal>
  );
}

export default RobotAuth;
