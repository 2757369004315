import { Percent } from '@/constants/myDoc';

// 分页查询参数
export interface SearchQuery {
  pageSize: number; // 每页大小
  sid: string; // 分页标志
}
// 推荐主题详情
export interface IntroThemes {
  id: string;
  coverUrl: string;
}
// 机器人列表
export interface RobotItem {
  accessUsers: unknown[]; // [授权账号]
  documentCount: number; // 文档数
  dailyContentCount: number; // 每日推送数量
  name: string; // 机器人名字
  id: string; // 机器人 id
  introThemeIds?: string[]; // 推荐主题 ids
  introThemes: IntroThemes[]; // 推荐主题
}

// 机器人授权号列表
export interface AuthItem {
  id: string;
  name: string; // 订阅号名字
  isShow: boolean;
}

export enum ReleaseType {
  //  发布类型;normal-常规 timely-及时发布
  normal = 'normal',
  timely = 'timely',
}

// 机器人文档列表
export interface DocItem {
  id: string; // 文档 id
  title: string; // 标题
  size: number; // 大小
  createdAt: string; // 创建时间
  musicInfo: {
    name: string; // 音乐名字
  };
  labelName: string[]; // 标题
  imageThumbnailUrl: string; // 缩略图地址
  isShow: boolean; // 是否上架
  releaseType: ReleaseType;
  isBlank: false;
  production: string;
  showText: string;
}

// 机器人文档列表查询参数
export interface DocSearchQuery extends SearchQuery {
  labelId?: string; // 标签 id
  isShow?: boolean; // 是否上下架
  orderBy?: number; // 创建时间排序
}

interface IntroVideoInfo {
  introVideoKey?: string;
  width?: number; // 素材宽
  height?: number; // 素材高
  duration?: number; // 视频时长
  introVideoUrl?: string;
  videoCoverUrl?: string;
}

// 订阅号列表项
export interface ListItem {
  totalRevenue: number; // 0
  isFeed: boolean; // 是否免费
  userId: string; // 用户 id
  name: string; // 订阅号名字
  brief: string; // 描述
  goodsId: string; // 商品 id
  isShow: boolean; // 是否上架
  createdAt: Date;
  updatedAt: Date;
  logoUrl: string; // 订阅号logo
  introImageUrl: string; // 长图介绍
  introVideoInfo: IntroVideoInfo; // 视频介绍
  id: string;
  type: 'video' | 'live';
}

export interface ThemeCategory {
  id: string; // 分类id
  name: string; // 分类名
}

export interface ThemeCollapseList {
  coverConvertUrl?: string;
  coverKey?: string;
  coverUrl: string;
  id: string;
  name?: string;
  percen?: Percent;
  preVideoUrl?: string;
  percent?: Percent;
  previewCoverUrl?: string; // 预览图
}

/** 机器人管理商品参数 */
export interface GoodsParams {
  /* 机器人id */
  liveRobotId: string;
  sid?: string; // 游标
  pageSize: number;
}

/** 机器人管理商品回调 */
export interface GoodsResult {
  id: string;
  /* 达人id */
  liveRobotId?: string;
  /* 平台 douyi 默认 */
  platform: string;
  /* 名称 必填 */
  name: string;
  /* 链接 必填 */
  link: string;
  /* 标签 */
  tags: string[];
  /* 商品图片 */
  imageUrl: string;
  imageKey: string;
  /* 商品价格：非必填，单位分 */
  price?: number;
  /* 商品佣金率：非必填，整数型（0 */
  commissionRate?: number;
  /** 创建时间 */
  createdAt: string;
}
/* 商品信息 选填 默认[] */
export interface LiveRobotGoodsInfo {
  /* 平台 douyi 默认 */
  platform: string;
  /* 名称 必填 */
  name: string;
  /* 链接 必填 */
  link: string;

  /* 商品图片 */
  imageUrl: string;
  /* 商品价格：非必填，单位分 */
  price?: number;
  /* 商品佣金率：非必填，整数型（0 */
  commissionRate?: number;
  /** 创建时间 */
  createdAt: string;
  /* 标签 选填 */
  tags: string[];
  /* id */
  id: string;
}

export interface LiveAddRobot {
  /* 机器人名称 */
  name?: string;
  goodsInfo?: LiveRobotGoodsInfo[];
  /* 标签 */
  tags?: string[];
}

export interface LiveRobotDetail extends LiveAddRobot {
  /* 机器人id */
  id: string;
  /* 用户id */
  userId: string;
  /* 封面 */
  coverUrl: {
    type: string;
    default: boolean;
  };
  /* 订阅号授权token */
  subscribeTokens: { type: []; default: [] };
  /* 是否标记为删除 */
  isDeleted: {
    type: boolean;
    default: boolean;
  };
  createdAt: Date;
  /* 订阅号名字列表 */
  accessUsers: string[];
  /* 商品列表 */
  goodsInfo: LiveRobotGoodsInfo[];
  /** 直播数 */
  count: number;
}

export interface RobotPublishVideo {
  type?: string;
  /* 商品标题 */
  title?: string;
  goodsInfo?: GoodsResult[];
}
