import cookie from 'js-cookie';
import { ENV } from '@/utils';

/**
 * 固定字符串型的api列表；
 */
type StringObject = Record<string, string>;

// 获取当前domain
export function getDomain() {
  // 根据环境配置cookie设置域名
  const domainMap: StringObject = {
    dev: '',
    test: 'test.bhbapp.cn',
    gray: 'gray.bhbapp.cn',
    prod: 'shanjian.tv',
    i18nTest: 'test.bhbapp.cn', // 测试环境
    i18nGray: 'gray.boltcut.ai', // 预发布环境
    i18nProd: 'boltcut.ai', // 正式环境
  };
  return domainMap[ENV];
}

const cookieSetOption = {
  path: '/',
  domain: getDomain(),
};

/* 一年时间 */
export const expiresYear = 1000 * 60 * 60 * 24 * 365;

/* 一年到期时间 */
export const expiresDate = new Date(
  new Date(new Date().toLocaleDateString()).getTime() + expiresYear
);

// 获取cookie信息
export function getCookie(name: string) {
  return cookie.get(name);
}

// 设置cookie信息
export function setCookie(name: string, value: string, params = {}) {
  cookie.set(name, value, {
    ...cookieSetOption,
    ...params,
  });
}

// 移除cookie信息
export function removeCookie(name: string) {
  cookie.remove(name, cookieSetOption);
}
