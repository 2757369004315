import EditorPojectItemAction from '@/types/editorPojectItemAction';
import { MoreMenuList } from '@/types/editorMoreMenuList';

/** 元素操作列表 */
const moreMenuListConst: MoreMenuList.MoreMenuListConst = {
  [EditorPojectItemAction.edit]: {
    icon: 'icon-a-program_icon_edit2x',
    label: '编辑',
    value: EditorPojectItemAction.edit,
  },
  [EditorPojectItemAction.rename]: {
    icon: 'icon-a-document_icon_mingming2x',
    label: '重命名',
    value: EditorPojectItemAction.rename,
  },
  [EditorPojectItemAction.remove]: {
    icon: 'icon-a-pop_icon_delete2x',
    label: '删除',
    value: EditorPojectItemAction.remove,
  },
  [EditorPojectItemAction.retry]: {
    icon: 'icon-a-common_icon_retry1x',
    label: '重试',
    value: EditorPojectItemAction.retry,
  },
};

export default moreMenuListConst;
