export enum GoodsModuleEnum {
  Video = 'video',
  Live = 'live',
  Subscribe = 'subscribe',
  RedeemCode = 'redeemCode',
  FigureLive = 'figureLive',
  PicSpeaks = 'picSpeaks',
  Mktg = 'mktg',
  AiLive = 'aiLive',
  Common = 'common',
  Custom = 'custom',
  Shanjian = 'shanjian',
}

export const GoodsModuleMap = {
  [GoodsModuleEnum.Video]: '图文快剪',
  [GoodsModuleEnum.Live]: '直播快剪',
  [GoodsModuleEnum.Subscribe]: '订阅号',
  [GoodsModuleEnum.RedeemCode]: '兑换码',
  [GoodsModuleEnum.FigureLive]: '数字人直播系统',
  [GoodsModuleEnum.PicSpeaks]: '照片数字人',
  [GoodsModuleEnum.Mktg]: '团队带货',
  [GoodsModuleEnum.AiLive]: '智播',
  [GoodsModuleEnum.Common]: '算力',
  [GoodsModuleEnum.Custom]: '定制服务',
  [GoodsModuleEnum.Shanjian]: '闪剪会员',
};
