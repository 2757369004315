import { action, makeAutoObservable, toJS } from 'mobx';
import { getConfigs } from '@/api/config';
import { ConfigApp } from '@/types/config';

class Confiure {
  private CONFIG: Partial<ConfigApp> = {};

  constructor() {
    makeAutoObservable(this);
  }

  get config() {
    return toJS(this.CONFIG);
  }

  /**
   * 获取分类列表
   */
  @action
  public getConfigs = async () => {
    try {
      const { data } = await getConfigs();
      this.CONFIG = data;
    } catch (error) {
      console.error(
        '获取配置信息失败,',
        `错误路径：${window?.location?.pathname},`,
        error
      );
    }
  };
}

export const configStore = new Confiure();
