/** 文档大小单位转换 */
export function bit2Mb(size = 0) {
  if (size / 1024 < 100) {
    return `${Number(size / 1024).toFixed(2)}KB`;
  }
  return `${Number(size / 1024 / 1024).toFixed(2)}M`;
}

/** 格式化日期 今天 11:40 */
export function formatDate(
  time: number | string,
  label: { toDayLable: string; yestetdayLable: string }
) {
  const diff = new Date(time).getTime() - new Date().setHours(0, 0, 0, 0);
  let day = '';
  if (diff >= 0) {
    day = label.toDayLable;
  } else if (diff >= -24 * 60 * 60 * 1000) {
    day = label.yestetdayLable;
  } else {
    const t = new Date(time);
    const m = t.getMonth() + 1;
    const d = t.getDate();
    return `${t.getFullYear()}-${m > 9 ? m : `0${m}`}-${d > 9 ? d : `0${d}`}`;
  }
  const hour = new Date(time).getHours();
  const minutes = new Date(time).getMinutes();
  return `${day} ${hour}:${minutes < 10 ? `0${minutes}` : minutes}`;
}

// 解析url
export function parseUrl(url: string): any {
  const a = document.createElement('a');
  a.href = url;
  return {
    source: url,
    protocol: a.protocol.replace(':', ''),
    host: a.hostname,
    port: a.port,
    query: a.search,
    params: (function () {
      const ret = {};
      const seg = a.search.replace(/^\?/, '').split('&');
      const len = seg.length;
      let i = 0;
      let s;
      // eslint-disable-next-line no-plusplus
      for (; i < len; i++) {
        if (!seg[i]) {
          continue;
        }
        s = seg[i].split('=');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore：无法被执行的代码的错误
        ret[s[0]] = s[1];
      }
      return ret;
    })(),
    file: (a.pathname.match(/\/([^/?#]+)$/i) || [null, ''])[1],
    hash: a.hash.replace('#', ''),
    path: a.pathname.replace(/^([^/])/, '/$1'),
    relative: (a.href.match(/tps?:\/\/[^/]+(.+)/) || [null, ''])[1],
    segments: a.pathname.replace(/^\//, '').split('/'),
    hashQuery: (function () {
      const ret = {};
      let tempQuery = '';
      if (a.hash.indexOf('?') !== -1) {
        tempQuery = a.hash.split('?')[1];
      } else {
        return;
      }
      const seg = tempQuery.split('&');
      const len = seg.length;
      let i = 0;
      let s;
      // eslint-disable-next-line no-plusplus
      for (; i < len; i++) {
        if (!seg[i]) {
          continue;
        }
        s = seg[i].split('=');
        ret[s[0]] = decodeURIComponent(s[1]);
      }
      // eslint-disable-next-line consistent-return
      return ret;
    })(),
  };
}

/** 传入一个秒，当大于一分钟时，返回整除分钟，不取秒，当不足一分钟时，返回多少秒 */
export function formatSeconds(seconds: number) {
  if (seconds < 60) {
    return `${seconds} 秒`;
  }
  const minute = Math.floor(seconds / 60);
  return `${minute} 分钟`;
}
