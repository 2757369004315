/* eslint-disable @typescript-eslint/no-namespace */
import { ListQuery } from './api';

/** 元素操作类型 */
export enum VideoStatus {
  succeed = 'succeed' /** 成功 */,
  pending = 'pending' /** 生成中 */,
  failed = 'failed' /** 生成失败 */,
}

/** 元素操作类型 */
export enum VideoAction {
  delete = 'delete' /** 删除元素 */,
  preview = 'preview' /** 预览视频弹窗 */,
}

export declare namespace EditorProjectsApiModal {
  interface PListQuery extends ListQuery {}

  interface RgetEditorVideosAPI extends ListQuery {
    /** 搜索关键字，如输入标签id，或 文档标题 */
    keyword?: string;
  }

  interface DgetEditorVideosAPI {
    /** 视频id */
    id: string;
    /** 时长 */
    duration: number;
    /** 创建时间 */
    createdAt: string;
    /** 封面url */
    imageUrl: string;
    /** 转换状态 */
    status: VideoStatus;
    /** 名称 */
    name: string;
    /** 项目id */
    projectId: string;
    /** url地址 */
    renderVideoUrl: string;
    /** 用户id */
    userId: string;
    width: number;
    height: number;
  }

  interface DdeleteEditorVideosAPIi {
    id: string;
  }
}
