import request from '@/api/init';
import { DetailResponse, CustomListResponse } from '@/types/api';
import { EditorProjectsApiModal as Emodal } from '@/types/editorVideo';

/**
 * 获取视频列表
 */
export function getEditorVideosAPI(
  params: Emodal.RgetEditorVideosAPI
): Promise<CustomListResponse<Emodal.DgetEditorVideosAPI>> {
  return request.get('/editor/videos', params);
}

/**
 * 删除项目
 */
export function deleteEditorVideosAPI(
  id: string
): Promise<DetailResponse<Emodal.DdeleteEditorVideosAPIi>> {
  return request.delete(`/editor/videos/${id}`);
}
