import { TagItem } from '@/types/myDoc';

export enum MoreMenusType {
  addTab = 'addTab',
  rename = 'rename',
  remove = 'remove',
  addRobot = 'addRobot',
  copy = 'copy',
}

export type Tag = Pick<TagItem, 'name' | 'id'>;
