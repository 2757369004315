import { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react';
import { LoadMore, Button, Toast, Spin } from '@bhb-frontend/lithe-ui';
import { store } from '@bhb-frontend/store';
import { observer } from 'mobx-react';
import Style from './MyDoc.module.less';
import DocTableHead from './DocTableHead';
import DocTableItem from './DocTableItem';
import RemoveDoc from './RemoveDocModal';
import RenameDoc from './RenameDocModal';
import AddTag from './AddTagModal';
import AddToRobotModal from './AddToRobotModal';
import CustomToast from './CustomToast';
import {
  getDocList,
  createNewDoc,
  removeDoc,
  renameDoc,
  getTags,
  addTag,
  setTag,
  editTag,
  removeTag,
  removeDocs,
  copyDoc,
} from '@/api/myDoc';
import { ListQuery } from '@/types/api';
import { Ordering, DocItem, SearchType } from '@/types/myDoc';
import { MoreMenusType, Tag } from './types';
import { MY_DOC_SEARCH_KEY, MY_DOC_SEARCH_NAMESPACE } from '@/constants/myDoc';
import { userStore } from '@/store';
import EmptyList from '@/components/EmptyList';
import { goToDoc } from '@/utils/doc';
import socket from '@/utils/socket';
import { SocketEvent } from '@/constants/socketEvents';

// allTag 信息
const allTagInfo = {
  name: '全部标签',
  id: '',
};

function MyDoc() {
  const [ordering, setOrdering] = useState<Ordering>(Ordering.descending);
  const [docList, setDocList] = useState<DocItem[]>([]);
  const [tagList, setTagList] = useState<Tag[]>([]);
  const [selectedIdList, setSelectedIdList] = useState<string[]>([]);
  const [operationDocsInfo, setOperationDocsInfo] = useState<DocItem>();

  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [addTagModalVisible, setAddTagModalVisible] = useState(false);
  const [addToRobotModalVisible, setAddToRobotModalVisible] = useState(false);

  /* 创建副本 Toast队列 */
  const [toastIdList, setToastIdList] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isBatch, setIsBatch] = useState(false);

  const [selectedTag, setSelectedTag] = useState<Tag>(allTagInfo);
  const [searchInputValue, setSearchInputValue] = useState('');

  const listQuery = useRef<ListQuery>({
    sid: '',
    pageSize: 10,
  });

  const subscribeAuth = useMemo(
    () => userStore.subscribeAuthList.includes('video'),
    [userStore.subscribeAuthList]
  );

  const refreshListBySearch = ({ newValue }) => {
    setSearchInputValue(newValue);
  };

  useEffect(() => {
    store.listen(
      MY_DOC_SEARCH_NAMESPACE,
      MY_DOC_SEARCH_KEY,
      refreshListBySearch
    );

    return () => {
      store.unlisten(
        MY_DOC_SEARCH_NAMESPACE,
        MY_DOC_SEARCH_KEY,
        refreshListBySearch
      );
    };
  }, []);

  // =============== 获取数据 ================
  const getList = async () => {
    try {
      setLoading(true);
      const params = {
        ...listQuery.current,
        orderBy: ordering,
      };
      /** 添加tag搜索条件 */
      if (selectedTag.id) {
        Object.assign(params, {
          keyword: selectedTag.id,
          searchType: SearchType.label,
        });
      }
      /** 添加Input搜索条件 ，可以覆盖tag 条件*/
      if (searchInputValue) {
        Object.assign(params, {
          keyword: searchInputValue,
          searchType: SearchType.production,
        });
      }

      const { data } = await getDocList(params);
      listQuery.current = {
        ...listQuery.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (err) {
      console.error('获取列表失败', err);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    listQuery.current = {
      ...listQuery.current,
      sid: '',
    };
    const data = await getList();
    data && setDocList(data.results);
  };

  const listenCopyDoc = res => {
    const { event, data } = res;
    if (event === SocketEvent.DOCUMENT_COPY_RESULT) {
      /* 0成功 非0失败 */
      setToastIdList(state => state.filter(id => id !== data.taskId));

      if (data.code === 0) {
        initList();
        Toast.success('复制成功');
      } else {
        Toast.error('复制失败');
      }
    }
  };

  useEffect(() => {
    if (userStore.userInfo.socketLink) {
      socket.on(listenCopyDoc);
    }
    return () => {
      socket.off(listenCopyDoc);
    };
  }, [userStore.userInfo.socketLink]);

  /** 加载更多 */
  const loadMore = async () => {
    const data = await getList();
    data && setDocList([...docList, ...data.results]);
  };

  /** 通过tag获取列表 */
  const getListByTag = (tag: Tag) => {
    setSelectedTag(tag);
  };

  useLayoutEffect(() => {
    initList();
  }, [selectedTag, ordering, searchInputValue]);

  /** 获取tags */
  const getTagList = async () => {
    const { data } = await getTags();
    setTagList(data.results);
  };

  useEffect(() => {
    getTagList();
  }, []);

  /** 排序 */
  const onSort = () => {
    const order =
      ordering === Ordering.ascending
        ? Ordering.descending
        : Ordering.ascending;
    setOrdering(order);
  };

  const findItemIndex = id => docList.findIndex(item => id === item.id);

  // ================= 操作 =================
  /** 点击文档进行跳转 */
  const jumpDoc = (id: string) => {
    goToDoc(`/?id=${id}&back=doc`);
  };

  /** 创建文档 */
  const createDoc = async () => {
    try {
      const { data } = await createNewDoc({ title: '' });
      jumpDoc(data.documentId);
    } catch (err) {
      console.error('创建文档失败', err);
    }
  };

  /** 全选 */
  const selectAll = (isSelect: boolean) => {
    if (!isSelect) {
      setSelectedIdList([]);
      return;
    }
    setSelectedIdList(docList.map(({ id }) => id));
  };

  /** 单选 */
  const selectSingle = (id: string, isSelect: boolean) => {
    if (isSelect) {
      setSelectedIdList([...selectedIdList, id]);
      return;
    }
    setSelectedIdList(selectedIdList.filter(item => item !== id));
  };

  /** 单个删除 */
  const singleRemove = async () => {
    if (!operationDocsInfo) return;
    const { error } = await removeDoc(operationDocsInfo.id);
    if (error === 0) {
      const index = findItemIndex(operationDocsInfo.id);
      if (index === -1) return;
      const newList = [...docList];
      newList.splice(index, 1);
      setDocList(newList);
      Toast.success('删除成功');
    }
  };

  /** 批量删除 */
  const batchRemove = async () => {
    await removeDocs(selectedIdList);
    setIsBatch(false);
    initList();
    setSelectedIdList([]);
    Toast.success('删除成功');
  };

  /** 删除文档 */
  const onRemove = () => {
    if (isBatch) {
      batchRemove();
      return;
    }
    singleRemove();
  };

  /** 重命名 */
  const onRename = async () => {
    if (!operationDocsInfo) return;
    const production = operationDocsInfo.production || '未命名文档';
    await renameDoc(operationDocsInfo.id, production);
    initList();
    // if (error === 0) {
    //   const index = findItemIndex(operationDocsInfo.id);
    //   if (index === -1) return;
    //   const newList = [...docList];
    //   newList[index].production = operationDocsInfo.production;
    //   setDocList(newList);
    // }
  };

  /** 设置 tag */
  const onSetTag = async (tagIds: string[]) => {
    if (!operationDocsInfo) return;
    const newTagIds = tagIds.filter(tag => !!tag);
    await setTag(operationDocsInfo.id, newTagIds);
    initList();
    Toast.success('标签设置成功！');
  };

  /** 编辑 tag */
  const onEditTag = async (id: string, name: string) => {
    await editTag(id, name);
    getTagList();
    initList();
  };

  /** 删除 tag */
  const onRemoveTag = async (id: string) => {
    await removeTag(id);
    getTagList();
    initList();
  };

  /** 添加 tag */
  const onAddTag = async (name: string) => {
    if (!operationDocsInfo) return;
    const { data } = await addTag(name);
    await onSetTag([data.id]);
    await getTagList();
    setOperationDocsInfo({ ...operationDocsInfo, labelName: [name] });
    initList();
  };

  /** 添加到机器人成功 */
  const addToRobotSuccess = () => {
    setAddToRobotModalVisible(false);
    isBatch && setSelectedIdList([]);
  };

  /* 创建文档副本 */
  const createDocCopy = async (id: string) => {
    try {
      const { data } = await copyDoc(id);
      if (data.taskId) {
        setToastIdList(state => [...state, data.taskId]);
      }
    } catch (err) {
      console.error('创建文档副本失败：', err);
    }
  };

  const onOperation = (type, docInfo) => {
    setOperationDocsInfo(docInfo);
    switch (type) {
      case MoreMenusType.addTab: {
        setAddTagModalVisible(true);
        break;
      }
      case MoreMenusType.remove: {
        setRemoveModalVisible(true);
        break;
      }
      case MoreMenusType.rename: {
        setRenameModalVisible(true);
        break;
      }
      case MoreMenusType.addRobot: {
        setAddToRobotModalVisible(true);
        break;
      }
      case MoreMenusType.copy: {
        createDocCopy(docInfo.id);
        break;
      }
      default:
        break;
    }
  };
  /* 关闭Toast */
  const closeToast = (id: string) => {
    setToastIdList(state => state.filter(item => id !== item));
  };
  return (
    <div className={Style['doc-container']}>
      <div className={Style.header}>
        <div className={Style.title}>我的文档</div>
        <div className={Style['header-right']}>
          {selectedIdList.length > 0 ? (
            <>
              <Button
                onClick={() => {
                  selectAll(false);
                }}
                size="large"
                type="default"
              >
                取消
              </Button>

              {subscribeAuth && (
                <Button
                  style={{
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    setIsBatch(true);
                    setAddToRobotModalVisible(true);
                  }}
                  size="large"
                  type="default"
                >
                  添加至机器人
                </Button>
              )}

              <Button
                onClick={() => {
                  setIsBatch(true);
                  setRemoveModalVisible(true);
                }}
                size="large"
                type="default"
                style={{
                  marginLeft: 10,
                }}
              >
                删除
              </Button>
            </>
          ) : (
            <div className="button-primary" onClick={createDoc}>
              新建文档
            </div>
          )}
        </div>
      </div>

      <div className={Style['doc-table']}>
        <DocTableHead
          selectedIdList={selectedIdList}
          isSelectedAll={selectedIdList.length === docList.length}
          onSort={onSort}
          tagList={tagList}
          getListByTag={getListByTag}
          selectAll={selectAll}
          selectedTag={selectedTag}
          allTagInfo={allTagInfo}
        />

        <div className={Style['doc-table-body']}>
          {!docList.length && !loading ? (
            <EmptyList text="暂时没有文档" style={{ marginTop: 250 }} />
          ) : (
            <Spin
              spinning={loading}
              style={{
                width: '100%',
                minHeight: '300px',
                height: '100%',
              }}
            >
              <LoadMore
                onReachBottom={loadMore}
                className={Style['load-more-wrapper']}
                loading={loading}
                hasMore={hasMore}
                finishedText={null}
              >
                {docList.map(item => (
                  <DocTableItem
                    selectedIdList={selectedIdList}
                    selectSingle={selectSingle}
                    onMouseUp={() => jumpDoc(item.id)}
                    onOperation={onOperation}
                    docInfo={item}
                    key={item.id}
                  />
                ))}
              </LoadMore>
            </Spin>
          )}
        </div>
      </div>

      <RemoveDoc
        visible={removeModalVisible}
        onClose={() => {
          setRemoveModalVisible(false);
        }}
        onConfirm={onRemove}
      />

      <RenameDoc
        value={operationDocsInfo?.production || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          operationDocsInfo &&
            setOperationDocsInfo({
              ...operationDocsInfo,
              production: e.target.value,
            });
        }}
        visible={renameModalVisible}
        onClose={() => {
          setRenameModalVisible(false);
        }}
        onConfirm={onRename}
      />

      <AddTag
        productionName={operationDocsInfo?.production || ''}
        visible={addTagModalVisible}
        onClose={() => {
          setAddTagModalVisible(false);
        }}
        onSetTag={onSetTag}
        onAddTag={onAddTag}
        onEditTag={onEditTag}
        onRemoveTag={onRemoveTag}
        tagList={tagList}
        labelName={operationDocsInfo?.labelName?.[0] ?? ''}
      />

      {subscribeAuth && (
        <AddToRobotModal
          selectedDocIds={
            isBatch ? selectedIdList : [operationDocsInfo?.id || '']
          }
          visible={addToRobotModalVisible}
          onClose={() => setAddToRobotModalVisible(false)}
          addRobotSuccess={addToRobotSuccess}
        />
      )}

      <CustomToast toastIdList={toastIdList} closeToast={closeToast} />
    </div>
  );
}

export default observer(MyDoc);
