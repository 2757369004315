import { userStore } from '@/store';
import DocVideoList from '@/components/VideoList';
import NotLogin from '../Home/NotLogin';
import { useStickyTop } from '@/hooks';
import Style from './style.module.less';

function withContent(
  WrappedComponent: (props: Record<string, unknown>) => JSX.Element
) {
  return (
    <div className={Style.content}>
      <WrappedComponent />
    </div>
  );
}

function MyVideo() {
  const { topDistance } = useStickyTop();

  return (
    <>
      <h2
        className={Style.title}
        style={{
          position: 'sticky',
          top: topDistance,
          background: '#fff',
          zIndex: 66,
        }}
      >
        我的作品
      </h2>
      <div className={Style.tab}>
        {userStore.isLogin() ? (
          withContent(DocVideoList)
        ) : (
          <NotLogin classNames={Style['not-login']} />
        )}
      </div>
    </>
  );
}
export default MyVideo;
