/** 公共前缀，用于区分项目缓存 */
const CachePrefix = 'BHB:MTV:WORKBENCH';

/** 我的文档搜索命名空间 */
export const MY_DOC_SEARCH_NAMESPACE = `${CachePrefix}:MYDOC:NAMESPACE`;

/** 我的文档搜索key */
export const MY_DOC_SEARCH_KEY = `${CachePrefix}:MYDOC:SEARCH`;

export enum Percent {
  // 9and16表示9:16的，16and9表示16:9的  默认9and16
  NINETOSIXTEEN = '9and16',
  SIXTEENTONINE = '16and9',
}

/**
 * 模板的模块常量，即用于判断取的是哪个模块的模板信息
 */
export const TEMPLATE_MODULE = {
  /**
   * 图文快剪
   */
  VIDEO: 'theme',
  /**
   * 订阅号/机器人
   */
  ROBOT: 'subscribeTheme',
};
