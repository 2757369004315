import { observer } from 'mobx-react';
import Template from '@/components/Template';

function TemplateIndex() {
  return (
    <div style={{ minWidth: 1050 }}>
      <Template />
    </div>
  );
}
export default observer(TemplateIndex);
