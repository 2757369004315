enum VideoTypeEnum {
  /** 图文快剪 */
  VIDEO = 'video',
  /** 照片说话 */
  PIC_SPEAK = 'picSpeaks',
  /** 智能成片 */
  INTELLIGENT_VIDEO = 'intelligentVideo',
  /** 模板 */
  TEMPLATE = 'template',
  /** 定制数字人样片 */
  CUSTOM_FIGURE_SAMPLE = 'customFigureSample',
  /** 口播照片数字人 */
  LIP_SYNC_PHOTO_FIGURE = 'lipSyncPhotoFigure',
  /** 口播视频数字人 */
  LIP_SYNC_VIDEO_FIGURE = 'lipSyncVideoFigure',
  /** 链接成片 */
  LINK_TO_VIDEO = 'linkToVideo',
}
export default VideoTypeEnum;
