/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * 右侧菜单
 */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { IMenuConfig, RouteModule } from '@/types/menu';
import Logo from '@/components/Logo';
import Navbar from '../Navbar';
import TopAdvansiting from '@/components/TopAdvansiting';
import { getCookie } from '@/utils/cookie';
import { TOP_AD_HEIGHT, NAVBAR_HEIGHT } from '@/constants/Layout';

import assets from '@/assets';
import Routes from '@/config/Routes';
import Styles from './Menu.module.less';

const SHOW_AD_PAGES = [
  Routes.Root.path,
  Routes.Home.path,
  Routes.Product.path,
  Routes.VideoAccount.path,
  Routes.Orders.path,
  Routes.CustomDigitalList.path,
  Routes.MyRights.path,
];

/** 前往备案 */
const enterBeiAn = () => {
  window.open('https://beian.miit.gov.cn/', '_blank');
};

interface IProps {
  menuConfig: IMenuConfig;
  navbarHeight?: number;
  menuWidth?: number;
  /** 是否隐藏菜单自定义按钮 */
  isHidden?: boolean;
  /** 是否隐藏会员中心 */
  isHiddenMember?: boolean;

  /** 页面模块
   * 根据模块来展示会员中心
   */
  module?: RouteModule;
  /** 是否显示邀请有礼 */
  isShowInvite?: boolean;
  /** 左侧侧边栏标题 */
  title?: string;
  /** 左侧搜索栏 */
  searchBox?: React.ReactNode;
  /** 内容className */
  contentClassName?: string;
  /** 邀请有礼文案，app 显示图片，web 显示文案 */
  inviteText?: string;

  /** 是否显示备案号 */
  isShowBeiAn?: boolean;
}

export default function Menu(props: IProps) {
  const {
    menuConfig,

    navbarHeight = NAVBAR_HEIGHT,
    menuWidth = 248,
    isHidden = true,
    isHiddenMember = false,
    module = '',
    isShowInvite = false,
    title,
    searchBox,
    contentClassName,
    inviteText,
    isShowBeiAn = true,
  } = props;

  const location = useLocation();

  const [routeShowTopAd, setShowTopAd] = useState(true);
  const [haveAd, setHaveAd] = useState(false);

  const showTopAd = routeShowTopAd && haveAd;

  const paddingStyle = {
    paddingLeft: menuWidth,
    paddingTop: showTopAd ? navbarHeight + TOP_AD_HEIGHT : navbarHeight,
  };

  useEffect(() => {
    const isShow =
      SHOW_AD_PAGES.includes(location.pathname) && !getCookie('closeAd');
    setShowTopAd(isShow);
  }, [location]);

  const navigate = useNavigate();

  const toDoc = () => {
    navigate('/doc');
  };

  const cutButton = () => (
    <button className={Styles.sidebarButton} onClick={toDoc}>
      <img
        src={assets.images.common['home_icon_Graphic Cut.png']}
        className={Styles.sidebarButtonIcon}
        alt="口播视频"
      />
      <span>Graphic Cut</span>
    </button>
  );

  return (
    <>
      {routeShowTopAd && (
        <TopAdvansiting
          module="web"
          updateHaveAd={setHaveAd}
          closeAdCallback={() => {
            setShowTopAd(false);
          }}
        />
      )}

      <div
        className={Styles.sidebar}
        style={{ paddingTop: showTopAd ? TOP_AD_HEIGHT : 0 }}
      >
        <Logo title={title} />
        {isHidden ? null : cutButton()}
        <ul className={Styles.sidebarWrapper}>
          {menuConfig.map(item => (
            <li
              key={item.key}
              className={`${Styles.sidebarItem} ${
                location.pathname === item.path ? Styles.sidebarItemActive : ''
              }`}
            >
              <Link to={item.path}>
                <i
                  className={`${Styles.sidebarItemIcon} iconfont ${item.iconClass}`}
                />
                {item.name}
              </Link>
            </li>
          ))}
        </ul>

        {isShowBeiAn && (
          <div className={Styles.beian} onClick={enterBeiAn}>
            粤ICP备11082172号
          </div>
        )}
      </div>

      <div className={Styles.content} style={paddingStyle}>
        <Navbar
          showTopAd={showTopAd}
          isHiddenMember={isHiddenMember}
          module={module}
          isShowInvite={isShowInvite}
          searchBox={searchBox}
          inviteText={inviteText}
        />
        <div className={contentClassName}>
          <Outlet context={{ showTopAd }} />
        </div>
      </div>
    </>
  );
}

/** outlet 上下文 */
export function outletContext() {
  return useOutletContext<{ showTopAd: boolean }>();
}
