import * as React from 'react';
import {
  Modal,
  Button,
  Toast,
  Checkbox,
  LoadMore,
} from '@bhb-frontend/lithe-ui';
import { store } from '@bhb-frontend/store/lib/store';
import cs from 'classnames';

import { GLOBAL_EVENTS, PublishStatusEnum } from '@/constants/videos';
import assets from '@/assets';
import { getAccountList, publishVideo } from '@/api/videos';
import { PublishVideoBody, VideoItem } from '@/types/videos';
import { configStore, userStore } from '@/store';

import PublishMessage from '../PublishMessage';
import PublishBrief from './PublishBrief';
import Styles from './index.module.less';
import AddAccountDialog from '@/components/AddAccountDialog';
import { PlatformEnum, ThirdAccountItem } from '@/types/third-account';

const { useState, useEffect, useMemo, useRef } = React;

type TPublishModal = React.FC<Record<string, any>> & {
  show?: (info: VideoItem) => void;
};

interface CheckedStatus {
  checked?: boolean;
}

let PublishModal: TPublishModal | null = null;

function BaseModal() {
  // const cookieYoutubeToken = getCookie('youtubeToken') || '';
  /* 显隐发布信息 */
  const [showMessage, setShowMessage] = useState(false);
  /* 用户主动触发关闭弹窗，则不需要接收发布状态了。 */
  const [isShowMessage, setIsShowMessage] = useState(true);
  /* 发布须知是否勾选 */
  const [isKnow, setKnow] = useState(false);
  /* 发布状态 */
  const [publishStatus, setPublishStatus] = useState(
    PublishStatusEnum.PUBLISHLOADING
  );
  const [thirdAccounts, setThirdAccounts] = useState<
    (ThirdAccountItem & CheckedStatus)[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  /* 发布描述 */
  const [videoDsc, setVideoDsc] = useState('');

  /* 当前modal框的显隐 */
  const [visible, setVisible] = useState(false);
  /** 添加账号的 dialog */
  const [addAccountVisible, setAddAccountVisible] = useState(false);
  /* 当前视频信息 */
  const info = useRef<Partial<VideoItem>>({});
  /* 是否播放 */
  const [isPlay, setPlay] = useState(false);
  /* 左侧视频播放 */
  const videoRef = useRef<HTMLVideoElement>();

  /* 发布须知地址 */
  const publishMustKnow = useMemo(
    () => configStore.config.publishMustKnow,
    [configStore.config.publishMustKnow]
  );
  const params = useRef({
    sid: '',
    pageSize: 8,
  });

  const listenPublish = (res: any) => {
    if (!isShowMessage) {
      return;
    }

    setShowMessage(true);
    setPublishStatus(res.newValue.status);
  };

  /* 点击播放 */
  const handlePlay = () => {
    if (isPlay) {
      videoRef.current?.pause && videoRef.current.pause?.();
      setPlay(false);
    } else {
      videoRef.current?.play && videoRef.current.play?.();
      setPlay(true);
    }
  };

  const cancel = () => {
    setVisible(false);
  };

  // 重置表单状态
  const resetForm = () => {
    setVideoDsc('');
    setThirdAccounts([]);
    setKnow(false);
  };

  const isValid = () => {
    if (!videoDsc.trim()) {
      Toast.warning('作品描述为空');
      return false;
    }
    const ids = thirdAccounts.filter(item => item.checked);
    if (!ids.length) {
      Toast.warning('请选择发布账号');
      return false;
    }
    if (!isKnow) {
      Toast.warning('请同意发布须知');
      return false;
    }
    return true;
  };

  const publish = () => {
    // 重新点击发布，被记作为重新触发发布任务
    setIsShowMessage(true);
    if (!isValid()) return;
    const obj: PublishVideoBody = {
      fileUrl: info.current?.videoUrl as string,
      coverUrl: info.current?.coverUrl as string,
      brief: videoDsc, // 视频描述
      thirdAccountIds: thirdAccounts
        .filter(item => item.checked)
        .map(item => item.id),
    };
    publishVideo(obj).then(() => {
      setVisible(false);
      resetForm();
      setShowMessage(true);
      setPublishStatus(PublishStatusEnum.PUBLISHLOADING);
    });
  };

  const toUserAgreement = () => {
    window.open(publishMustKnow);
  };

  const closeMessage = () => {
    setIsShowMessage(false);
    setShowMessage(false);
  };

  const selectPlatform = (item: ThirdAccountItem & CheckedStatus) => {
    if (item.isExpired) return;
    item.checked = !item.checked;
    setThirdAccounts([...thirdAccounts]);
  };

  // 获取第三方平台账号
  const getThirdAccount = async () => {
    try {
      setLoading(true);
      const { data } = await getAccountList({
        ...params.current,
      });
      params.current = {
        ...params.current,
        sid: data.sid,
      };
      setHasMore(!!data.sid);
      return data;
    } catch (error) {
      console.warn(error);
      setHasMore(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // 滚动加载
  /** 加载更多 */
  const loadMore = async () => {
    const data = await getThirdAccount();
    if (data) {
      setThirdAccounts([...thirdAccounts, ...data.results]);
    }
  };

  /** 初始化列表 */
  const initList = async () => {
    params.current = { ...params.current, sid: '' };
    const data = await getThirdAccount();
    if (data) {
      setThirdAccounts(data.results);
    }
  };

  useEffect(() => {
    store.listen(
      GLOBAL_EVENTS.PUBLISH_VIDEO,
      GLOBAL_EVENTS.PUBLISH_VIDEO,
      listenPublish
    );
    return () => {
      store.unlisten(
        GLOBAL_EVENTS.PUBLISH_VIDEO,
        GLOBAL_EVENTS.PUBLISH_VIDEO,
        listenPublish
      );
    };
  }, [userStore.userInfo.socketLink]);

  useEffect(() => {
    if (visible) {
      initList();
    }
  }, [visible]);

  useEffect(() => {
    if (PublishModal) {
      PublishModal.show = (detail: VideoItem) => {
        info.current = detail;
        setVideoDsc(detail.videoName);
        setVisible(true);
      };
    }
    initList();
  }, []);

  return (
    <>
      <Modal
        width={975}
        height="auto"
        visible={visible}
        onClose={cancel}
        timeout={0}
      >
        <div className={Styles.modal}>
          <div className={Styles.title}>
            <span>发布视频</span>
          </div>
          <div className={Styles.publish}>
            {/* 视频组件 */}
            <div className={Styles.player} onClick={handlePlay}>
              <video
                ref={videoRef as React.LegacyRef<HTMLVideoElement>}
                src={info.current?.videoUrl}
                poster={info.current?.coverUrl}
                className={Styles.video}
                webkit-playsinline="true"
                playsInline
                preload="true"
              />
              {!isPlay && (
                <img
                  className={Styles.icon}
                  src={assets.images.common['common_icon_play@2x.svg']}
                  alt=""
                />
              )}
            </div>
            {/* 发布设置 */}
            <div className={Styles.setting}>
              <div className={Styles.inner}>
                {/* 选择发布账号 */}
                <div className={Styles.subtitle}>选择发布帐号</div>
                <LoadMore
                  className={Styles.platforms}
                  onReachBottom={loadMore}
                  loading={loading}
                  hasMore={hasMore}
                  finishedText=""
                >
                  <div
                    className={Styles.addButton}
                    onClick={() => setAddAccountVisible(true)}
                  >
                    <i
                      className={`iconfont icon-a-doc_icon_add2x ${Styles.addButtonIcon}`}
                    />
                    添加账号
                  </div>
                  {thirdAccounts.map(item => (
                    <div
                      className={cs(Styles.platform, {
                        [Styles.activity]: item.checked,
                        [Styles.expried]: item.isExpired,
                      })}
                      key={item.id}
                      onClick={() => selectPlatform(item)}
                    >
                      <img src={item.avatar} className={Styles.avatar} alt="" />
                      <div className={Styles.userInfoBox}>
                        <p className={Styles.userInfoName}>
                          <span>{item.name}</span>
                          <i
                            className={`iconfont ${
                              item.platform === PlatformEnum.DOU_YIN
                                ? `icon-a-tiktok ${Styles.userInfoTiktok}`
                                : `icon-a-kuaishou ${Styles.userInfoKuaishou}`
                            }`}
                          />
                        </p>
                      </div>
                      <img
                        style={{ display: item.checked ? '' : 'none' }}
                        src={
                          assets.images.videos['issue_icon_pitch on @2x.png']
                        }
                        alt=""
                      />
                    </div>
                  ))}
                </LoadMore>
                {/* 分割线 */}
                <hr className={Styles.publishLine} />
                {/* 视频描述 */}
                <div className={Styles.subtitle}>视频描述</div>
                <PublishBrief
                  maxLength={55}
                  onChange={setVideoDsc}
                  defaultValue={info.current.videoName || ''}
                />
              </div>
              {/* 底部按钮 */}
              <div className={Styles.bottom}>
                <div className={Styles.wrapper}>
                  <Button className={Styles.gray} onClick={cancel}>
                    取消
                  </Button>
                  <Button className={Styles.primary} onClick={publish}>
                    确认
                  </Button>
                </div>
                <div className={Styles.wrapper}>
                  <Checkbox
                    className={Styles.checkbox}
                    value={isKnow as any}
                    onChange={(_, value: boolean) => {
                      setKnow(value);
                    }}
                  />
                  <span>我已知晓</span>
                  <div className={Styles.link} onClick={toUserAgreement}>
                    《 <span>发布须知</span> 》
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <PublishMessage
        status={publishStatus}
        visible={showMessage}
        close={closeMessage}
      />
      <AddAccountDialog
        visible={addAccountVisible}
        close={() => setAddAccountVisible(false)}
      />
    </>
  );
}

PublishModal = BaseModal as TPublishModal;

export default BaseModal;
