import { Input } from '@bhb-frontend/lithe-ui';
import { ModalProps } from '@bhb-frontend/lithe-ui/lib/Modal';
import ModalWrapper from '../ModalWrapper';

interface RenameDocProps extends ModalProps {
  /** 名称内容 */
  value: string;
  /** Madol关闭事件 */
  onClose: () => void;
  /** Madol确认事件 */
  onConfirm: () => void;
  /** input change事件 */
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export default function RenameDoc(props: RenameDocProps) {
  const { value, onChange, ...otherProps } = props;

  return (
    <ModalWrapper {...otherProps} title="输入文档名称" width={448} height={228}>
      <Input
        placeholder="0-30个字符"
        maxLength={30}
        onChange={onChange}
        value={value}
      />
    </ModalWrapper>
  );
}
