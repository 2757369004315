import { ProductEnum } from '@/constants/ProductEnum';
import { ListQuery } from './api';
import EditorTransitionStatus from '@/types/editorTransitionStatus';

export enum Ordering {
  /** 升序 */
  ascending = 1,
  /** 降序 */
  descending = -1,
}

export enum SearchType {
  /** 标签 */
  label = 'label',
  /** 标题 */
  title = 'title',
  /** 产品名称 */
  production = 'production',
}

/** 视频详情 */
interface VideoInfo {
  id: string; // 视频id
  videoUrl: string; // 视频地址
  resolvingPower: number; // 分辨率
  width: string; // 宽
  height: string; // 高
}

/** 通用分类结构体 */
export interface TypeItem {
  id: string; // 分类id
  name: string; // 分类名称
}

/** 获取文档参数 */
export interface GetDocParmas extends ListQuery {
  /** 根据最后更新时间排序  1表示升序， -1表示降序 */
  orderBy: Ordering;
  /**  搜索类型，如label表示标签，title表示标题 */
  searchType?: SearchType;
  /** 搜索关键字，如输入标签id，或 文档标题 */
  keyword?: string;
}

/** 获取直播列表参数 */
export interface GetLiveParmas extends ListQuery {
  /** 搜索关键字 */
  keyword?: string;
}

/** 获取视频编辑项目列表参数 */
export interface GetVideoProjectParmas extends ListQuery {
  name?: string;
}

/** 文档列表项 */
export interface DocDetail {
  /** 文档id */
  id: string;
  /** 已经生成的视频id */
  videoId: string;
  video: VideoInfo;
  /** 缩略图 */
  imageThumbnailUrl: string;
  /** 标题 */
  title: string;
  /** 截取过的内容 */
  showText: string;
  /** 标签名 */
  labelName: string[];
  /** 表示视频生成状态 init-未生成, pending-生成中, completed-已生成，fail-生成失败 */
  status: 'init' | 'pending' | 'completed' | 'fail';
  /** 最后更新时间 */
  updatedTime: string;
  /** 文档大小 字节 */
  size: number;
  /** 是否空白文档 */
  isBlank: boolean;
  /**  private-默认私有  onlyRead-公开只读 */
  permission: 'private' | 'onlyRead';
  /** 文档名字 */
  production: string;
}

/** 直播间状态 */
export enum LIVE_ROOM_STATUS {
  /** 创建中 */
  INIT = 'init',
  /** 直播中/录制中 */
  RUNNING = 'running',
  /** 已结束 */
  FINISHED = 'finished',
  /** 直播中断 */
  DISCONNECT = 'disconnect',
  /** 已过期 */
  EXPIRED = 'expired',
  /** 已终止 */
  TERMINATED = 'terminated',
}

/** 录播状态类型 */
export type recordStatusType = 'start' | 'stop';

/** 音乐来源 */
export type MusicSource = 'network' | 'material';

/**
 * 快捷设置详情
 */
export interface LiveConfig {
  // 时长配置
  // generateDuration: LiveGenerateDuration;
  // 主题
  renderThemeId?: string;
  // 标题
  renderShortVideoName?: string;
  // 描述
  publishBrief?: string;
}

export interface MyMusicItem {
  type?: string; // 素材类型 ["audio" - 音频]
  materialUrl: string; // 素材url地址
  createdAt?: string; // 创建时间
}

export interface OriginInfo {
  author: {
    name: string; // 昵称
    avatar: string; // 头像
    brief: string; // 简介
  };
  title?: string;
}

/**
 * 背景音乐数据结构
 */
export interface MusicInfo extends MyMusicItem {
  musicUrl: string; // 音乐地址
  id?: string; // 音乐id，如果本地上传音乐，为空
  duration: number; // 音乐时长
  source?: MusicSource; // 音乐来源 network-网络音乐(后台音乐) material-素材音乐(用户自己上传)
  coverUrl?: string; // 封面地址
  name?: string; // 音乐名称
  artist?: string; // 作者 （可选）
  fileSize?: number; // 文件大小，单位 字节（可选）
  categoryId: string; // 音乐分类id (适用网络音乐,随机音乐)
  categoryName: string; // 音乐分类名称(适用网络音乐)
  volume: number; // 音量 0-100
}

/** 直播平台 */
export enum LivePlatform {
  douyin = 'douyin',
  kuaishou = 'kuaishou',
}
/**
 * 直播列表项
 */
export interface LiveDetail {
  id: string;
  // 直播间标题
  title: string;
  // 第三方直播平台
  platform: LivePlatform;
  // 源站拉流地址
  sourcePullUrl: string;
  // 录播地址
  recordUrl: string;
  // 直播状态
  status: LIVE_ROOM_STATUS;
  // 直播创建时间
  createdAt: string;
  // 直播总时长
  duration: number;
  // 录播时长
  recordDuration: number;
  // 录播状态
  recordStatus: recordStatusType;
  // 快捷配置
  quickConfig: LiveConfig;
  // 封面图片
  coverUrl: string;
  // 主题
  themeInfo?: {
    id: string; // 主题id
    title: string; // 主题标题
    // jsonText: NewThemeDetail; // 主题信息
  };
  musicInfo?: MusicInfo;
  transcribeDuration?: number; // 自定义直播时长(单位:h)
  isSample?: boolean;
  originInfo: OriginInfo; // 主播详情
  tags: TypeItem[];
  keywords: string[]; // 关键字
  sliceLabels: TypeItem[]; // 直播切片标签列表
  cutSlice: boolean; // 是否有直播切片
  sliceVideoDuration: {
    // v1.12.0 新增 直播切片时长
    min: number;
    max: number;
  };

  // 直播机器人 - 生成直播切片时长限制
  robotVideoDuration?: {
    min: number;
    max: number;
  };
}

/**
 * 视频编辑器列表项
 *  */
export interface VideoProjectDetail {
  coverUrl: string;
  createdAt: string;
  id: string;
  name: string;
  size: string;
  duration: string;
  updatedAt: string;
  userId: string;
  projectStatus: EditorTransitionStatus /** 剪辑函数-jianji */;
  loadingProgress: null | number;
  videoId?: string;
}

export interface GetAgentAccessTokenParams {
  /**
   * videoMainPage-图文快剪
   * liveMainPage-直播快剪
   * homePage- 闪剪工具页
   * myPage- 推广中心（该值web端没有入口）
   * AApplyForm - 分销申请服务商页面
   */
  page: 'videoMainPage' | 'liveMainPage' | 'homePage' | 'AApplyForm' | 'P-PC';
}

export interface GetAgentAccessTokenReturns {
  linkUrl: string;
}

/** 产品 */
export interface ProductItem {
  /** 产品名称 */
  name: string;
  /** 描述 */
  desc: string;
  /** 图标 */
  iconfont: string;
  /** 跳转的路径 */
  path: string;
  /** 产品标识 */
  key: ProductEnum;
  /** 展示 主要提示 新功能Icon */
  showNew?: boolean;
  /** 状态类型 */
  jumpType?: '_self' | '_blank';
}
