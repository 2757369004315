import { useState, useEffect } from 'react';
import { Modal, Button } from '@bhb-frontend/lithe-ui';
import assets from '@/assets';
import { upgradeMember } from '@/api/member';
import { getToken } from '@/utils/token';

import Style from './MemberUpgradeModal.module.less';

const memberList = [
  { lable: '口播时长', icon: assets.images.global['koubo.png'] },
  { lable: '文案创作', icon: assets.images.global['ai_text.png'] },
  { lable: '算力', icon: assets.images.global['coin.png'] },
];

type MemberUpgradeModalType = React.FC & {
  show?: () => void;
};

const MemberUpgradeModal: MemberUpgradeModalType = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const [detailsUrl, setDetailsUrl] = useState('');

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  const upgradeMemberHandle = async () => {
    const { data } = await upgradeMember();
    setDetailsUrl(data.url);
  };

  const viewDetails = () => {
    if (detailsUrl) {
      window.open(`${detailsUrl}?token=${getToken()}`);
      hide();
    }
  };

  useEffect(() => {
    MemberUpgradeModal.show = show;
    return () => {
      MemberUpgradeModal.show = show;
    };
  }, []);

  useEffect(() => {
    visible && upgradeMemberHandle();
  }, [visible]);

  return (
    <Modal
      visible={visible}
      className={Style.member_upgrade_modal}
      width={303}
      height={307}
      closable={false}
    >
      <div className={Style.member_upgrade_modal_content}>
        <p className={Style.title}>闪剪会员升级通知</p>
        <p className={Style.desc}>一口播视频制作工具共享权益一</p>

        <div className={Style.member_wrapper}>
          {memberList.map(member => (
            <div key={member.lable}>
              <img
                className={Style.member_item_img}
                src={member.icon}
                alt={member.lable}
              />
              <p className={Style.member_item_desc}>{member.lable}</p>
            </div>
          ))}
        </div>

        <Button block onClick={viewDetails} className={Style.details_btn}>
          查看详情
        </Button>

        <img
          className={Style.member_image}
          src={assets.images.global['common_icon_shenji.png']}
          alt="logo"
        />

        <img
          onClick={hide}
          className={Style.close_icon}
          src={assets.images.global['vip_icon_quit.png']}
          alt="close"
        />
      </div>
    </Modal>
  );
};

export default MemberUpgradeModal;
