import { Dialog, Input } from '@bhb-frontend/lithe-ui';
import style from './RenameModal.module.less';

interface RenameModalProps {
  visible: boolean;
  value: string;
  onClose: () => void;
  onOk: () => void;
  onChange: (val: string) => void;
}

export default function RenameModal(props: RenameModalProps) {
  const { visible, value, onClose, onOk, onChange } = props;
  return (
    <Dialog
      timeout={0}
      visible={visible}
      isShowTitle={false}
      height={200}
      onCancel={onClose}
      onOk={onOk}
    >
      <h1 className={style.title}>输入名称</h1>
      <Input
        onChange={(_, value) => {
          onChange(value);
        }}
        value={value}
        size="large"
      />
    </Dialog>
  );
}
