/**
 * UrlScheme，与客户端一致，主要在这里根据urlscheme执行应用内部的逻辑
 */

/**
 * 协议
 */
export const Protocol = 'miaotui:';

/**
 * 微信协议
 */
export const WeiXin = 'weixin:';
/**
 * 路径
 */
export const SchemeMap = {
  Member: '/member',
  Goods: '/goods',
};
