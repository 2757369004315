import { Popup } from '@bhb-frontend/lithe-ui';
import { useMemo, useRef } from 'react';
import assets from '@/assets';
import { RobotItem } from '@/types/robot';
import './style.less';

interface RobotItemProps<T> {
  info: T;
  onEdit: (item: T) => void;
  showAuth?: (item: T) => void;
  showManage?: (item: T) => void;
  onSelect?: (item: T) => void;
  onDelete?: (item: T) => void;
  module?: 'live' | 'doc';
}

export default function RobotItemCm<T extends Record<string, any> = RobotItem>(
  props: RobotItemProps<T>
) {
  const {
    info,
    showAuth,
    onEdit,
    showManage,
    onSelect,
    onDelete,
    module = 'doc',
  } = props;

  const moreButRef = useRef<HTMLDivElement>(null);

  const { accessUsers = [] } = info;

  const menuList = useMemo(() => {
    const list = [
      {
        icon: 'icon-a-robot_icon_set2x',
        title: '机器人设置',
        type: 'edit',
      },
    ];

    if (accessUsers.length) {
      list.push({
        icon: 'icon-a-tab_icon_protect_def2x',
        title: '授权管理',
        type: 'manage',
      });
    } else {
      list.push({
        icon: 'icon-a-tab_icon_protect_def2x',
        title: '授权订阅号',
        type: 'auth',
      });
    }

    list.push({
      icon: 'icon-a-document_icon_delete2x',
      title: '删除',
      type: 'delete',
    });

    return list;
  }, [accessUsers]);

  const goDetail = e => {
    if (moreButRef.current?.contains(e.target)) {
      return;
    }
    onSelect?.(info);
  };

  const handleClick = (type: string) => {
    switch (type) {
      case 'edit':
        onEdit(info);
        return;

      case 'auth':
        showAuth?.(info);
        break;

      case 'manage':
        showManage?.(info);
        break;

      default:
        onDelete?.(info);
        break;
    }
  };

  const renderOverlay = (
    <div className="menu">
      {menuList.map(item => (
        <div
          key={item.type}
          className="menu-item"
          onClick={() => handleClick(item.type)}
        >
          <div className="menu-icon">
            <i className={`iconfont ${item.icon}`} />
          </div>
          <div className="menu-title">{item.title}</div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="robot-item" id={`robot-${info.id}`} onClick={goDetail}>
      <div className="info">
        <img
          className="avatar"
          src={assets.images.robot['icon-robot.png']}
          alt=""
        />
        <div className="meta">
          <span className="name">{info.name}</span>
          {module === 'doc' && <p>文档：{info.documentCount || 0}</p>}
          {module === 'live' && <p>直播：{info.count || 0}</p>}
          {accessUsers.length ? <p>授权账号：{accessUsers?.[0]}</p> : null}
        </div>
      </div>

      <Popup
        placement="bottom"
        overlay={renderOverlay}
        trigger="click"
        className="robot-popup"
        container={() =>
          document.querySelector(`#robot-${info.id}`) || document.body
        }
      >
        <div
          className="more-btn"
          // onClick={e => e.stopPropagation()}
          ref={moreButRef}
        >
          <img src={assets.images.robot['icon-more.png']} alt="" />
        </div>
      </Popup>
    </div>
  );
}
