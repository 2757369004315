import request from './init';
import { CustomDigitalConfig } from '@/types/customDigital';
import { CustomListResponse, DetailResponse } from '@/types/api';

/** 获取定制数字人配置 */
export function getCustomDigitalConfig() {
  return request.get<DetailResponse<CustomDigitalConfig>>(
    '/config/custom_figure'
  );
}

/** 获取数字人卡位 */
export function getCustomDigitalCard() {
  return request.get<CustomListResponse<void>>('/custom_figure_record/count');
}
