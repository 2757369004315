/**
 * 视频的合成状态
 */
export enum VIDEO_STATUS {
  /**
   * 生成中
   */
  PENDING = 'pending',
  /**
   * 生成成功
   */
  COMPLETED = 'completed',
  /**
   * 生成失败
   */
  FAIL = 'fail',
  /**
   * 取消
   */
  CANCEL = 'cancel',
  /**
   * 排队中
   */
  QUEUEING = 'queueing',
  /** 审核不通过 */
  REJECT = 'reject',
  /** 审核中 */
  REVIEW = 'review',
  /** 审核失败 */
  REVIEW_FAIL = 'reviewFail',
  /** 申诉中 */
  APPEAL = 'appeal',
  /** 申诉不通过 */
  APPEAL_REJECT = 'appealReject',
}

/* 视频合成状态的映射 */
export const VideoStatusTextMap = {
  [VIDEO_STATUS.FAIL]: '视频生成失败',
  [VIDEO_STATUS.REJECT]: '审核不通过',
  [VIDEO_STATUS.REVIEW_FAIL]: '审核服务异常',
  [VIDEO_STATUS.PENDING]: '视频生成中',
  [VIDEO_STATUS.QUEUEING]: '正在等待生成',
  [VIDEO_STATUS.APPEAL]: '申诉中，正在人工处理请耐心等待',
  [VIDEO_STATUS.APPEAL_REJECT]: '申诉不通过',
};

export enum PlatformEnum {
  YOU_TU_BE = 'youtube',
  DOU_YIN = 'douyin',
  KUAI_SHOU = 'kuaishou',
}

/* 视频发布状态枚举 */
export enum PublishStatusEnum {
  PUBLISHLOADING = 'publishLoading',
  PUBLISHSUCCEED = 'publishSucceed',
  PUBLISHFAILED = 'publishFailed',
  PUBLISHPARTSUCCEED = 'publishPartSucceed',
}

export const SOCKET_EVENTS = {
  /**
   * 抠像结果
   */
  IMAGE_SEGMENT_RESULT: 'imageSegmentResult',
  /**
   * 支付结果
   */
  PAY_RESULT: 'pay.result',
  /**
   * 生成视频成功
   */
  CREATE_VIDEO_SUCCESS: 'createVideoSuccess',
  /**
   * 生成视频失败
   */
  CREATE_VIDEO_FAILED: 'createVideoFailed',
  /**
   * 生成视频状态变更
   */
  VIDEO_STATUS_CHANGE: 'videoStatusChange',
  /**
   * 短视频发布结果
   */
  VIDEO_PUBLISH_RESULT: 'video.publish.result',
  /**
   * 提取视频/音频文字成功消息
   */
  ASR_SUCCESS: 'asrSuccess',
  /**
   * 提取视频/音频文字失败消息
   */
  ASR_FAILED: 'asrFailed',
  LIVE_STATUS_CHANGE: 'live.status.change',
  LIVE_DURATION_TIPS: 'live.duration.tips',
  LIVE_RECORD_UPDATE: 'live.record.update',
  SHORT_VIDEO_MONTAGE_INFO: 'shortVideo.montageInfo',
  /**
   * 文档保存成功
   */
  SAVE_DOCUMENTS_SUCCESS: 'saveDocumentsSuccess',
  /**
   * 保存文档失败
   */
  SAVE_DOCUMENTS_FAILED: 'saveDocumentsFailed',
  PARAMS_ERROR: 'paramsError',
  /**
   * 保存主题成功
   */
  SAVE_DOCUMENTS_THEME_SUCCESS: 'saveDocumentsThemeSuccess',
  /**
   * 保存主题失败
   */
  SAVE_DOCUMENTS_THEME_FAILED: 'saveDocumentsThemeFailed',

  /** 智能配图任务结果 */
  TEXT_MAP_IMAGE_RESULT: 'effectTaskResult',
  /**
   * 编辑器-视频导出成功
   */
  EDITOR: 'editor',
  /**
   * 编辑器-视频导出成功
   */
  EDITOR_RENDER_RESULT: 'editor.render.result',
  /**
   * 编辑器-二次编辑通知
   */
  EDITOR_TWICE_EDITING_CHANGE: 'editor.twice.editing.change',

  /** -----v3.3.0----- */

  /** 照片说话 -- 用户作品生成通知 */
  PIC_SPEAKS_VCR_VIDEO_RESULT: 'picSpeaks.vcr.video.result',
  /** 智能成片 -- 用户作品生成通知 */
  INTELLIGENT_VIDEO_RESULT: 'intelligent.video.result',
  /** 链接成片 -- 用户作品生成通知 */
  LINK_TO_VIDEO_RESULT: 'linkToVideo.video.result',
  /** 视频数字人 -- 用户作品生成通知 */
  FIGURE_LIP_SYNC_VIDEO_RESULT: 'figureLipSync.vcr.video.result',
};

/**
 * 通过 eventbus 触发的全局事件名称
 */
export const GLOBAL_EVENTS = {
  /**
   * 触发镜头保存
   */
  FOOTAGE_SAVE: 'FOOTAGE_SAVE',
  /**
   * 镜头模式剪辑视频确认时，触发镜头保存
   */
  FOOTAGE_SAVE_ON_CLIP: 'FOOTAGE_SAVE_ON_CLIP',
  /**
   * 文档中的视频素材裁剪确认
   */
  DOCUMENT_VIDEO_CLIP_CONFIRM: 'DOCUMENT_VIDEO_CLIP_CONFIRM',
  /**
   * 支付结果通知
   */
  PAY_RESULT: 'pay/result',
  /**
   * 当进度条滚动时
   */
  SCROLL_PROGRESS: 'scroll/progress',
  CLIP_LIVE_PLAY: 'clip/live/play',
  CLIP_LIVE_PAUSE: 'clip/live/pause',
  CLIP_SHOW_BUY_DIALOG: 'clip/showBuyDialog',
  PAUSE_MUSIC: 'pause-music',
  STOP_ALL: 'stop-all',
  STOP_PLAY: 'stop-play',
  /**
   * 用于触发配音或音乐播放
   */
  PLAY_MUSIC: 'play-music',
  /**
   * 停止配音的播放
   */
  PAUSE_DUBBING: 'PAUSE_DUBBING',
  /**
   * 打开标签管理
   */
  OPEN_TAGS_MANAGE: 'openTagsManage',
  PREVIEW_OR_CREATE: 'previewOrCreate',
  /**
   * 资源上传按钮点击
   */
  UPLOAD_RESOURCE_CLICK: 'upload-resource-click',
  /**
   * 发布视频
   */
  PUBLISH_VIDEO: 'publish/video',
  LIVE_SEARCH: 'live/search',
  DOC_SEARCH: 'docSearch',

  /** 素材搜索 */
  SEARCH_PIC: 'search_pic',

  /** 素材复制 */
  MATERIAL_COPY: 'material_copy',
};
