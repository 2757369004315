import { Input } from '@bhb-frontend/lithe-ui';
import { store } from '@bhb-frontend/store';
import { useLocation } from 'react-router-dom';
import Menu from '../Menu';
import { editorMenu } from './menuConfig';
import Style from './Editor.module.less';
import { EDITOR_SEARCH_KEY, EDITOR_SEARCH_NAMESPACE } from '@/constants/Editor';

function EditorLayout() {
  const location = useLocation();

  const content =
    location.pathname === '/editor' ? (
      <span className={Style['input-comp']}>
        <Input
          onPressEnter={event => {
            store.set(
              EDITOR_SEARCH_NAMESPACE,
              EDITOR_SEARCH_KEY,
              (event.target as HTMLInputElement).value
            );
          }}
          placeholder="搜索"
          contentClassName={Style['input-content']}
        />
      </span>
    ) : null;

  return (
    <Menu
      menuConfig={editorMenu}
      isHidden
      module="editor"
      title="视频编辑"
      searchBox={content}
      isHiddenMember
      isShowBeiAn={false}
    />
  );
}

export default EditorLayout;
