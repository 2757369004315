/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import jsxCustomEvent from '@micro-zoe/micro-app/polyfill/jsx-custom-event';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Login from '@bhb-frontend/mtv-login/lib/login';
import { APP_INFO, APP_NAME, getAppInfo } from '@/constants/Apps';
import { configStore, customDigitalStore, userStore } from '@/store';
import { EVENT_NAME } from '@/constants/event-name';
import { notEmptyObj } from '@/utils/notEmptyObj';
import { showLoginAndRemoveToken } from '@/utils/login';

const url = getAppInfo(APP_NAME.createCustomDigital).host;

function CreateCustomDigital() {
  const { config } = configStore;

  const navigate = useNavigate();

  const handleDataChange = async (e: CustomEvent) => {
    const data = e.detail.data;
    if (data.type === EVENT_NAME.BACK) {
      navigate('/child-custom-digital/list');
    }
  };

  const isLogin = () => {
    if (!userStore.isLogin()) {
      Login.show?.();
      return false;
    }
    return true;
  };

  return (
    <div>
      {notEmptyObj(config) && notEmptyObj(customDigitalStore.config) && (
        <micro-app
          name={APP_INFO.CREATE_CUSTOM_DIGITAL.name}
          url={url}
          data={{
            isLogin,
            getUser: () => userStore.getUser(),
            config,
            customDigitalconfig: customDigitalStore.config,
            showLoginAndRemoveToken,
          }}
          onDataChange={handleDataChange}
        />
      )}
    </div>
  );
}

export default observer(CreateCustomDigital);
