import React, { useState } from 'react';
import { Dialog, Toast, Spin } from '@bhb-frontend/lithe-ui';
import classNames from 'classnames';
import * as LEGO from '@bhb-frontend/lego-types/lib';
import { postEditorProjectsAPI } from '@/api/editorProjects';
import {
  EditorProjectsApiModal as EApimodal,
  ProjectName,
} from '@/types/editorProjectsApi';
import Styles from './index.module.less';

interface TipProps {
  visible: boolean;
  modalActionFN: (data: boolean) => void;
  onConfirmFN: (data: EApimodal.DgetEditorProjectsAPI) => void;
  width?: number;
}

export default function TipDialog(props: TipProps) {
  const { visible, modalActionFN, onConfirmFN, width = 664 } = props;

  /**
   * lego 编辑器地址
   */
  const legoURL = import.meta.env.VITE_APP_LEGO_URL;

  /**
   * 可以选择项目的种类
   */
  const projectList = [
    {
      name: ProjectName.ULTRA,
      style: {
        width: 132,
        height: 74,
      },
      scale: {
        width: 1280,
        height: 720,
      },
    },
    {
      name: ProjectName.HIGH_DEFINITION,
      style: {
        width: 74,
        height: 132,
      },
      scale: {
        width: 720,
        height: 1280,
      },
    },
    {
      name: ProjectName.LOW,
      style: {
        width: 99,
        height: 132,
      },
      scale: {
        width: 960,
        height: 1280,
      },
    },
    {
      name: ProjectName.REGULAR,
      style: {
        width: 132,
        height: 99,
      },
      scale: {
        width: 1280,
        height: 960,
      },
    },
    {
      name: ProjectName.SQUARE,
      style: {
        width: 132,
        height: 132,
      },
      scale: {
        width: 720,
        height: 720,
      },
    },
  ];

  /**
   * 选中的项目
   */
  const [project, setProjectFN] = useState(projectList[0]);

  /**
   * 创建loading
   */
  const [btnLoading, setBtnLoadingFN] = useState(false);

  /**
   * 初始化项目数据
   */
  const transformDataFN = () => {
    const { width, height } = project.scale;
    const duration = 5000;
    const configure: LEGO.MovieConfigureParams = {
      width,
      height,
      frameRate: 30,
      definition: 720,
      version: [5, 0, 0],
      aspect: {
        num: Number(project.name.split(':')[0]),
        den: Number(project.name.split(':')[1]),
      },
    };
    const footage: LEGO.MovieFootageParams = {
      comps: [
        {
          id: 'comp-main',
          type: LEGO.FootageType.Composition,
          direction: 1,
          width,
          height,
          duration,
          frameRate: 30,
          transitions: [],
          layers: [],
          extra: {},
        },
        {
          id: 'comp-audio',
          type: LEGO.FootageType.Composition,
          direction: 0,
          width,
          height,
          duration,
          frameRate: 30,
          transitions: [],
          layers: [],
          extra: {},
        },
      ],
      images: [],
      videos: [],
      audios: [],
      texts: [],
      styles: [],
      colors: [],
      animations: [],
      transitions: [],
    };

    const composition: LEGO.MovieCompositionParam = {
      name: '输出',
      direction: 0,
      width,
      height,
      layers: [
        {
          id: 'layer-comp-main',
          sourceId: 'comp-main',
          tag: 'main',
          showRange: {
            start: 0,
            end: duration,
          },
          width,
          height,
          name: '',
          layerIndex: 1,
          clipRange: {
            start: 0,
            end: 0,
          },
          anchor: {
            x: 0,
            y: 0,
          },
          x: 0,
          y: 0,
          extra: {},
        },
        {
          id: 'layer-comp-audio',
          sourceId: 'comp-audio',
          tag: 'audio',
          showRange: {
            start: 0,
            end: 5000,
          },
          width,
          height,
          name: '',
          layerIndex: 0,
          clipRange: {
            start: 0,
            end: 0,
          },
          anchor: {
            x: 0,
            y: 0,
          },
          x: 0,
          y: 0,
          extra: {},
        },
      ],
      duration,
    };

    const data: LEGO.MovieClipData = {
      configure,
      footage,
      composition,
      extras: {},
    };
    return JSON.stringify(data);
  };

  /**
   * 创建项目
   */
  const createProjectFN = () => {
    setBtnLoadingFN(true);
    postEditorProjectsAPI({
      name: '未命名项目',
      size: project.name,
      basicInfo: transformDataFN(),
    })
      .then(({ data }) => {
        Toast.success('创建成功');
        onConfirmFN(data);
        modalActionFN(false);
        setTimeout(() => {
          window.open(`${legoURL}/${data.id}`, '_blank');
        }, 1000);
      })
      .catch(() => {
        Toast.error('创建失败');
      })
      .finally(() => {
        setBtnLoadingFN(false);
      });
  };

  return (
    <Dialog
      width={width}
      height="auto"
      visible={visible}
      title=""
      footer={null}
      onCancel={() => {
        modalActionFN(false);
      }}
      placementOffset={100}
      contentClassName={Styles['add-project-dialog']}
    >
      <Spin spinning={btnLoading} className={Styles['btn-loading']}>
        <div className={Styles.title}>选择画布尺寸</div>
        <div className={Styles.content}>
          {projectList.map(item => (
            <div
              key={item.name}
              className={classNames(
                Styles['project-item'],
                project.name === item.name ? Styles['project-item-active'] : ''
              )}
              onClick={() => {
                setProjectFN(item);
              }}
              style={item.style}
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className={Styles.btns}>
          <div
            className={classNames(Styles.cancel, Styles.btn)}
            onClick={() => {
              modalActionFN(false);
            }}
          >
            取消
          </div>
          <div
            className={classNames(Styles.ok, Styles.btn)}
            onClick={createProjectFN}
          >
            创建
          </div>
        </div>
      </Spin>
    </Dialog>
  );
}
